import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { FormsService } from './forms.service';
import { HttpClient } from '@angular/common/http';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { Subject } from 'rxjs';
import { debounce, debounceTime } from 'rxjs/operators';

export interface IsWatchHour {
  id: boolean;
  viewValue: boolean;
}

export interface IsVougeDetail {
  id: boolean;
  viewValue: boolean;
}


@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss']
})
export class FormsComponent implements OnInit {
  private filterSubject = new Subject<string>();
  displayedColumns: string[] = ['id', 'formName', 'formImage', 'mobileImage', 'hName','sectionName', 'edit'];
  dataSource

  isLoading = true;
  myForm: FormGroup;
  afs: any;
  success: boolean;
  loading: boolean;
  data: any = [];
  // id : any;
  product: any;
  public formData;
  formsData;
  hierarchyType: any = []
  // formType:any=[];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild('deleteSwal', { static: false }) public deleteSwal: SwalComponent;
  @ViewChild('successbox', { static: true }) public successbox: SwalComponent;

  @ViewChild('errorbox', { static: true }) public errorbox: SwalComponent;

  iswatchhour: IsWatchHour[] = [
    { id: true, viewValue: true },
    { id: false, viewValue: false }
  ]

  isvougedetail: IsVougeDetail[] = [
    { id: true, viewValue: true },
    { id: false, viewValue: false }
  ]

  constructor(public fb: FormBuilder, public formsService: FormsService,
    public http: HttpClient) {
    this.resetForm();
    this.filterSubject.pipe(debounceTime(300)).subscribe(value => {
      this.applyFilter(value);
    })
  }

  ngOnInit() {

    // of(this.loading).pipe(delay(1000))
    //  .subscribe(data => {
    //    this.isLoading = false;
    //    this.formData = data
    //  }, error => this.isLoading = false);
    // this.getProductDetails(this.route.snapshot.params['id']);

    this.formsService.getData((response) => {
      console.log(response);
      this.isLoading = false;
      this.dataSource = new MatTableDataSource(response.data);
      this.formsData = response.data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    })

    this.formsService.getHierarchyData((response) => {
      console.log(response);
      this.hierarchyType = response.data;

    })

    this.myForm = this.fb.group({
      'formName': new FormControl(null, [Validators.required]),
      'mobileImage': new FormControl(null),
      'formImage': new FormControl(null),
      'hierarchyId':new FormControl(null, [Validators.required])


    })
  }

  doFilter(value: string): void {
    this.filterSubject.next(value.trim().toLowerCase());
  }

  applyFilter(value: string): void {
    this.dataSource.filter = value || '';
  }

  uploadImage(event) {

    const fileInfo = document.querySelector('.file-info');
    const name = event.target.value.split(/\\|\//).pop();
    const truncated = name.length > 20
      ? name.substr(name.length - 20)
      : name;

    fileInfo.innerHTML = truncated;

    if (event.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      console.log(event.target.files);

      this.formsService.uploadImage(formData, (response) => {
        // console.log(response);
        if (response.responsecode == 200) {
          console.log(response);
          this.formsService.formData.formImage = response.fileurl;

          this.myForm.get('formImage').setValue(response.fileurl);
        }
      })
      // console.log(this.service.uploadDocumentsDriver);
    }
  }

  uploadMobileImage(event) {

    const fileInfo = document.querySelector('.file-info1');
    const name = event.target.value.split(/\\|\//).pop();
    const truncated = name.length > 20
      ? name.substr(name.length - 20)
      : name;

    fileInfo.innerHTML = truncated;

    if (event.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      console.log(event.target.files);

      this.formsService.uploadImage(formData, (response) => {
        // console.log(response);
        if (response.responsecode == 200) {
          console.log(response);

          this.formsService.formData.mobileImage = response.fileurl;
          this.myForm.get('mobileImage').setValue(response.fileurl);


        }
      })
      // console.log(this.service.uploadDocumentsDriver);
    }
  }

  submitHandler() {
    this.loading = true;
    console.log(this.myForm);
    if (this.myForm.valid) {
      console.log(this.myForm.value);
      if (this.formsService.formData.id) {
        this.formsService.putData(this.formsService.formData.id, this.formsService.formData, (data) => {
          console.log(data);
          if (data.responsecode === 200 || data.responsecode === '200') {
            this.formsService.getData((response) => {
              console.log(response);
              this.dataSource = new MatTableDataSource(response.data);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.loading = false;
              this.successbox.show();
              this.resetForm();
              this.myForm.reset();
            })
          } else {
            this.loading = false;
            this.errorbox.text = data.message;
            this.errorbox.show();
          }
        })
      }
      else {

        this.formsService.postData(this.formsService.formData, (response) => {
          if (response.responsecode === 200 || response.responsecode === '200') {
            this.formsService.getData((response) => {
              console.log(response);
              this.dataSource = new MatTableDataSource(response.data);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.loading = false;
              this.successbox.show();
              this.resetForm();
              this.myForm.reset();
            })
            //  console.log(response);
          } else {
            this.loading = false;
            this.errorbox.text = response.message;
            this.errorbox.show();
          }
        })
      }

    }
  }

  // getProductDetails(id) {
  //   this.fieldTypeService.getDataById(id,(response)=>{
  //     console.log(response);
  //     this.product = response;
  //   })
  // }

  onClick(action, data) {
    if (action == 'edit') {
      this.formsService.formData.id = data.id;
      this.formsService.formData.formName = data.formName;
      this.formsService.formData.hierarchyId = data.hierarchyId;

      if (data.formImage) {
        this.formsService.formData.formImage = data.formImage;

      } else {
        this.formsService.formData.formImage = 'assets/media/logo/logo.png';

      }

      if (data.mobileImage) {
        this.formsService.formData.mobileImage = data.mobileImage;

      } else {
        this.formsService.formData.mobileImage = 'assets/media/logo/logo.png';

      }

      this.myForm.get('formImage').setValue(this.formsService.formData.formImage);
      this.myForm.get('mobileImage').setValue(this.formsService.formData.mobileImage);
    } else {
      this.formsService.deleteData(data.id).subscribe((data) => {
        console.log('Deleted');
        this.formsService.getData((response) => {
          console.log(response);
          this.dataSource = new MatTableDataSource(response.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;

        })
      })
    }
    window.scroll(0,0);

  }

  resetForm() {
    // this.myForm.reset();
    this.formsService.formData.id = "";
    this.formsService.formData.formName = "";
    this.formsService.formData.hierarchyId = "";

    this.formsService.formData.formImage = 'assets/media/logo/logo.png';
    this.formsService.formData.mobileImage = 'assets/media/logo/logo.png';

    if (document.querySelector('.file-info')) {
      const fileInfo = document.querySelector('.file-info');
      fileInfo.innerHTML = "";
    }

    if (document.querySelector('.file-info1')) {
      const fileInfo1 = document.querySelector('.file-info1');
      fileInfo1.innerHTML = "";
    }

  }

}
