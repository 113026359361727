import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from '../apiroot.service';

@Injectable({
  providedIn: 'root'
})
export class ChecklistService {

formTypeData = {id:'' , checklistName:'', checklistCategoryId:'', revisionDate:'', authorityIds:'', isNotify:'', checklistNotification:'', reportHeader:'', reportHeader1:'', reportHeader2:''}
  
constructor(public httpClient : HttpClient,public service:ApiRootService) { }

getAllCat(callback){
    return this.httpClient.get(this.service.getBaseUrl()+'/getAllChecklistCategory').subscribe(
      (data) =>{
          console.log(data);
        callback(data);
      }
    )
  } 

  getData(callback){
      console.log("--list process--");
    return this.httpClient.get(this.service.getBaseUrl()+'/getAllChecklistDetails').subscribe(
      (data) =>{
      console.log("--list done--");
        console.log(data);
        callback(data);
      }
    )
  } 
  

  getDataById(id,callback){
    return this.httpClient.get(this.service.getBaseUrl()+'/getChecklistDetailsByChecklistCategoryId/'+id).subscribe(
      (data) =>{
        // console.log(data);
        callback(data);
      }
    )
  }
  
  getChecklistAdditionalInfoByChecklistId(id,callback){
    return this.httpClient.get(this.service.getBaseUrl()+'/getChecklistAdditionalInfoByChecklistId/'+id).subscribe(
      (data) =>{
        console.log(data);
        callback(data);
      }
    )}
    
    saveChecklistAdditionalInfo(FormData,callback){
    return this.httpClient.post(this.service.getBaseUrl()+'/saveChecklistAdditionalInfoList',FormData).subscribe(
      (data) =>{
        console.log(data);
        callback(data);
      }
    )}
    
  postData(formData,callback){
      console.log('---save data--');
      console.log(formData);
    return this.httpClient.post(this.service.getBaseUrl()+'/saveChecklistDetails',formData).subscribe((data)=>{
      console.log(data);
      console.log('---save data done--');
    callback(data);
    })
  }

  putData(id,formData,callback){
      console.log('---update data--');
      console.log(formData);
    return this.httpClient.put(this.service.getBaseUrl()+'/updateChecklistDetails/'+id,formData).subscribe((data)=>{
      console.log('---update data done--');
      //console.log(data);
      callback(data);

    })
  }

  deleteData(id){
     return this.httpClient.delete(this.service.getBaseUrl()+'/softDeleteChecklistDetailsByChecklistDetailsId/'+id);   
  }
  
  getAllRole(callback){
    return this.httpClient.get(this.service.getBaseUrl()+'/getAllRoleMaster').subscribe(
      (data) =>{
        callback(data);
      }
    )}
}
