import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator, MatDialogRef } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl,ReactiveFormsModule,FormArray } from '@angular/forms';
import { ReleasenoteService } from './releasenote.service';
import { HttpClient } from '@angular/common/http';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { SessionStorageService } from 'angular-web-storage';
import { ActivatedRoute, RouterModule, Routes,Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-releasenote',
  templateUrl: './releasenote.component.html',
  styleUrls: ['./releasenote.component.scss']
})
export class ReleasenoteComponent implements OnInit {

  releaseNote:any = [];

    @ViewChild('deleteSwal', { static: false }) public deleteSwal: SwalComponent;
  @ViewChild('successbox', { static: true }) public successbox: SwalComponent;

  @ViewChild('errorbox', { static: true }) public errorbox: SwalComponent;

  constructor( public dialogRef: MatDialogRef<ReleasenoteComponent>, private router:Router, public releasenoteService: ReleasenoteService,private formBuilder: FormBuilder, public session: SessionStorageService) {

  }





  ngOnInit() {
      this.releasenoteService.getVersionDetails((response) => {
            console.log("===getVersionDetails===",response.data);
            this.releaseNote = response.data
           // console.log("===this.releaseNote ===",this.releaseNote.webReleaseNote);
       })

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
