import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { ORBCodeService } from './orbcode.service';
import { HttpClient } from '@angular/common/http';
import { of, Subject } from 'rxjs';
import { delay, debounceTime, filter } from 'rxjs/operators';

@Component({
  selector: 'app-orbcode',
  templateUrl: './orbcode.component.html',
  styleUrls: ['./orbcode.component.scss']
})
export class ORBCodeComponent implements OnInit {
  private filterSubject = new Subject<string>();
  displayedColumns: string[] = ['id', 'code', 'description', 'edit'];
  dataSource = new MatTableDataSource()

  myForm: FormGroup;

  isLoading = true;
  loading: boolean
  data: any = [];
  formTypeId: any;
  product: any;
  public formData;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild('deleteSwal', { static: false }) public deleteSwal: SwalComponent;
  @ViewChild('successbox', { static: true }) public successbox: SwalComponent;

  @ViewChild('errorbox', { static: true }) public errorbox: SwalComponent;

  success;
  constructor(public fb: FormBuilder, public orbCodeService: ORBCodeService,
    public http: HttpClient) {
    this.resetForm();
    this.filterSubject.pipe(debounceTime(300)).subscribe(value => {
      this.applyFilter(value)
    })
  }

  ngOnInit() {

    of(this.dataSource).pipe(delay(1000))
      .subscribe(data => {
        this.isLoading = false;
        this.data = data;
      }, error => this.isLoading = false)
    // this.getProductDetails(this.route.snapshot.params['id']);

    this.orbCodeService.getData((response) => {
      console.log(response);
      this.dataSource = new MatTableDataSource(response.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })

    this.myForm = new FormGroup({
      'code': new FormControl(null, [Validators.required]),
      'description': new FormControl(null, [Validators.required])
    });


  }

  doFilter(value: string): void {
    this.filterSubject.next(value.trim().toLowerCase())
  }

  applyFilter(value: string): void {
    this.dataSource.filter = value || '';
  }

  submitHandler() {

    this.loading = true;

    if (this.myForm.valid) {
      console.log(this.myForm.value);

      if (this.orbCodeService.formTypeData.id) {
        this.orbCodeService.putData(this.orbCodeService.formTypeData.id, this.orbCodeService.formTypeData, (data) => {
          console.log(data);

          this.loading = false;

          if (data.responsecode === 200 || data.responsecode === '200') {

            this.orbCodeService.getData((response) => {
              console.log(response);
              this.dataSource = new MatTableDataSource(response.data);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.successbox.show();

              this.resetForm();
              this.myForm.reset();
            })
          } else {
            this.errorbox.text = data.message;
            this.errorbox.show();
          }
        })
      }
      else {
        this.orbCodeService.postData(this.orbCodeService.formTypeData, (response) => {
          console.log(response);

          this.loading = false;

          if (response.responsecode === 200 || response.responsecode === '200') {


            this.orbCodeService.getData((response) => {
              console.log(response);
              this.dataSource = new MatTableDataSource(response.data);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.successbox.show();

              this.resetForm();
              this.myForm.reset();
            })
          } else {
            this.errorbox.text = response.message;
            this.errorbox.show();
          }
        })
      }
    }
  }

  // getProductDetails(id) {
  //   this.orbCodeService.getDataById(id, (response) => {
  //     console.log(response);
  //     this.product = response;
  //   })
  // }



  onClick(action, data) {
    if (action == 'edit') {
      this.orbCodeService.formTypeData.id = data.id;
      this.orbCodeService.formTypeData.code = data.code;
      this.orbCodeService.formTypeData.description = data.description;
    } else {
      this.orbCodeService.deleteData(data.id).subscribe((data) => {
        console.log('Deleted');
        this.orbCodeService.getData((response) => {
          console.log(response);
          this.dataSource = new MatTableDataSource(response.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        })
      })
    }
    window.scroll(0, 0);

  }

  resetForm() {
    this.orbCodeService.formTypeData.code = "";
    this.orbCodeService.formTypeData.description = "";
    // this.vts.formTypeData.vesselTypeId = "";
    // this.myForm.reset();
  }


}
