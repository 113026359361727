import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CompanyService } from './company.service';
import { HttpClient } from '@angular/common/http';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {

  loading: boolean;
  isLoading: boolean;

  tooltipMessage(): string {
    if (this.myForm.invalid) {
      return "Please fill out all required fields correctly";
    }
    return "";
  }



  // displayedColumns: string[] = ['id', 'companyName', 'email', 'phoneNumber', 'street','city','state','country','zipCode', 'edit', 'delete'];
  displayedColumns: string[] = ['id', 'companyName', 'email', 'phoneNumber', 'street','city','state','country','zipCode', 'edit'];

  dataSource

  myForm: FormGroup;
  afs: any;
  success: boolean;
  // loading: boolean;
  data: any = [];
  fieldTypeId: any;
  product: any;
  public formData;
  orgName = [];
  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('deleteSwal', { static: false }) public deleteSwal: SwalComponent;
  @ViewChild('successbox', { static: true }) public successbox: SwalComponent;

  @ViewChild('errorbox', { static: true }) public errorbox: SwalComponent;

  constructor(public fb: FormBuilder, public companyService: CompanyService,
    private http: HttpClient) {
    this.resetForm();
    this.loading = false;
  }

  ngOnInit() {
    this.isLoading = true;
    // this.getProductDetails(this.route.snapshot.params['id']);

    this.companyService.getData((response) => {
      console.log(response);
      this.dataSource = new MatTableDataSource(response.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.isLoading = false;
    })

    this.companyService.getOrgData((response) => {
      console.log(response);
      this.orgName = response.data;
      // this.dataSource.paginator = this.paginator;
      // this.dataSource.sort = this.sort;
    })


    

    this.myForm = this.fb.group({
      companyName: new FormControl(null, [Validators.required, Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9.,\'&\\s!@#$%^*()_+\\-=\\[\\]{};:\\\'",.<>?]*$'),
      , Validators.maxLength(30)]),
      email: new FormControl(null, [Validators.required, Validators.email, Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')]),
      phoneNumber: new FormControl(null, [Validators.required,Validators.pattern('^[0-9]*$'), Validators.maxLength(20)]),
      street: new FormControl(null, Validators.required),
      city: new FormControl(null, [Validators.required, Validators.maxLength(25)]),
      state: new FormControl(null, [Validators.required, Validators.maxLength(20)]),
      country: new FormControl(null, [Validators.required, Validators.maxLength(25)]),
      zipCode: new FormControl(null, [Validators.required, Validators.pattern('^[0-9]{5,6}$'), Validators.maxLength(6)]),
      profileImage: new FormControl(null)
    })
  }



  submitHandler() {

    if (this.myForm.valid) {
      this.loading = true; 
      console.log(this.myForm.value);
      if (this.companyService.formData.id) {
        this.companyService.putData(this.companyService.formData.id, this.companyService.formData, (data) => {
          console.log(data);

          if (data.responsecode === 200 || data.responsecode === '200') {

          this.companyService.getData((response) => {
            console.log(response);
            this.dataSource = new MatTableDataSource(response.data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.successbox.show();
            this.resetForm();
            this.myForm.reset();
            this.loading = false
            })
          } else {
            this.errorbox.text = data.message;
            this.errorbox.show();
            this.loading = false; 
          }
        })
      } else {
        this.companyService.postData(this.companyService.formData, (response) => {
          console.log(response);

          if (response.responsecode === 200 || response.responsecode === '200') {

          this.companyService.getData((response) => {
            console.log(response);
            this.dataSource = new MatTableDataSource(response.data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.successbox.show();

            this.resetForm();
            this.loading = false; 
          })
        } else {
          this.errorbox.text = response.message;
          this.errorbox.show();
          this.loading = false; 
        }
        })

      }
    }
  }

  onClick(action, data) {
    if (action == 'edit') {
      this.companyService.formData.companyName = data.companyName;
      this.companyService.formData.id = data.id;
      this.companyService.formData.phoneNumber = data.phoneNumber;
      this.companyService.formData.email = data.email;
      this.companyService.formData.street = data.street;
      this.companyService.formData.city = data.city;
      this.companyService.formData.state = data.state;
      this.companyService.formData.country = data.country;
      this.companyService.formData.zipCode = data.zipCode;
      this.companyService.formData.profileImage = data.profileImage;


    } else {
      this.companyService.deleteData(data.id).subscribe((data) => {
        console.log('Deleted');
        this.companyService.getData((response) => {
          console.log(response);
          this.dataSource = new MatTableDataSource(response.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        })
      })
    }
    window.scroll(0, 0);
  }


    // onclick(action:string, data:any) {
  //   if(action === 'edit') {
  //     // Handle edit action here 
  //     this.companyService.formData.companyName = data.companyName;
  //     this.companyService.formData.id = data.id;
  //     this.companyService.formData.phoneNumber = data.phoneNumber;
  //     this.companyService.formData.email = data.email;
  //     this.companyService.formData.street = data.street;
  //     this.companyService.formData.city = data.city;
  //     this.companyService.formData.state = data.state;
  //     this.companyService.formData.country = data.country;
  //     this.companyService.formData.zipCode = data.zipCode;
  //     this.companyService.formData.profileImage = data.profileImage;
  //   } else if (action === 'delete'){
  //     // Handle data for delete 
  //     this.companyService.deleteData(data.id).subscribe((response) => {
  //       console.log('Deleted');
  //       this.companyService.getData((responseData) => {
  //         this.dataSource.getData((responseData) => {
  //           this.dataSource = new MatTableDataSource(responseData.data);
  //           this.dataSource.paginator = this.paginator;
  //           this.dataSource.sort = this.sort;
  //         })
  //       })
  //     })
  //   }
  // }




  resetForm() {
      this.companyService.formData.companyName = "";
      this.companyService.formData.id = "";
      this.companyService.formData.phoneNumber = "";
      this.companyService.formData.email = "";
      this.companyService.formData.street = "";
      this.companyService.formData.city = "";
      this.companyService.formData.state = "";
      this.companyService.formData.country = "";
      this.companyService.formData.zipCode = "";
      this.companyService.formData.profileImage = "";

  }

  uploadCompanyImage(event) {

    const fileInfo = document.querySelector('.file-info');
    const name = event.target.value.split(/\\|\//).pop();
    const truncated = name.length > 20
      ? name.substr(name.length - 20)
      : name;

    fileInfo.innerHTML = truncated;

    if (event.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      console.log(event.target.files);

      this.companyService.uploadImage(formData, (response) => {
        // console.log(response);
        if (response.responsecode == 200) {
          console.log(response);

          this.companyService.formData.profileImage = response.fileurl;
          this.myForm.get('profileImage').setValue(response.fileurl);


        }
      })
      // console.log(this.service.uploadDocumentsDriver);
    }
  }
  fileChangeEvent(fileInput: any) {
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
        // Size Filter Bytes
        const max_size = 20971520;
        const allowed_types = ['image/png', 'image/jpeg'];
        const max_height = 15200;
        const max_width = 25600;

        if (fileInput.target.files[0].size > max_size) {
            this.imageError =
                'Maximum size allowed is ' + max_size / 1000 + 'Mb';

            return false;
        }

//            if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
//                this.imageError = 'Only Images are allowed ( JPG | PNG )';
//                return false;
//            }
        const reader = new FileReader(); 
        reader.onload = (e: any) => {
            const image = new Image();
            image.src = e.target.result;
            image.onload = rs => {
                const img_height = rs.currentTarget['height'];
                const img_width = rs.currentTarget['width'];

                console.log(img_height, img_width);


                if (img_height > max_height && img_width > max_width) {
                    this.imageError =
                        'Maximum dimentions allowed ' +
                        max_height +
                        '*' +
                        max_width +
                        'px';
                    return false;
                } else {
                    const imgBase64Path = e.target.result;
                    this.cardImageBase64 = imgBase64Path;
                    this.companyService.formData.profileImage = imgBase64Path;
                    this.isImageSaved = true;
                    // this.previewImagePath = imgBase64Path;
                }
            };
        };

        reader.readAsDataURL(fileInput.target.files[0]);
    }
}

}
