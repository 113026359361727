import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { WorkflowService } from './workflow.service';
import { HttpClient } from '@angular/common/http';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.scss']
})
export class WorkflowComponent implements OnInit {

  displayedColumns: string[] = ['workFlowId', 'workFlowName', 'description', 'edit'];
  dataSource = new MatTableDataSource()

  myForm: FormGroup;

  isLoading = true;
  data: any = [];
  formTypeId: any;
  product: any;
  public formData;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('deleteSwal', { static: false }) public deleteSwal: SwalComponent;
  @ViewChild('successbox', { static: true }) public successbox: SwalComponent;

  @ViewChild('errorbox', { static: true }) public errorbox: SwalComponent;

success;
  constructor(public fb: FormBuilder, public wfs: WorkflowService,
    public http: HttpClient) {
      this.resetForm();


  }

  ngOnInit() {

    of(this.formData).pipe(delay(1000))
      .subscribe( data => {
        this.isLoading = false;
        this.data = data;
      }, error => this.isLoading = false)
    // this.getProductDetails(this.route.snapshot.params['id']);

    this.wfs.getData((response) => {
      console.log(response);
      this.dataSource = new MatTableDataSource(response.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })

    this.myForm = this.fb.group({
      
      'workFlowName': new FormControl(null, [
        Validators.required,
        Validators.minLength(4),
        Validators.pattern('^[a-zA-Z ]*$')
      ]),
      'description': new FormControl(null, [
        Validators.required,
        Validators.minLength(4)
      ])

    })
  }

  submitHandler() {

    if (this.myForm.valid) {
      console.log(this.myForm.value);

      if (this.wfs.formTypeData.workFlowId) {
        this.wfs.putData(this.wfs.formTypeData.workFlowId, this.wfs.formTypeData, (data) => {
          console.log(data);
          if (data.responsecode === 200 || data.responsecode === '200') {

          this.wfs.getData((response) => {
            console.log(response);
            this.dataSource = new MatTableDataSource(response.data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.successbox.show();

              this.resetForm();
            })
          } else {
            this.errorbox.text = data.message;
            this.errorbox.show();
          }
        })
      }
      else {
        this.wfs.postData(this.wfs.formTypeData, (response) => {
          console.log(response);
          if (response.responsecode === 200 || response.responsecode === '200') {

          this.wfs.getData((response) => {
            console.log(response);
            this.dataSource = new MatTableDataSource(response.data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.successbox.show();

            this.resetForm();
          })
        } else {
          this.errorbox.text = response.message;
          this.errorbox.show();
        }
        })
      }
    }
  }

  getProductDetails(id) {
    this.wfs.getDataById(id, (response) => {
      console.log(response);
      this.product = response;
    })
  }



  onClick(action, data) {
    if (action == 'edit') {
      this.wfs.formTypeData.workFlowName = data.workFlowName;
      this.wfs.formTypeData.description = data.description;
      this.wfs.formTypeData.workFlowId = data.workFlowId;
    } else {
      this.wfs.deleteData(data.workFlowId).subscribe((data) => {
        console.log('Deleted');
        this.wfs.getData((response) => {
          console.log(response);
          this.dataSource = new MatTableDataSource(response.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        })
      })
    }
    window.scroll(0,0);

  }

  resetForm(){
    this.wfs.formTypeData.workFlowName = "";
    this.wfs.formTypeData.description = "";
    this.wfs.formTypeData.workFlowId = "";
    // this.myForm.reset();
  }

}
