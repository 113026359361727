import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray, ValidatorFn, NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { VesselsService } from '../vessels/vessels.service';
import { VesseltypeService } from '../vesseltype/vesseltype.service';
import { EospService } from './eosp.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { NgxCarousel } from 'ngx-carousel';
import { MatRadioModule } from '@angular/material/radio';
import { SessionStorageService } from 'angular-web-storage';
@Component({
  selector: 'app-cosp',
  templateUrl: './eosp.component.html',
  styleUrls: ['./eosp.component.scss']
})


export class EospComponent implements OnInit {
  displayedColumnsWB: string[] = ['SNo', 'name', 'position', 'capacity', 'weight', 'isActive', 'edit'];
  displayedColumnsFW: string[] = ['SNo', 'name', 'position', 'capacity', 'weight', 'isActive', 'edit'];
  displayedColumnsFO: string[] = ['SNo', 'name', 'position', 'capacity', 'weight', 'isActive', 'edit'];
  displayedColumnsLO: string[] = ['SNo', 'name', 'position', 'capacity', 'weight', 'isActive', 'edit'];
  displayedColumnsOT: string[] = ['SNo', 'name', 'position', 'capacity', 'weight', 'isActive', 'edit'];

  dataSourceWB = new MatTableDataSource();
  dataSourceFW = new MatTableDataSource();
  dataSourceFO
  dataSourceLO
  dataSourceOT
  ischecked: any = true;

  isLoading = false;
  myForm: FormGroup;
  formWB: FormGroup;
  formFW: FormGroup;
  formFO: FormGroup;
  formLO: FormGroup;
  formOT: FormGroup;
  smtpForm: FormGroup;
  saveSuccessMessage: string = '';

  cargoArrangementForm: FormGroup;
  rows: FormArray;
  itemForm: any = [];

  hatchArrangementForm: FormGroup;
  hatchRows: FormArray;
  afs: any;
  success: boolean;
  loading: boolean;
  data: any = [];
  private fieldArray: Array<any> = [];
  private newAttribute: any = {};

  showEdit: any = -1;
  checked: boolean = true;
  vesselType = [];

  //
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild(MatPaginator, { static: true }) paginatorFW: MatPaginator;
  @ViewChild(MatSort, { static: true }) sortFW: MatSort;


  @ViewChild('deleteSwal', { static: false }) public deleteSwal: SwalComponent;
  @ViewChild('successbox', { static: true }) public successbox: SwalComponent;
  @ViewChild('errorbox', { static: true }) public errorbox: SwalComponent;
  @ViewChild('updatebox', { static: true }) public updatebox: SwalComponent;
  constructor(public vts: VesseltypeService, public es: EospService, public vs: VesselsService, public fb: FormBuilder, public http: HttpClient, public session: SessionStorageService) {
    this.cargoArrangementForm = this.fb.group({
      unit: [null, Validators.required]
    });

    this.hatchArrangementForm = this.fb.group({
      unit: [null],
      makeOfHatches: [this.es.formTypeData.makeOfHatches],
      typeOfHatchCover: [this.es.formTypeData.typeOfHatchCover]
    });

    this.rows = this.fb.array([]);
    this.hatchRows = this.fb.array([]);

  }

  ngOnInit() {
    console.log("this is SMtp Config");
    this.getSmtpSetting();
    this.data = this.session.get('vesselData');
    console.log("vesseldata");
    console.log(this.data);


    this.vts.getData((response) => {
      console.log(response);
      this.vesselType = response.data;

    })

    if (this.data.id) {
      this.es.getCargoArrangementData(this.data.id, (response) => {
        console.log(response.data);
        if (response.data) {
          this.addDataRowArrangement(response.data.length, response.data);
        } else {
          this.onAddRowArrangement(1);
        }
      })

      this.es.getCargoDeckAndHatchData(this.data.id, (response) => {
        console.log(response.data);
        if (response.data) {
          this.es.formTypeData.makeOfHatches = response.data[0].makeOfHatches;
          this.es.formTypeData.typeOfHatchCover = response.data[0].typeOfHatchCover;
          this.addDataRowDackAndHatch(response.data.length, response.data)
        }
        else {
          this.onAddRowDackAndHatch(1);
        }
      })

    }


    this.cargoArrangementForm.addControl('rows', this.rows);
    this.hatchArrangementForm.addControl('hatchRows', this.hatchRows);

    this.myForm = this.fb.group({
      "id": new FormControl(null),
      "vesselCode": new FormControl(null),
      "vesselName": new FormControl(null),
      "vesselTypeId": new FormControl(null),
      "vesselTypeName": new FormControl(null),
      "customer": new FormControl(null),
      "imoNo": new FormControl(null),
      "mv": new FormControl(null),
      "flag": new FormControl(null),
      "userId": new FormControl(null),
      "userName": new FormControl(null),
      "companyId": new FormControl(null),
      "companyName": new FormControl(null),
      "passcode": new FormControl(null),
      "noOfDevice": new FormControl(null),
      "vesselPreviousNameDetails": new FormControl(null),
      "vesselPreviousDatesDetails": new FormControl(null),
      "portOfRegistory": new FormControl(null),
      "typeOfVessel": new FormControl(null),
      "typeOfHull": new FormControl(null),
      "registeredOwner": new FormControl(null),
      "parentCompany": new FormControl(null),
      "technicalOperator": new FormControl(null),
      "commercialOperator": new FormControl(null),
      "disponentOwner": new FormControl(null),
      "builderName": new FormControl(null),
      "builderAddress": new FormControl(null),
      "yardNumber": new FormControl(null, Validators.pattern("^[0-9]+(.[0-9]+)?$")),
      "dateDelivered": new FormControl(null),
      "classificationSociety": new FormControl(null),
      "classNotation": new FormControl(null),
      "isClassificationSocietyChanged": new FormControl(null),
      "dateOfLastDryDock": new FormControl(null),
      "lastDryDockPlace": new FormControl(null),
      "nextDryDockDueDate": new FormControl(null),
      "lastSpecialSurveyDate": new FormControl(null),
      "nextSpecialSurveyDate": new FormControl(null),
      "lastAnnualSurveyDate": new FormControl(null),
      "nextAnnualSurveyDate": new FormControl(null),
      "lengthOverAll": new FormControl(null, Validators.pattern("^[0-9]+(.[0-9]+)?$")),
      "lengthBetweenPerpendiculars": new FormControl(null, Validators.pattern("^[0-9]+(.[0-9]+)?$")),
      "extremeBreadth": new FormControl(null, Validators.pattern("^[0-9]+(.[0-9]+)?$")),
      "mouldedDepth": new FormControl(null, Validators.pattern("^[0-9]+(.[0-9]+)?$")),
      "grossTonnage": new FormControl(null, Validators.pattern("^[0-9]+(.[0-9]+)?$")),
      "netRegisteredTonnage": new FormControl(null, Validators.pattern("^[0-9]+(.[0-9]+)?$")),
      "suezCanalTonnageGross": new FormControl(null, Validators.pattern("^[0-9]+(.[0-9]+)?$")),
      "panamaCanalNetTonnage": new FormControl(null, Validators.pattern("^[0-9]+(.[0-9]+)?$")),
      "docNumber": new FormControl(null),
      "issuingAuthority": new FormControl(null),
      "outstandingRecommendations": new FormControl(null),
      "isVesselOperatesUnderQMS": new FormControl(null),
      "typeOfSystem": new FormControl(null),
      "vesselBallastingTime": new FormControl(null),
      "rateOfBallasting": new FormControl(null),
      "deballastingTime": new FormControl(null),
      "deballastingRate": new FormControl(null),
      "isGearedState": new FormControl(null),
      "numberOfCranes": new FormControl(null, Validators.pattern("^[0-9]+(.[0-9]+)?$")),
      "locationOfDerricks": new FormControl(null),
      "maximumOutreachOfGear": new FormControl(null),
      "maximumCargoLiftOnHook": new FormControl(null),
      "speedBallast": new FormControl(null),
      "speedLaden": new FormControl(null),
      "passageBallastNumber": new FormControl(null),
      "passageBallastAux": new FormControl(null),
      "passageLadenNumber": new FormControl(null),
      "passageLadenAux": new FormControl(null),
      "inportWorkingNumber": new FormControl(null),
      "inportWorkingAux": new FormControl(null),
      "inportIdleNumber": new FormControl(null),
      "inportIdleAux": new FormControl(null),
      "inportOtherNumber": new FormControl(null),
      "inportOtherAux": new FormControl(null),
      "smcnumber": new FormControl(null),
      "isVesselLogsFitted": new FormControl(null),
      "registeredOwnerAddress": new FormControl(null),
      "parentCompanyAddress": new FormControl(null),
      "technicalOperatorAddress": new FormControl(null),
      "commercialOperatorAddress": new FormControl(null),
      "disponentOwnerAddress": new FormControl(null),
      "safetyManagementCertNo": new FormControl(null),
      "machineCallSign": new FormControl(null),
      "machineINMARSATNumber": new FormControl(null),
      "machineTelexNumber": new FormControl(null),
      "machineFaxNumber": new FormControl(null),
      "machineEmail": new FormControl(null),
      "machineMMSINo": new FormControl(null),
      "machineElectricalSupply": new FormControl(null),
      "freshWaterStateCapacity": new FormControl(null),
      "productionOfEvaporator": new FormControl(null),
      "pAndIClub": new FormControl(null),
      "hullAndMachineryPlaced": new FormControl(null),
      "emailAddress": new FormControl(null, [Validators.required, Validators.email])
    });

    if (this.data != null)
      this.setFormValues();

    // Tanks Form

    this.formWB = this.fb.group({
      'vesselId': new FormControl(this.data.id),
      'name': new FormControl(null, [Validators.required]),
      'capacity': new FormControl(null, [Validators.required]),
      'isActive': new FormControl(null),
      'position': new FormControl(null, [Validators.required]),
      'weight': new FormControl(null, [Validators.pattern("^[0-9]+(.[0-9]+)?$")]),
      //'formId':new FormControl(null, [Validators.required])
    })

    this.formFW = this.fb.group({
      'vesselId': new FormControl(this.data.id),
      'name': new FormControl(null, [Validators.required]),
      'capacity': new FormControl(null, [Validators.required]),
      'isActive': new FormControl(null),
      'position': new FormControl(null, [Validators.required]),
      'weight': new FormControl(null, [Validators.pattern("^[0-9]+(.[0-9]+)?$")]),
      //'formId':new FormControl(null, [Validators.required])
    })

    this.formFO = this.fb.group({
      'vesselId': new FormControl(this.data.id),
      'name': new FormControl(null, [Validators.required]),
      'capacity': new FormControl(null, [Validators.required]),
      'isActive': new FormControl(null),
      'position': new FormControl(null, [Validators.required]),
      'weight': new FormControl(null, [Validators.pattern("^[0-9]+(.[0-9]+)?$")]),
      //'formId':new FormControl(null, [Validators.required])
    })
    this.formLO = this.fb.group({
      'vesselId': new FormControl(this.data.id),
      'name': new FormControl(null, [Validators.required]),
      'capacity': new FormControl(null, [Validators.required]),
      'isActive': new FormControl(null),
      'position': new FormControl(null, [Validators.required]),
      'weight': new FormControl(null, [Validators.pattern("^[0-9]+(.[0-9]+)?$")]),
      //'formId':new FormControl(null, [Validators.required])
    })
    this.formOT = this.fb.group({
      'vesselId': new FormControl(this.data.id),
      'name': new FormControl(null, [Validators.required]),
      'capacity': new FormControl(null, [Validators.required]),
      'isActive': new FormControl(null),
      'position': new FormControl(null, [Validators.required]),
      'weight': new FormControl(null, [Validators.pattern("^[0-9]+(.[0-9]+)?$")]),
      //'formId':new FormControl(null, [Validators.required])
    })
    this.getAllData();

    // SMTP Form
    this.smtpForm = this.fb.group({
      id:[''],
      userName: ['', Validators.required],
      userEmail: ['', [Validators.required, Validators.email]],
      emailPassword: ['', Validators.required],
      host: ['', Validators.required],
      port: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      sslenable: ['true'],
      storeType: ['IMAP', Validators.required]
    });

  }



    getSmtpSetting(){
      this.es.getSmtpSettingsById(1, (smtpData)=>{
        console.log("smtpData",smtpData);
        this.smtpForm.patchValue({
          id:smtpData.message.id,
          userName: smtpData.message.userName,
          userEmail: smtpData.message.userEmail,
          emailPassword: smtpData.message.emailPassword,
          host: smtpData.message.host,
          port: smtpData.message.port,
          sslenable: smtpData.message.sslenable,
          storeType: smtpData.message.storeType
        });
        console.log("SmtpForm ::", this.smtpForm.value);

      })
      this.isLoading=false;
    }

  getAllData() {
    this.es.getDataWB(this.data.id, (response) => {
      //console.log(response);
      this.dataSourceWB = new MatTableDataSource(response.data);
      this.dataSourceWB.paginator = this.paginator;
      this.dataSourceWB.sort = this.sort;
    })

    this.es.getDataFW(this.data.id, (response) => {
      //console.log(response);
      this.dataSourceFW = new MatTableDataSource(response.data);
      this.dataSourceFW.paginator = this.paginatorFW;
      this.dataSourceFW.sort = this.sortFW;
    })
    this.es.getDataFO(this.data.id, (response) => {
      //console.log(response);
      this.dataSourceFO = new MatTableDataSource(response.data);
      this.dataSourceFO.paginator = this.paginator;
      this.dataSourceFO.sort = this.sort;
    })
    this.es.getDataLO(this.data.id, (response) => {
      //console.log(response);
      this.dataSourceLO = new MatTableDataSource(response.data);
      this.dataSourceLO.paginator = this.paginator;
      this.dataSourceLO.sort = this.sort;
    })
    this.es.getDataOT(this.data.id, (response) => {
      //console.log(response);
      this.dataSourceOT = new MatTableDataSource(response.data);
      this.dataSourceOT.paginator = this.paginator;
      this.dataSourceOT.sort = this.sort;
    })
  }

  setFormValues() {
    console.log("====this.data===", this.data);
    this.vs.vesselFormData.id = this.data.id;
    this.vs.vesselFormData.vesselCode = this.data.vesselCode;
    this.vs.vesselFormData.vesselName = this.data.vesselName;
    this.vs.vesselFormData.vesselTypeId = this.data.vesselTypeId;
    this.vs.vesselFormData.vesselTypeName = this.data.vesselTypeName;
    this.vs.vesselFormData.customer = this.data.customer;
    this.vs.vesselFormData.imoNo = (this.data.imoNo) ? this.data.imoNo : this.data.vesselCode;
    this.vs.vesselFormData.mv = this.data.mv;
    this.vs.vesselFormData.userId = this.data.userId;
    this.vs.vesselFormData.userName = this.data.userName;
    this.vs.vesselFormData.companyId = this.data.companyId;
    this.vs.vesselFormData.companyName = this.data.companyName;
    this.vs.vesselFormData.passcode = this.data.passcode;
    this.vs.vesselFormData.noOfDevice = this.data.noOfDevice;
    this.vs.vesselFormData.vesselPreviousNameDetails = (this.data.vesselPreviousNameDetails) ? this.data.vesselPreviousNameDetails : this.data.vesselName;
    this.vs.vesselFormData.vesselPreviousDatesDetails = this.data.vesselPreviousDatesDetails;
    this.vs.vesselFormData.portOfRegistory = this.data.portOfRegistory;
    this.vs.vesselFormData.typeOfVessel = this.data.typeOfVessel;
    this.vs.vesselFormData.typeOfHull = this.data.typeOfHull;
    this.vs.vesselFormData.registeredOwner = this.data.registeredOwner;
    this.vs.vesselFormData.parentCompany = this.data.parentCompany;
    this.vs.vesselFormData.technicalOperator = this.data.technicalOperator;
    this.vs.vesselFormData.commercialOperator = this.data.commercialOperator;
    this.vs.vesselFormData.disponentOwner = this.data.disponentOwner;
    this.vs.vesselFormData.builderName = this.data.builderName;
    this.vs.vesselFormData.builderAddress = this.data.builderAddress;
    this.vs.vesselFormData.yardNumber = this.data.yardNumber;
    this.vs.vesselFormData.dateDelivered = this.data.dateDelivered;
    this.vs.vesselFormData.classificationSociety = this.data.classificationSociety;
    this.vs.vesselFormData.classNotation = this.data.classNotation;
    this.vs.vesselFormData.isClassificationSocietyChanged = this.data.isClassificationSocietyChanged;
    this.vs.vesselFormData.dateOfLastDryDock = this.data.dateOfLastDryDock;
    this.vs.vesselFormData.lastDryDockPlace = this.data.lastDryDockPlace;
    this.vs.vesselFormData.nextDryDockDueDate = this.data.nextDryDockDueDate;
    this.vs.vesselFormData.lastSpecialSurveyDate = this.data.lastSpecialSurveyDate;
    this.vs.vesselFormData.nextSpecialSurveyDate = this.data.nextSpecialSurveyDate;
    this.vs.vesselFormData.lastAnnualSurveyDate = this.data.lastAnnualSurveyDate;
    this.vs.vesselFormData.nextAnnualSurveyDate = this.data.nextAnnualSurveyDate;
    this.vs.vesselFormData.lengthOverAll = this.data.lengthOverAll;
    this.vs.vesselFormData.lengthBetweenPerpendiculars = this.data.lengthBetweenPerpendiculars;
    this.vs.vesselFormData.extremeBreadth = this.data.extremeBreadth;
    this.vs.vesselFormData.mouldedDepth = this.data.mouldedDepth;
    this.vs.vesselFormData.grossTonnage = this.data.grossTonnage;
    this.vs.vesselFormData.netRegisteredTonnage = this.data.netRegisteredTonnage;
    this.vs.vesselFormData.suezCanalTonnageGross = this.data.suezCanalTonnageGross;
    this.vs.vesselFormData.panamaCanalNetTonnage = this.data.panamaCanalNetTonnage;
    this.vs.vesselFormData.docNumber = this.data.docNumber;
    this.vs.vesselFormData.issuingAuthority = this.data.issuingAuthority;
    this.vs.vesselFormData.outstandingRecommendations = this.data.outstandingRecommendations;
    this.vs.vesselFormData.isVesselOperatesUnderQMS = this.data.isVesselOperatesUnderQMS;
    this.vs.vesselFormData.typeOfSystem = this.data.typeOfSystem;
    this.vs.vesselFormData.vesselBallastingTime = this.data.vesselBallastingTime;
    this.vs.vesselFormData.rateOfBallasting = this.data.rateOfBallasting;
    this.vs.vesselFormData.deballastingTime = this.data.deballastingTime;
    this.vs.vesselFormData.deballastingRate = this.data.deballastingRate;
    this.vs.vesselFormData.isGearedState = this.data.isGearedState;
    this.vs.vesselFormData.numberOfCranes = this.data.numberOfCranes;
    this.vs.vesselFormData.locationOfDerricks = this.data.locationOfDerricks;
    this.vs.vesselFormData.maximumOutreachOfGear = this.data.maximumOutreachOfGear;
    this.vs.vesselFormData.maximumCargoLiftOnHook = this.data.maximumCargoLiftOnHook;
    this.vs.vesselFormData.speedBallast = this.data.speedBallast;
    this.vs.vesselFormData.speedLaden = this.data.speedLaden;
    this.vs.vesselFormData.passageBallastNumber = this.data.passageBallastNumber;
    this.vs.vesselFormData.passageBallastAux = this.data.passageBallastAux;
    this.vs.vesselFormData.passageLadenNumber = this.data.passageLadenNumber;
    this.vs.vesselFormData.passageLadenAux = this.data.passageLadenAux;
    this.vs.vesselFormData.inportWorkingNumber = this.data.inportWorkingNumber;
    this.vs.vesselFormData.inportWorkingAux = this.data.inportWorkingAux;
    this.vs.vesselFormData.inportIdleNumber = this.data.inportIdleNumber;
    this.vs.vesselFormData.inportIdleAux = this.data.inportIdleAux;
    this.vs.vesselFormData.inportOtherNumber = this.data.inportOtherNumber;
    this.vs.vesselFormData.inportOtherAux = this.data.inportOtherAux;
    this.vs.vesselFormData.smcnumber = this.data.smcnumber;
    this.vs.vesselFormData.isVesselLogsFitted = this.data.isVesselLogsFitted;
    this.vs.vesselFormData.registeredOwnerAddress = this.data.registeredOwnerAddress;
    this.vs.vesselFormData.parentCompanyAddress = this.data.parentCompanyAddress;
    this.vs.vesselFormData.technicalOperatorAddress = this.data.technicalOperatorAddress;
    this.vs.vesselFormData.commercialOperatorAddress = this.data.commercialOperatorAddress;
    this.vs.vesselFormData.disponentOwnerAddress = this.data.disponentOwnerAddress;
    this.vs.vesselFormData.safetyManagementCertNo = this.data.safetyManagementCertNo;
    this.vs.vesselFormData.machineCallSign = this.data.machineCallSign;
    this.vs.vesselFormData.machineINMARSATNumber = this.data.machineINMARSATNumber;
    this.vs.vesselFormData.machineTelexNumber = this.data.machineTelexNumber;
    this.vs.vesselFormData.machineFaxNumber = this.data.machineFaxNumber;
    this.vs.vesselFormData.machineEmail = this.data.machineEmail;
    this.vs.vesselFormData.machineMMSINo = this.data.machineMMSINo;
    this.vs.vesselFormData.machineElectricalSupply = this.data.machineElectricalSupply;
    this.vs.vesselFormData.freshWaterStateCapacity = this.data.freshWaterStateCapacity;
    this.vs.vesselFormData.productionOfEvaporator = this.data.productionOfEvaporator;
    this.vs.vesselFormData.pAndIClub = this.data.pAndIClub;
    this.vs.vesselFormData.hullAndMachineryPlaced = this.data.hullAndMachineryPlaced;
    this.vs.vesselFormData.emailAddress = this.data.emailAddress;

  }

  saveSmtpSettings() {
    if (this.smtpForm.valid) {
      this.isLoading=true
      const formData = this.smtpForm.value;
      console.log('Form data to be submitted:', formData); // Console log the form data
      this.es.saveSmtpSettings(formData, (response) => {
        console.log('SMTP settings saved successfully!', response);
        this.getSmtpSetting();
        this.successbox.show();

      });
    } else {
      console.log('Form is invalid. Please check all fields.');
      this.markFormGroupTouched(this.smtpForm);
    }
  }


  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }


  submitHandler() {
    if (this.myForm.valid) {
      console.log(this.myForm.value);
      if (this.vs.vesselFormData.id) {
        this.vs.putData(this.vs.vesselFormData.id, this.vs.vesselFormData, (data) => {
          console.log(data);
          if (data.responsecode === 200 || data.responsecode === '200') {
            this.successbox.show();
            this.session.set('vesselData', this.vs.vesselFormData);
          } else {
            this.errorbox.text = data.message;
            this.errorbox.show();
          }
        })
      }
    }
  }


  public submitCargoArrangement() {
    if (this.cargoArrangementForm.valid) {
      console.log(this.cargoArrangementForm.value.rows);

      for (let t = 0; t < this.cargoArrangementForm.value.rows.length; t++) {
        if (this.cargoArrangementForm.value.rows[t].newrow == 1) {
          this.itemForm.push(this.cargoArrangementForm.value.rows[t]);
        }
      }

      if (this.itemForm.length > 0) {
        this.es.saveCargoArrangementData(this.itemForm, (response) => {
          console.log(response);
          if (response.responsecode === 200 || response.responsecode === '200') {
            this.successbox.show();
            //this.resetForm();
          } else {
            this.errorbox.text = response.message;
            this.errorbox.show();
          }
        })
      }

      console.log(this.itemForm);
    }
  }


  onAddRowArrangement(event: any) {
    var arr = { 'length': null, 'width': null, 'height': null, 'vesselId': null, 'newrow': 1 }
    for (var i = 0; i < event; i++) {
      this.rows.push(this.createItemFormGroupArrangement(arr));

    }
  }

  addDataRowArrangement(count, arr: any) {
    for (var i = 0; i < count; i++) {
      this.rows.push(this.createItemFormGroupArrangement(arr[i]));

    }
  }


  onRemoveRowArrangement(rowIndex: number) {
    if (this.cargoArrangementForm.value.rows[rowIndex].id) {
      this.es.deleteCargoArrangementData(this.cargoArrangementForm.value.rows[rowIndex].id).subscribe((data) => {
        console.log('Deleted');
        this.vs.getData((response) => {
          console.log(response);
        })
      })
    }

    this.rows.removeAt(rowIndex);
  }

  updateRowArrangement(rowIndex: number) {
    console.log(this.cargoArrangementForm.value.rows[rowIndex]);
    if (this.cargoArrangementForm.value.rows[rowIndex].newrow == 0) {
      this.es.updateCargoArrangementData(this.cargoArrangementForm.value.rows[rowIndex].id, this.cargoArrangementForm.value.rows[rowIndex], (data) => {
        console.log(data);
        if (data.responsecode === 200 || data.responsecode === '200') {
          this.successbox.show();
        } else {
          this.errorbox.text = data.message;
          this.errorbox.show();
        }
      })
    }
    this.showEdit = -1;
  }

  editRowArrangement(rowIndex: number) {
    this.showEdit = rowIndex;
  }




  createItemFormGroupArrangement(datas: any): FormGroup {
    return this.fb.group({
      length: (datas.length),
      width: (datas.width),
      height: (datas.height),
      vesselId: this.data.id,
      newrow: (datas.newrow ? datas.newrow : 0),
      id: (datas.id ? datas.id : null)
    });
  }

  /* Dack and Hatch */

  public submitDackAndHatch() {

    if (this.hatchArrangementForm.valid) {
      for (let t = 0; t < this.hatchArrangementForm.value.hatchRows.length; t++) {
        this.hatchArrangementForm.value.hatchRows[t].makeOfHatches = this.hatchArrangementForm.get('makeOfHatches').value;
        this.hatchArrangementForm.value.hatchRows[t].typeOfHatchCover = this.hatchArrangementForm.get('typeOfHatchCover').value;
      }

      console.log(this.hatchArrangementForm.value.hatchRows);

      for (let t = 0; t < this.hatchArrangementForm.value.hatchRows.length; t++) {
        if (this.hatchArrangementForm.value.hatchRows[t].newrow == 1) {
          this.itemForm.push(this.hatchArrangementForm.value.hatchRows[t]);
        }
      }

      if (this.itemForm.length > 0) {

        this.es.saveCargoDeckAndHatch(this.itemForm, (response) => {
          console.log(response);
          if (response.responsecode === 200 || response.responsecode === '200') {
            this.successbox.show();
            //this.resetForm();
          } else {
            this.errorbox.text = response.message;
            this.errorbox.show();
          }
        })
      }

      console.log(this.itemForm);
    }
  }


  onAddRowDackAndHatch(event: any) {
    var arr = { 'length': null, 'width': null, 'height': null, 'vesselId': null, 'newrow': 1, 'makeOfHatches': this.hatchArrangementForm.get('makeOfHatches').value, 'typeOfHatchCover': this.hatchArrangementForm.get('typeOfHatchCover').value }
    for (var i = 0; i < event; i++) {
      this.hatchRows.push(this.createItemFormGroupDackAndHatch(arr));

    }
  }

  addDataRowDackAndHatch(count, arr: any) {
    for (var i = 0; i < count; i++) {
      this.hatchRows.push(this.createItemFormGroupDackAndHatch(arr[i]));

    }

    this.es.formTypeData.makeOfHatches = arr[0].makeOfHatches;
    this.es.formTypeData.typeOfHatchCover = arr[0].typeOfHatchCover;
  }


  onRemoveRowDackAndHatch(rowIndex: number) {
    if (this.hatchArrangementForm.value.hatchRows[rowIndex].id) {
      this.es.deleteCargoDeckAndHatchData(this.hatchArrangementForm.value.hatchRows[rowIndex].id).subscribe((data) => {
        console.log('Deleted');
        this.vs.getData((response) => {
          console.log(response);
        })
      })
    }

    this.hatchRows.removeAt(rowIndex);
  }

  updateRowDackAndHatch(rowIndex: number) {
    if (this.hatchArrangementForm.value.hatchRows[rowIndex].newrow == 0) {
      for (let t = 0; t < this.hatchArrangementForm.value.hatchRows.length; t++) {
        this.hatchArrangementForm.value.hatchRows[t].makeOfHatches = this.hatchArrangementForm.get('makeOfHatches').value;
        this.hatchArrangementForm.value.hatchRows[t].typeOfHatchCover = this.hatchArrangementForm.get('typeOfHatchCover').value;
      }
      this.es.updateCargoDeckAndHatchData(this.hatchArrangementForm.value.hatchRows[rowIndex].id, this.hatchArrangementForm.value.hatchRows[rowIndex], (data) => {
        console.log(data);
        if (data.responsecode === 200 || data.responsecode === '200') {
          this.successbox.show();
        } else {
          this.errorbox.text = data.message;
          this.errorbox.show();
        }
      })
    }
    this.showEdit = -1;
  }

  editRowDackAndHatch(rowIndex: number) {
    this.showEdit = rowIndex;
  }

  onTextChange(event: any) {
    for (let t = 0; t < this.hatchArrangementForm.value.hatchRows.length; t++) {
      if (event.target.id == 'makeofhatch')
        this.hatchArrangementForm.value.hatchRows[t].makeOfHatches = event.target.value;
      if (event.target.id == 'typeofcover')
        this.hatchArrangementForm.value.hatchRows[t].typeOfHatchCover = event.target.value;
    }

  }

  createItemFormGroupDackAndHatch(datas: any): FormGroup {
    return this.fb.group({
      makeOfHatches: (datas.makeOfHatches),
      typeOfHatchCover: (datas.typeOfHatchCover),
      length: (datas.length),
      width: (datas.width),
      height: (datas.height),
      vesselId: this.data.id,
      newrow: (datas.newrow ? datas.newrow : 0),
      id: (datas.id ? datas.id : null)
    });
  }

  minSelectedCheckboxes(): ValidatorFn {
    const validator: ValidatorFn = (formArray: FormArray) => {

      const selectedCount = formArray.controls
        .map(control => control.value)
        .reduce((prev, next) => next ? prev + next : prev, 0);

      return selectedCount >= 1 ? null : { notSelected: true };
    };

    return validator;
  }


  //Tanks

  submitFormWB() {
    this.es.formDataWB.vesselId = this.data.id;
    console.log(this.formWB);
    console.log(this.es.formDataWB);

    if (this.formWB.valid) {
      if (this.es.formDataWB.id) {
        this.es.putDataWB(this.es.formDataWB.id, this.es.formDataWB, (response) => {
          console.log(response);
          if (response.responsecode === 200 || response.responsecode === '200') {
            this.es.formDataWB.id = '';
            this.getAllData();
            this.successbox.show();
            this.formWB.reset();
          } else {
            this.errorbox.text = response.message;
            this.errorbox.show();
          }
        })
      } else {
        this.es.postDataWB(this.es.formDataWB, (response) => {
          console.log(response);
          if (response.responsecode === 200 || response.responsecode === '200') {
            this.getAllData();
            this.successbox.show();
            this.formWB.reset()
          } else {
            this.errorbox.text = response.message;
            this.errorbox.show();
          }
        })

      }
    }
  }

  submitFormFW() {
    this.es.formDataFW.vesselId = this.data.id;
    console.log(this.formFW);
    if (this.formFW.valid) {

      console.log(this.formFW.value);
      if (this.es.formDataFW.id) {
        this.es.putDataFW(this.es.formDataFW.id, this.es.formDataFW, (response) => {
          console.log(response);
          if (response.responsecode === 200 || response.responsecode === '200') {
            this.es.formDataFW.id = '';
            this.getAllData();
            this.successbox.show();
            this.formFW.reset();
          } else {
            this.errorbox.text = response.message;
            this.errorbox.show();
          }
        })
      } else {
        this.es.postDataFW(this.es.formDataFW, (response) => {
          console.log(response);
          if (response.responsecode === 200 || response.responsecode === '200') {
            this.getAllData();
            this.successbox.show();
            this.formFW.reset()
          } else {
            this.errorbox.text = response.message;
            this.errorbox.show();
          }
        })

      }
    }
  }


  submitFormFO() {
    this.es.formDataFO.vesselId = this.data.id;
    console.log(this.formFO);
    if (this.formFO.valid) {
      console.log(this.formFO.value);
      if (this.es.formDataFO.id) {
        this.es.putDataFO(this.es.formDataFO.id, this.es.formDataFO, (response) => {
          console.log(response);
          if (response.responsecode === 200 || response.responsecode === '200') {
            this.es.formDataFO.id = '';
            this.getAllData();
            this.successbox.show();
            this.formFO.reset();
          } else {
            this.errorbox.text = response.message;
            this.errorbox.show();
          }
        })
      } else {
        this.es.postDataFO(this.es.formDataFO, (response) => {
          console.log(response);
          if (response.responsecode === 200 || response.responsecode === '200') {
            this.getAllData();
            this.successbox.show();
            this.formFO.reset()
          } else {
            this.errorbox.text = response.message;
            this.errorbox.show();
          }
        })

      }
    }
  }

  submitFormLO() {
    this.es.formDataLO.vesselId = this.data.id;
    console.log(this.formLO);
    if (this.formLO.valid) {
      console.log(this.formLO.value);
      if (this.es.formDataLO.id) {
        this.es.putDataLO(this.es.formDataLO.id, this.es.formDataLO, (response) => {
          console.log(response);
          if (response.responsecode === 200 || response.resqeqeponsecode === '200') {
            this.es.formDataLO.id = '';
            this.getAllData();
            this.successbox.show();
            this.formLO.reset();
          } else {
            this.errorbox.text = response.message;
            this.errorbox.show();
          }
        })
      } else {
        this.es.postDataLO(this.es.formDataLO, (response) => {
          console.log(response);
          if (response.responsecode === 200 || response.responsecode === '200') {
            this.getAllData();
            this.successbox.show();
            this.formLO.reset()
          } else {
            this.errorbox.text = response.message;
            this.errorbox.show();
          }
        })

      }
    }
  }

  submitFormOT() {
    this.es.formDataOT.vesselId = this.data.id;
    console.log(this.formOT);
    if (this.formOT.valid) {
      console.log(this.formOT.value);
      if (this.es.formDataOT.id) {
        this.es.putDataOT(this.es.formDataOT.id, this.es.formDataOT, (response) => {
          console.log(response);
          if (response.responsecode === 200 || response.responsecode === '200') {
            this.es.formDataOT.id = '';
            this.getAllData();
            this.successbox.show();
            this.formOT.reset();
          } else {
            this.errorbox.text = response.message;
            this.errorbox.show();
          }
        })
      } else {
        this.es.postDataOT(this.es.formDataOT, (response) => {
          console.log(response);
          if (response.responsecode === 200 || response.responsecode === '200') {
            this.getAllData();
            this.successbox.show();
            this.formOT.reset()
          } else {
            this.errorbox.text = response.message;
            this.errorbox.show();
          }
        })

      }
    }
  }

  onClickWB(action, data) {
    console.log(data);
    if (action == 'edit') {
      this.es.formDataWB.capacity = data.capacity;
      this.es.formDataWB.id = data.id;
      this.es.formDataWB.name = data.name;
      this.es.formDataWB.position = data.position;
      this.es.formDataWB.weight = data.weight;
      this.es.formDataWB.vesselId = this.data.id;
      this.es.formDataWB.isActive = data.isActive;
    } else {
      this.es.deleteDataWB(data.id).subscribe((data) => {
        console.log('Deleted');
        this.es.formDataWB.id = '';
        this.es.getDataWB(this.data.id, (response) => {
          console.log(response);
          this.dataSourceWB = new MatTableDataSource(response.data);
          this.dataSourceWB.paginator = this.paginator;
          this.dataSourceWB.sort = this.sort;
        })
      })
    }

  }

  onClickFW(action, data) {
    console.log(data);
    if (action == 'edit') {
      this.es.formDataFW.capacity = data.capacity;
      this.es.formDataFW.id = data.id;
      this.es.formDataFW.name = data.name;
      this.es.formDataFW.position = data.position;
      this.es.formDataFW.weight = data.weight;
      this.es.formDataFW.vesselId = this.data.id;
      this.es.formDataFW.isActive = data.isActive;
    } else {
      this.es.deleteDataFW(data.id).subscribe((data) => {
        console.log('Deleted');
        this.es.formDataFW.id = '';
        this.es.getDataFW(this.data.id, (response) => {
          console.log(response);
          this.dataSourceFW = new MatTableDataSource(response.data);
          this.dataSourceFW.paginator = this.paginatorFW;
          this.dataSourceFW.sort = this.sortFW;
        })
      })
    }

  }

  onClickFO(action, data) {
    console.log(data);
    if (action == 'edit') {
      this.es.formDataFO.capacity = data.capacity;
      this.es.formDataFO.id = data.id;
      this.es.formDataFO.name = data.name;
      this.es.formDataFO.position = data.position;
      this.es.formDataFO.weight = data.weight;
      this.es.formDataFO.isActive = data.isActive;
      this.es.formDataFO.vesselId = this.data.id;
    } else {
      this.es.deleteDataFO(data.id).subscribe((data) => {
        console.log('Deleted');
        this.es.formDataFO.id = '';
        this.es.getDataFO(this.data.id, (response) => {
          console.log(response);
          this.dataSourceFO = new MatTableDataSource(response.data);
          this.dataSourceFO.paginator = this.paginator;
          this.dataSourceFO.sort = this.sort;
        })
      })
    }

  }
  onClickLO(action, data) {
    console.log(data);
    if (action == 'edit') {
      this.es.formDataLO.capacity = data.capacity;
      this.es.formDataLO.id = data.id;
      this.es.formDataLO.name = data.name;
      this.es.formDataLO.position = data.position;
      this.es.formDataLO.weight = data.weight;
      this.es.formDataLO.isActive = data.isActive;
      this.es.formDataLO.vesselId = this.data.id;
    } else {
      this.es.deleteDataLO(data.id).subscribe((data) => {
        console.log('Deleted');
        this.es.formDataLO.id = '';
        this.es.getDataLO(this.data.id, (response) => {
          console.log(response);
          this.dataSourceLO = new MatTableDataSource(response.data);
          this.dataSourceLO.paginator = this.paginator;
          this.dataSourceLO.sort = this.sort;
        })
      })
    }

  }
  onClickOT(action, data) {
    console.log(data);
    if (action == 'edit') {
      this.es.formDataOT.capacity = data.capacity;
      this.es.formDataOT.id = data.id;
      this.es.formDataOT.name = data.name;
      this.es.formDataOT.position = data.position;
      this.es.formDataOT.weight = data.weight;
      this.es.formDataOT.isActive = data.isActive;
      this.es.formDataOT.vesselId = this.data.id;
    } else {
      this.es.deleteDataOT(data.id).subscribe((data) => {
        console.log('Deleted');
        this.es.formDataOT.id = '';
        this.es.getDataOT(this.data.id, (response) => {
          console.log(response);
          this.dataSourceOT = new MatTableDataSource(response.data);
          this.dataSourceOT.paginator = this.paginator;
          this.dataSourceOT.sort = this.sort;
        })
      })
    }

  }


  isActionFn(active) {
    if (active == true) {
      return "Active";
    } else {
      return "Inactive";
    }
  }

}



