import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate  {

  constructor(private auth: AuthService, private route: Router, private currentRoutes: ActivatedRoute) {}
  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean> |Promise<boolean> | boolean {
    // console.log('!!!!!!!!!');
    console.log(state.url);
     return this.auth.isAuthenticated(state.url)
      .then(
        (authenticated: boolean) => {
          console.log(authenticated);
          
          if(authenticated) {
            this.auth.login();
            return true;
          } else {
            // if(state.url !== '/employee') {
            //   this.auth.logout();
              this.route.navigate(['/dashboard']);
            // }
            return false;
          }
        }
      );
  }


}
