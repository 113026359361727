import { Component, ChangeDetectorRef, ChangeDetectionStrategy, Output, EventEmitter, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import { ReportsService } from '../reports/reports.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { SessionStorageService } from 'angular-web-storage';
import { windowWhen } from 'rxjs/operators';

@Component({
  selector: 'app-comment-dialog',
  templateUrl: './comment-dialog.component.html',
  styleUrls: ['./comment-dialog.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentDialogComponent implements OnInit {
  commentText: string = '';
  selectedColor: string = 'no-color';
  isCommentInvalid: boolean = false;
  isColorInvalid: boolean = false;
  loading: boolean;
  requestData;

  @ViewChild('successbox', { static: true }) successbox: SwalComponent;
  @ViewChild('errorbox', { static: true }) errorbox: SwalComponent;
  

  constructor(
    private rs: ReportsService,
    public dialogRef: MatDialogRef<CommentDialogComponent>,
    public session: SessionStorageService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    console.log('Data received in dialog:', this.data);
    // console.log("check-data-comment-object", this.data.commentData.comment);
    console.log("check-type", this.data.type);
    if (this.data && this.data.comment) {
      console.log("if condition working edit mode")
      console.log("edit comment is", this.data.comment);
      console.log("edit color is", this.data.colour)
      this.commentText = this.data.comment;
      this.selectedColor = this.data.colour;
    } else {
      console.log("else condition working")
      this.commentText = '';
      this.selectedColor = 'no-color';
    }
  }

  sanitizeComment(): void {
    if (this.commentText) {
      this.commentText = this.commentText.replace(/[^a-zA-Z0-9\s.,!?'-]/g, '');
    }
  }

  onPaste(event: ClipboardEvent): void {
    console.log("onPaste-event", event);
    event.preventDefault();
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    let pastedText = clipboardData.getData('text') || '';
    let sanitizedText = pastedText.replace(/[^a-zA-Z0-9\s.,!?'-]/g, '');
    const totalLength = this.commentText.length + sanitizedText.length;
    if (totalLength > 500) {
      const remainingSpace = 500 - this.commentText.length;
      sanitizedText = sanitizedText.substring(0, remainingSpace);
    }
    this.commentText += sanitizedText;
    console.log("Updated commentText after paste:", this.commentText);
  }
  

  saveComment(): void {
    this.loading = true;
    this.isCommentInvalid = false;

    if (!this.commentText.trim()) {
      this.isCommentInvalid = true;
    }

    if (this.isCommentInvalid) {
      return;
    }

    let commentedBy = `${this.session.get('firstname')} ${this.session.get('lastname')}`;
    console.log("check-commentedBy", commentedBy);

    this.requestData = {
      commentBy: commentedBy,
      colour: this.selectedColor || 'no-color',
      comment: this.commentText,
      fieldName: this.data.fieldName,
      formId: this.data.formId,
      rowId: this.data.rowId,
      userId: this.data.userId,
      id: this.data.id,
      vesselId: this.data.vesselId,
      status: '2',
      genralDetailsId: this.data.objectId
    };

    console.log("check-requested-data", this.requestData);


    this.rs.saveComments(this.requestData, (response) => {
      if (response.responsecode === 200) {
        this.loading = false;
        console.log('Comment saved successfully:', response);
        this.successbox.title = 'Success';
        this.successbox.text = response.message;
        this.successbox.timer = 2000;
        this.successbox.show();
        setTimeout(() => {   
          this.dialogRef.close(response);
          this.ngOnInit();
        }, 2000);

      } else {
        this.loading = false;
        console.error('Error saving comment:', response);
        this.errorbox.title = 'Error';
        this.errorbox.text = response.message;
        this.errorbox.timer = 2000; +
          this.errorbox.show();
      }
    },
    (error) => {
      this.loading = false;
      console.error('Request timed out or failed:', error);
      this.errorbox.title = 'Error';
      this.errorbox.text = 'Server timeout. Please check the network connection and try again later';
      this.errorbox.timer = 3000;
      this.errorbox.show();
    });
  }


  onClose() {
    console.log("dialog close button is triggering");
    this.dialogRef.close();
  }
}
