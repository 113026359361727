import { Component, Inject, OnInit } from '@angular/core';
import {MatFormField,MatSelect,MatOption, MAT_DIALOG_DATA, MatDialogRef,MatSelectModule} from '@angular/material'
import { element } from 'protractor';
import { DialogData } from '../log-book-forms/log-book-forms.component';
import { SessionStorageService } from 'angular-web-storage';

@Component({
  selector: 'app-editdeckdialog',
  templateUrl: './editdeckdialog.component.html',
  styleUrls: ['./editdeckdialog.component.scss']
})
export class EditdeckdialogComponent implements OnInit {

  constructor(  public dialogRef: MatDialogRef<EditdeckdialogComponent>,
    @Inject(MAT_DIALOG_DATA)  public data: DialogData,public session: SessionStorageService,) {
      dialogRef.disableClose = true ;
    }

    date :any = []
    selecteddate:any = '';
    watchhour : any = []

    ngOnInit() {

      console.log(this.data)

      this.data.popupwatchhours.forEach((element) =>{
          this.date.push(element.date)
          // console.log(this.date)
          })
    this.selecteddate = this.date[0];
    let index = this.data.popupwatchhours.findIndex(item => item.date == this.selecteddate);
    console.log(index);
    if(this.data.popupwatchhours[index]){
      this.watchhour = this.data.popupwatchhours[index].watchHour
     }
     console.log(this.watchhour);
   //  this.selecteddate = this.date[index]
   //sessionStorage.setItem('actionDate',dat);
   //sessionStorage.setItem('actionTime',JSON.stringify(this.watchhour));
  var splitdate = this.selecteddate;
  var watchhourdate = splitdate.split("-");
  console.log(watchhourdate[0]+"  "+watchhourdate[1]+"  "+watchhourdate[2]);
  var watchhouractiondate = watchhourdate[1]+"/"+watchhourdate[0]+"/"+watchhourdate[2]
   this.session.set('editdeckDate', watchhouractiondate);
   console.log("==editdeckDate==",this.session.get('editdeckDate'));
  }




  selection(dat:any) {
   console.log(dat);

  let index = this.data.popupwatchhours.findIndex(item => item.date == dat);
  console.log(index);
  if(this.data.popupwatchhours[index]){
    this.watchhour = this.data.popupwatchhours[index].watchHour
   }
   console.log(this.watchhour);
 //  this.selecteddate = this.date[index]
 //sessionStorage.setItem('actionDate',dat);
 //sessionStorage.setItem('actionTime',JSON.stringify(this.watchhour));
var splitdate = dat;
var watchhourdate = splitdate.split("-");
console.log(watchhourdate[0]+"  "+watchhourdate[1]+"  "+watchhourdate[2]);
var watchhouractiondate = watchhourdate[1]+"/"+watchhourdate[0]+"/"+watchhourdate[2]
 this.session.set('editdeckDate', watchhouractiondate);
}

     onNoClick(): void {
       this.dialogRef.close();
     }

 }



