import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from '../apiroot.service';

@Injectable({
  providedIn: 'root'
})
export class VoyagelistService {

  formTypeData = {vesselId:''};

  constructor(public httpClient : HttpClient,public service:ApiRootService) { }

  
  getDataById(id,callback){
    return this.httpClient.get(this.service.getBaseUrl()+'/getCOSPByvesselId/'+id).subscribe(
     // return this.httpClient.get(this.service.getBaseUrl()+'/getAllCOSPByvesselId/'+id).subscribe(
      
      (data) =>{
        // console.log(data);
        callback(data);
      }
    )}
    
     getEospDataById(id,callback){
    return this.httpClient.get(this.service.getBaseUrl()+'/getEOSPByvesselId/'+id).subscribe(
      //return this.httpClient.get(this.service.getBaseUrl()+'/getEOSPListByvesselId/'+id).subscribe(
      
      (data) =>{
        // console.log(data);
        callback(data);
      }
    )}
    
    getAllClosedVoyageByvesselId(id,callback){
        return this.httpClient.get(this.service.getBaseUrl()+'/getAllClosedVoyageByvesselId/'+id).subscribe(
        //return this.httpClient.get(this.service.getBaseUrl()+'/getEOSPListByvesselId/'+id).subscribe(

        (data) =>{
          // console.log(data);
          callback(data);
        }
      )
    }
    
   
  }
