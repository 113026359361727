import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { SessionStorageService } from 'angular-web-storage';
declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class ApiRootService {

  public APIROOT;
  public BASE_URL;
  public DEVAPIROOT;
  public DEV_URL;
  public AUTH;
  public DOWNLOAD_PDF;
  cookieIp: any;

  constructor(private cookieService: CookieService, public session: SessionStorageService) {
    const url = window.location.host;
    var urlSplit = url.split(":");
    var isLocalRunOnLocalhost = urlSplit[0] && urlSplit[0] === 'localhost' ? true : false;
    console.log('BaseUrlSet_Localhost', isLocalRunOnLocalhost);
    if(isLocalRunOnLocalhost){
      this.cookieIp = this.cookieService.get('campbell_app_ip_address');
      console.log('BaseUrlSet_1', this.cookieIp);
    } else {
    if(!cookieService.get('campbell_app_ip_address')){
      this.cookieService.set('campbell_app_ip_address', window.location.host, 365 ,'/','',false,'Lax');
      this.cookieIp = this.cookieService.get('campbell_app_ip_address');
      console.log('BaseUrlSet_0', this.cookieIp);
    } else if(this.cookieService.get('campbell_app_ip_address') === window.location.host) {
      this.cookieIp = this.cookieService.get('campbell_app_ip_address');
      console.log('BaseUrlSet_1', this.cookieIp);
    } else {
      this.cookieService.set('campbell_app_ip_address', window.location.host, 365 ,'/','',false,'Lax');
      this.cookieIp = this.cookieService.get('campbell_app_ip_address');
      console.log('BaseUrlSet_2', this.cookieIp);
    }
  }

    this.APIROOT = 'http://' + this.cookieIp + ':9002/campbellservice';
    this.DEVAPIROOT = 'http://' + this.cookieIp + ':9002/campbellservice';
    this.AUTH = 'http://' + this.cookieIp + ':9002';
    // this.DOWNLOAD_PDF = `https://htmltopdf.esoftech.in/htmltopdf`;
    this.DOWNLOAD_PDF = 'https://htmltopdf.esoftech.in/html2pdf';
    this.session.set('Authurl', this.AUTH);
    this.initializeURL();
   }

   initializeURL() {
    this.BASE_URL = this.APIROOT;
    this.DEV_URL = this.DEVAPIROOT;
  }

  getBaseUrl() {
    return this.BASE_URL;
  }

  getDevBaseURL() {
    return this.DEV_URL;

  }

  getAuthURL() {
    return this.AUTH;

  }
}
