
import { Component, Inject, OnInit } from '@angular/core';
import {MatFormField,MatSelect,MatOption, MAT_DIALOG_DATA, MatDialogRef,MatSelectModule} from '@angular/material'
import { element } from 'protractor';
import { DialogData } from '../log-book-forms/log-book-forms.component';
import { SessionStorageService } from 'angular-web-storage';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-decklogdialogbox',
  templateUrl: './decklogdialogbox.component.html',
  styleUrls: ['./decklogdialogbox.component.scss']
})
export class DecklogdialogboxComponent implements OnInit {
  date :any = []
  watchhour : any = []
  selecteddate:any = '';
  show:boolean = false;
  deckWatchTime:string ='';
  flag:boolean=false;

  constructor(  public dialogRef: MatDialogRef<DecklogdialogboxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,public session: SessionStorageService,private router:Router,
    private route:ActivatedRoute) { 
      dialogRef.disableClose = true ;
    }

    ngOnInit() {

      // this.popupwatchhours.forEach((element) =>{
       //  this.date.push(element.date)
       //  console.log(this.date)
        
       //})
       console.log(this.data)
   
       this.data.popupwatchhours.forEach((element) =>{
           this.date.push(element.date)
           
          // console.log(this.date)
     })
     this.selecteddate = this.date[0];
   
     let index = this.data.popupwatchhours.findIndex(item => item.date == this.selecteddate);
     console.log(index);
     if(this.data.popupwatchhours[index]){
       this.watchhour = this.data.popupwatchhours[index].watchHour
      }
      console.log(this.watchhour);
    //  this.selecteddate = this.date[index]
    //sessionStorage.setItem('actionDate',dat);
    //sessionStorage.setItem('actionTime',JSON.stringify(this.watchhour));
   var splitdate = this.selecteddate;
   var watchhourdate = splitdate.split("-"); 
   console.log(watchhourdate[0]+"  "+watchhourdate[1]+"  "+watchhourdate[2]);
   var watchhouractiondate = watchhourdate[1]+"-"+watchhourdate[0]+"-"+watchhourdate[2]
    this.session.set('deckwatchHourDate', watchhouractiondate);
   }
   
    
      selection(dat:any) {
        console.log(dat);
       
       let index = this.data.popupwatchhours.findIndex(item => item.date == dat);
       console.log(index);
       if(this.data.popupwatchhours[index]){
         this.watchhour = this.data.popupwatchhours[index].watchHour
        }
        console.log(this.watchhour);
      //  this.selecteddate = this.date[index]
      //sessionStorage.setItem('actionDate',dat);
      //sessionStorage.setItem('actionTime',JSON.stringify(this.watchhour));
   var splitdate = dat;
   var watchhourdate = splitdate.split("-"); 
   console.log(watchhourdate[0]+"  "+watchhourdate[1]+"  "+watchhourdate[2]);
   var watchhouractiondate = watchhourdate[1]+"-"+watchhourdate[0]+"-"+watchhourdate[2]
      this.session.set('deckwatchHourDate', watchhouractiondate);
     
     
       }
       
      selected(dm:any,id:any) {
        console.log("BUTTON VALUE " + dm+ " "+id)
        this.session.set('deckwatchHourTime', dm);
        this.session.set('watchHourId', id);
        this.deckWatchTime=dm;
        this.flag=false;
        
      }
  

      onNoClick(): void {
        this.dialogRef.close();
      }
     
      submitHandler(){
        if(this.deckWatchTime == ''){
          this.flag=true;
        }else{
          this.flag=false;
          this.dialogRef.close();
        }
      }

  }

    