import { Component, OnInit,NgZone } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginService } from './login.service';
import { Router } from '@angular/router';
import { SessionStorageService } from 'angular-web-storage';
import { CookieService } from 'ngx-cookie-service';
import { ReportsService } from 'src/app/reports/reports.service';




declare global {
  interface Window {
    RTCPeerConnection: RTCPeerConnection;
    mozRTCPeerConnection: RTCPeerConnection;
    webkitRTCPeerConnection: RTCPeerConnection;
  }
}


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})


export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  submitted = true;
  message: any;
  errormessage: any;
  token;
  showModel:any = true;
  cookieValue:any;
  currentVessel: any;
  hide = true;

  public localIp = sessionStorage.getItem('LOCAL_IP');
  private ipRegex = new RegExp(/([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/);


  constructor(
    public service: LoginService,
    public session: SessionStorageService,
    public router: Router,private zone: NgZone,
    private cookieService: CookieService,
    public reportService : ReportsService
  ) {
        if (this.session.get('token')) {
           this.router.navigate(['/dashboard']);
        }
  }

  ngOnInit() {

    if(this.cookieService.get('campbell_app_ip_address')){
        this.closeMe();
    }else{
            this.determineLocalIp();
            console.log("=====console====");
            console.log(this.localIp);
            this.cookieService.set('campbell_app_ip_address', this.localIp, 365 ,'/','',false,'Lax');

    }


    this.cookieValue = this.cookieService.get('campbell_app_ip_address');
    this.loginForm = new FormGroup({
      'username': new FormControl(null, [Validators.required]),
      'password': new FormControl(null, [Validators.required]),
      'passcode': new FormControl(null, [Validators.required])
    });

  }

  closeMe(){
      this.showModel=false;
  }


  showMe(){
      this.showModel=true;
  }

  togglePasswordVisibility(): void {
    this.hide = !this.hide;
  }

  loginSubmit() {
    console.log(this.loginForm);
    if (this.loginForm.valid) {
      this.submitted = true;
      this.service.loginUser(this.loginForm.value, (response) => {
        console.log(response);
        const responseData = response.responsecode;
        console.log("===responseData===",responseData);
        if(responseData.responsecode == 200) {
          this.token = response.token;
          // this.session.set('isLoogedIn',response.result);
          // this.session.set('username',response.username);
          this.session.set('companyId',responseData.companyid); // (need to add after api changes)
          if(responseData.rolename.toString().toLowerCase()=='super admin'){
              this.session.set('companyId',0);
          }
          this.session.set('userid',responseData.crewid);
          this.session.set('token', this.token);
          this.session.set('firstname', responseData.firstname);
          this.session.set('lastname', responseData.lastname);
          this.session.set('profileImage', responseData.profileImage);
          this.session.set('rolename', responseData.rolename);
          this.session.set('roleid', responseData.roleid);
          this.session.set('companyIdLogo', responseData.companyid);
          this.session.set('companyName', responseData.companyname);

          this.session.set('passcode', this.loginForm.get('passcode').value);

          this.reportService.getCrewSignByCrewId(this.session.get('userid'),(response) => {
            if (response.responsecode === 200 || response.responsecode === '200') {
              if(response.data.signatureData!=null && response.data.signatureData!=""){

              this.session.set('userSignature', response.data.signatureData);
              }
                           console.log('============saved successfully============');
            }
          })

          if (this.session.get("passcode")) {
            this.service.getLatestCOSPByPasscode(
              this.session.get("passcode"),
              (response) => {
                const responseData = response.data;
                if (response.responsecode == 200) {
                this.currentVessel = responseData.vesselId;
                console.log(this.currentVessel);
                this.session.set('VesselId',this.currentVessel);
                }
              }
            );
          }

          // console.log(this.session.get('token'));
          if (this.session.get('token')) {

              this.router.navigate(['/dashboard']);

          }
        } else {
          console.log(responseData.message)
          this.errormessage = responseData.message;
          setTimeout(() => {
            this.errormessage = '';
          }, 2500);
        }
        // if (response.responsecode.body.responsecode == 200) {
          // this.token = response.token;
          // // this.session.set('isLoogedIn',response.result);
          // // this.session.set('username',response.username);
          // // this.session.set('userid',response.userid);
          // this.session.set('token', this.token);
          // this.session.set('firstname', response.responsecode.body.firstname);
          // this.session.set('lastname', response.responsecode.body.lastname);
          // this.session.set('profileImage', response.responsecode.body.profileImage);


          // console.log(this.session.get('token'));
          // if (this.session.get('token')) {

          //     this.router.navigate(['/dashboard']);

          // }
        // } else {
          // console.log(response.message)
          // this.errormessage = response.message;
          // setTimeout(() => {
          //   this.errormessage = '';
          // }, 2500);
        // }
      })
    } else {
      this.submitted = false;
    }
  }


  private determineLocalIp() {
    window.RTCPeerConnection = this.getRTCPeerConnection();

    const pc = new RTCPeerConnection({ iceServers: [] });
    pc.createDataChannel('');
    pc.createOffer().then(pc.setLocalDescription.bind(pc));

    pc.onicecandidate = (ice) => {
      this.zone.run(() => {
        if (!ice || !ice.candidate || !ice.candidate.candidate) {
          return;
        }

        this.localIp = this.ipRegex.exec(ice.candidate.candidate)[1];
        sessionStorage.setItem('LOCAL_IP', this.localIp);

        pc.onicecandidate = () => {};
        pc.close();
      });
    };
  }

  private getRTCPeerConnection() {
    return window.RTCPeerConnection ||
      window.mozRTCPeerConnection ||
      window.webkitRTCPeerConnection;
  }

}
