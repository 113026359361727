import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from '../apiroot.service';

@Injectable({
  providedIn: 'root'
})
export class FormlistService {

//formTypeData = {id:'' , checklistName:'', checklistCategoryId:'', revisionDate:'', authorityIds:'', isNotify:'', checklistNotification:'', reportHeader:'', reportHeader1:'', reportHeader2:''}
formTypeData = {id:'',formsName:'',categoryId:'',revisionDate:'',authorityIds:'',isNotify:'',formsNotification:'',reportHeader:'',reportHeader1:'', reportHeader2:''}

  
constructor(public httpClient : HttpClient,public service:ApiRootService) { }

getAllCat(callback){
    return this.httpClient.get(this.service.getBaseUrl()+'/getAllFormsCategory').subscribe(
      (data) =>{
          console.log(data);
        callback(data);
      }
    )
  } 

  getData(callback){
      console.log("--list process--");
    return this.httpClient.get(this.service.getBaseUrl()+'/getAllForms').subscribe(
      (data) =>{
      console.log("--list done--");
        console.log(data);
        callback(data);
      }
    )
  } 
  

  getDataById(id,callback){
    return this.httpClient.get(this.service.getBaseUrl()+'/getFormtDetailsByCategoryId/'+id).subscribe(
      (data) =>{
        // console.log(data);
        callback(data);
      }
    )
  }
  
  getFormAdditionalInfoByFormId(id,callback){
    return this.httpClient.get(this.service.getBaseUrl()+'/getFormAdditionalInfoByFormId/'+id).subscribe(
      (data) =>{
        console.log(data);
        callback(data);
      }
    )}
    
    saveFormAdditionalInfo(formData,callback){
      console.log('---save additional info data--');
      console.log(formData);
    return this.httpClient.post(this.service.getBaseUrl()+'/saveFormAdditionalInfoList',formData).subscribe(
      (data) =>{
        console.log(data);
        callback(data);
      }
    )}
    
  postData(formData,callback){
      console.log('---save data--');
      console.log(formData);
    return this.httpClient.post(this.service.getBaseUrl()+'/saveFormsDetails',formData).subscribe((data)=>{
      console.log(data);
      console.log('---save data done--');
    callback(data);
    })
  }

  putData(formsDetailsId,formData,callback){
      console.log('---update data--');
      console.log(formData);
    return this.httpClient.put(this.service.getBaseUrl()+'/updatFormsDetails/'+formsDetailsId,formData).subscribe((data)=>{
      console.log('---update data done--');
      console.log(data);
      callback(data);

    })
  }

  deleteData(id){
     return this.httpClient.delete(this.service.getBaseUrl()+'/deleteMultipleForms/'+id);   
  }
  
  getAllRole(callback){
    return this.httpClient.get(this.service.getBaseUrl()+'/getAllRoleMaster').subscribe(
      (data) =>{
        callback(data);
      }
    )}
}
