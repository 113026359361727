import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from '../apiroot.service';

@Injectable({
  providedIn: 'root'
})
export class QuestionformsService {

  formData = {section_id:''}

  constructor(public http:HttpClient,public service:ApiRootService) { }

  getControlTypeData(callback){
    return this.http.get(this.service.getBaseUrl()+'/getAllfieldType').subscribe(
      (data) =>{
        console.log(data);
        callback(data);
      }
    )}

  getAllSections(callback){
   return this.http.get(this.service.getBaseUrl()+'/getAllFormsSections').subscribe(
      (data) => {
        console.log(data);
        callback(data);
    });

  }

  getFormsDetailsByVesselId(VesselId, callback){
    // http://app.intelligentlogs.com:9002/campbellservice/getFormsDetailsByVesselId/1
    return this.http.get(this.service.getBaseUrl()+'/getFormsDetailsByVesselId/'+VesselId).subscribe(
       (data) => {
         console.log(data);
         callback(data);
     });
 
   }
  
  getQuestionData(formId,sectionId,callback){
   return this.http.get(this.service.getBaseUrl()+'/getFormsQuestionDetailsByFormsIdAndSectionId/'+formId+'/'+sectionId).subscribe(
      (data) => {
        console.log(data);
        callback(data);
    });

  }
  
  getQuestionDataOfFormlistId(formId,callback){
   return this.http.get(this.service.getBaseUrl()+'/getFormsQuestionDetailsByFormsId/'+formId).subscribe(
      (data) => {
        console.log(data);
        callback(data);
    });

  }
  
  
//  getData(){
     
//  }
 
 updateQuestionDetails(questionId,details,callback){
   return this.http.put(this.service.getBaseUrl()+'/updateFormsQuestionDetails/'+questionId,details).subscribe(
      (data) => {
        console.log(data);
        callback(data);
    });
 }


  deleteData(id){
    return this.http.delete(this.service.getBaseUrl()+'/softDeleteFormsQuestionDetailsByFormsQuestionDetailsId/'+id);
  }

  
  saveFormListData(FormData,callback){
    return this.http.post(this.service.getBaseUrl()+'/saveFormsQuestionDetailsList',FormData).subscribe(
      (data) =>{
        console.log(data);
        callback(data);
      }
    )}
}
