import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { FormsService } from '../forms/forms.service';
import { FieldTypeService } from '../field-type.service';
import { SectionsService } from '../sections/sections.service';
import { HttpClient } from '@angular/common/http';
import { FormfieldsService } from './formfields.service';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {of} from 'rxjs';
import {delay} from 'rxjs/operators';


export interface Datatype {
  value: string;
  viewValue: string;
}

export interface DropDownTable {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-formfields',
  templateUrl: './formfields.component.html',
  styleUrls: ['./formfields.component.scss']
})
export class FormfieldsComponent implements OnInit {

  displayedColumns: string[] = ['id', 'name', 'label', 'dataType','formName','htmlControlName','sectionName','dropdownTableName','edit'];
  // displayedColumns: string[] = ['id', 'name', 'label', 'dataType','formName','htmlControlName','sectionName', 'edit', 'delete'];
  dataSource = new MatTableDataSource()

  myForm: FormGroup;

  data: any = [];
  formTypeId: any;
  product: any;
  public formData;
  forms = [];
  htmlControls = [];
  sections =[];
  loading: boolean;
  isLoading = true;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('deleteSwal', { static: false }) public deleteSwal: SwalComponent;
  @ViewChild('successbox', { static: true }) public successbox: SwalComponent;
  @ViewChild('errorbox', { static: true }) public errorbox: SwalComponent;
  success;

  datatypes: Datatype[] = [
    { value: 'number', viewValue: 'number' },
    { value: 'string', viewValue: 'string' },
    { value: 'boolean', viewValue: 'boolean' },
    { value: 'date', viewValue: 'date' },
    { value: 'time', viewValue: 'time' },
    { value: 'text', viewValue: 'text' },
    { value: 'decimal', viewValue: 'decimal' }

  ]

  dropdowntablename: DropDownTable[] = [
    { value: 'tanks', viewValue: 'tanks' },
    { value: 'orb_action', viewValue: 'orb_action' },
    { value: 'orb_port', viewValue: 'orb_port' },
    { value: 'orb_method', viewValue: 'orb_method' },
    { value: 'chemical_type', viewValue: 'chemical_type' },
    { value: 'garbageCategories', viewValue: 'garbageCategories' },
    { value: 'garbageRecordDropdown', viewValue: 'garbageRecordDropdown' },
    { value: 'participant', viewValue: 'participant' },
    { value: 'rank', viewValue: 'rank' },
    { value: 'certificate', viewValue: 'certificate' },
    { value: 'crewName', viewValue: 'crewName' },
    { value: 'fuelType', viewValue: 'fuelType' },
    { value: 'supplier', viewValue: 'supplier' },
    { value: 'ballastTanks', viewValue: 'ballastTanks' },
    { value: 'longDirection', viewValue: 'longDirection' },
    { value: 'latDirection', viewValue: 'latDirection' }

  ]

  constructor(public fb: FormBuilder, public formsService: FormsService, public fieldTypeService: FieldTypeService,
    public sectionService: SectionsService, public formFieldService : FormfieldsService, public http: HttpClient) {
    this.resetForm();


  }

  ngOnInit() {

    // of(this.sections).pipe(delay(3000))
    //  .subscribe(data => {
    //    this.formData = data
    //  }, error => this.isLoading = false);
    // this.getProductDetails(this.route.snapshot.params['id']);

    this.formFieldService.getData((response) => {
      console.log(response);
      this.isLoading = false;

      this.dataSource = new MatTableDataSource(response.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })

    this.formsService.getData((response) => {
      console.log(response);
      this.forms = response.data;
      // this.dataSource = new MatTableDataSource(response.data);
    })


    this.sectionService.getData((response) => {
      console.log(response);
      this.sections = response.data;
      // this.dataSource = new MatTableDataSource(response.data);
    })

    this.fieldTypeService.getData((response) => {
      console.log(response);
      this.htmlControls = response.data;
      // this.dataSource = new MatTableDataSource(response.data);
    })


    this.myForm = this.fb.group({

      'name': new FormControl(null, Validators.required),

      'label': new FormControl(null),

      'dataType': new FormControl(null, Validators.required),
      'formId': new FormControl(null, Validators.required),
      'htmlControl': new FormControl(null, Validators.required),
      'sectionId': new FormControl(null, Validators.required),
      'dropdownTableName' : new FormControl(null)
    })


  }

  submitHandler() {
    this.loading = true;

    if (this.myForm.valid) {
      console.log(this.myForm.value);

      if (this.formFieldService.formTypeData.id) {
        this.formFieldService.putData(this.formFieldService.formTypeData.id, this.formFieldService.formTypeData, (data) => {
          console.log(data);
          this.loading = false;

          if (data.responsecode === 200 || data.responsecode === '200') {

            this.formFieldService.getData((response) => {
              console.log(response);
              this.dataSource = new MatTableDataSource(response.data);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.successbox.show();

              this.resetForm();
              this.myForm.reset();
            })
          } else {
            this.errorbox.text = data.message;
            this.errorbox.show();
          }
        })
      }
      else {
        this.formFieldService.postData(this.formFieldService.formTypeData, (response) => {
          console.log(response);
          this.loading = false;

          if (response.responsecode === 200 || response.responsecode === '200') {

            this.formFieldService.getData((response) => {
              console.log(response);
              this.dataSource = new MatTableDataSource(response.data);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.successbox.show();

              this.resetForm();
              this.myForm.reset();
            })
          } else {
            this.errorbox.text = response.message;
            this.errorbox.show();
          }
        })
      }
    }
  }



  onClick(action, data) {
    console.log(data);
    if (action == 'edit') {
      this.formFieldService.formTypeData.id = data.id;
      this.formFieldService.formTypeData.name = data.name;
      this.formFieldService.formTypeData.label = data.label;

      this.formFieldService.formTypeData.dataType = data.dataType;
      this.formFieldService.formTypeData.formId = data.formId;
      this.formFieldService.formTypeData.htmlControl = data.htmlControl;
      this.formFieldService.formTypeData.sectionId = data.sectionId;
      this.formFieldService.formTypeData.dropdownTableName = data.dropdownTableName;


    } else {
      this.formFieldService.deleteData(data.id).subscribe((data) => {
        console.log('Deleted');
        this.formFieldService.getData((response) => {
          console.log(response);
          this.dataSource = new MatTableDataSource(response.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        })
      })
    }

    window.scroll(0,0);

  }
  resetForm() {
    this.formFieldService.formTypeData.id = "";
      this.formFieldService.formTypeData.name = "";
      this.formFieldService.formTypeData.label = "";

      this.formFieldService.formTypeData.dataType = "";
      this.formFieldService.formTypeData.formId = "";
      this.formFieldService.formTypeData.htmlControl = "";
      this.formFieldService.formTypeData.sectionId = "";
      this.formFieldService.formTypeData.dropdownTableName = "";
    // this.myForm.reset();
  }


}
