import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from '../apiroot.service';

@Injectable({
  providedIn: 'root'
})
export class ChecklistvesselsService {

formTypeData = {id:'', vesselId:''}
  
constructor(public httpClient : HttpClient,public service:ApiRootService) { }

  postData(formData,callback){
      console.log('---save data--');
      console.log(formData);
      return this.httpClient.post(this.service.getBaseUrl()+'/saveNewChecklistVesselDetailswithVesselList',formData).subscribe((data)=>{
      console.log(data);
      console.log('---save data done--');
      callback(data);
    })
  }
  
  getDataById(id,callback){
    console.log('---getDataById--');
    console.log(this.service.getBaseUrl()+'/getChecklistVesselDetailsByChecklistId/'+id);
    return this.httpClient.get(this.service.getBaseUrl()+'/getChecklistVesselDetailsByChecklistId/'+id).subscribe(
      (data) =>{
           console.log('---getDataById done--');
        // console.log(data);
        callback(data);
      }
    )
  }  

  deleteData(id){
     return this.httpClient.delete(this.service.getBaseUrl()+'/softDeleteChecklistVesselDetailsByChecklistId/'+id);   
  }
}
