import { Component, OnInit, ViewChild } from '@angular/core';
import { FieldTypeService } from '../field-type.service';
import { HttpClient } from '@angular/common/http';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-field-type',
  templateUrl: './field-type.component.html',
  styleUrls: ['./field-type.component.css']
})
export class FieldTypeComponent implements OnInit {

  displayedColumns: string[] = ['fieldTypeId', 'fieldTypeName', 'edit', 'delete'];
  dataSource;

  myForm: FormGroup;
  afs: any;
  success: boolean;
  loading: boolean;
  data: any = [];
  fieldTypeId: any;
  product: any;
  public formData;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('deleteSwal', { static: false }) public deleteSwal: SwalComponent;
  @ViewChild('successbox', { static: true }) public successbox: SwalComponent;

  @ViewChild('errorbox', { static: true }) public errorbox: SwalComponent;


  constructor(public fb: FormBuilder, public fieldTypeService: FieldTypeService,
    public http: HttpClient) {
      this.resetForm();

  }

  ngOnInit() {

    // this.getProductDetails(this.route.snapshot.params['id']);
    this.fieldTypeService.getData((response) => {
      console.log(response);
      this.dataSource = new MatTableDataSource(response.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });

    this.myForm = this.fb.group({
      'fieldTypeName': new FormControl(null, [Validators.required])

    });

  }

  submitHandler() {

    if (this.myForm.valid) {
      console.log(this.myForm.value);
      if (this.fieldTypeService.formData.fieldTypeId) {
        this.fieldTypeService.putData(this.fieldTypeService.formData.fieldTypeId, this.fieldTypeService.formData, (data) => {
          console.log(data);
          if (data.responsecode === 200 || data.responsecode === '200') {

          this.fieldTypeService.getData((response) => {
            console.log(response);
            this.dataSource = new MatTableDataSource(response.data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.successbox.show();
            this.resetForm();

          });

        } else {
          this.errorbox.text = data.message;
              this.errorbox.show();
        }
        });
      } else {

        this.fieldTypeService.postData(this.fieldTypeService.formData, (response) => {
         console.log(response);
         if (response.responsecode === 200 || response.responsecode === '200') {

         this.fieldTypeService.getData((response) => {
          console.log(response);
          this.dataSource = new MatTableDataSource(response.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.successbox.show();

          this.resetForm();

        });
      } else {
          this.errorbox.text = response.message;
            this.errorbox.show();
      }
        });
      }

    }
  }

  // getProductDetails(id) {
  //   this.fieldTypeService.getDataById(id,(response)=>{
  //     console.log(response);
  //     this.product = response;
  //   })
  // }

  onClick(action, data) {
    if (action == 'edit') {
      this.fieldTypeService.formData.fieldTypeName = data.fieldTypeName;
      this.fieldTypeService.formData.fieldTypeId = data.fieldTypeId;
  } else {
    this.fieldTypeService.deleteData(data.fieldTypeId).subscribe((data) => {
      console.log('Deleted');
      this.fieldTypeService.getData((response) => {
        console.log(response);
        this.dataSource = new MatTableDataSource(response.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  });
  }
  }

  resetForm() {
    this.fieldTypeService.formData.fieldTypeName = '';
      this.fieldTypeService.formData.fieldTypeId = '';
    // this.myForm.reset();
  }

}
