import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from '../apiroot.service';


@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  

  constructor(public http:HttpClient,public service:ApiRootService) { }

  getCopsDataById(id,callback){
    return this.http.get(this.service.getBaseUrl()+'/getCOSPByvesselId/'+id).subscribe(
      (data) =>{
        callback(data);
      }
    )
  }
  
}
