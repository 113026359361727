import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { HttpClient } from '@angular/common/http';
import { HierarchyService } from '../hierarchy/hierarchy.service';
import { WorkflowService } from '../workflow/workflow.service';
import { WorkflowactionService } from './workflowaction.service';
import { RolesService } from '../roles/roles.service';

export interface Actions {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-workflowaction',
  templateUrl: './workflowaction.component.html',
  styleUrls: ['./workflowaction.component.scss']
})
export class WorkflowactionComponent implements OnInit {

  displayedColumns: string[] = ['workFlowActionId', 'workFlowName', 'action', 'actor', 'edit'];
  dataSource = new MatTableDataSource()

  myForm: FormGroup;

  data: any = [];
  formTypeId: any;
  product: any;
  public formData;
  workFlowNames = [];
  actors = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('deleteSwal', { static: false }) public deleteSwal: SwalComponent;
  @ViewChild('successbox', { static: true }) public successbox: SwalComponent;

  @ViewChild('errorbox', { static: true }) public errorbox: SwalComponent;
success;

actions: Actions[] = [
  { value: 'Create', viewValue: 'Create' },
  { value: 'Approve', viewValue: 'Approve' },
  { value: 'Review', viewValue: 'Review' }

]

  constructor(public fb: FormBuilder, public roleService: RolesService, public workFlowService: WorkflowService,
    public wrkflwActionService: WorkflowactionService, public http: HttpClient) {
    this.resetForm();


  }

  ngOnInit() {

    // this.getProductDetails(this.route.snapshot.params['id']);

    this.wrkflwActionService.getData((response) => {
      console.log(response);
      this.dataSource = new MatTableDataSource(response.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })

    this.workFlowService.getData((response) => {
      console.log(response);
      this.workFlowNames = response.data;
      // this.dataSource = new MatTableDataSource(response.data);
    })

    this.roleService.getData((response) => {
      console.log(response);
      this.actors = response.data;
      // this.dataSource = new MatTableDataSource(response.data);
    })

    this.myForm = this.fb.group({

      'workflowId': new FormControl(null, Validators.required),

      'action': new FormControl(null, Validators.required),

      'actor': new FormControl(null, Validators.required)
    })
  }

  submitHandler() {

    if (this.myForm.valid) {
      console.log(this.myForm.value);

      if (this.wrkflwActionService.formTypeData.workFlowActionId) {
        this.wrkflwActionService.putData(this.wrkflwActionService.formTypeData.workFlowActionId, this.wrkflwActionService.formTypeData, (data) => {
          console.log(data);

          if (data.responsecode === 200 || data.responsecode === '200') {

            this.wrkflwActionService.getData((response) => {
              console.log(response);
              this.dataSource = new MatTableDataSource(response.data);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.successbox.show();

              this.resetForm();
            })
          } else {
            this.errorbox.text = data.message;
            this.errorbox.show();
          }
        })
      }
      else {
        this.wrkflwActionService.postData(this.wrkflwActionService.formTypeData, (response) => {
          console.log(response);

          if (response.responsecode === 200 || response.responsecode === '200') {

            this.wrkflwActionService.getData((response) => {
              console.log(response);
              this.dataSource = new MatTableDataSource(response.data);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.successbox.show();

              this.resetForm();
            })
          } else {
            this.errorbox.text = response.message;
            this.errorbox.show();
          }
        })
      }
    }
  }

  getProductDetails(id) {
    this.wrkflwActionService.getDataById(id, (response) => {
      console.log(response);
      this.product = response;
    })
  }



  onClick(action, data) {
    console.log(data);
    if (action == 'edit') {
      this.wrkflwActionService.formTypeData.workFlowActionId = data.workFlowActionId;
      this.wrkflwActionService.formTypeData.workflowId = data.workFlowId;
      this.wrkflwActionService.formTypeData.action = data.action;
      this.wrkflwActionService.formTypeData.actor = data.actor;


    } else {
      this.wrkflwActionService.deleteData(data.workFlowActionId).subscribe((data) => {
        console.log('Deleted');
        this.wrkflwActionService.getData((response) => {
          console.log(response);
          this.dataSource = new MatTableDataSource(response.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        })
      })
    }
    window.scroll(0,0);

  }
  resetForm() {
    this.wrkflwActionService.formTypeData.workFlowActionId = "";
    this.wrkflwActionService.formTypeData.workflowId =  "";
    this.wrkflwActionService.formTypeData.action =  "";
    this.wrkflwActionService.formTypeData.actor =  "";
    // this.myForm.reset();
  }

}
