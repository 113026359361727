import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormbuilderService } from './formbuilder.service';
import { HttpClient } from '@angular/common/http';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ValidationDialogComponent } from './validation-dialog/validation-dialog.component';
import { of, Subject } from 'rxjs';
import { delay, debounceTime } from 'rxjs/operators';


@Component({
  selector: 'app-formbuilder',
  templateUrl: './formbuilder.component.html',
  styleUrls: ['./formbuilder.component.scss']
})
export class FormbuilderComponent implements OnInit {
  private filterSubject = new Subject<string>();
  displayedColumns: string[] = ['id', 'hierarchyId','hName','formId','formName','sectionId','sectionName','image','mobileImageUrl','fieldId','fieldName','labelName','dataType','htmlControl','dropdownTableName','validation'];


  formsData = [];
  dataSource 
  
  isLoading = true;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor( public formBuilderService: FormbuilderService,
    public http: HttpClient, public dialog: MatDialog) { 
      this.filterSubject.pipe(debounceTime(300)).subscribe(value => {
        this.applyFilter(value)
      })
    }

  ngOnInit() {

    this.formBuilderService.getData((response) => {
      console.log(response);
      this.isLoading = false;
      this.dataSource = new MatTableDataSource(response.data);
      this.formsData = response.data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    })
  }

  doFilter(value: string): void {
    this.filterSubject.next(value.trim().toLowerCase());
  }

  private applyFilter(value: string): void {
    this.dataSource.filter = value || '';
  }


  openDialog(validationData): void {
    const dialogRef = this.dialog.open(ValidationDialogComponent, {
      
      data: validationData
      
    });
  }

}

