import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from '../apiroot.service';

@Injectable({
  providedIn: 'root'
})
export class EospService {

  smtpData = {id:'', userName: '', userEmail: '',emailPassword: '',host: '',port: '',sslEnabled: '',storeType: ''}


  formCargoArrangementData = { length: '', width: '', height: '', unit: '', vesselId: '' };
  formTypeData = { makeOfHatches: '', typeOfHatchCover: '' }
  formDataWB = { sno: '', capacity: '', isActive: '', name: '', position: '', vesselId: '', weight: '', id: '' };
  formDataFW = { sno: '', capacity: '', isActive: '', name: '', position: '', vesselId: '', weight: '', id: '' };
  formDataFO = { sno: '', capacity: '', isActive: '', name: '', position: '', vesselId: '', weight: '', id: '' };
  formDataLO = { sno: '', capacity: '', isActive: '', name: '', position: '', vesselId: '', weight: '', id: '' };
  formDataOT = { sno: '', capacity: '', isActive: '', name: '', position: '', vesselId: '', weight: '', id: '' };

  constructor(public http: HttpClient, public service: ApiRootService) { }

  public saveSmtpSettings(formData, callback) {
    return this.http.post(this.service.getBaseUrl() + '/saveSmtpForRead', formData).subscribe(
      (data) => {
        console.log('SMTP settings saved successfully!', data);
        callback(data);
      },
      (error) => {
        console.error('Error saving SMTP settings:', error);
      }
    );
  }

  public getSmtpSettings(callback) {
    return this.http.get(this.service.getBaseUrl() + '/getSmtpReadMailById/1').subscribe(
      (data: any) => {
        console.log(data);
        if (data.responsecode === 200) {
          this.smtpData = data.message;
        }
        callback(data);
      },
      (error) => {
        console.error('Error fetching SMTP settings:', error);
      }
    );
  }
  

  public getSmtpSettingsById(id: number, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getSmtpReadMailById/' + 1).subscribe(
      (data) => {
        console.log('SMTP settings fetched successfully!', data);
        callback(data);
      },
      (error) => {
        console.error('Error fetching SMTP settings:', error);
      }
    );
  }

  public getParentCategory(callback) {
    return this.http.get(this.service.getBaseUrl() + '/getAllFolderWithChildInTree').subscribe(
      (data) => {
        console.log(data);
        callback(data);
      });

  }

  public saveCargoArrangementData(formData, callback) {
    return this.http.post(this.service.getBaseUrl() + '/saveCargoArrangmentsList', formData).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      });

  }

  public deleteCargoArrangementData(id) {
    return this.http.delete(this.service.getBaseUrl() + '/softDeleteCargoArrangmentsByid/' + id);

  }

  updateCargoArrangementData(id, formData, callback) {
    return this.http.put(this.service.getBaseUrl() + '/updateCargoArrangments/' + id, formData).subscribe((data) => {
      console.log(data);
      callback(data);

    })
  }

  public getCargoArrangementData(vesselid, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getCargoArrangmentsByVesselId/' + vesselid).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      });

  }

  public saveCargoDeckAndHatch(formData, callback) {
    //alert(this.formTypeData.makeOfHatches); return;
    return this.http.post(this.service.getBaseUrl() + '/saveDeckAndHatchesList', formData).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      });

  }

  public deleteCargoDeckAndHatchData(id) {
    return this.http.delete(this.service.getBaseUrl() + '/softDeleteDeckAndHatchesByid/' + id);

  }

  updateCargoDeckAndHatchData(id, formData, callback) {
    return this.http.put(this.service.getBaseUrl() + '/updateDeckAndHatches/' + id, formData).subscribe((data) => {
      console.log(data);
      callback(data);

    })
  }

  public getCargoDeckAndHatchData(vesselid, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getDeckAndHatchesByVesselId/' + vesselid).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      });

  }

  //Tanks

  getDataWB(id, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getWaterBallastTanksByVesselId/' + id).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }

  getDataFW(id, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getFreshWaterByVesselId/' + id).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }

  getDataFO(id, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getFuelOilByVesselId/' + id).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }
  getDataLO(id, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getLubricatingOilTanksByVesselId/' + id).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }
  getDataOT(id, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getOtherTanksByVesselId/' + id).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }

  postDataWB(formDataWB, callback) {
    return this.http.post(this.service.getBaseUrl() + '/saveWaterBallastTanks', formDataWB).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      });

  }
  postDataFW(formDataFW, callback) {
    return this.http.post(this.service.getBaseUrl() + '/saveFreshWater', formDataFW).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      });

  }
  postDataFO(formDataFO, callback) {
    return this.http.post(this.service.getBaseUrl() + '/saveFuelOil', formDataFO).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      });
  }
  postDataLO(formDataLO, callback) {
    return this.http.post(this.service.getBaseUrl() + '/saveLubricatingOilTanks', formDataLO).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      });
  }
  postDataOT(formDataOT, callback) {
    return this.http.post(this.service.getBaseUrl() + '/saveOtherTanks', formDataOT).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      });
  }

  putDataWB(id, formDataWB, callback) {
    return this.http.put(this.service.getBaseUrl() + '/updateWaterBallastTanks/' + id, formDataWB).subscribe((data) => {
      console.log(data);
      callback(data);
    }
    )
  }
  putDataFW(id, formDataFW, callback) {
    return this.http.put(this.service.getBaseUrl() + '/updateFreshWater/' + id, formDataFW).subscribe((data) => {
      console.log(data);
      callback(data);
    }
    )
  }

  putDataFO(id, formDataFO, callback) {
    return this.http.put(this.service.getBaseUrl() + '/updateFuelOil/' + id, formDataFO).subscribe((data) => {
      console.log(data);
      callback(data);
    }
    )
  }
  putDataLO(id, formDataLO, callback) {
    return this.http.put(this.service.getBaseUrl() + '/updateLubricatingOilTanks/' + id, formDataLO).subscribe((data) => {
      console.log(data);
      callback(data);
    }
    )
  }
  putDataOT(id, formDataOT, callback) {
    return this.http.put(this.service.getBaseUrl() + '/updateOtherTanks/' + id, formDataOT).subscribe((data) => {
      console.log(data);
      callback(data);
    }
    )
  }


  deleteDataWB(id) {
    return this.http.delete(this.service.getBaseUrl() + '/softDeleteWaterBallastTanksByid/' + id);
  }
  deleteDataFW(id) {
    return this.http.delete(this.service.getBaseUrl() + '/softDeleteFreshWaterByid/' + id);
  }
  deleteDataFO(id) {
    return this.http.delete(this.service.getBaseUrl() + '/softDeleteFuelOilByid/' + id);
  }
  deleteDataLO(id) {
    return this.http.delete(this.service.getBaseUrl() + '/softDeleteLubricatingOilTanksByid/' + id);
  }
  deleteDataOT(id) {
    return this.http.delete(this.service.getBaseUrl() + '/softDeleteOtherTanksByid/' + id);
  }


}
