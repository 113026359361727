import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl,ReactiveFormsModule,FormArray } from '@angular/forms';
import { AddnoteService } from './addnote.service';
import { HttpClient } from '@angular/common/http';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { SessionStorageService } from 'angular-web-storage';
import { ActivatedRoute, RouterModule, Routes,Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-addnote',
  templateUrl: './addnote.component.html',
  styleUrls: ['./addnote.component.scss']
})
export class AddnoteComponent implements OnInit {

  formData: any = [];
  rows: FormArray;
  itemForm: any = [];
  noteData: any = [];
  checklistId:any; sectionId:any;
  showEdit: any;
  htmlControls = [];
  dataExists:any;
  noteListType:any = [];
  
    @ViewChild('deleteSwal', { static: false }) public deleteSwal: SwalComponent;
  @ViewChild('successbox', { static: true }) public successbox: SwalComponent;

  @ViewChild('errorbox', { static: true }) public errorbox: SwalComponent;
  
  constructor(private router:Router, public addnoteService: AddnoteService,private formBuilder: FormBuilder, public session: SessionStorageService) { 
       this.rows = this.formBuilder.array([]);
  }


 
  ngOnInit() {
      this.checklistId = this.session.get('checklistId');
      this.sectionId = 1;
      
      this.formData = this.formBuilder.group({
              checklistId: new FormControl(null)
      })
      
      this.addnoteService.getAllNoteByChecklistId(this.checklistId,(response) => {
            console.log(response.data);
            this.noteData = response.data;
            if(response.data){
                this.addDataRowArrangement(this.noteData.length,this.noteData);
            }else{
                this.onAddSectionArrangement(1);
                
            }
       })
       
       
       
      this.formData.addControl('rows', this.rows);
      this.createNoteTypeJson();
    
  }
  
  submitHandler(){
      console.log("before save");
      this.itemForm = [];
      if (this.formData.valid) {
        console.log(this.formData.value);
          for(let row of this.formData.get('rows').controls){
              if(row.controls.newsection.value==1){
                  this.itemForm.push({'checklistId':this.checklistId,'title':row.controls.title.value,'description':row.controls.description.value,'position':row.controls.position.value});
              }
          }

           console.log(this.itemForm); 
        
        if(this.itemForm.length>0){
               this.addnoteService.saveChecklistNotes(this.itemForm, (response) => {
                     console.log(response);
                    if (response.responsecode === 200 || response.responsecode === '200') {
                            this.successbox.show();
                             //this.resetForm();
                    } else {
                              this.errorbox.text = response.message;
                              this.errorbox.show();
                    }
                })
        }
           
           console.log(this.itemForm);
       }
  }
  
  createNoteTypeJson(){
      this.noteListType[0] = {'notTypeValue':'Header','notTypeName':'Header Note'};
      this.noteListType[1] = {'notTypeValue':'Footer','notTypeName':'Footer Note'};
      this.noteListType[2] = {'notTypeValue':'FooterBottom','notTypeName':'Footer below Signature'};
  }
  
  
  onSectionSelect(index,section_id){
            for (let r = 0; r<this.rows.controls.length; r++){
                    if(r==index){//alert(index)
                        this.rows.controls[r]['controls']['sectionId'].value==section_id;
                    }
                }
   }
   
   onAddSectionArrangement(event: any) {
     console.log(event);
        var arr = {'checklistId':this.checklistId,'title':'','position':'Header','description':'','newsection':1,'hidingsection':0,'id':null}
        for(var i=0; i<event; i++){
            this.rows.push(this.createItemFormGroupArrangement(arr));
          console.log(this.rows);
        }
   }
  
    addDataRowArrangement(count,arr: any) {
       for(var i=0; i<count; i++){
           this.rows.push(this.createItemFormGroupArrangement(arr[i]));

       }
     }
     
     
 createItemFormGroupArrangement(datas: any): FormGroup {
                    
    return this.formBuilder.group({
      checklistId: (datas.checklistId),
      description: (datas.description),
      position: (datas.position),
      title: (datas.title),
      newsection:(datas.newsection?datas.newsection:0),
      hidesection:(0),
      id:(datas.id?datas.id:null)
    });
  }
  

  onRemoveRowArrangement(rowIndex:number){
        if(this.formData.value.rows[rowIndex].id){
              this.addnoteService.deleteData(this.formData.value.rows[rowIndex].id).subscribe((data) => {
                console.log('Deleted');
              })
            }
        
        this.rows.removeAt(rowIndex);
        if(this.rows.length<=0)
        this.router.navigate(['/checklist']);
    }
    
    updateRowArrangement(rowIndex:number){
      console.log("click")
        console.log(this.formData.value.rows[rowIndex]);
              if (this.formData.value.rows[rowIndex].newsection==0) {
                    this.addnoteService.updateNotesDetails(this.formData.value.rows[rowIndex].id, this.formData.value.rows[rowIndex], (data) => {
                      console.log(data);
                      if (data.responsecode === 200 || data.responsecode === '200') {
                       this.successbox.show();
                      } else {
                        this.errorbox.text = data.message;
                        this.errorbox.show();
                      }
                    })
            }
        this.showEdit = -1;
    }
    
    editRowArrangement(rowIndex:number){
        if (this.formData.value.rows[rowIndex].newsection==0) {
            this.showEdit = rowIndex;
        }
    }        
  
  backButton(){
   this.router.navigate(['/checklist']);   
  }
  
    
  
  htmlContent = '';

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    sanitize: false,
    height: '8rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
  
  
  
  
  

}
