import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import * as pbi from 'powerbi-client';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['../app.component.scss', './dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})


export class DashboardComponent implements OnInit {
  constructor() { }
  @ViewChild('reportContainer', { static: true }) reportContainer: ElementRef;

   public screenHeight: number;

  ngOnInit() {
    this.screenHeight = (window.screen.height);
       const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6ImppYk5ia0ZTU2JteFBZck45Q0ZxUms0SzRndyIsImtpZCI6ImppYk5ia0ZTU2JteFBZck45Q0ZxUms0SzRndyJ9.eyJhdWQiOiJodHRwczovL2FuYWx5c2lzLndpbmRvd3MubmV0L3Bvd2VyYmkvYXBpIiwiaXNzIjoiaHR0cHM6Ly9zdHMud2luZG93cy5uZXQvODhlZDc1YzYtMGMyZi00ZjNjLWIyYTItNjBkMDliMDU3MDAwLyIsImlhdCI6MTU5OTczNzgzNCwibmJmIjoxNTk5NzM3ODM0LCJleHAiOjE1OTk3NDE3MzQsImFjY3QiOjAsImFjciI6IjEiLCJhaW8iOiJBU1FBMi84UUFBQUFucmNIN2ZENlJISC8rTG82NkY3ekpXc1pOU0JTNmpRUHFjZmtXWGphQ0w4PSIsImFtciI6WyJwd2QiXSwiYXBwaWQiOiI3ZjU5YTc3My0yZWFmLTQyOWMtYTA1OS01MGZjNWJiMjhiNDQiLCJhcHBpZGFjciI6IjIiLCJmYW1pbHlfbmFtZSI6IlNpbmhhIiwiZ2l2ZW5fbmFtZSI6IkFydmluZCIsImlwYWRkciI6IjE1Ny40Ny42Mi4xMTciLCJuYW1lIjoiQXJ2aW5kIFNpbmhhIiwib2lkIjoiMTljNWRhMWUtZDlmZi00ODAzLTk5ZDMtOGEwZTdmYzM2ZmU5IiwicHVpZCI6IjEwMDMyMDAwQzU5NzM3NDAiLCJyaCI6IjAuQUFBQXhuWHRpQzhNUEUteW9tRFFtd1Z3QUhPbldYLXZMcHhDb0ZsUV9GdXlpMFJ5QUFRLiIsInNjcCI6InVzZXJfaW1wZXJzb25hdGlvbiIsInN1YiI6Ilk3OGxTOE11REdtNkhfVUxBajNnMFRjWEtwbFdvZndKeXFQbXZPbjZ0X3ciLCJ0aWQiOiI4OGVkNzVjNi0wYzJmLTRmM2MtYjJhMi02MGQwOWIwNTcwMDAiLCJ1bmlxdWVfbmFtZSI6ImFydmluZEBlc29mdGVjaC5jb20iLCJ1cG4iOiJhcnZpbmRAZXNvZnRlY2guY29tIiwidXRpIjoiZzROWG85Rlp1RS1hTE9ybDNPcHpBQSIsInZlciI6IjEuMCJ9.LHyTJD_r0GJi9yGfNhBBu08hYMOM0POX9isWt7i8Z7vc8u1gDMGYXdr9gEQ4zs9VGxIDKD0NXFAVKc0hiRVZAXZsYiSRKel9kSy0W9DqMqRVG2guJWjqNFt6fRZqbjoeyeULogcKkRxIOBZVNDM8E5XbecI0CJ9F3JPMjJSC9KUsIFA9OJcHbIcfHAkgL89VPlSQ6etk7p9pl80QuJ36lpBkHsUW3pL4M0eyHJOo9UYwrdzZ_9EzZKHcRO0dJDPv50EIXsjMafdUpHkhJBxEK-7p2vfN9Tm0zPdyRdPUldqpCkwhU_tOkBskQInKrwWnU9OZnnDeXEhF9fWrdextwg';
       this.showReport(token);
  }
//  @ViewChild('reportContainer') reportContainer: ElementRef;

showReport(accessToken) {
  // Embed URL
  const embedUrl = 'https://app.powerbi.com/reportEmbed?reportId=ce5ea58a-557b-4f99-8557-db31bda30d86&autoAuth=true&ctid=88ed75c6-0c2f-4f3c-b2a2-60d09b057000&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWluZGlhLWNlbnRyYWwtYS1wcmltYXJ5LXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9' + 'ce5ea58a-557b-4f99-8557-db31bda30d86' + '&groupId=' + '8e18fadf-e224-434e-a391-753b64b8f3bf';
  const embedReportId = 'ce5ea58a-557b-4f99-8557-db31bda30d86';
  const config = {
      type: 'report',
      accessToken: accessToken,
      embedUrl: embedUrl,
      id: embedReportId,
      settings: {}
  };
  const reportContainer = this.reportContainer.nativeElement;
  const powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
  const report = powerbi.embed(reportContainer, config);
  report.off('loaded');
  report.on('loaded', () => {
      console.log('Loaded');
  });
  report.on('error', () => {
      // this.getToken();
  });
}
}
