import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from '../apiroot.service';

@Injectable({
  providedIn: 'root'
})
export class LogbookformsService {

 formData = {bookTypeId:'',formId:''};
 //FormtypeDate = {actionDate:'',actionTime:'',changeFields:'',formId:'',is_mobile_sync:'',is_sync:'',rowId:'',sectionId:'',timeStamp:'',vesselId:'',voyageId:'',voyageNo:'',watchId:'',userId:'',userName:'',userSign:''}
// formTypeData = {clientName:'',comment:'',date:'',name:'',rating:'',signOffItemsList:[{date:'',designation:'',itemName:'',name:'',sign:'',status:''}],signature:'',vessel:'',vesselIMONo:''}
 
  constructor(public http:HttpClient,public service:ApiRootService) { }

  public getParentCategory(callback){
    return this.http.get(this.service.getBaseUrl()+'/getAllFolderWithChildInTree').subscribe(
      (data) => {
        console.log(data);
        callback(data);
    });

  }
  
 public getLogBookDetailsById(id,callback){
    return this.http.get(this.service.getBaseUrl()+'/getAllFolderWithChildInTree').subscribe(
      (data) => {
        console.log(data);
        callback(data);
    });

  }
  
  
  public getFormByHid(callback){
    return this.http.get(this.service.getBaseUrl()+'/getAllFormBuilder').subscribe(
      (data) => {
      //  console.log(data);
        callback(data);
    });

  }
  
  public getFormDetails(ids,voyageNo,callback){
    return this.http.get(this.service.getBaseUrl()+'/getFormDataWithAllRecordsByVoyageNo/'+ids+'/'+voyageNo).subscribe(
      (data) => {
        console.log(data);
        callback(data);
    });

  }
  
  public getFormDetailsByIds(ids,voyageNo,callback){
    return this.http.get(this.service.getBaseUrl()+'/getFormDataWithAllRecordsByVoyageNoGroupByActionDateTime/'+ids+'/'+voyageNo).subscribe(
      (data) => {
       // console.log(data);
        callback(data);
    });

  }
  
    public getFormDetailsByActionDateAndTime(ids,voyageNo,actionDate='',actionTime='',fromDate='',toDate='',callback){
        //alert(actionDate+"===="+actionTime+'====='+fromDate+'========='+toDate);
    if(fromDate){
        if(toDate=='' || toDate==null){
          toDate='NoDate';  
        }
        if(actionTime=='' || actionTime==null){
          actionTime='NoTime';              
        } 
        //alert(this.service.getBaseUrl()+'/getFormDataWithAllRecordsByVoyageNoWithFromToDateTime/'+ids+'/'+voyageNo+'/'+fromDate+'/'+toDate+'/'+actionTime);
        return this.http.get(this.service.getBaseUrl()+'/getFormDataWithAllRecordsByVoyageNoWithFromToDateTime/'+ids+'/'+voyageNo+'/'+fromDate+'/'+toDate+'/'+actionTime).subscribe(
          (data) => {
            console.log(data);
            callback(data);
        });          
               
    }else if(actionTime && actionTime!=''){
       //alert(this.service.getBaseUrl()+'/getFormDataWithAllRecordsByVoyageNoWithActionDateTime/'+ids+'/'+voyageNo+'/'+actionDate+'/'+actionTime);
        return this.http.get(this.service.getBaseUrl()+'/getFormDataWithAllRecordsByVoyageNoWithActionDateTime/'+ids+'/'+voyageNo+'/'+actionDate+'/'+actionTime).subscribe(
          (data) => {
            console.log(data);
            callback(data);
        });
    }else{  
       // alert(this.service.getBaseUrl()+'/getFormDataWithAllRecordsByVoyageNoWithActionDate/'+ids+'/'+voyageNo+'/'+actionDate);
        return this.http.get(this.service.getBaseUrl()+'/getFormDataWithAllRecordsByVoyageNoWithActionDate/'+ids+'/'+voyageNo+'/'+actionDate).subscribe(
          (data) => {
            console.log(data);
            callback(data);
        });  
    }  

  }
  
    public getFormDetailsById(id,voyageNo,callback){
       return this.http.get(this.service.getBaseUrl()+'/getFormDataWithAllRecordsByVoyageNo/'+id+'/'+voyageNo).subscribe(
         (data) => {
           console.log(data);
           callback(data);
       });

     }
  
    public getAllActiveWatchHour(callback){
      return this.http.get(this.service.getBaseUrl()+'/getAllWatchHourWithActive').subscribe(
        (data) => {
     //     console.log(data);
          callback(data);
      });

    }
  
  
    public getWatchHourById(id,callback){
      return this.http.get(this.service.getBaseUrl()+'/getDLBWatchHourById/'+id).subscribe(
        (data) => {
          console.log(data);
          callback(data);
      });

    }
    
    public  getLatestCOSPByPasscode(id,callback){
    return this.http.get(this.service.getBaseUrl()+'/getLatestCOSPByPasscode/'+id).subscribe(
      (data) =>{
      //  console.log(data); 
        callback(data);
      }
    )}

    public updateRankSignatureByFormAndRecordId(logbookAuthorizedBy,formId,recordId, callback){
      return this.http.post(this.service.getBaseUrl()+'/updateRankSignatureByFormAndRecordId/'+formId+'/'+recordId,logbookAuthorizedBy).subscribe(
        (data) =>{
            console.log(data);
           callback(data);
        }
      )
    }

    public updateRankSignatureForDeckByFormAndRecordId(logbookAuthorizedBy,formId,recordId, callback){
      return this.http.post(this.service.getBaseUrl()+'/updateRankSignatureForDeckByFormAndRecordId/'+formId+'/'+recordId,logbookAuthorizedBy).subscribe(
        (data) =>{
            console.log(data);
           callback(data);
        }
      )
    } 

    public createTableByFormData(FormData,callback){
      return this.http.post(this.service.getBaseUrl()+'/createTableByFormData',FormData).subscribe(
        (data) =>{
          console.log("mainmain")
            console.log(data);
           callback(data);
        }
      )
    }

    public  getCrewSignByCrewId(id,callback){
      return this.http.get(this.service.getBaseUrl()+'/getCrewByCrewId/'+id).subscribe(
        (data) =>{
          console.log(data); 
          callback(data);
        }
      )}

     
        //for popup 
        public getAllWatchHoursWithActive(callback){
          return this.http.get(this.service.getBaseUrl()+'/getAllWatchHourWithActive').subscribe(
            (data) => {
            //  console.log(data);
              callback(data);
          });
        }

        //for forms
        public getAllFormBuilder(callback){
          return this.http.get(this.service.getBaseUrl()+'/getAllFormBuilder').subscribe(
            (data) => {
          //    console.log(data);
              callback(data);
          });
        }

     


        //for tanks 
      public getAllTanks(callback)
      {
        return this.http.get(this.service.getBaseUrl()+'/getAllTanks').subscribe(
          (data) => {
        //    console.log(data);
            callback(data);
        });
      }

      //decklogbook crewrecord management rank
      public getAllRank(callback){
        return this.http.get(this.service.getBaseUrl()+'/getAllRank').subscribe(
          (data) => {
          //  console.log(data);
            callback(data);
        });
  
      }
      public getAllDropDowns(callback){
        return this.http.get(this.service.getBaseUrl()+'/getAllDropDowns').subscribe(
          (data) => {
         //   console.log(data);
            callback(data);
        });
      
      }

     

      public getVesselsByVesselPasscode(passcode,callback){
        return this.http.get(this.service.getBaseUrl()+'/getVesselsByVesselPasscode/'+passcode).subscribe((data)=>{
          callback(data);
        })
      }
} 
