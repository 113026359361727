import { Component,NgZone} from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ApiRootService } from '../../apiroot.service';
@Component({
  selector: 'app-clickme',
  template: `
    <input #newHero value="{{cookieIp}}"> &nbsp; &nbsp;
    <button (click)="addHero(newHero.value)">Save</button> &nbsp; &nbsp; &nbsp;
    <button (click)="getSystemIP()">Get System IP</button>
  `
})
export class ClickmeComponent {

 public localIp = sessionStorage.getItem('LOCAL_IP');
 private ipRegex = new RegExp(/([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/);

 public cookieIp = "";
    constructor(private cookieService: CookieService,private zone: NgZone,public apiRoot: ApiRootService){
        this.cookieIp = cookieService.get('campbell_app_ip_address');
    }

  addHero(newHero: string) {
    if (newHero) {
      this.saveFile(newHero);
    }
  }

   saveFile(data) {
            this.cookieService.set( 'campbell_app_ip_address', data, 365 ,'/','',false,'Lax');
            //this.cookieService.set('campbell_app_ip_address', data, 36);
            this.cookieIp = this.cookieService.get('campbell_app_ip_address');
            this.apiRoot.APIROOT = "http://"+this.cookieIp+":9002/campbellservice";
            this.apiRoot.DEVAPIROOT = "http://"+this.cookieIp+":9002/campbellservice";
            this.apiRoot.AUTH = "http://"+this.cookieIp+":9002";
            this.apiRoot.initializeURL();
            alert("server ip address has been set successfully");
   }

   getSystemIP(){
       this.determineLocalIp();
       this.cookieIp = this.localIp;
   }

   private determineLocalIp() {
    window.RTCPeerConnection = this.getRTCPeerConnection();

    const pc = new RTCPeerConnection({ iceServers: [] });
    pc.createDataChannel('');
    pc.createOffer().then(pc.setLocalDescription.bind(pc));

    pc.onicecandidate = (ice) => {
      this.zone.run(() => {
        if (!ice || !ice.candidate || !ice.candidate.candidate) {
          return;
        }

        this.localIp = this.ipRegex.exec(ice.candidate.candidate)[1];
        sessionStorage.setItem('LOCAL_IP', this.localIp);

        pc.onicecandidate = () => {};
        pc.close();
      });
    };
  }

  private getRTCPeerConnection() {
    return window.RTCPeerConnection ||
      window.mozRTCPeerConnection ||
      window.webkitRTCPeerConnection;
  }

}
