import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from '../apiroot.service';

@Injectable({
  providedIn: 'root'
})
export class StatussheetService {

  constructor(public http:HttpClient,public service:ApiRootService) { }

  public saveVesselsStatusSheet(Formdata,callback){
    return this.http.post(this.service.getBaseUrl()+'/saveVesselsStatusSheet',Formdata).subscribe((data)=>{
      callback(data);
    })
  }

  public getVesselsByVesselPasscode(passcode,callback){
    return this.http.get(this.service.getBaseUrl()+'/getVesselsByVesselPasscode/'+passcode).subscribe((data)=>{
      callback(data);
    })
  }
}
