import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from '../apiroot.service';

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  formData = {id:'',formName:'',mobileImage:'assets/media/logo/logo.png',formImage:'assets/media/logo/logo.png',hierarchyId:''}

  constructor(public http:HttpClient,public service:ApiRootService) { }

  postData(formData,callback){
   return this.http.post(this.service.getBaseUrl()+'/saveNewFormList', formData).subscribe(
      (data) => {
        console.log(data);
        callback(data);
    });

  }

  getData(callback){
    return this.http.get(this.service.getBaseUrl()+'/getAllFormList').subscribe(
      (data) =>{
        console.log(data);
        callback(data);
      }
    )}

    getHierarchyData(callback){
      return this.http.get(this.service.getBaseUrl()+'/getAllFolder').subscribe(
        (data) =>{
          console.log(data);
          callback(data);
        }
      )}

    uploadImage(formData,callback){
      return this.http.post(this.service.getBaseUrl()+'/uploadFile',formData).subscribe((data)=>{
        callback(data);
      })
  
    }


    getFormType(callback){
      return this.http.get(this.service.getBaseUrl()+'/getAllFormType').subscribe(
        (data) =>{
          console.log(data);
          callback(data);
        }
      )}

    // getDataById(id,formData,callback){
    //   return this.http.get('http://192.168.0.2:8087/campbellservice/getfieldTypeByfieldTypeId/'+id,formData).subscribe(
    //     (data) =>{
    //       // console.log(data);
    //       callback(data);
    //     }
    //   )}

    getDataById(id,callback){
      return this.http.get(this.service.getBaseUrl()+'/getfieldTypeByfieldTypeId/'+id).subscribe(
        (data) =>{
          // console.log(data);
          callback(data);
        }
      )}

    putData(id,formData,callback){
      return this.http.put(this.service.getBaseUrl()+'/updateFormList/'+id,formData).subscribe((data)=>{
        console.log(data);
        callback(data);
    }
  )}

  deleteData(id){
    return this.http.delete(this.service.getBaseUrl()+'/softDeleteFormLists/'+id);
  }
}
