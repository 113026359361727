import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from '../apiroot.service';

@Injectable({
  providedIn: 'root'
})

export class AddnoteService {

  formData = {section_id:''}

  constructor(public http:HttpClient,public service:ApiRootService) { }

  getAllNoteByChecklistId(id,callback){
    return this.http.get(this.service.getBaseUrl()+'/getChecklistNotesByChecklistId/'+id).subscribe(
      (data) =>{
        console.log(data);
        callback(data);
      }
    )}

  getAllSections(callback){
   return this.http.get(this.service.getBaseUrl()+'/getAllChecklistSections').subscribe(
      (data) => {
        console.log(data);
        callback(data);
    });

  }
  
  getQuestionData(checklistid,sectionid,callback){
   return this.http.get(this.service.getBaseUrl()+'/getChecklistQuestionDetailsByChecklistIdAndSectionId/'+checklistid+'/'+sectionid).subscribe(
      (data) => {
        console.log(data);
        callback(data);
    });

  }
  
  getQuestionDataOfChecklistId(checklistid,callback){
   return this.http.get(this.service.getBaseUrl()+'/getChecklistQuestionDetailsByChecklistId/'+checklistid).subscribe(
      (data) => {
        console.log(data);
        callback(data);
    });

  }
  
  
 getData(){
     
 }
 
 updateNotesDetails(noteId,details,callback){
   return this.http.put(this.service.getBaseUrl()+'/updateChecklistNotes/'+noteId,details).subscribe(
      (data) => {
        console.log(data);
        callback(data);
    });
 }


  deleteData(id){
    return this.http.delete(this.service.getBaseUrl()+'/softDeleteChecklistNotesByChecklistNotesId/'+id);
  }

  
  saveChecklistNotes(FormData,callback){
    return this.http.post(this.service.getBaseUrl()+'/saveChecklistNotesList',FormData).subscribe(
      (data) =>{
        console.log(data);
        callback(data);
      }
    )}
    
    
   
    

}
