import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatPaginator, MatSort } from '@angular/material';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { of } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { NgxCarousel } from 'ngx-carousel';
import { ReportsService } from '../reports/reports.service';
import { CompanyService } from '../company/company.service';
import { SessionStorageService } from 'angular-web-storage';
import { VesselsService } from '../vessels/vessels.service';
import { LocalStorageService } from 'src/app/local-storage.service';
import { NetworkStatusService } from 'src/app/network-status.service.ts.service';
import { AppCommonService } from 'src/app/app.common.service';
import * as _ from "lodash";
import { Router } from '@angular/router';
import { Observable, from } from 'rxjs';
import { concatMap } from 'rxjs/operators';

@Component({
  selector: 'app-weeklyworkentry',
  templateUrl: './weeklyworkentry.component.html',
  styleUrls: ['./weeklyworkentry.component.scss']
})


export class WeeklyworkentryComponent implements OnInit {
  myForm: FormGroup;
  loading: boolean;
  data: any = [];
  vesselName: any;
  companyId: any;
  companyName: any;
  companyImage: any;
  userVesselData: any = [];
  companyList: any = [];
  currentVessel: string;
  selectedCompany: string;
  formData: any;
  formDataLoad: any;
  newCompanyId: number;
  newVesselId: number;
  reportList: any = [];
  weeklyformlist: boolean;
  isOnline: boolean = true;
  matchedEntries: any[] = [];
  offlineRecordid;
  showButton: boolean;
  editFormId: number;
  syncMsgLoading: boolean = false;
  wwdOfflineFormData: any = [];
  offlineRecordLimit: number = 6;
  shipLoading: boolean = false;
  serverErrMsg: string = "The request took too long to respond. Please try again.";
  deviceErrMsg: string = "No internet connection detected. Please check your network.";
  connErrtitle: string = "Connection Issue"


  public Config: NgxCarousel = {
    grid: { xs: 2, sm: 3, md: 3, lg: 5, all: 0 },
    slide: 1,
    speed: 5,
    interval: 10000,
    point: {
      visible: true
    },
    touch: true,
    loop: false,
    custom: 'banner'
  };

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('successbox', { static: true }) successbox: SwalComponent;
  @ViewChild('errorbox', { static: true }) errorbox: SwalComponent;

  constructor(public cmps: CompanyService, public rs: ReportsService, public vs: VesselsService, public session: SessionStorageService, public fb: FormBuilder,
    public http: HttpClient,
    public commonService: AppCommonService,
    private router: Router,
    public LocalStorage: LocalStorageService,
    public networkStatusService: NetworkStatusService,
  ) { }

  ngOnInit() {
    console.log("ngoninit fire");
    this.weeklyformlist = true;
    this.networkStatusService.isOnline.subscribe(status => {
      this.isOnline = status;
      console.log("this.isOnline:", this.isOnline);
      this.getAllEventOnload();
    });

    this.myForm = this.fb.group({})
  }
  
  toggleLoader(status: boolean = false){
    this.loading = status;
    console.log("==Loader-status==", this.loading)
  }

  setsyncMsgLoading(status: boolean = false){
    this.shipLoading = status;
  }

  deleteOfflineData(){

    var wwd_hseq= false;
    var wwd_technical= false;
    var wwd_mlc= false;

    new Promise((resolve, reject) => {
      this.LocalStorage.deleteMatchingKeys("wwd_hseq_").then((result) => {
        wwd_hseq=true;
        console.log("[setTimeout]wwd_hseq_Delete operation result:", result);
      }).catch((error) => {
        console.error("Error during delete operation:", error);
      });
      this.LocalStorage.deleteMatchingKeys("wwd_technical_").then((result) => {
        wwd_technical=true;
        console.log("[setTimeout]wwd_technical_Delete operation result:", result);
      }).catch((error) => {
        console.error("Error during delete operation:", error);
      });
      this.LocalStorage.deleteMatchingKeys("wwd_mlc_").then((result) => {
        wwd_mlc=true;
        console.log("[setTimeout]wwd_mlc_Delete operation result:", result);
      }).catch((error) => {
        console.error("Error during delete operation:", error);
      });
      if (wwd_hseq && wwd_technical && wwd_mlc) {
        resolve("[setTimeout]Continue execution");
      } else {
        reject("[setTimeout]Stopping further execution");
      }
    })
    .then((result) => {
      console.log(result); // This won't run if the Promise is rejected
    })
    .catch((error) => {
      console.error("[setTimeout]Stopped:", error); // Handle the stop condition here
    });
  }

  changeNetStatus() {
    if (this.isOnline) {
      this.isOnline = false;
    } else {
      this.isOnline = true;
    }
    console.log("=== this.isOnline===", this.isOnline);
    this.ngOnInit();
  }

  goBack() {
    this.showButton = true;
    this.formData = null;
    this.weeklyformlist = true;
    this.closeForm();
  }

  getVesselData(event) {
    console.log("==getVesselData-loader-true==")
    this.toggleLoader(true)
    this.showButton = true;
    this.formData = null;
    this.weeklyformlist = true;
    this.closeForm();
    this.newVesselId = event;
    console.log('===userVesselData===', this.userVesselData);
    if(this.userVesselData.length>0){
      for (let key in this.userVesselData) {
        if (this.userVesselData[key].id == event) {
          this.vesselName = this.userVesselData[key].vesselName;
        }
      }
    }

    if(this.isOnline && this.newVesselId){
        this.rs.getMasterAndChiefByVesselId(this.newVesselId, (res) => {
          console.log("==getVesselData-loader-false==")
          this.toggleLoader();
          if (res.responsecode === 200) {
            this.add('masters', res.data[0].masterList);
            this.add('chiefOfficers', res.data[0].chiefOfficerList);
          }else if ((res.responsecode === 404 || res.responsecode === '404') || (res.responsecode === 400 || res.responsecode === '400')) {
            this.showError('Error', res.message)
          }
        });
    }

    this.get('reportList');
    this.get('masters');
    this.get('chiefOfficers');
    console.log("==getVesselData-loader-false==")
    this.toggleLoader();
  }

  syncData(){

    if (!navigator.onLine) {
      console.log("No internet connection. Unable to close the report.");
      this.showError('Network Error', this.deviceErrMsg)
      return;
    }


    if (this.isOnline) {
      this.savewwdOfflineForm();
   }
  }


  async savewwdOfflineForm() {
    try {
      const allgeneralData = await this.LocalStorage.getMatchingKeys("wwd_general_save_");
      console.log("[wwd_offline_save] wwd_general_save_ operation result:", allgeneralData);

      if (Array.isArray(allgeneralData) && allgeneralData.length > 0) {
        const lastItemIndex = allgeneralData.length - 1;
        this.setsyncMsgLoading(true);

        for (let index = 0; index < allgeneralData.length; index++) {
          const generalData = allgeneralData[index];
          console.log(`[offline_save] Processing item at index: ${index}`);
          const wwdId = generalData.replace("wwd_general_save_", "");

          try {
            const weeklyData = await this.LocalStorage.get(generalData);

            if (weeklyData) {
              await new Promise<void>((resolve, reject) => {
                this.rs.weeklyWorkDone(weeklyData, (response: any) => {
                  console.log('[offline_save] API Response:', response);

                  if (response.responsecode === 200 && response.data._id) {
                    this.wrapDelete(generalData)
                      .then(() => this.syncRelatedData(wwdId, response.data._id))
                      .then(resolve)
                      .catch(reject);
                  } else {
                    const confirmed = window.confirm(response.message + " Do you want to remove this record and reload?");
                    if (confirmed) {
                      this.wrapDelete(generalData).then(resolve).catch(reject);
                    } else {
                      reject(new Error(response.message));
                    }
                  }
                }, (error) => {  // Error Callback (Handles Timeout)
                  console.log('[offline_save] API Error:', error);
                  this.setsyncMsgLoading();
                  this.showError(this.connErrtitle, this.serverErrMsg);
                });
              });
            }
          } catch (error) {
            console.error(`[offline_save] Error processing item at index ${index}:`, error);
          }

          // Update data if last item is processed
          if (index === lastItemIndex) {
            this.updatewwdData();
          }
        }
      } else {
        console.log("[wwd_offline_save] No data to sync:", allgeneralData);
        this.updatewwdData();
      }
    } catch (error) {
      console.error("[wwd_offline_save] Unexpected error:", error);
    }
  }

  // Sync related data (HSEQ, Technical, MLC)
  private async syncRelatedData(wwdId: string, recordId: any) {
    const keys = ["hseq", "technical", "mlc"];

    for (const key of keys) {
      const saveId = `wwd_${key}_save_${wwdId}`;
      const resData = await this.LocalStorage.get(saveId);

      if (resData) {
        (resData as { data: { recordId: number }[] }).data[0].recordId=recordId;
        await new Promise<void>((resolve, reject) => {
          this.rs.weeklyWorkDone(resData, (response: any) => {
            console.log(`[offline_save] API Response (${saveId}):`, response);

            if (response.responsecode === 200 && response.data._id) {
              this.wrapDelete(saveId).then(resolve).catch(reject);
            } else {
              reject(new Error(response.message));
            }
          }, (error) => {  // Error Callback (Handles Timeout)
            console.log('[offline_save] API Error:', error);
            this.setsyncMsgLoading();
            this.showError(this.connErrtitle, this.serverErrMsg);
          });
        }).catch(error => {
          console.error(`[offline_save] Error syncing ${saveId}:`, error);
        });
      }
    }
  }

  // Wrap delete method in a Promise
  private wrapDelete(key: string): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        const result = this.delete(key); // Call original delete method
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  }



updatewwdData(){
  let matchingKeys: string[]  =["wwd_general_update_","wwd_hseq_update_","wwd_technical_update_","wwd_mlc_update_"];
  this.LocalStorage.getAllMatchingKeys(matchingKeys).then((allMatchingIds) => {
    console.log("[wwd_offline_save] operation result:", allMatchingIds);
    if(Array.isArray(allMatchingIds) && allMatchingIds.length>0){
      const lastItemIndex = allMatchingIds.length - 1;
      console.log('[offline_save] lastItemIndex:', lastItemIndex);
      this.setsyncMsgLoading(true);
      from(allMatchingIds).pipe(concatMap((matchingId, index) => {
          this.setsyncMsgLoading(true);
          console.log(`[offline_save] Processing item at index: ${index}`);
          console.log('[offline_save] weeklyData:', matchingId);

          return new Promise((resolve, reject) => {
            this.LocalStorage.get(matchingId).then((matchingData) => {
              if (matchingData) {
                this.rs.weeklyWorkDone(matchingData, (response) => {
                  console.log('[offline_save] API Response:', response);
                  if (response['responsecode'] === 200 || response['responsecode'] === '200' && response.data._id) {
                    this.delete(matchingId);
                    resolve({ success: true, index });
                  }else{
                    this.showError('', response['message'])
                    resolve({ success: true, index });
                  }
                }, (error) => {  // Error Callback (Handles Timeout)
                  console.log('[offline_save] API Error:', error);
                  this.setsyncMsgLoading();
                  this.showError(this.connErrtitle, this.serverErrMsg);
                });
              }
            });
          });
        })
        ).subscribe({next: (result) => {
            console.log(`[offline_save] Successfully processed item at index: `,result, (result as { index: number }).index);
            if ((result as { index: number }).index === lastItemIndex) {
              this.setsyncMsgLoading();
              this.syncOnlineData();
            }
          }
        });
    }else{
        console.log("[wwd_offline_save] run sync without data update:");
        this.setsyncMsgLoading();
        this.syncOnlineData();
      }
    })
  }

  syncOnlineData(){
    if (this.isOnline && this.newVesselId) {
      console.log("testnew build00")
      this.deleteOfflineData();
      this.offline_getWeeklyWorkReportList();
    }

  }

  syncOfflineData(){
    this.get('addEditForSaveList');
  }

  getAllEventOnload() {
    if (this.isOnline) {
      this.companyId = this.session.get('companyId');
      console.log("==this.companyId==",this.companyId);
      if (this.companyId) {
        this.getVesselList(this.companyId);
        this.getCompanyDetails(this.companyId);
      } else {
        this.getCompanyList();
      }
    }else{
      this.get('companyListData');
      this.get('userVesselData');
      if(this.newVesselId){
        this.get('reportList');
      }
    }
  }

  ngAfterViewInit(): void {
    //this.ngOnInit();
  }

  synchronizeDataGet() {
    this.get('companyListData');
    this.get('userVesselData');
    if(this.newVesselId){
      this.get('reportList');
    }
  }

  showCompanyListDetailData(companyList: any) {
    if (companyList && companyList.length > 0) {
      const companyListLength = companyList.length;
      let index = 0;
      const companyInterval = setInterval(() => {
        if (index < companyListLength) {
          const company = companyList[index];
          console.log('[INFO] Current company data:', company);
          if (company && company.id) {
            this.getVesselList(company.id);
          }
          index++;
        } else {
          console.log('[INFO] Completed all iterations of the company list.');
          clearInterval(companyInterval);
        }
      }, 500);

    }
  }


  add(keyName, value) {
    this.LocalStorage.add(keyName, value).then(res => {
      if (res) {
        console.log(keyName + " Inserted Successfully.",)
      }
    });
  }

  get(keyName) {
    this.resetIndexData(keyName);
    this.LocalStorage.get(keyName).then(res => {
      if (res) {
        if (keyName === 'companyListData') {
          this.companyList = res;
        }else if (keyName === 'userVesselData') {
          this.userVesselData = res;
        }else if (keyName === 'reportList') {
          this.reportList = res;
        }
      }
      console.log(' keyName: ',keyName,' res: ',res);
    });
  }

  resetIndexData(keyName){
    console.log(' keyName: ',keyName);
    if (keyName === 'companyListData') {
      this.companyList = [];
    }else if (keyName === 'userVesselData') {
      this.userVesselData = [];
    }else if (keyName === 'reportList') {
      this.reportList = [];
    }else if (keyName === 'addEditForSaveList') {
      this.wwdOfflineFormData = [];
    }
  }

  delete(keyName) {
    console.log(' before delete ',keyName)
    this.LocalStorage.delete(keyName).then(res => {
      if (res === 'Success') {
        console.log(' after delete ',keyName)
      }
    });
  }

  offline_getWeeklyWorkReportList() {
    console.log('[INFO] function triggered with vesselId:', this.newVesselId);

    if(this.newVesselId) {
      const formIds = [81, 82, 83];
      this.setsyncMsgLoading(true);
      this.rs.getWeeklyFormDataByVesselIdAndFormId(80, this.newVesselId, (res) => {
        if(res.responsecode === 200 || res.responsecode === '200') {
          var newdate =new Date();
          console.log("testnew build11 ",newdate)
          setTimeout(() => {
            var newdate =new Date();
            console.log("testnew build22 ",newdate)
            this.setsyncMsgLoading();
            this.add('reportList', res.data);
            this.reportList = res.data;

            var wwdRowData = [];
            if(res.data && res.data.length > this.offlineRecordLimit) {
              wwdRowData = res.data.slice(0, this.offlineRecordLimit);
            } else {
              wwdRowData = res.data;
            }
            const recordIds = wwdRowData.map(item => item.id);
            if(recordIds.length>0){
              this.getwwdRowData(recordIds, formIds);
            }
          }, 5000);
        } else if (res.responsecode === 404 || res.responsecode === '400'){
          // Not Found Case
          this.setsyncMsgLoading();
          this.add('reportList', []);
          this.reportList = [];
          this.showError('', res.message || 'Data not found.')
        } else if (res.responsecode === 400 || res.responsecode === '400') {
          // Bad Request Case
          this.setsyncMsgLoading();
          this.add('reportList', []);
          this.reportList = [];
          this.showError('', res.message || 'Bad request.')
        } else {
          // All Other Case
          this.setsyncMsgLoading();
          this.add('reportList', []);
          this.reportList = [];
          this.showError('', res.message || 'Unexpected error occurred (Code: ${res.responsecode}).')
        }
      }, (error) => { // Error Callback (Handles Timeout)
        console.log('[API Timeout/Error]', error);
        this.setsyncMsgLoading();
        this.showError(this.connErrtitle, this.serverErrMsg);
      })
    }
  }


  getwwdRowData(recordIds, formIds) {
    const timeoutDuration = 30000; // 10 second in milliseconds

    if (recordIds.length > 0) {
      const lastItemIndex = recordIds.length - 1;
      console.log('[getwwdRowData] lastItemIndex:', lastItemIndex);
      this.setsyncMsgLoading(true);

      from(recordIds) // Converts the array into an observable sequence
        .pipe(
          concatMap((recordId, index) => {

            if (recordId) {
              console.log("==getwwdRowData recordId==", recordId);

              // Wrap the API call in an observable
              return new Observable((observer) => {
                this.rs.getWeeklyIndiviualData(formIds, this.newVesselId, recordId, (response) => {
                  console.log("===getWeeklyIndiviualData=====", response);

                  if (response['responsecode'] === 200 || response['responsecode'] === '200') {
                    if (response.data[0].wwd_hseq[0]) {
                      this.add('wwd_hseq_' + recordId, response.data[0].wwd_hseq[0]);
                      console.log('getwwdRowData ' + 'wwd_hseq_' + recordId, response.data[0].wwd_hseq[0]);
                    }else{
                      this.add('wwd_hseq_' + recordId, '');
                    }
                    if (response.data[1].wwd_technical[0]) {
                      this.add('wwd_technical_' + recordId, response.data[1].wwd_technical[0]);
                      console.log('getwwdRowData ' + 'wwd_technical_' + recordId, response.data[1].wwd_technical[0]);
                    }else{
                      this.add('wwd_technical_' + recordId, '');
                    }
                    if (response.data[2].wwd_mlc[0]) {
                      this.add('wwd_mlc_' + recordId, response.data[2].wwd_mlc[0]);
                      console.log('getwwdRowData ' + 'wwd_mlc_' + recordId, response.data[2].wwd_mlc[0]);
                    }else{
                      this.add('wwd_mlc_' + recordId, '');
                    }
                    observer.next({ success: true, index });
                    observer.complete();
                  } else {
                    this.setsyncMsgLoading();
                    this.showError('', response.message)
                    observer.error({ success: false, message: response['message'] });
                  }
                }, (error) => {
                  // console.log('===error===', error);
                  this.syncMsgLoading = false;
                  this.showError(this.connErrtitle, this.serverErrMsg);
                });
              }).pipe(
                timeout(timeoutDuration), // Apply a timeout to the API request
                catchError((err) => {
                  if (err.name === 'TimeoutError') {
                    console.error(`[getwwdRowData] Timeout for index: ${index}, moving to next request.`);
                  } else {
                    console.error(`[getwwdRowData] Error for index: ${index}, error: `, err);
                  }
                  // Continue with the next item by returning a fallback observable
                  return of({ success: false, index });
                })
              );
            } else {
              // If rowData is invalid, return an empty observable
              return '';
            }
          })
        )
        .subscribe({
          next: (result) => {
            console.log(`[getwwdRowData] Processed item at index: `, result, (result as { index: number }).index);
            if ((result as { index: number }).index === lastItemIndex) {
              this.setsyncMsgLoading();
            }
          },
          error: (err) => {
            console.error(`[getwwdRowData] Error: `, err);
          },
          complete: () => {
            console.log(`[getwwdRowData] All items processed.`);
          },
        });
    }
  }

  getCompanyList() {
    this.cmps.getData((response) => {
      console.log('CompanyRes_Data: ', response);
      this.companyList = response.data;
      this.add('companyListData', response.data);
        if (response.data) {
          this.showCompanyListDetailData(response.data);
        }
    });

  }

  selectCompany(id: number) {
    // console.log(`Selected company ID: ${id}`);
    this.currentVessel = null;
    // console.log('Resetting the current vessel as we are selecting a new company', this.currentVessel);
    this.newCompanyId = id;
    // console.log(`Storing new company ID: ${this.newCompanyId}`);
    this.getVesselList(id);
    // console.log(`Fetching vessel list for company ID: ${id}`);
  }

  getVesselList(id: number) {
    if(id){
      this.toggleLoader(true);
      this.vs.getDataByCompanyId(id, (response) => { 
        this.toggleLoader();
        this.userVesselData = response.data;
        this.add('userVesselData', response.data);
      });
      this.toggleLoader();
    }
  }
  
  showError(title: string, text: string) {
    // console.log("Displaying error message:", title, text);
    this.errorbox.title = title;
    // console.log("Error title set to:", title);
    this.errorbox.text = text;
    // console.log("Error text set to:", text);
    this.errorbox.show();
    // console.log("Error box shown.");
  }

  getCompanyDetails(id) {
    console.log("---this.companyName--",this.companyName,'===this.isOnline==',this.isOnline)
    if(!this.companyName && this.isOnline){
      this.cmps.getDataById(id, (response) => {
        console.log('====company====')
        console.log(response.data)
        this.companyName = response.data.companyName;
        this.companyImage = response.data.profileImage;
      });
    }
  }

  formatDate(dateStr: string): string {
    const date = new Date(dateStr);  // Parse the date string
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    };
    return date.toLocaleDateString('en-GB', options);  // Format and return the date in dd/mm/yyyy
  }


  getweeklypage(formId, result) {
    console.log("getweeklypage-formId", formId);
    console.log("getweeklypage-result", result);
    this.weeklyformlist = false;
    this.formData = null;
    this.formDataLoad = result;
    this.editFormId = formId;
    this.matchedEntries = [];


    if (this.companyList.length > 0) {
      if (!this.newCompanyId || !this.newVesselId) {
        this.showError('Wait...', 'Please select a company and a vessel before proceeding.')
        return;
      }
    }else if (!this.newVesselId) {
      this.showError('Wait...', 'Please select a vessel before proceeding.')
      return;
    }

    if (result === 'skipped') {
      this.formData = { id: formId, vesselId: this.newVesselId, recordId: result.id, type: 'add-new', hseq: '', technical: '', mlc: '' };
    } else if (result) {
      this.offlineRecordid = result.id;
      this.formData = {
        id: formId,
        vesselId: result.vesselId,
        fromDate: result.formDate,
        toDate: result.toDate,
        master: result.master,
        chiefOfficer: result.chiefOfficer,
        recordId: result.id,
        type: 'proceed',
        matchedEntries: this.matchedEntries,
        hseq: '', technical: '', mlc: '',
      };
    } else {
      console.log('Dialog was canceled. Do not proceed.');
    }
    console.log("===this.formData wwek==", this.formData)
  }



  closeForm() {
    this.router.navigate([], {
      queryParams: {
        'yourParamName': null,
        'youCanRemoveMultiple': null,
      },
      queryParamsHandling: 'merge'
    })
  }

  downloadPDF(pdfTitle: any) {
    var node = document.getElementById('print-section');
    var newPdfTitle = pdfTitle ? pdfTitle : 'Title';
    const htmlContent = node.innerHTML;
    this.commonService.openDialog(htmlContent, newPdfTitle);
  }

  RefreshData(event: any) {
    if (event === 'refresh') {
      console.log('RefData_1', event);
      this.selectCompany(this.newCompanyId);
    }
    console.log("check-event11", event);
    if (event.data!= null && event.data.vesselId) {
     this.formDataLoad = {
        vesselId: event.data.vesselId,
        formDate: event.data.gd_From_Date,
        toDate: event.data.gd_To_Date,
        master: event.data.master_Name,
        chiefOfficer: event.data.chief_Officer_Name,
        id: event.data._id
      };
      console.log("=======formDataLoad1====", this.formDataLoad);
      this.get('reportList');
      console.log("=======this.reportList====", this.reportList);
    }
  }




}
