import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from '../apiroot.service';

@Injectable({
  providedIn: 'root'
})
export class FormbuilderService {

  constructor(public http:HttpClient,public service:ApiRootService) { }

  getData(callback){
    return this.http.get(this.service.getBaseUrl()+'/getAllFormBuilderRecords').subscribe(
      (data) =>{
        console.log(data);
        callback(data);
      }
    )}

}
