import { Component, OnInit } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';
import { RolesandrightsService } from '../rolesandrights/rolesandrights.service';
import {MatDialog,MatDialogRef,MAT_DIALOG_DATA,} from "@angular/material";
import { CospalertComponent } from '../cospalert/cospalert.component';
import { SidebarService } from './sidebar.service';
import { ActivatedRoute, RouterModule, Routes,Router } from '@angular/router';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public samplePagesCollapsed = true;
  showSailing;
  showForm; 
  showReports;
  showRole;
  showWF;
  showVD;
  showORB;
  showVS;
  userType: any;
  cssDisabledClass: any;
  dashboard:any;
  company:any;
  hierarchy:any;
  hierarchytree:any;
  watchhours:any;
  sailingmodes:any;
  sailingmodelogs:any;
  sections:any;
  forms:any;
  formfield:any;
  vessels:any;
  crew:any;
  roles:any;
  rolesandrights:any;
  workflow:any;
  formworkflow:any;
  workflowaction:any;
  validations:any;
  fieldvalidation:any;
  formbuilder:any;
  reports:any;
  orbcode:any;
  orbcodeitem:any;
  routerLinkAcitveInactive:any;
  eosp:any;
  cosp:any;
  voyagelist:any;
  checklist:any;
  checklistreports:any;
  roleDetails=[];
  allpermission = [];
  formreports:any;
  formlist :any;
  addform: any;
  logbookforms:any;
  editlogbookforms:any;
  vesselId: any = [];
  signoffreports:any;
  statussheetreport:any;
  weeklyworkentry: any;
  weeklyworkreport: any;
  
  constructor(public session: SessionStorageService, public rnr: RolesandrightsService,public dialog: MatDialog,
   public SidebarService:SidebarService,private router:Router) {  
     }

  ngOnInit() {

       
      
       if(this.session.get('companyId')>0){
       this.rnr.getAllRolePermissionDetailsByCompanyId(this.session.get('companyId'),(response) => {
                this.allpermission = response.data;
                console.log("check-sidebar", response)
                for(let key in response.data){
                    if(response.data[key].roleId == this.session.get('roleid')){
                        this.roleDetails = response.data[key].roleDetails.toString().split(',');
                        if(this.roleDetails.length>0){
         
                        this.cssDisabledClass = "";
                        this.routerLinkAcitveInactive = "active";
                        this.dashboard = (this.roleDetails.indexOf('dashboard')!=-1)?"/dashboard":"";
                        this.hierarchy = (this.roleDetails.indexOf('hierarchy')!=-1)?"/hierarchy":"";
                        this.hierarchytree = (this.roleDetails.indexOf('hierarchytree')!=-1)?"/hierarchytree":"";
                        this.watchhours = (this.roleDetails.indexOf('watchhours')!=-1)?"/watchhours":"";
                        this.sailingmodes = (this.roleDetails.indexOf('sailingmodes')!=-1)?"/sailingmodes":"";
                        this.sailingmodelogs = (this.roleDetails.indexOf('sailingmodelogs')!=-1)?"/sailingmodelogs":"";
                        this.sections = (this.roleDetails.indexOf('sections')!=-1)?"/sections":"";
                        this.forms = (this.roleDetails.indexOf('forms')!=-1)?"/forms":"";
                        this.formfield = (this.roleDetails.indexOf('formfield')!=-1)?"/formfield":"";
                        this.vessels = (this.roleDetails.indexOf('vessels')!=-1)?"/vessels":"";
                        this.crew = (this.roleDetails.indexOf('crew')!=-1)?"/crew":"";
                        this.roles = (this.roleDetails.indexOf('roles')!=-1)?"/roles":"";
                        this.rolesandrights = (this.roleDetails.indexOf('rolesandrights')!=-1)?"/rolesandrights":"";
                        this.validations = (this.roleDetails.indexOf('validations')!=-1)?"/validations":"";
                        this.fieldvalidation = (this.roleDetails.indexOf('fieldvalidation')!=-1)?"/fieldvalidation":"";
                        this.formbuilder = (this.roleDetails.indexOf('formbuilder')!=-1)?"/formbuilder":"";
                        this.reports = (this.roleDetails.indexOf('reports')!=-1)?"/reports":"";
                        this.orbcode = (this.roleDetails.indexOf('orbcode')!=-1)?"/orbcode":"";
                        this.orbcodeitem = (this.roleDetails.indexOf('orbcodeitem')!=-1)?"/orbcodeitem":"";
                        this.cosp = (this.roleDetails.indexOf('cosp')!=-1)?"/cosp":"";
                        this.voyagelist = (this.roleDetails.indexOf('voyagelist')!=-1)?"/voyagelist":"";
                        this.checklistreports = (this.roleDetails.indexOf('checklistreports')!=-1)?"/checklistreports":"";
                        this.checklist = (this.roleDetails.indexOf('checklist')!=-1)?"/checklist":"";
                        this.company = (this.roleDetails.indexOf('company')!=-1)?"/company":"";
                        this.formreports = (this.roleDetails.indexOf('formreports')!=-1)?"/formreports":"";
                        this.formlist = (this.roleDetails.indexOf('formlist')!=-1)?"/formlist":"";
                        this.addform = (this.roleDetails.indexOf('addform')!=-1)?"/addform":"";
                        this.logbookforms = (this.roleDetails.indexOf('logbookforms')!=-1)?"/logbookforms":"";
                        this.weeklyworkentry = (this.roleDetails.indexOf('weeklyworkentry')!=-1)?"/weeklyworkentry":"";
                        this.weeklyworkreport = (this.roleDetails.indexOf('weeklyworkreport')!=-1)?"/weeklyworkreport":"";
                      this.editlogbookforms = "/editlogbookforms";
                      this.signoffreports = "/signoffreports";
                       this.statussheetreport = "/statussheetreport"
                  }else{
                        this.cssDisabledClass = "adisabled";
                        this.routerLinkAcitveInactive = "";
                        this.dashboard = "";
                        this.hierarchy = "";
                        this.hierarchytree = "";
                        this.watchhours = "";
                        this.sailingmodes = "";
                        this.sailingmodelogs ="";
                        this.sections ="";
                        this.forms ="";
                        this.formfield ="";
                        this.vessels ="";
                        this.crew ="";
                        this.roles ="";
                        this.rolesandrights ="";
                        this.workflow ="";
                        this.formworkflow ="";
                        this.workflowaction ="";
                        this.validations ="";
                        this.fieldvalidation ="";
                        this.formbuilder ="";
                        this.reports ="/reports";
                        this.orbcode ="";
                        this.orbcodeitem ="";
                        this.eosp ="";
                        this.cosp ="/cosp";
                        this.voyagelist = "/voyagelist";
                        this.checklist ="";
                        this.checklistreports = "/checklistreports";
                        this.checklist = "/checklist";
                        this.company = "";
                        this.formreports = "/formreports";
                        this.formlist = "/formlist";
                        this.addform = "/addform";
                        this.logbookforms = "/logbookforms";
                        this.editlogbookforms = "/editlogbookforms"
                        this.signoffreports = "/signoffreports"
                        this.statussheetreport = "/statussheetreport"
                        this.weeklyworkentry = "/weeklyworkentry"
                        this.weeklyworkreport = "/weeklyworkreport";
                    }
                        
                    }
                    
                }
              })
       }else{
           this.roleDetails = ['dashboard','hierarchy','hierarchytree','watchhours','sailingmodes','sailingmodelogs','sections','forms','formfield','vessels','crew','roles','rolesandrights','workflow','formworkflow','workflowaction','validations','fieldvalidation','formbuilder','reports','orbcode','orbcodeitem','cosp','voyagelist','checklistreports','checklist','company','formreports','formlist','addform', 'logbookforms','editlogbookforms','signoffreports','this.statussheetreport','statussheetreport', 'weeklyworkentry', 'weeklyworkreport'];
           if(this.roleDetails.length>0){
                        this.cssDisabledClass = "";
                        this.routerLinkAcitveInactive = "active";
                        this.dashboard = (this.roleDetails.indexOf('dashboard')!=-1)?"/dashboard":"";
                        this.hierarchy = (this.roleDetails.indexOf('hierarchy')!=-1)?"/hierarchy":"";
                        this.hierarchytree = (this.roleDetails.indexOf('hierarchytree')!=-1)?"/hierarchytree":"";
                        this.watchhours = (this.roleDetails.indexOf('watchhours')!=-1)?"/watchhours":"";
                        this.sailingmodes = (this.roleDetails.indexOf('sailingmodes')!=-1)?"/sailingmodes":"";
                        this.sailingmodelogs = (this.roleDetails.indexOf('sailingmodelogs')!=-1)?"/sailingmodelogs":"";
                        this.sections = (this.roleDetails.indexOf('sections')!=-1)?"/sections":"";
                        this.forms = (this.roleDetails.indexOf('forms')!=-1)?"/forms":"";
                        this.formfield = (this.roleDetails.indexOf('formfield')!=-1)?"/formfield":"";
                        this.vessels = (this.roleDetails.indexOf('vessels')!=-1)?"/vessels":"";
                        this.crew = (this.roleDetails.indexOf('crew')!=-1)?"/crew":"";
                        this.roles = (this.roleDetails.indexOf('roles')!=-1)?"/roles":"";
                        this.rolesandrights = (this.roleDetails.indexOf('rolesandrights')!=-1)?"/rolesandrights":"";
                        this.validations = (this.roleDetails.indexOf('validations')!=-1)?"/validations":"";
                        this.fieldvalidation = (this.roleDetails.indexOf('fieldvalidation')!=-1)?"/fieldvalidation":"";
                        this.formbuilder = (this.roleDetails.indexOf('formbuilder')!=-1)?"/formbuilder":"";
                        this.reports = (this.roleDetails.indexOf('reports')!=-1)?"/reports":"";
                        this.orbcode = (this.roleDetails.indexOf('orbcode')!=-1)?"/orbcode":"";
                        this.orbcodeitem = (this.roleDetails.indexOf('orbcodeitem')!=-1)?"/orbcodeitem":"";
                        this.cosp = (this.roleDetails.indexOf('cosp')!=-1)?"/cosp":"";
                        this.voyagelist = (this.roleDetails.indexOf('voyagelist')!=-1)?"/voyagelist":"";
                        this.checklistreports = (this.roleDetails.indexOf('checklistreports')!=-1)?"/checklistreports":"";
                        this.checklist = (this.roleDetails.indexOf('checklist')!=-1)?"/checklist":"";
                        this.company = (this.roleDetails.indexOf('company')!=-1)?"/company":"";
                        this.formreports = (this.roleDetails.indexOf('formreports')!=-1)?"/formreports":"";
                        this.formlist = (this.roleDetails.indexOf('formlist')!=-1)?"/formlist":"";
                        this.addform = (this.roleDetails.indexOf('addform')!=-1)?"/addform":"";
                        this.logbookforms = (this.roleDetails.indexOf('logbookforms')!=-1)?"/logbookforms":"";
                        this.editlogbookforms = (this.roleDetails.indexOf('editlogbookforms')!=-1)?"/editlogbookforms":"";
                        this.signoffreports = (this.roleDetails.indexOf('signoffreports')!=-1)?"/signoffreports":"";
                        this.statussheetreport = (this.roleDetails.indexOf('statussheetreport')!=-1)?"/statussheetreport":"";
                        this.weeklyworkentry = (this.roleDetails.indexOf('weeklyworkentry')!=-1)?"/weeklyworkentry":"";
                        this.weeklyworkreport = (this.roleDetails.indexOf('weeklyworkreport')!=-1)?"/weeklyworkreport":"";
                  }else{
                        this.cssDisabledClass = "adisabled";
                        this.routerLinkAcitveInactive = "";
                        this.dashboard = "";
                        this.hierarchy = "";
                        this.hierarchytree = "";
                        this.watchhours = "";
                        this.sailingmodes = "";
                        this.sailingmodelogs ="";
                        this.sections ="";
                        this.forms ="";
                        this.formfield ="";
                        this.vessels ="";
                        this.crew ="";
                        this.roles ="";
                        this.rolesandrights ="";
                        this.workflow ="";
                        this.formworkflow ="";
                        this.workflowaction ="";
                        this.validations ="";
                        this.fieldvalidation ="";
                        this.formbuilder ="";
                        this.reports ="/reports";
                        this.orbcode ="";
                        this.orbcodeitem ="";
                        this.eosp ="";
                        this.cosp ="/cosp";
                        this.voyagelist = "/voyagelist";
                        this.checklist ="";
                        this.checklistreports = "/checklistreports";
                        this.checklist = "/checklist";
                        this.company = "";
                        this.formreports = "/formreports";
                        this.formlist = "/formlist";
                        this.addform = "/addform";
                        this.logbookforms = "/logbookforms"
                        this.editlogbookforms = "/editlogbookforms"
                        this.signoffreports ="/signoffreports"
                        this.statussheetreport = "/statussheetreport"
                        this.weeklyworkentry = "/weeklyworkentry"
                        this.weeklyworkreport = "/weeklyworkreport"
                    }
       }
       
      
      this.userType = this.session.get('rolename');

      
  }
  
  cospalert(){
    this.vesselId = this.session.get('VesselId');
    this.SidebarService.getCopsDataById(this.vesselId,(response) => {
      console.log('getting data cops');
      console.log(response.data);
      console.log(this.vesselId);
      const responsedata = response.data ;
      if(responsedata.vesselId === null || responsedata.vesselId === ""){
        const dialogRef =  this.dialog.open(CospalertComponent,{
            width:"400px",
            height:"200px"
          });
          dialogRef.disableClose = true;
      } 
      else {
        this.router.navigate(['/logbookforms']);
      }

   })
    
  }
  
  getRoleId(){
      for(let key in this.allpermission){
          
      }
  }

}
