import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HierarchyService } from './hierarchy.service';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { debounceTime, filter, } from 'rxjs/operators';
import { Subject } from 'rxjs';



export interface Type {
  id: string;
  viewValue: string;
}

export interface IsStatic {
  id: string;
  viewValue: string;
}



@Component({
  selector: 'app-hierarchy',
  templateUrl: './hierarchy.component.html',
  styleUrls: ['./hierarchy.component.scss']
})
export class HierarchyComponent implements OnInit {
  private filterSubject = new Subject<string>();

  category;
  displayedColumns: string[] = ['id', 'image', 'mobileImageUrl', 'name', 'parentname', 'type', 'isstatic', 'edit', 'delete'];
  dataSource;
  createHierarchy: FormGroup;
  loading: boolean;
  isLoading: boolean;

  type: Type[] = [
    { id: 'Hierarchy', viewValue: 'Hierarchy' },
    { id: 'Master Form', viewValue: 'Master Form' },
    { id: 'Entry Form', viewValue: 'Entry Form' }
  ]

  isstatic: IsStatic[] = [
    { id: 'Yes', viewValue: 'Yes' },
    { id: 'No', viewValue: 'No' }
  ]

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild('deleteSwal', { static: false }) public deleteSwal: SwalComponent;
  @ViewChild('successbox', { static: true }) public successbox: SwalComponent;

  @ViewChild('errorbox', { static: true }) public errorbox: SwalComponent;


  @ViewChild('image1', { static: false }) image1: ElementRef;
  @ViewChild('image2', { static: false }) image2: ElementRef;

  success
  constructor(public service: HierarchyService) {
    this.resetForm();
    this.filterSubject.pipe(debounceTime(300)).subscribe(value => {
      this.applyFilter(value);
    })

  }

  ngOnInit() {
    this.isLoading = true;
    this.service.getParentCategory((data) => {
      this.category = data.data;
      this.dataSource = new MatTableDataSource(data.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.isLoading = false;

    });
    this.createHierarchy = new FormGroup({
      'hName': new FormControl(null, [Validators.required]),
      'parentid': new FormControl(null),
      'image': new FormControl(null),
      'mobileImageUrl': new FormControl(null),
      'type': new FormControl(null, [Validators.required]),
      'staticForm': new FormControl(null)

    });
  }

  doFilter(value: string): void {
    this.filterSubject.next(value.trim().toLowerCase());
  }

  private applyFilter(value: string): void {
    this.dataSource.filter = value || '';
  }



  onClick(action, data) {
    // console.log(data);
    if (action == 'edit') {
      this.service.formData.id = data.id;
      this.service.formData.hName = data.hName;
      this.service.formData.parentid = data.parentid;
      this.service.formData.type = data.type;
      this.service.formData.staticForm = data.staticForm;
      if (data.image) {
        this.service.formData.image = data.image;

      } else {
        this.service.formData.image = 'assets/media/logo/logo.png';

      }

      if (data.mobileImageUrl) {
        this.service.formData.mobileImageUrl = data.mobileImageUrl;

      } else {
        this.service.formData.mobileImageUrl = 'assets/media/logo/logo.png';

      }
      this.createHierarchy.get('image').setValue(this.service.formData.image);
      this.createHierarchy.get('mobileImageUrl').setValue(this.service.formData.mobileImageUrl);

      // this.sailingModesService.formData.watchHourTiming = data.watchHourTiming;
    } else {
      this.service.deleteData(data.id).subscribe((data) => {
        console.log('Deleted');
        this.service.getParentCategory((response) => {
          console.log(response);
          this.dataSource = new MatTableDataSource(response.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        })
      })
    }
    window.scroll(0, 0);

  }

  uploadImage(event) {
    const fileInfo = document.querySelector('.file-info');
    const name = event.target.value.split(/\\|\//).pop();
    const truncated = name.length > 20
      ? name.substr(name.length - 20)
      : name;

    fileInfo.innerHTML = truncated;

    if (event.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      console.log(event.target.files);

      this.service.uploadImage(formData, (response) => {
        // console.log(response);
        if (response.responsecode == 200) {
          console.log(response);
          this.service.formData.image = response.fileurl;

          //this.createHierarchy.get('image').setValue(response.fileurl);
        }
      })
      // console.log(this.service.uploadDocumentsDriver);
    }
  }


  uploadMobileImage(event) {
    const fileInfo = document.querySelector('.file-info1');
    const name = event.target.value.split(/\\|\//).pop();
    const truncated = name.length > 20
      ? name.substr(name.length - 20)
      : name;

    fileInfo.innerHTML = truncated;

    if (event.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      console.log(event.target.files);

      this.service.uploadImage(formData, (response) => {
        // console.log(response);
        if (response.responsecode == 200) {
          console.log(response);
          //this.createHierarchy.get('mobileImageUrl').setValue(response.fileurl);
          this.service.formData.mobileImageUrl = response.fileurl;


        }
      })
      // console.log(this.service.uploadDocumentsDriver);
    }
  }

  submitHandler() {
    this.loading = true;
    console.log(this.createHierarchy);
    if (this.createHierarchy.valid) {
      console.log(this.service.formData);
      if (this.service.formData.id) {
        this.service.putData(this.service.formData.id, this.service.formData, (data) => {
          console.log(data);

          this.loading = false;

          if (data.responsecode === 200 || data.responsecode === '200') {

            this.service.getParentCategory((response) => {
              console.log(response);
              this.dataSource = new MatTableDataSource(response.data);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.successbox.show();
              this.createHierarchy.reset();

              this.resetForm();
            })
          } else {
            this.errorbox.text = data.message;
            this.errorbox.show();
          }
        })


      }
      else {

        this.service.postData(this.service.formData, (response) => {
          console.log(response);

          this.loading = false;

          if (response.responsecode === 200 || response.responsecode === '200') {

            this.service.getParentCategory((response) => {
              console.log(response);
              this.dataSource = new MatTableDataSource(response.data);
              this.dataSource.paginator = this.paginator;
              this.successbox.show();

              this.resetForm();
            })
          } else {
            this.errorbox.text = response.message;
            this.errorbox.show();
          }
        })
      }

    }
  }
  resetForm() {
    // this.createHierarchy.reset();

    this.service.formData.image = 'assets/media/logo/logo.png';
    this.service.formData.mobileImageUrl = 'assets/media/logo/logo.png';

    console.log(document.querySelector('.file-info'));
    console.log(document.querySelector('.file-info1'));
    if (document.querySelector('.file-info')) {

      const fileInfo = document.querySelector('.file-info');
      fileInfo.innerHTML = "";
    }
    if (document.querySelector('.file-info1')) {

      const fileInfo1 = document.querySelector('.file-info1');
      fileInfo1.innerHTML = ""
    }

    this.service.formData.id = "";
    this.service.formData.hName = "";
    this.service.formData.parentid = "";
    this.service.formData.type = "";
    this.service.formData.staticForm = "";
  }
}
