import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatFormFieldControl } from '@angular/material';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators} from '@angular/forms';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { HttpClient } from '@angular/common/http';
import { ChecklistvesselsService } from './checklistvessels.service';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { SessionStorageService } from 'angular-web-storage';
import { VesselsService } from '../vessels/vessels.service';
import { ActivatedRoute, RouterModule, Routes,Router } from '@angular/router';

@Component({
  selector: 'app-checklistvessels',
  templateUrl: './checklistvessels.component.html',
  styleUrls: ['./checklistvessels.component.scss']
})
export class ChecklistvesselsComponent implements OnInit {

  displayedColumns: string[] = ['id','vesselId', 'edit', 'Add'];

  dataSource = new MatTableDataSource()

  myForm: FormGroup;

  allcategory = [];
  allCrew=[];
  isLoading = true;
  data: any = [];
  userCompanyId: any;
  allVessels: any;
  formTypeId: any;
  product: any;
  checklistId: any;
  public formData;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild('deleteSwal', { static: false }) public deleteSwal: SwalComponent;
  @ViewChild('successbox', { static: true }) public successbox: SwalComponent;

  @ViewChild('errorbox', { static: true }) public errorbox: SwalComponent;

  success;
  constructor(private router:Router,public fb: FormBuilder, public checklistvesselsService: ChecklistvesselsService, public vesselsService: VesselsService, public session: SessionStorageService, public http: HttpClient) {
    this.resetForm();
  }

  ngOnInit() {
    this.checklistId = this.session.get('checklistId');
   // this.checklistvesselsService.formTypeData.checklistId=this.session.get('checklistId');

    of(this.dataSource).pipe(delay(1000))
      .subscribe(data => {
        this.isLoading = false;
        this.data = data;
      }, error => this.isLoading = false)

    this.myForm = new FormGroup({
      'checklistId': new FormControl(this.checklistId, Validators.required),
      'vesselId': new FormArray([])
    });

    this.userCompanyId = this.session.get('companyId');
    console.log('--userCompanyId--');
    console.log(this.userCompanyId);
    this.getAllVesselsData(this.userCompanyId);
  }

  getAllVesselsData(companyId){
       if(companyId){
               this.vesselsService.getDataByCompanyId(companyId,(response) => {
                console.log(response);
                this.allVessels=response.data;
                this.loadselected();
                console.log('--getAllVesselsData companyId--');
                console.log(this.allVessels);
            })
    }else{
           this.vesselsService.getData((response) => {
            console.log(response);
            this.allVessels=response.data;
            this.loadselected();
            console.log('--getAllVesselsData--');
            console.log(this.allVessels);
          })
    }
  }

  resetForm(){

  }

   submitHandler() {
    console.log('======save-----');
    console.log(this.myForm.value);
    if (this.myForm.valid) {
        console.log('======save111-----');
        console.log(this.checklistvesselsService.formTypeData);
      if (this.myForm.get('checklistId').value) {

         this.checklistvesselsService.deleteData(this.myForm.get('checklistId').value).subscribe((data) => {
            console.log('Deleted');

             this.checklistvesselsService.postData(this.myForm.value, (data) => {
              console.log(data);
              console.log(this.myForm.value);
              if (data.responsecode === 200 || data.responsecode === '200') {
                  this.router.navigate(['/checklist']);
              } else {
                this.errorbox.text = data.message;
                this.errorbox.show();
              }
        })

        })


       /*   this.checklistvesselsService.postData(this.myForm.value, (data) => {
          console.log(data);
          if (data.responsecode === 200 || data.responsecode === '200') {
              this.router.navigate(['/checklist']);
           this.checklistvesselsService.getDataById(this.myForm.get('checklistId').value, (response) => {
              console.log(response);
              console.log("====done updated===");
              this.router.navigate(['/checklist']);
            })
          } else {
            this.errorbox.text = data.message;
            this.errorbox.show();
          }
        }) */
      }
    }
  }

  loadselected() {
    if(this.checklistId){
      console.log(this.checklistId)
       this.checklistvesselsService.getDataById(this.checklistId, (response) => {
          // console.log("--checked data---");
          Object.keys(this.allVessels).filter((item, index) => {
            this.onChangeEdit(this.allVessels[index].id,false);
           });
           if(response.responsecode === 200){
            var resData=response.data;
            // console.log(resData);
            // console.log(this.allVessels);          
            Object.keys(resData).filter((item, index) => {
              this.onChangeEdit(resData[index].vesselId,true);
            });
          }
          // console.log('Load With Selected: ', this.allVessels);
        })
    }
  }

  onChangeEdit(value: string, isChecked: boolean) {
    const vesselIdFormArray = this.myForm.controls.vesselId;
    console.log('=== start7777===='+value+'=='+isChecked);

    if (isChecked) {
      (this.myForm.get('vesselId') as FormArray).push(new FormControl(value));
    } else {
      let index = (this.myForm.get('vesselId') as FormArray).controls.findIndex(x => x.value == value);
      (this.myForm.get('vesselId') as FormArray).removeAt(index);
    }

    for (var i=0; i<this.allVessels.length; i++) {
        var rnId=this.allVessels[i].id;
        if (rnId == value) {
           this.allVessels[i].checked=isChecked;
        }
    }

    console.log(this.allVessels);
    console.log(this.myForm.controls.vesselId.value);
  }

  backButton(){
   this.router.navigate(['/checklist']);
  }

  onChangeEdit1(value: string, isChecked: boolean) {
    const authorityFormArray = this.myForm.controls.authorityIds;
    console.log('=== start7777===='+value+'=='+isChecked);
    if (isChecked) {
      (this.myForm.get('authorityIds') as FormArray).push(new FormControl(value));
    } else {
      let index = (this.myForm.get('authorityIds') as FormArray).controls.findIndex(x => x.value == value);
      (this.myForm.get('authorityIds') as FormArray).removeAt(index);
    }

    for (var i=0; i<this.allCrew.length; i++) {
        var rnId=this.allCrew[i].id;
        if (rnId == value) {
           this.allCrew[i].checked=isChecked;
        }
    }

    console.log(this.allCrew);
    console.log(this.myForm.controls.authorityIds.value);
  }

}
