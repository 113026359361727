import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from '../apiroot.service';

@Injectable({
  providedIn: 'root'
})
export class FormfieldsService {

  formTypeData = {id:'' , name:'' , label:'',dataType:'',formId:'',htmlControl:'',sectionId:'',dropdownTableName:''}

  // url : String = "http://192.168.0.58:8087/campbellservice/";


  constructor(public httpClient : HttpClient,public service:ApiRootService) { }

  postData(formData,callback){
    return this.httpClient.post(this.service.getBaseUrl()+'/saveNewFormFieldList',formData).subscribe((data)=>{
      console.log(data);
    callback(data);
    })
  }

  getData(callback){
    return this.httpClient.get(this.service.getBaseUrl()+'/getAllFormFieldList').subscribe(
      (data) =>{
        callback(data);
      }
    )
  }

  // editData(formData,callback){
  //   return this.httpClient.get("http://localhost:3000/user/"+formData).subscribe((data) => {
  //     console.log(data);
  //     callback(data);

  //   })
  // }

  deleteData(id){
     return this.httpClient.delete(this.service.getBaseUrl()+'/softDeleteFormFieldListsById/'+id);
   
  }

  putData(id,formData,callback){
    return this.httpClient.put(this.service.getBaseUrl()+'/updateFormFieldList/'+id,formData).subscribe((data)=>{
      console.log(data);
      callback(data);

    })
  }
}
