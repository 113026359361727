import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReportsService } from '../reports/reports.service';
import { LocalStorageService } from 'src/app/local-storage.service';
import { NetworkStatusService } from 'src/app/network-status.service.ts.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-weekly-work-dialog',
  templateUrl: './weekly-work-dialog.component.html',
  styleUrls: ['./weekly-work-dialog.component.scss']
})
export class WeeklyWorkDialogComponent implements OnInit {

  dateRanges: string[] = [];
  selectedRange: string;
  showError: boolean = false;
  loading: boolean = false;

  responseData: any = [];
  isOnline: boolean = true;

  @ViewChild('errorbox', { static: true }) errorbox: SwalComponent;

  constructor(
    public dialogRef: MatDialogRef<WeeklyWorkDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public networkStatusService: NetworkStatusService,
    public rs: ReportsService,
    public LocalStorage: LocalStorageService,
  ) {
    console.log("WeeklyWorkDialogComponent constructor called with data:", data);
  }

  ngOnInit() {
    console.log("ngOnInit called", this.data);
    this.networkStatusService.isOnline.subscribe(status => {
      this.isOnline = status;
    });
    if(this.isOnline){
      this.getDateRangeOptions();
    } else {
      // this.get('reportList');
    }
    if(this.data && this.data.dateRanges && !this.isOnline){
      this.loading=false;
      this.dateRanges = this.data.dateRanges;
    }
  }

  get(keyName){
    console.log('Get_Data: ', keyName);
    this.LocalStorage.get(keyName).then(res => {
      console.log('Get_Data_res_1: ', res);
      if(res){
        console.log('Get_Data_res: ', res);
        if(keyName === 'reportList'){
          this.loading=false;
          this.responseData = res;
        }
      }
    });
  }

  getDateRangeOptions(): void {
    console.log("getDateRangeOptions called");
    this.rs.getWeeklyFormDataByVesselIdAndFormId(80, this.data.vesselId, (res) => {
      console.log("API response:", res);
      if (res.responsecode === 200) {
        this.responseData = res.data;
        console.log("API success: responseData set", this.responseData);
        this.formatDateRanges(res.data);
      } else {
        console.log("API failed. Response code:", res.responsecode);
      }
    }, (error) => { 
      console.log('[API Timeout/Error]', error);
      this.errorbox.text = "Request timed out. Please try again.";
      this.errorbox.show();
    });
  }

  formatDateRanges(data: any[]): void {
    console.log("formatDateRanges called with data:", data);
    this.dateRanges = data.map(item => {
      const formattedFromDate = this.formatDate(item.formDate);
      const formattedToDate = this.formatDate(item.toDate);
      const range = `${formattedFromDate} to ${formattedToDate}`;
      console.log("Formatted date range:", range);
      return range;
    });
    console.log("Date ranges:", this.dateRanges);
  }

  // formatDate(dateStr: string): string {
  //   console.log("formatDate called with dateStr:", dateStr);
  //   const date = new Date(dateStr);
  //   const options: Intl.DateTimeFormatOptions = {
  //     day: '2-digit',
  //     month: '2-digit',
  //     year: 'numeric'
  //   };
  //   const formattedDate = date.toLocaleDateString('en-GB', options);
  //   console.log("Formatted date:", formattedDate);
  //   return formattedDate;
  // }

  formatDate(dateStr: string): string {
    console.log("formatDate called with dateStr:", dateStr);

    // Split the date string to rearrange
    const [year, day, month] = dateStr.split("-");
    const correctedDateStr = `${year}-${month}-${day}`;

    const date = new Date(correctedDateStr);

    if (isNaN(date.getTime())) {
      console.error("Invalid date after reformatting:", correctedDateStr);
      return "Invalid Date";
    }

    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    };
    const formattedDate = date.toLocaleDateString('en-GB', options);
    console.log("Formatted date:", formattedDate);
    return formattedDate;
  }


  onClose(): void {
    console.log("onClose called - closing dialog with no result");
    this.dialogRef.close(null);
  }

  onSkip(): void {
    console.log("onSkip called - closing dialog with 'skipped'");
    //this.dialogRef.close('skipped');
  }

  onSave(): void {
    console.log("onSave called");
    if (!this.selectedRange) {
      console.log("No selectedRange, setting showError to true");
      this.showError = true;
      return;
    }
    this.showError = false;
    console.log("Selected range:", this.selectedRange);

    const selectedEntry = this.responseData.find(entry => {
      const formattedRange = `${this.formatDate(entry.formDate)} to ${this.formatDate(entry.toDate)}`;
      return formattedRange === this.selectedRange;
    });

    console.log("Selected entry based on selected range:", selectedEntry);

    const result = {
      selectedRange: this.selectedRange,
      fromDate: selectedEntry ? this.formatDate(selectedEntry.formDate) : null,
      toDate: selectedEntry ? this.formatDate(selectedEntry.toDate) : null,
      vesselName: selectedEntry ? selectedEntry.vesselName : null,
      id: selectedEntry ? selectedEntry.id : null,
      master: selectedEntry ? selectedEntry.master : null,
      chiefOfficer: selectedEntry ? selectedEntry.chiefOfficer : null,
      vesselId: this.data.vesselId,
      formId: this.data.formId,
    };

    if (this.data.formId === 80) {
      result['type'] = 'generalDetail';
      console.log('General Detail: Binding selected range:', this.selectedRange);
    } else {
      result['type'] = 'otherTabs';
      console.log('Other tabs: Passing selected date:', this.selectedRange);
    }

    console.log("Closing dialog with result:", result);
    this.dialogRef.close(result);
  }
}
