import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { ReportsService } from '../reports/reports.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { SessionStorageService } from 'angular-web-storage';

@Component({
  selector: 'app-comment-dialog',
  templateUrl: './comment-dialog.component.html',
  styleUrls: ['./comment-dialog.component.scss']
})
export class CommentDialogComponent implements OnInit {
  commentText: string = ''; 
  selectedColor: string = 'no-color';
  isCommentInvalid: boolean = false; 
  isColorInvalid: boolean = false; 
  loading: boolean;

  @ViewChild('successbox', { static: true }) successbox: SwalComponent;
  @ViewChild('errorbox', { static: true }) errorbox: SwalComponent;

  constructor(
    private rs: ReportsService,
    public dialogRef: MatDialogRef<CommentDialogComponent>,
    public session: SessionStorageService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    console.log('Data received in dialog:', this.data);
  }

  onPaste(event: ClipboardEvent): void {
    event.preventDefault(); 
}

  saveComment(): void {
    this.loading = true;
    this.isCommentInvalid = false;

    if (!this.commentText.trim()) {
      this.isCommentInvalid = true; 
    }

    if (this.isCommentInvalid) {
      return;
    }

    let commentedBy = `${this.session.get('firstname')} ${this.session.get('lastname')}`;
    console.log("check-commentedBy", commentedBy);

    const requestData = {
      commentBy: commentedBy,
      colour: this.selectedColor || 'no-color',
      comment: this.commentText,
      fieldName: this.data.fieldName,
      formId: this.data.formId,
      rowId: this.data.rowId,
      userId: this.data.userId,
      vesselId: this.data.vesselId,
      status: '2',
      genralDetailsId: this.data.objectId
    };

    console.log("check-requested-data", requestData);


    this.rs.saveComments(requestData, (response) => {
      if (response.responsecode === 200) {
        this.loading = false;
        console.log('Comment saved successfully:', response);
        this.successbox.title = 'Success';
        this.successbox.text = response.message;
        this.successbox.timer = 2000; 
        this.successbox.show(); 

        setTimeout(() => {
          this.dialogRef.close(response); 
          this.ngOnInit(); 
        }, 1000); 

      } else {
        this.loading = false;
        console.error('Error saving comment:', response);
        this.errorbox.title = 'Error';
        this.errorbox.text = response.message;
        this.errorbox.timer = 2000; 
        this.errorbox.show(); 
      }
    });
  }

  onClose() {
    console.log("dialog close button is triggering");
    this.dialogRef.close();
  }
}
