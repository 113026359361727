import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import {
  MatTableDataSource,
  MatPaginator,
  MatSort,
  DateAdapter,
  MAT_DATE_FORMATS,
} from "@angular/material";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { HttpClient } from "@angular/common/http";
import { CospService } from "./cosp.service";
import { CompanyService } from "../company/company.service";
import { VesselsService } from "../vessels/vessels.service";
import { of } from "rxjs";
import { delay } from "rxjs/operators";
import { SessionStorageService } from "angular-web-storage";
import { AppDateAdapter } from "./date-adapter";
import { ActivatedRoute, RouterModule, Routes, Router } from "@angular/router";

export const APP_DATE_FORMATS = {
  parse: { dateInput: { month: "short", year: "numeric", day: "numeric" } },
  display: {
    dateInput: { month: "short", year: "numeric", day: "numeric" },
    monthYearLabel: { year: "numeric" },
  },
};

export interface Datatype {
  value: string;
  viewValue: string;
}

export interface DropDownTable {
  value: string;
  viewValue: string;
}

@Component({
  selector: "app-cosp",
  templateUrl: "./cosp.component.html",
  styleUrls: ["./cosp.component.scss"],
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS,
    },
  ],
})
export class CospComponent implements OnInit {
  formCosp: any = [];

  cospForm: FormGroup;
  isCospSubmitted = false;
  isEospSubmitted = false;
  eospForm: FormGroup;

  data: any = [];
  formTypeId: any;
  formData: any = [];
  userId: any;
  mydate: any;
  companyData: any = [];
  userCompanyId: any = [];
  userVesselData: any = [];
  userShipName: any;
  isEOSPCreated: any;
  loading: boolean;
  showBack: any = false;

  @ViewChild("deleteSwal", { static: false }) public deleteSwal: SwalComponent;
  @ViewChild("successbox", { static: true }) public successbox: SwalComponent;
  @ViewChild("updatesuccessbox", { static: true })
  public updatesuccessbox: SwalComponent;
  @ViewChild("errorbox", { static: true }) public errorbox: SwalComponent;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public vesselsService: VesselsService,
    private formBuilder: FormBuilder,
    public cospService: CospService,
    public companyService: CompanyService,
    public http: HttpClient,
    public session: SessionStorageService
  ) {
    this.resetForm();
  }

  ngOnInit() {
    this.isEOSPCreated = 0;
    this.userCompanyId = this.session.get("companyId");
    this.getCompanyData();
    this.getCompanyVesselList(this.userCompanyId);
    this.userId = this.session.get("userid");
    //alert(this.userId);

    if (this.session.get("cospId") && this.session.get("eospId")) {
      this.getCospEospDataByVoyageId(this.session.get("cospId"), this.session.get("eospId"));
      this.showBack = true;
    }

    //alert(this.userId);
    this.cospForm = this.formBuilder.group({
      chartersParty: new FormControl(null),
      distanceToGo: new FormControl(null, Validators.maxLength(20)),
      etaDate: new FormControl(null),
      etaTime: new FormControl(null, Validators.maxLength(10)),
      freshWater: new FormControl(null, [
        Validators.pattern("^[0-9]+(.[0-9]+)?$"),
        Validators.maxLength(20),
      ]),
      fromPort: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
      fuelGas: new FormControl(null, [
        Validators.pattern("^[0-9]+(.[0-9]+)?$"),
        Validators.maxLength(20),
      ]),
      fuelLube: new FormControl(null, [
        Validators.pattern("^[0-9]+(.[0-9]+)?$"),
        Validators.maxLength(20),
      ]),
      fuelVLSFO: new FormControl(null, [
        Validators.pattern("^[0-9]+(.[0-9]+)?$"),
        Validators.maxLength(20),
      ]),
      latDegree: new FormControl(null, [
        Validators.pattern("^[0-9]+(.[0-9]+)?$"),
        Validators.maxLength(5),
      ]),
      latDirection: new FormControl(null, Validators.maxLength(2)),
      latMinutes: new FormControl(null, [
        Validators.pattern("^[0-9]+(.[0-9]+)?$"),
        Validators.maxLength(5),
      ]),
      loadedBallast: new FormControl(null, Validators.required),
      longDegree: new FormControl(null, [
        Validators.pattern("^[0-9]+(.[0-9]+)?$"),
        Validators.maxLength(5),
      ]),
      longDirection: new FormControl(null, Validators.maxLength(2)),
      longMinutes: new FormControl(null, [
        Validators.pattern("^[0-9]+(.[0-9]+)?$"),
        Validators.maxLength(5),
      ]),
      remarksGeneral: new FormControl(null),
      remarkscargo: new FormControl(null),
      robBallast: new FormControl(null, [
        Validators.pattern("^[0-9]+(.[0-9]+)?$"),
        Validators.maxLength(20),
      ]),
      robCargo: new FormControl(null, [
        Validators.pattern("^[0-9]+(.[0-9]+)?$"),
        Validators.maxLength(20),
      ]),
      shipName: new FormControl(this.userShipName, Validators.maxLength(50)),
      vesselId: new FormControl(null, Validators.required),
      voyageNo: new FormControl(null, [
        Validators.required,
        Validators.pattern("^[0-9a-zA-Z]+(\\.[0-9a-zA-Z]+)?$"),
        Validators.maxLength(50),
      ]),
      userid: new FormControl(this.userId),
      voyageStartDate: new FormControl(null, Validators.required),
      voyageStartTime: new FormControl(null, [
        Validators.required,
        Validators.maxLength(10)
    ]),
    zoneTimePreFix: new FormControl(null, Validators.required),
      zoneTimeValue: new FormControl(null, Validators.required),
      toPort: new FormControl(null, [Validators.required, Validators.maxLength(100) ]),
      companyId: new FormControl(this.userCompanyId, Validators.required),
    });

    this.eospForm = this.formBuilder.group({
      averageDailyConsumption: new FormControl(null, [
        Validators.pattern("^[0-9]+(.[0-9]+)?$"),
        Validators.maxLength(20),
      ]),
      averageSpeed: new FormControl(null, Validators.maxLength(20)),
      freshWater: new FormControl(null, Validators.maxLength(20)),
      fromPort: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
      toPort: new FormControl(null, [Validators.required, Validators.maxLength(100) ]),
      fuelGas: new FormControl(null, [
        Validators.pattern("^[0-9]+(.[0-9]+)?$"),
        Validators.maxLength(20),
      ]),
      fuelLube: new FormControl(null, [
        Validators.pattern("^[0-9]+(.[0-9]+)?$"),
        Validators.maxLength(20),
      ]),
      fuelVLSFO: new FormControl(null, [
        Validators.pattern("^[0-9]+(.[0-9]+)?$"),
        Validators.maxLength(20),
      ]),
      latDegree: new FormControl(null, [
        Validators.pattern("^[0-9]+(.[0-9]+)?$"),
        Validators.maxLength(5),
      ]),
      latDirection: new FormControl(null, Validators.maxLength(2)),
      latMinutes: new FormControl(null, [
        Validators.pattern("^[0-9]+(.[0-9]+)?$"),
        Validators.maxLength(5),
      ]),
      loadedBallast: new FormControl(null, Validators.required),
      longDegree: new FormControl(null, [
        Validators.pattern("^[0-9]+(.[0-9]+)?$"),
        Validators.maxLength(5),
      ]),
      longDirection: new FormControl(null, Validators.maxLength(2)),
      longMinutes: new FormControl(null, [
        Validators.pattern("^[0-9]+(.[0-9]+)?$"),
        Validators.maxLength(5),
      ]),
      remarksCargoCarriage: new FormControl(null),
      remarksGeneral: new FormControl(null),
      robBallast: new FormControl(null, [
        Validators.pattern("^[0-9]+(.[0-9]+)?$"),
        Validators.maxLength(20),
      ]),
      robCargo: new FormControl(null, [
        Validators.pattern("^[0-9]+(.[0-9]+)?$"),
        Validators.maxLength(20),
      ]),
      shipName: new FormControl(this.userShipName, Validators.maxLength(50)),
      vesselId: new FormControl(null, Validators.required),
      totalSteamingTime: new FormControl(null, Validators.maxLength(20)),
      totalDistance: new FormControl(null, [
        Validators.pattern("^[0-9]+(.[0-9]+)?$"),
        Validators.maxLength(20),
      ]),
      voyageCondition: new FormControl(null, [Validators.required, Validators.maxLength(20)]),
      voyageNo: new FormControl(null, [
        Validators.required,
        Validators.pattern("^[0-9a-zA-Z]+(\\.[0-9a-zA-Z]+)?$"),
        Validators.maxLength(50),
      ]),
      userid: new FormControl(this.userId),
      voyageStartDate: new FormControl(null, Validators.required),
      voyageStartTime: new FormControl(null, [
        Validators.required,
        Validators.maxLength(10)
    ]),
    zoneTimePreFix: new FormControl(null, Validators.required),
      zoneTimeValue: new FormControl(null, Validators.required),
      voyagePerformance: new FormControl(null, Validators.maxLength(100)),
      companyId: new FormControl(this.userCompanyId, Validators.required),
    });

    if (this.userCompanyId) {
      this.cospForm.controls["companyId"].disable();
      this.eospForm.controls["companyId"].disable();
    }
  }

  getCompanyData() {
    this.companyService.getData((response) => {
      console.log("===getAllShipCompany===", response);
      this.companyData = response.data;
    });
  }

  cospsubmitHandler() {
    this.cospForm.markAllAsTouched();
    console.log(this.cospForm.value);
    this.cospForm.value.shipName = this.userShipName;
    this.cospService.formData.shipName = this.userShipName;
    //alert(this.cospService.formData.userid);
    console.log("after validate");
    if (this.cospForm.valid) {
      this.loading = true;
      if (this.cospService.formData.id) {
        console.log("===edit cospsubmitHandler==",this.cospService.formData);
        console.log(this.cospForm.value);
        this.cospService.postDataWithCospUserId(
          this.userId,
          this.cospForm.value,
          (response) => {
            console.log(response);
            this.loading = false;
            if (response.responsecode == "200") {
              this.updatesuccessbox.show();
              this.cospService.formData = response.data;
            } else {
              this.errorbox.text = response.message;
              this.errorbox.show();
            }
          }
        );
      } else {
        console.log(this.cospForm.value);
        this.cospService.postData(this.cospForm.value, (response) => {
          console.log(response);
          this.loading = false;
          if (response.responsecode == "200") {
            this.successbox.show();
            this.cospService.formData = response.data;
          } else {
            this.errorbox.text = response.message;
            this.errorbox.show();
          }
        });
      }
    }
  }

  eospSubmitHandler() {
    this.eospForm.markAllAsTouched();
    this.eospForm.value.shipName = this.userShipName;
    this.cospService.formDataEops.shipName = this.userShipName;
    console.log("===eospSubmitHandler==", this.eospForm);
    console.log("===this.cospService.formDataEops==", this.cospService.formDataEops);
    if (this.eospForm.valid) {
      console.log("after 1");
      if (this.cospService.formDataEops.id) {
        console.log("===edit eospSubmitHandler==");
        this.cospService.postDataWithEospUserId(
          this.userId,
          this.eospForm.value,
          (response) => {
            console.log(response);
            if (response.responsecode == "200") {
              this.updatesuccessbox.show();
              this.cospForm.reset();
              this.eospForm.reset();
            } else {
              this.errorbox.text = response.message;
              this.errorbox.show();
            }
          }
        );
      } else {
        console.log("===add eospSubmitHandler==");
        this.cospService.postDataEops(this.eospForm.value, (response) => {
          console.log(response);
          if (response.responsecode == "200") {
            this.successbox.show();
            this.cospForm.reset();
            this.eospForm.reset();
          } else {
            this.errorbox.text = response.message;
            this.errorbox.show();
          }
        });
      }
    }
  }

  setDate() {}

  resetForm() {
    //this.cospForm.reset();
    this.cospService.formData.id = "";
    this.cospService.formData.chartersParty = "";
    this.cospService.formData.vesselId = "";
    this.cospService.formData.distanceToGo = "";
    this.cospService.formData.etaDate = "";
    this.cospService.formData.etaTime = "";
    this.cospService.formData.freshWater = "";
    this.cospService.formData.freshWaterUOM = "";
    this.cospService.formData.fromPort = "";
    this.cospService.formData.fuelGas = "";
    this.cospService.formData.fuelGasUOM = "";
    this.cospService.formData.fuelLube = "";
    this.cospService.formData.fuelLubeUOM = "";
    this.cospService.formData.fuelVLSFO = "";
    this.cospService.formData.fuelVLSFOUOM = "";
    this.cospService.formData.latDegree = "";
    this.cospService.formData.latDirection = "";
    this.cospService.formData.latMinutes = "";
    this.cospService.formData.loadedBallast = "";
    this.cospService.formData.longDegree = "";
    this.cospService.formData.longDirection = "";
    this.cospService.formData.longMinutes = "";
    this.cospService.formData.remarksGeneral = "";
    this.cospService.formData.remarksCargo = "";
    this.cospService.formData.robBallast = "";
    this.cospService.formData.robBallastUOM = "";
    this.cospService.formData.robCargo = "";
    this.cospService.formData.robCargoUOM = "";
    this.cospService.formData.shipName = "";
    this.cospService.formData.toPort = "";
    this.cospService.formData.voyageNo = "";
    this.cospService.formData.voyageStartDate = "";
    this.cospService.formData.voyageStartTime = "";
    this.cospService.formData.zoneTimePreFix = "";
    this.cospService.formData.zoneTimeValue = "";

    this.cospService.formDataEops.id = "";
    this.cospService.formDataEops.averageDailyConsumption = "";
    this.cospService.formDataEops.vesselId = "";
    this.cospService.formDataEops.averageSpeed = "";
    this.cospService.formDataEops.freshWater = "";
    this.cospService.formDataEops.fromPort = "";
    this.cospService.formDataEops.fuelGas = "";
    this.cospService.formDataEops.fuelLube = "";
    this.cospService.formDataEops.fuelVLSFO = "";
    this.cospService.formDataEops.latDegree = "";
    this.cospService.formDataEops.latDirection = "";
    this.cospService.formDataEops.latMinutes = "";
    this.cospService.formDataEops.longDegree = "";
    this.cospService.formDataEops.longDirection = "";
    this.cospService.formDataEops.longMinutes = "";
    this.cospService.formDataEops.remarksCargoCarriage = "";
    this.cospService.formDataEops.remarksGeneral = "";
    this.cospService.formDataEops.robBallast = "";
    this.cospService.formDataEops.robCargo = "";
    this.cospService.formDataEops.shipName = "";
    this.cospService.formDataEops.toPort = "";
    this.cospService.formDataEops.totalDistance = "";
    this.cospService.formDataEops.totalSteamingTime = "";
    this.cospService.formDataEops.voyageCondition = "";
    this.cospService.formDataEops.voyageNo = "";
    this.cospService.formDataEops.voyagePerformance = "";
    this.cospService.formDataEops.voyageStartDate = "";
    this.cospService.formDataEops.zoneTimePreFix = "";
    this.cospService.formDataEops.zoneTimeValue = "";
    this.cospService.formDataEops.voyageStartTime = "";
  }

  getCompanyVesselList(userCompanyId) {
    if (userCompanyId && userCompanyId != null && userCompanyId != "") {
      console.log("in company====" , userCompanyId);
      this.userCompanyId = userCompanyId;
      this.vesselsService.getDataByCompanyId(userCompanyId, (response) => {
        console.log("===getCompanyVesselList==", response);
        this.userVesselData = response.data;
        console.log(response);
      });
    }
  }

  getCospEospByVesselId(id) {
    this.isEOSPCreated = 0;
    this.resetForm();
    //this.eospForm.reset();
    this.cospForm.enable();
    console.log("===this.userVesselData==", this.userVesselData);
    console.log("===vesselId==", id);
    for (var val of this.userVesselData) {
      if (val.id == id) {
        this.userShipName = val.vesselName;
        this.cospService.getCOSPByvesselId(id, (response) => {
          console.log("getting data cops", response.data);
          if (response.responsecode == "200") {
            this.cospService.formData = response.data;
            this.cospService.formData.companyId = this.userCompanyId;
            this.cospService.formData.userid = this.userId;
          }
        });

        this.cospService.getEOSPByvesselId(id, (response) => {
          console.log("getting data eops", response.data);
          if (response.responsecode == "200") {
            this.cospService.formDataEops = response.data;
            this.cospService.formData.userid = this.userId;
            // if (response.data.id != null) {
            //   this.isEOSPCreated = 1;
            //   this.cospForm.disable();
            //   this.cospForm.controls["vesselId"].enable();
            //   this.cospForm.controls["companyId"].enable();
            // }
          }
        });
      }
    }
  }

  /* getCospEospByVesselId(id) {
    this.resetForm();
    this.cospForm.enable();
    this.cospService.getCOSPByvesselId(id, (response) => {
      console.log("getting data cops");
      console.log(response.data);
      if (response.responsecode == "200") {
        this.cospService.formData = response.data;
        this.cospService.formData.companyId = id;
        this.cospService.formData.userid = this.userId;
      }
    });

    this.cospService.getEOSPByvesselId(id, (response) => {
      console.log("getting data eops");
      console.log(response.data);
      if (response.responsecode == "200") {
        this.cospService.formDataEops = response.data;
        this.cospService.formData.companyId = id;
        this.cospService.formData.userid = this.userId;
        if (response.data.id != null) {
          this.isEOSPCreated = 1;
          this.cospForm.disable();
          this.cospForm.controls["vesselId"].enable();
          this.cospForm.controls["companyId"].enable();
        }
      }
    });
  }*/

  getCospEospDataByVoyageId(cospId, eospId) {
    this.resetForm();

    this.cospService.getCOSPByCOSPId(cospId, (response) => {
      console.log("getting data cops",response.data);
      if (response.responsecode == "200") {
        this.cospService.formData = response.data;
        this.cospService.formData.userid = this.userId;
        //this.cospService.formDataEops.companyId = this.session.get('companyId');
        this.cospService.formData.companyId = this.session.get("companyId");
        this.getCompanyVesselList(this.session.get("companyId"));
        this.userShipName = response.data.shipName;
        if (this.session.get("cospId")) {
            this.cospForm.disable();
            this.session.set("cospId",'');
        }
      }
    });

    this.cospService.getEOSPByEOSPId(eospId, (response) => {
      console.log("getting data eops",response.data);
      if (response.responsecode == "200") {
        this.cospService.formDataEops = response.data;
        //this.cospService.formData.companyId = val.id;
        this.cospService.formData.userid = this.userId;
        if (this.session.get("eospId")) {
            this.session.set("eospId",'');
        }
      }
    });
  }

  goBack() {
    this.router.navigate(["/voyagelist"]);
  }
}
