import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { SessionStorageService } from 'angular-web-storage';
import { LoginService } from './Authentication/login/login.service';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loggedIn = new BehaviorSubject<boolean>(false); // {1}
  username;
  userdata;
  userType
  constructor(
      private route: Router,
      public local: SessionStorageService,
      public loginService: LoginService,
      private cookieService: CookieService
      ) { }

  isAuthenticated(path) {
     const promise = new Promise(
      (resolve,reject) =>{
        if(this.local.get('token')) {
       
          this.username = this.local.get('username');
          this.userType = this.local.get('user_type');
          resolve(this.local.get('token'));
        } else {
          reject(this.route.navigate(['/']));
        }
      }
    )
    return promise;
  }

  // sendToken(token: string) {
  //   this.cookieService.set( 'token', token );
  // }
  // getToken() {
  //   console.log(this.cookieService.get('token'));
  //   return this.cookieService.get('token');
  // }

  login(){
    this.loggedIn.next(true);
    // console.log('dfdf');
  }
  logout() {         
    this.local.set('profileImage', null);

    this.local.set('firstname', null);
    this.local.set('lastname', null);
    this.local.set('token',null);     // {4}
    // this.cookieService.deleteAll();
    this.loggedIn.next(false);
    this.route.navigate(['/']);
    location.reload();
  }
  get isLoggedIn() {
    return this.loggedIn.asObservable(); // {2}
  }


}
