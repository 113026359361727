import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from '../apiroot.service';

@Injectable({
  providedIn: 'root'
})
export class StatussheetreportService {

  constructor(public http:HttpClient,public service:ApiRootService) { }

  public  getLatestCOSPByPasscode(id,callback){
    return this.http.get(this.service.getBaseUrl()+'/getLatestCOSPByPasscode/'+id).subscribe(
      (data) =>{
      //  console.log(data); 
        callback(data);
      }
    )}

  public getAllVesselsStatusSheetReport(callback){
    return this.http.get(this.service.getBaseUrl()+'/getAllVesselsStatusSheetReport').subscribe((data)=>
    {
      callback(data)
    });
  }

  
}
