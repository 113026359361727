import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from '../apiroot.service';

@Injectable({
  providedIn: 'root'
})

/**
 * Class to crud operation for Voyge settins (Add,Edit and Delete operation performed)
 *
 * Created By: Anoop Saxena
 * Date: 02-nov-2019
 */
export class CospService {
  formData = {id: '', chartersParty:'',companyId:'',vesselId:'',distanceToGo:'',etaDate:'',etaTime:'',freshWater:'',freshWaterUOM:'',fromPort:'',fuelGas:'',fuelGasUOM:'',fuelLube:'',fuelLubeUOM:'',fuelVLSFO:'',fuelVLSFOUOM:'',latDegree:'',latDirection:'',latMinutes:'',loadedBallast:'',longDegree:'',longDirection:'',longMinutes:'',remarksGeneral:'',remarksCargo:'',robBallast:'',robBallastUOM:'',robCargo:'',robCargoUOM:'',shipName:'',toPort:'',userid:'',voyageNo:'',voyageStartDate:'',voyageStartTime:'',zoneTimePreFix:'',zoneTimeValue:''};
  formDataEops = {id: '', averageDailyConsumption:'',vesselId:'',averageSpeed:'',freshWater:'',fromPort:'',fuelGas:'',fuelLube:'',fuelVLSFO:'',latDegree:'',latDirection:'',latMinutes:'',longDegree:'',longDirection:'',longMinutes:'',remarksCargoCarriage:'',remarksGeneral:'',robBallast:'',robCargo:'',shipName:'',toPort:'',totalDistance:'',totalSteamingTime:'',userid:'',voyageCondition:'',voyageNo:'',voyagePerformance:'',voyageStartDate:'',zoneTimePreFix:'',zoneTimeValue:'',voyageStartTime:''};

  constructor(public httpClient : HttpClient,public service:ApiRootService) { }


    postData(formData,callback){
        //console.log(formData);
        return this.httpClient.post(this.service.getBaseUrl()+'/saveCOSP',formData).subscribe((data)=>{
          console.log(data);
        callback(data);
        })
    }


    postDataEops(formData,callback){
        console.log(formData);
        return this.httpClient.post(this.service.getBaseUrl()+'/saveEOSP',formData).subscribe((data)=>{
          console.log(data);
        callback(data);
        })
    }

    postDataWithCospUserId(id,formData,callback){
        //console.log(formData);
        return this.httpClient.put(this.service.getBaseUrl()+'/updateSaveCOSPByvesselId/'+formData.vesselId,formData).subscribe((data)=>{
          console.log(data);
        callback(data);
        })
    }

    postDataWithEospUserId(id,formData,callback){
        //console.log(formData);
        return this.httpClient.put(this.service.getBaseUrl()+'/updateSaveEOSPByvesselId/'+formData.vesselId,formData).subscribe((data)=>{
          console.log(data);
        callback(data);
        })
    }



    getCOSPByvesselId(id,callback){
    return this.httpClient.get(this.service.getBaseUrl()+'/getCOSPByvesselId/'+id).subscribe(
      (data) =>{
        callback(data);
      }
    )
  }

  getEOSPByvesselId(id,callback){
    return this.httpClient.get(this.service.getBaseUrl()+'/getEOSPByvesselId/'+id).subscribe(
      (data) =>{
        callback(data);
      }
    )
  }

  getCOSPByCOSPId(id,callback){
    return this.httpClient.get(this.service.getBaseUrl()+'/getCOSPByCOSPId/'+id).subscribe(
      (data) =>{
        callback(data);
      }
    )
  }

  getEOSPByEOSPId(id,callback){
    return this.httpClient.get(this.service.getBaseUrl()+'/getEOSPByEOSPId/'+id).subscribe(
      (data) =>{
        callback(data);
      }
    )
  }



   deleteData(id){
     return this.httpClient.delete(this.service.getBaseUrl()+'/softDeleteFormFieldListsById/'+id);

  }

}
