import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormbuilderService } from './formbuilder.service';
import { HttpClient } from '@angular/common/http';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ValidationDialogComponent } from './validation-dialog/validation-dialog.component';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';


@Component({
  selector: 'app-formbuilder',
  templateUrl: './formbuilder.component.html',
  styleUrls: ['./formbuilder.component.scss']
})
export class FormbuilderComponent implements OnInit {
  
  displayedColumns: string[] = ['id', 'hierarchyId','hName','formId','formName','sectionId','sectionName','image','mobileImageUrl','fieldId','fieldName','labelName','dataType','htmlControl','dropdownTableName','validation'];


  formsData = [];
  dataSource 
  
  isLoading = true;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor( public formBuilderService: FormbuilderService,
    public http: HttpClient, public dialog: MatDialog) { }

  ngOnInit() {

    // of(this.dataSource).pipe(delay(1000))
    //   .subscribe(data => {
    //     this.isLoading = false;
    //     this.formsData = data;
    //   }, error => this.isLoading = false)

    this.formBuilderService.getData((response) => {
      console.log(response);
      this.isLoading = false;
      this.dataSource = new MatTableDataSource(response.data);
      this.formsData = response.data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    })
  }

  openDialog(validationData): void {
    const dialogRef = this.dialog.open(ValidationDialogComponent, {
      
      data: validationData
      
    });
  }

}

