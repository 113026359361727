import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DialogboxService } from './dialogbox.service';
import { VesselsService } from '../vessels/vessels.service';
import { CospService } from '../cosp/cosp.service';;
import { CrewService } from '../crew/crew.service';
import { DatePipe } from '@angular/common';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { CrewComponent } from '../crew/crew.component';

@Component({
  selector: 'app-validation-dialog',
  templateUrl: './dialogbox.component.html',
  styleUrls: ['./dialogbox.component.scss']
})
export class DialogboxComponent implements OnInit {


  isLoading = true;
  myForm: FormGroup;
  afs: any;
  success: boolean;
  loading: boolean;
  userVesselData: any = [];
  formData: any = [];
  showPopup;
  vesselAssignmentData;


  @ViewChild('successbox', { static: true }) public successbox: SwalComponent;
  @ViewChild('errorbox', { static: true }) public errorbox: SwalComponent;
  @ViewChild('unassignment', { static: true }) public unassignment: SwalComponent;

  constructor(public dialogRef: MatDialogRef<DialogboxComponent>,
    @Inject(MAT_DIALOG_DATA) public data, public fb: FormBuilder, public dialogboxService: DialogboxService, public vesselsService: VesselsService, public cospService: CospService, public crewService: CrewService, public datePipe: DatePipe) { }


  ngOnInit() {
    // this.unassignment=true;
    console.log('Data-23', this.data)
    this.myForm = this.fb.group({
      'crewId': new FormControl(this.data.crewId),
      'userId': new FormControl(1),
      'vesselId': new FormControl(this.data.vesselId, [Validators.required]),
      'signinTime': new FormControl(this.data.signinTime, [Validators.required]),
      'signoutTime': new FormControl(this.data.signoutTime, [Validators.required])
    })
    if (this.data.vesselId != null) {
      this.myForm.disable();
    }
    // this.getCompanyVesselList();
    this.getCompanyVesselList(this.data.companyId);
    this.getAllCrewVesselAssignment();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getCompanyVesselList(companyId: number): void {
    console.log('company', companyId)
    this.vesselsService.getDataByCompanyId(companyId, (response) => {
      console.log(response);
      console.log('check-below-res', response.data);
      this.userVesselData = response.data;
    });
  }

  confirmUnassigncheck(crewName): void {
    this.unassignment.title = 'Unassign ' + crewName;
    this.unassignment.text = 'Are you sure you want to unassign?';
    this.unassignment.show();
  }

  getAllCrewVesselAssignment() {
    this.crewService.getAllCrewVesselAssignmentData((response) => {
      this.vesselAssignmentData = response.data;
      console.log('vesselAssignmentData-24:', this.vesselAssignmentData);
    });
  }

  performUnassign(): void {
    const id = this.data.crewId;
    var newArray = this.vesselAssignmentData.filter(function (el) {
      return el.crewId == id;
    });
    if (newArray.length > 0) {
      var newId = newArray[0].id
    }
    console.log("test-234", newId)
    const assignmentId = this.vesselAssignmentData.id;
    console.log("Check-assignmaent-id", assignmentId);


    this.crewService.unassignCrew(newId, (response) => {
      if (response.responsecode === 200 || response.responsecode === '200') {
        this.successbox.title = 'Success!';
        this.successbox.text = 'Crew unassigned successfully.';
        console.log("Crew unassigned successfully");
        this.successbox.show();
        setTimeout(() => {
          this.dialogRef.close();
        }, 2000);
      } else {
        this.errorbox.title = 'Oops...';
        this.errorbox.text = 'Failed to unassign crew. Please try again.';
        this.errorbox.show();
      }
    });
  }




  submitHandler() {
    this.loading = true;
    console.log("before validate");
    console.log(this.dialogboxService.formData);
    this.dialogboxService.formData.signinTime = this.datePipe.transform(this.dialogboxService.formData.signinTime, 'yyyy-MM-dd');
    this.dialogboxService.formData.signoutTime = this.datePipe.transform(this.dialogboxService.formData.signoutTime, 'yyyy-MM-dd');
    console.log(this.dialogboxService.formData);
    if (this.myForm.valid) {
      console.log("in validate");
      this.dialogboxService.postData(this.dialogboxService.formData, (response) => {
        this.loading = false;
        if (response.responsecode === 200 || response.responsecode === '200') {
          this.successbox.show();
          // this.dialogRef.close();
          setTimeout(() => {
            this.dialogRef.close();
          }, 2000);

          //this.crewcomp.getCrewVesselAssignmentDetail(this.dialogboxService.formData.crewId)
          //                this.crewService.getData((response) => {
          //                  console.log(response);
          //
          //                  //this.dataSource = new MatTableDataSource(response.data);
          //                  //this.dataSource.paginator = this.paginator;
          //                  //this.dataSource.sort = this.sort;
          //                })
        } else {
          this.errorbox.text = response.message;
          this.errorbox.show();
        }
      })
    } else {
      console.log("not validate");
      this.loading = false;
    }

  }

}

