import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ValidationService } from './validation.service';
import { HttpClient } from '@angular/common/http';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-validation',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.scss']
})
export class ValidationComponent implements OnInit {

  displayedColumns: string[] = ['id', 'name', 'validationTypeId', 'inputValues', 'edit'];
  dataSource

  isLoading = true;
  myForm: FormGroup;
  afs: any;
  success: boolean;
  loading: boolean;
  data: any = [];
  id: any;
  product: any;
  public formData;
  validationType = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('deleteSwal', { static: false }) public deleteSwal: SwalComponent;
  @ViewChild('successbox', { static: true }) public successbox: SwalComponent;

  @ViewChild('errorbox', { static: true }) public errorbox: SwalComponent;


  constructor(public fb: FormBuilder, public validationService: ValidationService,
    public http: HttpClient) {
    this.resetForm();
  }

  ngOnInit() {

    of(this.dataSource).pipe(delay(1000))
      .subscribe(data => {
        this.isLoading = false;
        this.data = data
      }, error => this.isLoading = false)
    // this.getProductDetails(this.route.snapshot.params['id']);

    this.validationService.getData((response) => {
      console.log(response);
      this.dataSource = new MatTableDataSource(response.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })

    this.validationService.getValidationTypeData((response) => {
      console.log(response);
      this.validationType = response.data;
      // this.dataSource.paginator = this.paginator;
      // this.dataSource.sort = this.sort;
    })

    this.myForm = this.fb.group({
      'name': new FormControl(null, [Validators.required]),
      'validationTypeId': new FormControl(null, [Validators.required]),
      'inputValues': new FormControl(null, [Validators.required]),
      'description': new FormControl(null)

    })
  }

  submitHandler() {

    if (this.myForm.valid) {
      console.log(this.myForm.value);
      if (this.validationService.formData.id) {
        this.validationService.putData(this.validationService.formData.id, this.validationService.formData, (data) => {
          if (data.responsecode === 200 || data.responsecode === '200') {

            console.log(data);
            this.validationService.getData((response) => {
              console.log(response);
              this.dataSource = new MatTableDataSource(response.data);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.successbox.show();
              this.resetForm();
            })
          } else {
            this.errorbox.text = data.message;
            this.errorbox.show();
          }
        })
      }
      else {

        this.validationService.postData(this.myForm.value, (response) => {
          console.log(response);
          if (response.responsecode === 200 || response.responsecode === '200') {

            this.validationService.getData((response) => {
              console.log(response);
              this.dataSource = new MatTableDataSource(response.data);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.successbox.show();
              this.resetForm();

            })
          } else {
            this.errorbox.text = response.message;
            this.errorbox.show();
          }
        })
      }

    }
  }

  // getProductDetails(id) {
  //   this.fieldTypeService.getDataById(id,(response)=>{
  //     console.log(response);
  //     this.product = response;
  //   })
  // }

  onClick(action, data) {
    if (action == 'edit') {
      this.validationService.formData.id = data.id;
      this.validationService.formData.name = data.name;
      this.validationService.formData.validationTypeId = data.validationTypeId;
      this.validationService.formData.inputValues = data.inputValues;
      this.validationService.formData.description = data.description;
    } else {
      this.validationService.deleteData(data.id).subscribe((data) => {
        console.log('Deleted');
        this.validationService.getData((response) => {
          console.log(response);
          this.dataSource = new MatTableDataSource(response.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        })
      })
    }
    window.scroll(0,0);

  }

  resetForm() {
    this.validationService.formData.id = "";
    this.validationService.formData.name = "";
    this.validationService.formData.validationTypeId = "";
    this.validationService.formData.inputValues = "";
    this.validationService.formData.description = "";
    // this.myForm.reset();
  }
}
