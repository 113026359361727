import { Component, OnInit, Input, forwardRef, ViewChild, AfterViewInit, Injector } from '@angular/core';
import { NgbTimeStruct, NgbDateStruct, NgbPopoverConfig, NgbPopover, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { DateTimeModel } from './date-time.model';
import { SailingModesChangeLogService } from 'src/app/sailing-modes-change-log.service';
import { noop } from 'rxjs';

@Component({
    selector: 'app-date-time-picker',
    templateUrl: './date-time-picker.component.html',
    styleUrls: ['./date-time-picker.component.scss'],
    providers: [
        DatePipe,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateTimePickerComponent),
            multi: true
        }
    ]
})
export class DateTimePickerComponent implements ControlValueAccessor, OnInit, AfterViewInit {
    @Input()
    dateString: string;

    @Input()
    inputDatetimeFormat = 'dd-MM-yyyy hh:mm:ss';
    @Input()
    hourStep = 1;
    @Input()
    minuteStep = 15;
    @Input()
    secondStep = 30;
    @Input()
    seconds = true;

    @Input()
    disabled = false;

    public showTimePickerToggle = false;

    public datetime: DateTimeModel = new DateTimeModel();
    public firstTimeAssign = true;

    @ViewChild(NgbDatepicker, { static: false })
    public dp: NgbDatepicker;

    @ViewChild(NgbPopover, { static: false })
    public popover: NgbPopover;

    public onTouched: () => void = noop;
    public onChange: (_: any) => void = noop;

    public ngControl: NgControl;

    constructor(public config: NgbPopoverConfig, public inj: Injector, public sailingModesChangeLogService: SailingModesChangeLogService) {
        config.autoClose = 'outside';
        config.placement = 'auto';
    }

    ngOnInit(): void {
        this.ngControl = this.inj.get(NgControl);
    }

    ngAfterViewInit(): void {
        this.popover.hidden.subscribe($event => {
            this.showTimePickerToggle = false;
        });
    }

    writeValue(newModel: string) {
        console.log('writeValue called with:', newModel);
        if (newModel) {
            this.datetime = Object.assign(this.datetime, DateTimeModel.fromLocalString(newModel));
            console.log('Updated datetime object:', this.datetime);
            this.dateString = newModel;
            this.setDateStringModel();
        } else {
            this.datetime = new DateTimeModel();
            console.log('Reset datetime object to default:', this.datetime);
        }
    }


    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    toggleDateTimeState($event) {
        console.log("check-event", $event);
        this.showTimePickerToggle = !this.showTimePickerToggle;
        $event.stopPropagation();
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    onInputChange($event: any) {
        const value = $event.target.value;
        console.log('Input change event value:', value);
        const dt = DateTimeModel.fromLocalString(value);

        if (dt) {
            this.datetime = dt;
            console.log('Updated datetime object from input:', this.datetime);
            this.setDateStringModel();
        } else if (value.trim() === '') {
            this.datetime = new DateTimeModel();
            this.dateString = '';
            console.log('Reset datetime object and dateString to empty:', this.datetime, this.dateString);
            this.onChange(this.dateString);
        } else {
            console.log('Setting onChange with value:', value);
            this.onChange(value);
        }
    }


    onDateChange($event) {
        console.log('Date change event:', $event);
        if ($event.year) {
            $event = `${$event.year}-${$event.month}-${$event.day}`;
        }

        const date = DateTimeModel.fromLocalString($event);
        console.log('Parsed date from event:', date);

        if (!date) {
            this.dateString = this.dateString; 
            console.log('Date parsing failed, retaining existing dateString:', this.dateString);
            return;
        }

        if (!this.datetime) {
            this.datetime = date;
        }

        this.datetime.year = date.year;
        this.datetime.month = date.month;
        this.datetime.day = date.day;

        console.log('Updated datetime object after date change:', this.datetime);

        this.dp.navigateTo({ year: this.datetime.year, month: this.datetime.month });
        this.showTimePickerToggle = true;

        this.setDateStringModel();
    }

    onTimeChange(event: NgbTimeStruct) {
        console.log('Time change event:', event);
        this.datetime.hour = event.hour;
        this.datetime.minute = event.minute;
        this.datetime.second = event.second;

        console.log('Updated datetime object after time change:', this.datetime);
        this.setDateStringModel();
    }

    setDateStringModel() {
        this.dateString = this.datetime.toString();
        console.log('DateString after conversion from datetime:', this.dateString);

        if (this.firstTimeAssign) {
            console.log('Triggering onChange with dateString:', this.dateString);
            this.onChange(this.dateString);
        } else {
            if (this.dateString !== null) {
                this.firstTimeAssign = false;
            }
        }
    }

    inputBlur($event) {
        this.onTouched();
    }
}
