import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from '../apiroot.service';

@Injectable({
  providedIn: 'root'
})
export class SectionsService {

  formData = {sectionId:'',sectionName:'',parentid:'',image:'assets/media/logo/logo.png',mobileImageUrl:'assets/media/logo/logo.png'};

  // formData1={
  //   "formFieldListsRequest": [
  //     {
  //       "dataType": "",
  //       "formFieldName": "",
  //       "htmlControl": 0,
  //       "id": 0,
  //       "isChanged": "",
  //       "isChangedControl": 0,
  //       "isMultiple": true,
  //       "isPublished": true,
  //       "label": "",
  //       "name": "",
  //       "sectionId": 0
  //     }
  //   ],
  //   "stage": "",
  //   "status": ""
  // };

  constructor(public http:HttpClient,public service:ApiRootService) { }

  postData(formData,callback){
   return this.http.post(this.service.getBaseUrl()+'/saveSection', formData).subscribe(
      (data) => {
        console.log(data);
        callback(data);
    });

  }

  getData(callback){
    return this.http.get(this.service.getBaseUrl()+'/getAllSection').subscribe(
      (data) =>{
        console.log(data);
        callback(data);
      }
    )}

    uploadImage(formData,callback){
      return this.http.post(this.service.getBaseUrl()+'/uploadFile',formData).subscribe((data)=>{
        callback(data);
      })
  
    }

    // getDataById(id,formData,callback){
    //   return this.http.get('http://192.168.0.2:8087/campbellservice/getfieldTypeByfieldTypeId/'+id,formData).subscribe(
    //     (data) =>{
    //       // console.log(data);
    //       callback(data);
    //     }
    //   )}

    getDataById(id,callback){
      return this.http.get(this.service.getBaseUrl()+'/getfieldTypeByfieldTypeId/'+id).subscribe(
        (data) =>{
          // console.log(data);
          callback(data);
        }
      )}

    putData(id,formData,callback){
      return this.http.put(this.service.getBaseUrl()+'/updateSectionById/'+id,formData).subscribe((data)=>{
        console.log(data);
        callback(data);
    }
  )}

  deleteData(id){
    return this.http.delete(this.service.getBaseUrl()+'/softDeleteSectionById/'+id);
  }

}
