import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { RolesService } from './roles.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {

  //displayedColumns: string[] = ['id', 'roleName','rolePermission','edit'];
  displayedColumns: string[] = ['roleName', 'rolePermission', 'edit'];
  dataSource

  isLoading = true;
  myForm: FormGroup;
  afs: any;
  success: boolean;
  loading: boolean;
  data: any = [];
  id: any;
  product: any;
  public formData;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild('deleteSwal', { static: false }) public deleteSwal: SwalComponent;
  @ViewChild('successbox', { static: true }) public successbox: SwalComponent;

  @ViewChild('errorbox', { static: true }) public errorbox: SwalComponent;

  constructor(public fb: FormBuilder, public rolesService: RolesService,
    public http: HttpClient) {
    this.resetForm();
  }

  ngOnInit() {

    of(this.formData).pipe(delay(1000))
      .subscribe(data => {
        this.isLoading = false;
        this.data = data;
        console.log("==ngonint-of-data==", this, data)
      }, error => this.isLoading = false)
    // this.getProductDetails(this.route.snapshot.params['id']);

    this.rolesService.getData((response) => {
      console.log("===getData==", response);
      this.dataSource = new MatTableDataSource(response.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })

    this.myForm = this.fb.group({
      'roleName': new FormControl(null, [Validators.required]),
      'rolePermission': new FormControl(null, [Validators.required])

    })
  }

  doFilter(value: string) {
    // console.log("===doFilter-value==", value)
    // console.log("==dofilter-this.dataSource==", this.dataSource)
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  submitHandler() {

    if (this.myForm.valid) {
      console.log(this.myForm.value);
      console.log(this.rolesService.formData);
      this.rolesService.formData.rolePermission = this.myForm.value.rolePermission;
      this.loading = true;
      if (this.rolesService.formData.id) {
        this.rolesService.putData(this.rolesService.formData.id, this.rolesService.formData, (data) => {
          console.log(data);
          this.loading = false;
          if (data.responsecode === 200 || data.responsecode === '200') {
            this.rolesService.getData((response) => {
              console.log(response);
              this.dataSource = new MatTableDataSource(response.data);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.successbox.show();
              this.resetForm();
              this.myForm.reset();
            })
          } else {
            this.errorbox.text = data.message;
            this.errorbox.show();
          }
        })
      }
      else {

        this.rolesService.postData(this.myForm.value, (response) => {
          console.log(response);
          this.loading = false;
          if (response.responsecode === 200 || response.responsecode === '200') {
            this.rolesService.getData((response) => {
              console.log(response);
              this.dataSource = new MatTableDataSource(response.data);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.successbox.show();
              this.resetForm();
              this.myForm.reset();
            })
          } else {
            this.errorbox.text = response.message;
            this.errorbox.show();
          }
        })
      }

    }
  }

  onClick(action, data) {
    if (action == 'edit') {
      this.rolesService.formData.id = data.id;
      this.rolesService.formData.roleName = data.roleName;
      this.rolesService.formData.rolePermission = data.rolePermission;
    } else {
      this.rolesService.deleteData(data.id).subscribe((data) => {
        console.log('Deleted');
        this.rolesService.getData((response) => {
          console.log(response);
          this.dataSource = new MatTableDataSource(response.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        })
      })
    }
    window.scroll(0, 0);

  }

  resetForm() {
    this.rolesService.formData.id = "";
    this.rolesService.formData.roleName = "";
    // this.myForm.reset();
  }



}
