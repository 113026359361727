import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { FormaddService } from '../formadd/formadd.service';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { NgxCarousel } from 'ngx-carousel';
import { CospService } from '../cosp/cosp.service';
import { ReportsService } from '../reports/reports.service';
import { FormlistService } from '../formlist/formlist.service';
import { CrewService } from '../crew/crew.service';
import { CompanyService } from '../company/company.service';
import { SessionStorageService } from 'angular-web-storage';
import { VesselsService } from '../vessels/vessels.service';
import { LocalStorageService } from 'src/app/local-storage.service';
import { NetworkStatusService } from 'src/app/network-status.service.ts.service';
import { AppCommonService } from 'src/app/app.common.service';
import { MatDialog } from '@angular/material';
import domtoimage from 'dom-to-image';
import * as jsPDF from 'jspdf';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import * as _ from "lodash";
import { CookieService } from 'ngx-cookie-service';
import { Console } from 'console';
import { Lightbox } from 'ngx-lightbox';
import { Router } from '@angular/router';
import { QuestionformsService } from 'src/app/questionforms/questionforms.service';
import { ChangeDetectorRef } from '@angular/core';
import { WeeklyWorkDialogComponent } from '../weekly-work-dialog/weekly-work-dialog.component';
import { Observable, from } from 'rxjs';
import { concatMap } from 'rxjs/operators';
// import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-weeklyworkentry',
  templateUrl: './weeklyworkentry.component.html',
  styleUrls: ['./weeklyworkentry.component.scss']
})


export class WeeklyworkentryComponent implements OnInit {
  @ViewChild('noData', { static: true }) public noData: SwalComponent;
  displayedColumns: string[] = ['bookTypeId', 'edit'];
  dataSource;

  isLoading = true;
  myForm: FormGroup;
  afs: any;
  success: boolean;
  loading: boolean;
  data: any = [];
  allQuestionAnswerList: any = [];
  allHeaderNotes: any = [];
  allFooterNotes: any = [];
  allFooterBottomNotes: any = [];
  formBuilder: any = [];
  vesselId: any;
  vesselName: any;
  revisionDate: any;
  allAuthorizedBy: any = [];
  formlistAuthorizedBy: any = [];
  formlistToBeAuthorized: any = [];
  formimage: any = [];
  reportHeader: any = [];
  authorizedBy: any = [];
  approvedIds: any;
  approvalDetail: any = [];
  formsName: any;
  groupedAnswer: any = [];
  cookieIp: any;
  formlistAdditionalInfo = [];
  companyId: any;
  companyName: any;
  companyImage: any;
  userVesselData: any = [];
  companyList: any = [];
  currentVessel: string;
  selectedCompany: string;
  imageUrl: any = 'http://13.232.247.131:8089/';
  newformarray: any = [];
  authurl: any = this.session.get('Authurl');
  formData: any;
  formDataLoad: any;
  newCompanyId: number;
  newVesselId: number;
  reportList: any = [];
  weeklyformlist: boolean;
  public getvalue: any;
  isOnline: boolean = true;
  offlineDataReqStoreList: any = [];
  matchedEntries: any[] = [];
  offlineRecordid;
  showButton: boolean;
  editFormId: number;
  storeUpdatedHSEQ;
  syncMsgLoading: boolean = false;
  wwdOfflineFormData: any = [];
  offlineRecordLimit=6;
  waitingStartTime:any='';


  public Config: NgxCarousel = {
    grid: { xs: 2, sm: 3, md: 3, lg: 5, all: 0 },
    slide: 1,
    speed: 5,
    interval: 10000,
    point: {
      visible: true
    },
    touch: true,
    loop: false,
    custom: 'banner'
  };

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('successbox', { static: true }) successbox: SwalComponent;
  @ViewChild('errorbox', { static: true }) errorbox: SwalComponent;

  constructor(private cookieService: CookieService, private _lightbox: Lightbox, private domSanitizer: DomSanitizer, public cmps: CompanyService, public crewService: CrewService, public rs: ReportsService, public vs: VesselsService, public cs: CospService, public session: SessionStorageService, public fb: FormBuilder, public formaddService: FormaddService, public formsService: FormlistService, private cdr: ChangeDetectorRef,
    public http: HttpClient, public qs: QuestionformsService,
    public commonService: AppCommonService,
    private router: Router,
    public dialog: MatDialog,
    public LocalStorage: LocalStorageService,
    public networkStatusService: NetworkStatusService,
  ) { }

  ngOnInit() {
    this.weeklyformlist = true;
    this.networkStatusService.isOnline.subscribe(status => {
      this.isOnline = status;
      console.log("this.isOnline:", this.isOnline);
      this.getAllEventOnload();
    });


    this.myForm = this.fb.group({
      'bookTypeId': new FormControl(null, [Validators.required])
    })
  }


  setloader(status=false){
    this.loading=status;
    console.log("==Loader status=",this.loading)
  }

  setsyncMsgLoading(status=false,comment=null){
    this.syncMsgLoading=status;
    console.log(comment+"==syncMsgLoading status=",this.syncMsgLoading)
  }

  deleteOfflineData(){
    //this.delete('reportList');
    this.LocalStorage.deleteMatchingKeys("wwd_hseq_").then((result) => {
      console.log("wwd_hseq_Delete operation result:", result);
    }).catch((error) => {
      console.error("Error during delete operation:", error);
    });
    this.LocalStorage.deleteMatchingKeys("wwd_technical_").then((result) => {
      console.log("wwd_technical_Delete operation result:", result);
    }).catch((error) => {
      console.error("Error during delete operation:", error);
    });
    this.LocalStorage.deleteMatchingKeys("wwd_mlc_").then((result) => {
      console.log("wwd_mlc_Delete operation result:", result);
    }).catch((error) => {
      console.error("Error during delete operation:", error);
    });
  }

  changeNetStatus() {
    if (this.isOnline) {
      this.isOnline = false;
    } else {
      this.isOnline = true;
    }
    console.log("=== this.isOnline===", this.isOnline);
    this.ngOnInit();
  }

  goBack() {
    this.showButton = true;
    this.formData = null;
    this.weeklyformlist = true;
    this.closeForm();
  }

  getVesselData(event) {
    this.setloader(true);
    this.showButton = true;
    this.formData = null;
    this.weeklyformlist = true;
    this.closeForm();
    this.newVesselId = event;
    console.log('===userVesselData===', this.userVesselData);
    if(this.userVesselData.length>0){
      for (let key in this.userVesselData) {
        if (this.userVesselData[key].id == event) {
          this.vesselName = this.userVesselData[key].vesselName;
        }
      }
    }

    if(this.isOnline && this.newVesselId){
        this.rs.getMasterAndChiefByVesselId(this.newVesselId, (res) => {
          this.setloader();
          if (res.responsecode === 200) {
            this.add('masters', res.data[0].masterList);
            this.add('chiefOfficers', res.data[0].chiefOfficerList);
          }else if ((res.responsecode === 404 || res.responsecode === '404') || (res.responsecode === 400 || res.responsecode === '400')) {
            this.errorbox.text = res.message;
            this.errorbox.show();
          }
        });
    }

    this.get('reportList');
    this.get('masters');
    this.get('chiefOfficers');
    this.setloader();
  }

  syncData(){
    if (this.isOnline) {
      //this.get('addEditForSaveList');
      //this.saveOfflineForm('ll');
      this.savewwdOfflineForm();
   }
  }



  savewwdOfflineForm() {
    this.LocalStorage.getMatchingKeys("wwd_general_save_").then((allgeneralData) => {
      console.log("[wwd_offline_save] wwd_general_save_ operation result:", allgeneralData);
      if (Array.isArray(allgeneralData) && allgeneralData.length>0) {
      const lastItemIndex = allgeneralData.length - 1;
      console.log('[offline_save] lastItemIndex:', lastItemIndex);
      this.setsyncMsgLoading(true);
      from(allgeneralData).pipe(concatMap((generalData, index) => {
          this.setsyncMsgLoading(true);
          console.log(`[offline_save] Processing item at index: ${index}`);
          console.log('[offline_save] weeklyData:', generalData);
          const wwdId = generalData.replace("wwd_general_save_", "");
          console.log(`Key ${index} after removing prefix:`, wwdId);

          return new Promise((resolve, reject) => {
            this.LocalStorage.get(generalData).then((weeklyData) => {
              if (weeklyData) {
                console.log(`Key: ${generalData}, Value:`, weeklyData);
                this.rs.weeklyWorkDone(weeklyData, (response) => {
                  console.log('[offline_save] API Response:', response);
                  if (response['responsecode'] === 200 || response['responsecode'] === '200' && response.data._id) {
                    this.delete(generalData);
                    resolve({ success: true, index });
                    var wwd_hseq_save_id = 'wwd_hseq_save_'+wwdId;
                    this.LocalStorage.get(wwd_hseq_save_id).then((resData) => {
                      // console.log('[offline_save] API Response:'+wwd_hseq_save_id, resData);
                      if (resData && (resData as { data: { recordId: number }[] }).data !== undefined) {
                        (resData as { data: { recordId: number }[] }).data[0].recordId=response.data._id;
                        this.rs.weeklyWorkDone(resData, (response) => {
                          console.log('[offline_save] API Response:'+wwd_hseq_save_id, response);
                          if (response['responsecode'] === 200 || response['responsecode'] === '200' && response.data._id) {
                              this.delete(wwd_hseq_save_id);
                            }
                        })
                      }
                    })


                    var wwd_technical_save_id = 'wwd_technical_save_'+wwdId;
                    this.LocalStorage.get(wwd_technical_save_id).then((resData) => {
                      ///console.log('[offline_save] API Response:'+wwd_technical_save_id, resData);
                      if (resData && (resData as { data: { recordId: number }[] }).data !== undefined) {
                        (resData as { data: { recordId: number }[] }).data[0].recordId=response.data._id;
                        this.rs.weeklyWorkDone(resData, (response) => {
                          console.log('[offline_save] API Response:'+wwd_hseq_save_id, response);
                          if (response['responsecode'] === 200 || response['responsecode'] === '200' && response.data._id) {
                              this.delete(wwd_technical_save_id);
                            }
                        })
                      }
                    })


                    var wwd_mlc_save_id = 'wwd_mlc_save_'+wwdId;
                    this.LocalStorage.get(wwd_mlc_save_id).then((resData) => {
                      //console.log('[offline_save] API Response:'+wwd_mlc_save_id, resData);
                      if (resData && (resData as { data: { recordId: number }[] }).data !== undefined) {
                        (resData as { data: { recordId: number }[] }).data[0].recordId=response.data._id;
                        this.rs.weeklyWorkDone(resData, (response) => {
                          console.log('[offline_save] API Response:'+wwd_hseq_save_id, response);
                          if (response['responsecode'] === 200 || response['responsecode'] === '200' && response.data._id) {
                              this.delete(wwd_mlc_save_id);
                            }
                        })
                      }
                    })

                  }else{
                    //this.errorbox.text = response['message'];
                    //this.errorbox.show();
                    this.setsyncMsgLoading();
                    const confirmed = window.confirm(response['message']+" Are you want to remove this record and reload?");
                    if (confirmed) {
                      this.delete(generalData);
                      resolve({ success: true, index });
                    }else{
                      reject({ success: false, message: response['message'] });
                    }
                  }
                });
              }
            })
            });
        })
        ).subscribe({next: (result) => {
            console.log(`[offline_save] Successfully processed item at index: `,result, (result as { index: number }).index);
            if ((result as { index: number }).index === lastItemIndex) {
              this.updatewwdData();
            }
          }
        });
      }else{
        console.log("[wwd_offline_save] run sync without data save:", allgeneralData);
        this.updatewwdData();
      }
    })
  }

updatewwdData(){
  let matchingKeys: string[]  =["wwd_general_update_","wwd_hseq_update_","wwd_technical_update_","wwd_mlc_update_"];
  this.LocalStorage.getAllMatchingKeys(matchingKeys).then((allMatchingIds) => {
    console.log("[wwd_offline_save] operation result:", allMatchingIds);
    if(Array.isArray(allMatchingIds) && allMatchingIds.length>0){
      const lastItemIndex = allMatchingIds.length - 1;
      console.log('[offline_save] lastItemIndex:', lastItemIndex);
      this.setsyncMsgLoading(true);
      from(allMatchingIds).pipe(concatMap((matchingId, index) => {
          this.setsyncMsgLoading(true);
          console.log(`[offline_save] Processing item at index: ${index}`);
          console.log('[offline_save] weeklyData:', matchingId);

          return new Promise((resolve, reject) => {
            this.LocalStorage.get(matchingId).then((matchingData) => {
              if (matchingData) {
                this.rs.weeklyWorkDone(matchingData, (response) => {
                  console.log('[offline_save] API Response:', response);
                  if (response['responsecode'] === 200 || response['responsecode'] === '200' && response.data._id) {
                    this.delete(matchingId);
                    resolve({ success: true, index });
                  }else{
                    this.errorbox.text = response['message'];
                    this.errorbox.show();
                    resolve({ success: true, index });
                  }
                });
              }
            });
          });
        })
        ).subscribe({next: (result) => {
            console.log(`[offline_save] Successfully processed item at index: `,result, (result as { index: number }).index);
            if ((result as { index: number }).index === lastItemIndex) {
              this.setsyncMsgLoading();
              this.syncOnlineData();
            }
          }
        });
    }else{
        console.log("[wwd_offline_save] run sync without data update:");
        this.setsyncMsgLoading();
        this.syncOnlineData();
      }
    })
  }

  syncOnlineData(){
    if (this.isOnline && this.newVesselId) {
      this.deleteOfflineData();
      this.offline_getWeeklyWorkReportList();
    }

  }

  syncOfflineData(){
    this.get('addEditForSaveList');
  }

  getAllEventOnload() {
    if (this.isOnline) {
      this.companyId = this.session.get('companyId');
      console.log("==this.companyId==",this.companyId);
      if (this.companyId) {
        this.getVesselList(this.companyId);
        this.getCompanyDetails(this.companyId);
      } else {
        this.getCompanyList();
      }
    }else{
      this.get('companyListData');
      this.get('userVesselData');
      if(this.newVesselId){
        this.get('reportList');
      }
    }
  }

  ngAfterViewInit(): void {
    //this.ngOnInit();
  }

  synchronizeDataGet() {
    this.get('companyListData');
    this.get('userVesselData');
    if(this.newVesselId){
      this.get('reportList');
    }
  }

  showCompanyListDetailData(companyList: any) {
    if (companyList && companyList.length > 0) {
      const companyListLength = companyList.length;
      let index = 0;
      const companyInterval = setInterval(() => {
        if (index < companyListLength) {
          const company = companyList[index];
          console.log('[INFO] Current company data:', company);
          if (company && company.id) {
            this.getVesselList(company.id);
          }
          index++;
        } else {
          console.log('[INFO] Completed all iterations of the company list.');
          clearInterval(companyInterval);
        }
      }, 500);

    }
  }


  add(keyName, value) {
    this.LocalStorage.add(keyName, value).then(res => {
      if (res) {
        console.log(keyName + " Inserted Successfully.",)
      }
    });
  }

  get(keyName) {
    this.resetIndexData(keyName);
    this.LocalStorage.get(keyName).then(res => {
      if (res) {
        if (keyName === 'companyListData') {
          this.companyList = res;
        }else if (keyName === 'userVesselData') {
          this.userVesselData = res;
        }else if (keyName === 'reportList') {
          this.reportList = res;
        }
      }
      console.log(' keyName: ',keyName,' res: ',res);
    });
  }

  resetIndexData(keyName){
    console.log(' keyName: ',keyName);
    if (keyName === 'companyListData') {
      this.companyList = [];
    }else if (keyName === 'userVesselData') {
      this.userVesselData = [];
    }else if (keyName === 'reportList') {
      this.reportList = [];
    }else if (keyName === 'addEditForSaveList') {
      this.wwdOfflineFormData = [];
    }
  }

  delete(keyName) {
    console.log(' before delete ',keyName)
    this.LocalStorage.delete(keyName).then(res => {
      if (res === 'Success') {
        console.log(' after delete ',keyName)
      }
    });
  }

  // offline_getWeeklyWorkReportList() {
  //   console.log('[INFO] function triggered with vesselId:', this.newVesselId);
  //   if (this.newVesselId) {
  //     this.setsyncMsgLoading(true);
  //     this.rs.getWeeklyFormDataByVesselIdAndFormId(80, this.newVesselId, (res) => {
  //       if (res.responsecode === 200 || res.responsecode === '200') {
  //         this.setsyncMsgLoading();
  //         this.add('reportList', res.data);
  //         this.reportList = res.data;
  //         if (res.data && res.data.length > this.offlineRecordLimit) {
  //           const wwdRowData = res.data.slice(0, this.offlineRecordLimit);
  //           this.getwwdRowData(wwdRowData);
  //         } else {
  //           this.getwwdRowData(res.data);
  //         }
  //       }else if ((res.responsecode === 404 || res.responsecode === '404') || (res.responsecode === 400 || res.responsecode === '400')) {
  //         this.setsyncMsgLoading();
  //         this.add('reportList', []);
  //         this.reportList = [];
  //         this.errorbox.text = res.message;
  //         this.errorbox.show();
  //       }
  //     });
  //   }
  // }

  offline_getWeeklyWorkReportList() {
    console.log('[INFO] function triggered with vesselId:', this.newVesselId);

    if(this.newVesselId) {
      this.setsyncMsgLoading(true);
      this.rs.getWeeklyFormDataByVesselIdAndFormId(80, this.newVesselId, (res) => {
        if(res.responsecode === 200 || res.responsecode === '200') {
          //Success Case
          this.setsyncMsgLoading();
          this.add('reportList', res.data);
          this.reportList = res.data;

          if(res.data && res.data.length > this.offlineRecordLimit) {
            const wwdRowData = res.data.slice(0, this.offlineRecordLimit);
            this.getwwdRowData(wwdRowData);
          } else {
            this.getwwdRowData(res.data);
          }
        } else if (res.responsecode === 404 || res.responsecode === '400'){
          // Not Found Case
          this.setsyncMsgLoading();
          this.add('reportList', []);
          this.reportList = [];
          this.errorbox.text = res.message || 'Data not found.';
          this.errorbox.show();
        } else if (res.responsecode === 400 || res.responsecode === '400') {
          // Bad Request Case 
          this.setsyncMsgLoading();
          this.add('reportList', []);
          this.reportList = [];
          this.errorbox.text = res.message || 'Bad request.';
          this.errorbox.show();
        } else {
          // All Other Case 
          this.setsyncMsgLoading();
          this.add('reportList', []);
          this.reportList = [];
          this.errorbox.text = res.message || 'Unexpected error occurred (Code: ${res.responsecode}).';
          this.errorbox.show();
        }
      })
    }
  }
  
  

  checkWaitingTime(status=false) {
    setTimeout(() => {
      const elapsedTime = Date.now() - this.waitingStartTime;
      if (elapsedTime > 3000) { // 30 seconds in milliseconds
        console.warn('Exceeded waiting time. Reloading the page...');
        if(status){
          const confirmed = window.confirm(" Backend API is not responding, Are you want to reload the application?");
          if (confirmed) {
            window.location.reload(); // Reload the page
          }
        }
      }
    }, 30000); // Check after 30 seconds
  }

  getwwdRowData(wwdRowData){
    const formIds = [81, 82, 83];
    const fetchPromises = [];
    if(wwdRowData.length >0){
    const lastItemIndex = wwdRowData.length - 1;
    console.log('[getwwdRowData] lastItemIndex:', lastItemIndex);
    this.setsyncMsgLoading(true);
    from(wwdRowData) // Converts the array into an observable sequence
    .pipe(
      concatMap((rowData, index) => {
        console.log("==getwwdRowData rowData==",rowData);
        if (rowData && (rowData as { id: number }).id !== undefined) {
          const recordId = (rowData as { id: number }).id;
          console.log("==getwwdRowData recordId==",recordId);
          return new Promise((resolve, reject) => {
              this.rs.getWeeklyIndiviualData(formIds, this.newVesselId, recordId, (response) => {
              console.log("===getWeeklyIndiviualData=====", response);
                if (response['responsecode'] === 200 || response['responsecode'] === '200') {
                  if(response.data[0].wwd_hseq[0]){
                    var genralDetailsId= response.data[0].wwd_hseq[0].genralDetailsId;
                    this.add('wwd_hseq_'+genralDetailsId, response.data[0].wwd_hseq[0]);
                          console.log('getwwdRowData '+'wwd_hseq_'+genralDetailsId,response.data[0].wwd_hseq[0]);
                  }
                  if(response.data[1].wwd_technical[0]){
                    var genralDetailsId= response.data[1].wwd_technical[0].genralDetailsId;
                    this.add('wwd_technical_'+genralDetailsId, response.data[1].wwd_technical[0]);
                          console.log('getwwdRowData '+'wwd_technical_'+genralDetailsId,response.data[0].wwd_hseq[0]);
                  }
                  if(response.data[2].wwd_mlc[0]){
                    var genralDetailsId= response.data[2].wwd_mlc[0].genralDetailsId;
                    this.add('wwd_mlc_'+genralDetailsId, response.data[2].wwd_mlc[0]);
                          console.log('getwwdRowData '+'wwd_mlc_'+genralDetailsId,response.data[0].wwd_hseq[0]);
                  }
                  resolve({ success: true, index });
                } else {
                  reject({ success: false, message: response['message'] });
                }
              });
          });
      }
      })
    )
    .subscribe({
      next: (result) => {
        console.log(`[getwwdRowData] Successfully processed item at index: `,result, (result as { index: number }).index);
        if ((result as { index: number }).index === lastItemIndex) {
          this.setsyncMsgLoading();
        }
      }
    });
  }
  }


  getCompanyList() {
    this.cmps.getData((response) => {
      console.log('CompanyRes_Data: ', response);
      this.companyList = response.data;
      this.add('companyListData', response.data);
        if (response.data) {
          this.showCompanyListDetailData(response.data);
        }
    });

  }

  selectCompany(id) {
    console.log("This is company id", id);
    this.newCompanyId = id;
    console.log('this-is-vessel-data', this.newCompanyId)
    // this.newVesselId = null;
    this.closeForm();
    this.getVesselList(id);
    this.formData = null;
  }

  getVesselList(id) {
    if(id){
      this.setloader(true);
      this.vs.getDataByCompanyId(id, (response) => {
        console.log('check-here-234', response);
        this.setloader();
        this.userVesselData = response.data;
        this.add('userVesselData', response.data);
      })

    }
  }

  getCompanyDetails(id) {
    console.log("---this.companyName--",this.companyName,'===this.isOnline==',this.isOnline)
    if(!this.companyName && this.isOnline){
      this.cmps.getDataById(id, (response) => {
        console.log('====company====')
        console.log(response.data)
        this.companyName = response.data.companyName;
        this.companyImage = response.data.profileImage;
      });
    }
  }

  formatDate(dateStr: string): string {
    const date = new Date(dateStr);  // Parse the date string
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    };
    return date.toLocaleDateString('en-GB', options);  // Format and return the date in dd/mm/yyyy
  }


  getweeklypage(formId, result) {
    console.log("getweeklypage-formId", formId);
    console.log("getweeklypage-result", result);
    this.weeklyformlist = false;
    this.formData = null;
    this.formDataLoad = result;
    this.editFormId = formId;
    this.matchedEntries = [];


    if (this.companyList.length > 0) {
      if (!this.newCompanyId || !this.newVesselId) {
        this.errorbox.title = 'Wait...';
        this.errorbox.text = 'Please select a company and a vessel before proceeding.';
        this.errorbox.show();
        return;
      }
    }else if (!this.newVesselId) {
      this.errorbox.title = 'Wait...';
      this.errorbox.text = 'Please select a vessel before proceeding.';
      this.errorbox.show();
      return;
    }

    // var masters = this.get('masters');
    // var chiefOfficers = this.get('chiefOfficers');
    // console.log("check-masters", masters)
    // console.log("check-chiefOfficers", chiefOfficers)
    // if(masters==undefined || chiefOfficers==undefined){
    //   this.errorbox.title = 'Wait...';
    //   this.errorbox.text = 'Please sync the data.';
    //   this.errorbox.show();
    //   return;
    // }

    console.log("check-result", result)
    if (result === 'skipped') {
      this.formData = { id: formId, vesselId: this.newVesselId, recordId: result.id, type: 'add-new', hseq: '', technical: '', mlc: '' };
    } else if (result) {
      this.offlineRecordid = result.id;
      this.formData = {
        id: formId,
        vesselId: result.vesselId,
        fromDate: result.formDate,
        toDate: result.toDate,
        master: result.master,
        chiefOfficer: result.chiefOfficer,
        recordId: result.id,
        type: 'proceed',
        matchedEntries: this.matchedEntries,
        hseq: '', technical: '', mlc: '',
      };
    } else {
      console.log('Dialog was canceled. Do not proceed.');
    }
    console.log("===this.formData wwek==", this.formData)
  }



  closeForm() {
    this.router.navigate([], {
      queryParams: {
        'yourParamName': null,
        'youCanRemoveMultiple': null,
      },
      queryParamsHandling: 'merge'
    })
  }

  downloadPDF(pdfTitle: any) {
    var node = document.getElementById('print-section');
    var newPdfTitle = pdfTitle ? pdfTitle : 'Title';
    const htmlContent = node.innerHTML;
    this.commonService.openDialog(htmlContent, newPdfTitle);
  }

  RefreshData(event: any) {
    if (event === 'refresh') {
      console.log('RefData_1', event);
      this.selectCompany(this.newCompanyId);
    }
    console.log("check-event11", event);
    if (event.data!= null && event.data.vesselId) {
     this.formDataLoad = {
        vesselId: event.data.vesselId,
        formDate: event.data.gd_From_Date,
        toDate: event.data.gd_To_Date,
        master: event.data.master_Name,
        chiefOfficer: event.data.chief_Officer_Name,
        id: event.data._id
      };
      console.log("=======formDataLoad1====", this.formDataLoad);
      this.get('reportList');
      console.log("=======this.reportList====", this.reportList);
    }
  }




}
