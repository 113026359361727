import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl,FormBuilder, Validators,FormArray, FormGroup, } from '@angular/forms';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { SessionStorageService } from 'angular-web-storage';
import { StatussheetService } from './statussheet.service';
import { HttpClient } from "@angular/common/http";


@Component({
  selector: 'app-statussheet',
  templateUrl: './statussheet.component.html',
  styleUrls: ['./statussheet.component.scss']
})
export class StatussheetComponent implements OnInit {
  Formdata:FormGroup;
  itemForm:any;
  ShipName:string;
  softwareImplementedByEsoft
crewListAvailable:any;
dataSyncFromShipServerToShoreServer:any;
dataSyncWithIpadTablet:any;
dataSyncWithDesktop:any;
dataFillingStarted:any;
nextStepsForCampbell:any;
responsibityNextStepsCampbell:any;
nextStepsForEsoft:any;
responsibityNextStepsEsoft:any;
percentCompletion:any;
supportRequiredFromCaptainKeshav:any;

  @ViewChild("successbox", { static: true }) successbox: SwalComponent;
  @ViewChild("errorbox", { static: true }) errorbox: SwalComponent;

  constructor(public fb: FormBuilder,public statussheet:StatussheetService,public http: HttpClient,public session: SessionStorageService,) { }

  ngOnInit() {

    this.Formdata = this.fb.group({
      'softwareImplementedByEsoft':new FormControl(null),
      'crewListAvailable':new FormControl(null),
      'dataSyncFromShipServerToShoreServer':new FormControl(null),
      'dataSyncWithIpadTablet':new FormControl(null),
      'dataSyncWithDesktop':new FormControl(null),
      'dataFillingStarted':new FormControl(null),
      'nextStepsForCampbell':new FormControl(null),
      'responsibityNextStepsCampbell':new FormControl(null),
      'nextStepsForEsoft':new FormControl(null),
      'responsibityNextStepsEsoft':new FormControl(null),
      'percentCompletion':new FormControl(null,[Validators.pattern("^[0-9]+(.[0-9]+)?$")]),
      'supportRequiredFromCaptainKeshav':new FormControl(null),

      // 'CSCelestesoftwareImplementedByEsoft':new FormControl(null),
      // 'CSCelestecrewListAvailable':new FormControl(null),
      // 'CSCelestedataSyncFromShipServerToShoreServer':new FormControl(null),
      // 'CSCelestedataSyncWithIpadTablet':new FormControl(null),
      // 'CSCelestedataSyncWithDesktop':new FormControl(null),
      // 'CSCelestedataFillingStarted':new FormControl(null),
      // 'CSCelestenextStepsForCampbell':new FormControl(null),
      // 'CSCelesteresponsibityNextStepsCampbell':new FormControl(null),
      // 'CSCelestenextStepsForEsoft':new FormControl(null),
      // 'CSCelesteresponsibityNextStepsEsoft':new FormControl(null),
      // 'CSCelestepercentCompletion':new FormControl(null),
      // 'CSCelestesupportRequiredFromCaptainKeshav':new FormControl(null),


      // 'CSCrystalsoftwareImplementedByEsoft':new FormControl(null),
      // 'CSCrystalcrewListAvailable':new FormControl(null),
      // 'CSCrystaldataSyncFromShipServerToShoreServer':new FormControl(null),
      // 'CSCrystaldataSyncWithIpadTablet':new FormControl(null),
      // 'CSCrystaldataSyncWithDesktop':new FormControl(null),
      // 'CSCrystaldataFillingStarted':new FormControl(null),
      // 'CSCrystalnextStepsForCampbell':new FormControl(null),
      // 'CSCrystalresponsibityNextStepsCampbell':new FormControl(null),
      // 'CSCrystalnextStepsForEsoft':new FormControl(null),
      // 'CSCrystalresponsibityNextStepsEsoft':new FormControl(null),
      // 'CSCrystalpercentCompletion':new FormControl(null),
      // 'CSCrystalsupportRequiredFromCaptainKeshav':new FormControl(null),

      // 'CSSarafinasoftwareImplementedByEsoft':new FormControl(null),
      // 'CSSarafinacrewListAvailable':new FormControl(null),
      // 'CSSarafinadataSyncFromShipServerToShoreServer':new FormControl(null),
      // 'CSSarafinadataSyncWithIpadTablet':new FormControl(null),
      // 'CSSarafinadataSyncWithDesktop':new FormControl(null),
      // 'CSSarafinadataFillingStarted':new FormControl(null),
      // 'CSSarafinanextStepsForCampbell':new FormControl(null),
      // 'CSSarafinaresponsibityNextStepsCampbell':new FormControl(null),
      // 'CSSarafinanextStepsForEsoft':new FormControl(null),
      // 'CSSarafinaresponsibityNextStepsEsoft':new FormControl(null),
      // 'CSSarafinapercentCompletion':new FormControl(null),
      // 'CSSarafinasupportRequiredFromCaptainKeshav':new FormControl(null),

      // 'CSSatirasoftwareImplementedByEsoft':new FormControl(null),
      // 'CSSatiracrewListAvailable':new FormControl(null),
      // 'CSSatiradataSyncFromShipServerToShoreServer':new FormControl(null),
      // 'CSSatiradataSyncWithIpadTablet':new FormControl(null),
      // 'CSSatiradataSyncWithDesktop':new FormControl(null),
      // 'CSSatiradataFillingStarted':new FormControl(null),
      // 'CSSatiranextStepsForCampbell':new FormControl(null),
      // 'CSSatiraresponsibityNextStepsCampbell':new FormControl(null),
      // 'CSSatiranextStepsForEsoft':new FormControl(null),
      // 'CSSatiraresponsibityNextStepsEsoft':new FormControl(null),
      // 'CSSatirapercentCompletion':new FormControl(null),
      // 'CSSatirasupportRequiredFromCaptainKeshav':new FormControl(null),

      // 'CSCaliasoftwareImplementedByEsoft':new FormControl(null),
      // 'CSCaliacrewListAvailable':new FormControl(null),
      // 'CSCaliadataSyncFromShipServerToShoreServer':new FormControl(null),
      // 'CSCaliadataSyncWithIpadTablet':new FormControl(null),
      // 'CSCaliadataSyncWithDesktop':new FormControl(null),
      // 'CSCaliadataFillingStarted':new FormControl(null),
      // 'CSCalianextStepsForCampbell':new FormControl(null),
      // 'CSCaliaresponsibityNextStepsCampbell':new FormControl(null),
      // 'CSCalianextStepsForEsoft':new FormControl(null),
      // 'CSCaliaresponsibityNextStepsEsoft':new FormControl(null),
      // 'CSCaliapercentCompletion':new FormControl(null),
      // 'CSCaliasupportRequiredFromCaptainKeshav':new FormControl(null),

      // 'CSCalvinasoftwareImplementedByEsoft':new FormControl(null),
      // 'CSCalvinacrewListAvailable':new FormControl(null),
      // 'CSCalvinadataSyncFromShipServerToShoreServer':new FormControl(null),
      // 'CSCalvinadataSyncWithIpadTablet':new FormControl(null),
      // 'CSCalvinadataSyncWithDesktop':new FormControl(null),
      // 'CSCalvinadataFillingStarted':new FormControl(null),
      // 'CSCalvinanextStepsForCampbell':new FormControl(null),
      // 'CSCalvinaresponsibityNextStepsCampbell':new FormControl(null),
      // 'CSCalvinanextStepsForEsoft':new FormControl(null),
      // 'CSCalvinaresponsibityNextStepsEsoft':new FormControl(null),
      // 'CSCalvinapercentCompletion':new FormControl(null),
      // 'CSCalvinasupportRequiredFromCaptainKeshav':new FormControl(null),

      // 'CSCandysoftwareImplementedByEsoft':new FormControl(null),
      // 'CSCandycrewListAvailable':new FormControl(null),
      // 'CSCandydataSyncFromShipServerToShoreServer':new FormControl(null),
      // 'CSCandydataSyncWithIpadTablet':new FormControl(null),
      // 'CSCandydataSyncWithDesktop':new FormControl(null),
      // 'CSCandydataFillingStarted':new FormControl(null),
      // 'CSCandynextStepsForCampbell':new FormControl(null),
      // 'CSCandyresponsibityNextStepsCampbell':new FormControl(null),
      // 'CSCandynextStepsForEsoft':new FormControl(null),
      // 'CSCandyresponsibityNextStepsEsoft':new FormControl(null),
      // 'CSCandypercentCompletion':new FormControl(null),
      // 'CSCandysupportRequiredFromCaptainKeshav':new FormControl(null),

      // 'CSCapricesoftwareImplementedByEsoft':new FormControl(null),
      // 'CSCapricecrewListAvailable':new FormControl(null),
      // 'CSCapricedataSyncFromShipServerToShoreServer':new FormControl(null),
      // 'CSCapricedataSyncWithIpadTablet':new FormControl(null),
      // 'CSCapricedataSyncWithDesktop':new FormControl(null),
      // 'CSCapricedataFillingStarted':new FormControl(null),
      // 'CSCapricenextStepsForCampbell':new FormControl(null),
      // 'CSCapriceresponsibityNextStepsCampbell':new FormControl(null),
      // 'CSCapricenextStepsForEsoft':new FormControl(null),
      // 'CSCapriceresponsibityNextStepsEsoft':new FormControl(null),
      // 'CSCapricepercentCompletion':new FormControl(null),
      // 'CSCapricesupportRequiredFromCaptainKeshav':new FormControl(null),

      // 'CSJadensoftwareImplementedByEsoft':new FormControl(null),
      // 'CSJadencrewListAvailable':new FormControl(null),
      // 'CSJadendataSyncFromShipServerToShoreServer':new FormControl(null),
      // 'CSJadendataSyncWithIpadTablet':new FormControl(null),
      // 'CSJadendataSyncWithDesktop':new FormControl(null),
      // 'CSJadendataFillingStarted':new FormControl(null),
      // 'CSJadennextStepsForCampbell':new FormControl(null),
      // 'CSJadenresponsibityNextStepsCampbell':new FormControl(null),
      // 'CSJadennextStepsForEsoft':new FormControl(null),
      // 'CSJadenresponsibityNextStepsEsoft':new FormControl(null),
      // 'CSJadenpercentCompletion':new FormControl(null),
      // 'CSJadensupportRequiredFromCaptainKeshav':new FormControl(null),

      // 'CSJolasoftwareImplementedByEsoft':new FormControl(null),
      // 'CSJolacrewListAvailable':new FormControl(null),
      // 'CSJoladataSyncFromShipServerToShoreServer':new FormControl(null),
      // 'CSJoladataSyncWithIpadTablet':new FormControl(null),
      // 'CSJoladataSyncWithDesktop':new FormControl(null),
      // 'CSJoladataFillingStarted':new FormControl(null),
      // 'CSJolanextStepsForCampbell':new FormControl(null),
      // 'CSJolaresponsibityNextStepsCampbell':new FormControl(null),
      // 'CSJolanextStepsForEsoft':new FormControl(null),
      // 'CSJolaresponsibityNextStepsEsoft':new FormControl(null),
      // 'CSJolapercentCompletion':new FormControl(null),
      // 'CSJolasupportRequiredFromCaptainKeshav':new FormControl(null),

      // 'CSSonomasoftwareImplementedByEsoft':new FormControl(null),
      // 'CSSonomacrewListAvailable':new FormControl(null),
      // 'CSSonomadataSyncFromShipServerToShoreServer':new FormControl(null),
      // 'CSSonomadataSyncWithIpadTablet':new FormControl(null),
      // 'CSSonomadataSyncWithDesktop':new FormControl(null),
      // 'CSSonomadataFillingStarted':new FormControl(null),
      // 'CSSonomanextStepsForCampbell':new FormControl(null),
      // 'CSSonomaresponsibityNextStepsCampbell':new FormControl(null),
      // 'CSSonomanextStepsForEsoft':new FormControl(null),
      // 'CSSonomaresponsibityNextStepsEsoft':new FormControl(null),
      // 'CSSonomapercentCompletion':new FormControl(null),
      // 'CSSonomasupportRequiredFromCaptainKeshav':new FormControl(null),

      // 'CSEllieMsoftwareImplementedByEsoft':new FormControl(null),
      // 'CSEllieMcrewListAvailable':new FormControl(null),
      // 'CSEllieMdataSyncFromShipServerToShoreServer':new FormControl(null),
      // 'CSEllieMdataSyncWithIpadTablet':new FormControl(null),
      // 'CSEllieMdataSyncWithDesktop':new FormControl(null),
      // 'CSEllieMdataFillingStarted':new FormControl(null),
      // 'CSEllieMnextStepsForCampbell':new FormControl(null),
      // 'CSEllieMresponsibityNextStepsCampbell':new FormControl(null),
      // 'CSEllieMnextStepsForEsoft':new FormControl(null),
      // 'CSEllieMresponsibityNextStepsEsoft':new FormControl(null),
      // 'CSEllieMpercentCompletion':new FormControl(null),
      // 'CSEllieMsupportRequiredFromCaptainKeshav':new FormControl(null),

      // 'crewListAvailableTotal':new FormControl(null),
      // 'dataSyncFromShipServerToShoreServerTotal':new FormControl(null),
      // 'dataSyncWithIpadTabletTotal':new FormControl(null),
      // 'dataSyncWithDesktopTotal':new FormControl(null),
      // 'dataFillingStartedTotal':new FormControl(null)
    });

    this.statussheet.getVesselsByVesselPasscode(this.session.get("passcode"),(response)=>{
      this.ShipName = response.data.vesselName;
      console.log(response);
      console.log(response.data);
      console.log(this.ShipName)
      })
  }

  submitHandler(){
    console.log("before save");
    this.itemForm;
    if(this.Formdata.valid)
    {
      console.log(this.Formdata);
      this.itemForm = {"crewListAvailable": this.Formdata.get('crewListAvailable').value,
      "dataFillingStarted":this.Formdata.get('dataFillingStarted').value,
      "dataSyncFromShipServerToShoreServer":this.Formdata.get('dataSyncFromShipServerToShoreServer').value,
      "dataSyncWithDesktop":this.Formdata.get('dataSyncWithDesktop').value,
      "dataSyncWithIpadTablet":this.Formdata.get('dataSyncWithIpadTablet').value,
      "nextStepsForCampbell":this.Formdata.get('nextStepsForCampbell').value,
      "nextStepsForEsoft":this.Formdata.get('nextStepsForEsoft').value,
      "percentCompletion":this.Formdata.get('percentCompletion').value,
      "responsibityNextStepsCampbell":this.Formdata.get('responsibityNextStepsCampbell').value,
      "responsibityNextStepsEsoft":this.Formdata.get('responsibityNextStepsEsoft').value,
      "shipName":this.ShipName,
      "softwareImplementedByEsoft":this.Formdata.get('softwareImplementedByEsoft').value,
      "supportRequiredFromCaptainKeshav":this.Formdata.get('supportRequiredFromCaptainKeshav').value};


    // let Formdata = {"crewListAvailableTotal": 0,
    // "dataFillingStartedTotal": 0,
    // "dataSyncFromShipServerToShoreServerTotal": 0,
    // "dataSyncWithDesktopTotal": 0,
    // "dataSyncWithIpadTabletTotal": 0,
    // "fleetOverallImplementationKPI": 0,
    // 'sheetRecordsReq':this.itemForm}

    this.statussheet.saveVesselsStatusSheet(this.itemForm,(response)=>{
      console.log("========response======");
      console.log(response);
      console.log(this.itemForm);
      if(response.responsecode === 200 || response.responsecode === '200'){
        this.successbox.show();
      }
      else{
        this.errorbox.show();
      }
      console.log("=======responsedata======")
      console.log(response.data)
    })
  }
  }


}
