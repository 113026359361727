import { Component, OnInit, ViewChild, ElementRef, Input, Injectable, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { HierarchyTreeService } from './hierarchy-tree.service';
import { TreeviewItem, TreeviewConfig, DownlineTreeviewItem, TreeviewComponent, TreeviewI18n, DropdownTreeviewComponent, TreeviewHelper } from 'ngx-treeview';
import { isNil, remove, reverse } from 'lodash';
import { DropdownTreeviewSelectI18n } from './dropdown-treeview-select-i18n';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

export interface Type {
  id: string;
  viewValue: string;
}

export interface IsStatic {
  id: string;
  viewValue: string;
}
@Injectable()
export class ProductTreeviewConfig extends TreeviewConfig {
  hasAllCheckBox = false;
  hasFilter = false;
  hasCollapseExpand = false;
  maxHeight = 400;
}

@Component({
  selector: 'app-hierarchy-tree',
  templateUrl: './hierarchy-tree.component.html',
  styleUrls: ['./hierarchy-tree.component.scss'],
  providers: [
    { provide: TreeviewI18n, useClass: DropdownTreeviewSelectI18n }
  ]
})
export class HierarchyTreeComponent implements OnInit, OnChanges {

  isLoading = true;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  @ViewChild('deleteSwal', { static: false }) public deleteSwal: SwalComponent;
  @ViewChild('successbox', { static: true }) public successbox: SwalComponent;
  @ViewChild('errorbox', { static: true }) public errorbox: SwalComponent;
  @ViewChild('image1', { static: false }) image1: ElementRef;
  @ViewChild('image2', { static: false }) image2: ElementRef;

  category = [];
  displayedColumns: string[] = ['id', 'image', 'mobileImageUrl', 'name', 'parentname', 'type', 'isstatic', 'edit', 'delete'];
  dataSource;
  createHierarchy: FormGroup;
  type: Type[] = [
    { id: 'Hierarchy', viewValue: 'Hierarchy' },
    { id: 'Master Form', viewValue: 'Master Form' },
    { id: 'Entry Form', viewValue: 'Entry Form' },
    { id: 'ORB Entry Form', viewValue: 'ORB Entry Form' },
    { id: 'GRB Entry Form', viewValue: 'GRB Entry Form' },
    { id: 'DLB Entry Form', viewValue: 'DLB Entry Form' },
    { id: 'Ballast Entry Form', viewValue: 'Ballast Entry Form' },
    { id: 'Fuel Entry Form', viewValue: 'Fuel Entry Form' }



  ]
  isstatic: IsStatic[] = [
    { id: 'Yes', viewValue: 'Yes' },
    { id: 'No', viewValue: 'No' }
  ]
  success;

  @Input() config: TreeviewConfig;
  @Input() value: any;
  @Output() valueChange = new EventEmitter<any>();
  @ViewChild(DropdownTreeviewComponent, { static: true }) dropdownTreeviewComponent: DropdownTreeviewComponent;
  filterText: string;
  private dropdownTreeviewSelectI18n: DropdownTreeviewSelectI18n;
  dropdownEnabled = true;
  buttonClasses = [
    'btn-outline-primary',
    'btn-outline-secondary',
    'btn-outline-success',
    'btn-outline-danger',
    'btn-outline-warning',
    'btn-outline-info',
    'btn-outline-light',
    'btn-outline-dark'
  ];
  buttonClass = this.buttonClasses[0];


  @ViewChild(TreeviewComponent, { static: true }) treeviewComponent: TreeviewComponent;
  items: TreeviewItem[];
  itemsData: TreeviewItem[];
  rows: string[];

  headerTemplate:HierarchyTreeComponent;

  constructor(
    public service: HierarchyTreeService,
    public i18n: TreeviewI18n
  ) {
    this.resetForm();
  }
  getSelectedValue(value,id){


    if(value.target.checked){

      console.log(id);
      this.service.formData.parentid = id.value;
      this.service.formData.parentName = id.text;


    }else{
      this.service.formData.parentid = "";
      this.service.formData.parentName = "";

    }
  }
  onSelectedChange(downlineItems: DownlineTreeviewItem[]) {
    this.rows = [];
    console.log(downlineItems);
    // downlineItems.forEach(downlineItem => {
    //   const item = downlineItem.item;
    //   const value = item.value;
    //   const texts = [item.text];
    //   let parent = downlineItem.parent;
    //   while (!isNil(parent)) {
    //     texts.push(parent.item.text);
    //     parent = parent.parent;
    //   }
    //   const reverseTexts = reverse(texts);
    //   const row = `${reverseTexts.join(' -> ')} : ${value}`;
    //   this.rows.push(row);
    // });
  }


  onValueChange(value: number) {
    console.log('valueChange raised with value: ' + value);
    this.createHierarchy.controls['parentid'].setValue(value);
}

  onFilterChange(value) {
    console.log('filter:', value);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (isNil(this.value)) {
      this.selectAll();
    } else {
      this.updateSelectedItem();
    }
  }

  select(item: TreeviewItem) {
    if (item.children === undefined) {
      this.selectItem(item);
    }
  }


  private selectAll() {
    const allItem = this.dropdownTreeviewComponent.treeviewComponent.allItem;
    this.selectItem(allItem);
  }

  private selectItem(item: TreeviewItem) {
    this.dropdownTreeviewComponent.dropdownDirective.close();
    if (this.dropdownTreeviewSelectI18n.selectedItem !== item) {
      this.dropdownTreeviewSelectI18n.selectedItem = item;
      if (this.value !== item.value) {
        this.value = item.value;
        this.valueChange.emit(item.value);
      }
    }
  }

  private updateSelectedItem() {
    if (!isNil(this.items)) {
      const selectedItem = TreeviewHelper.findItemInList(this.items, this.value);
      if (selectedItem) {
        this.selectItem(selectedItem);
      } else {
        this.selectAll();
      }
    }
  }
  ngOnInit() {

    of(this.dropdownTreeviewComponent).pipe(delay(3000))
      .subscribe(data => {
        this.isLoading = false;
       this.dataSource = data
      }, error => this.isLoading = false)

    this.config = TreeviewConfig.create({
      hasAllCheckBox: false,
      hasCollapseExpand: false,
      hasFilter: true,
      decoupleChildFromParent: false,
      maxHeight: 500
    });

    this.dropdownTreeviewSelectI18n = this.i18n as DropdownTreeviewSelectI18n;


    this.service.getParentCategory((data) => {
      // this.isLoading = false;

      this.category = data.data;
      this.dataSource = new MatTableDataSource(data.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    });


    this.service.getParentChildTreeCategory((data) => {
      console.log(data.data);
      // this.isLoading = false;

      let Prentdata = [];
      this.dataSource = data.data;
      //this.dataforItems= data.data;
      data.data.forEach(element => {
        element.text = element.hName;
        element.value = element.id;
        element.checked = false
        Prentdata.push(
          new TreeviewItem(element)
        );
      });

      setTimeout(() => {
        // this.category = data.data;
        console.log(Prentdata);
        this.items = data.data;
        this.itemsData = Prentdata;
      }, 500);
      // this.dataSource.paginator = this.paginator;
      // this.dataSource.sort = this.sort;

    });

    this.createHierarchy = new FormGroup({
      'hName': new FormControl(null, [Validators.required]),
      'parentid': new FormControl(null),
      'image': new FormControl(null),
      'mobileImageUrl': new FormControl(null),
      'type': new FormControl(null, [Validators.required]),
      'staticForm': new FormControl(null)

    });
  }

  onClick(action, data) {
    // console.log(data);
    if (action == 'edit') {

      console.log(data);
      console.log(this.itemsData);
      this.service.formData.id = data.id;
      this.service.formData.hName = data.hName;
      this.service.formData.parentid = data.parentid;
      this.service.formData.parentName = data.parentName;
      this.service.formData.type = data.type;
      this.service.formData.staticForm = data.staticForm;
      if (data.image) {
        this.service.formData.image = data.image;

      } else {
        this.service.formData.image = 'assets/media/logo/logo.png';

      }

      if (data.mobileImageUrl) {
        this.service.formData.mobileImageUrl = data.mobileImageUrl;

      } else {
        this.service.formData.mobileImageUrl = 'assets/media/logo/logo.png';

      }

      this.selectItem(data);
      this.createHierarchy.get('image').setValue(this.service.formData.image);
      this.createHierarchy.get('mobileImageUrl').setValue(this.service.formData.mobileImageUrl);

      // this.sailingModesService.formData.watchHourTiming = data.watchHourTiming;
    } else {

      console.log('Deleted');

      this.service.deleteData(data.id).subscribe((data) => {
        console.log('Deleted');

        this.service.getParentChildTreeCategory((data) => {
          console.log(data.data);
          let Prentdata = [];
          this.dataSource = data.data;
          //this.dataforItems= data.data;
          data.data.forEach(element => {
            element.text = element.hName;
            element.value = element.id;
            element.checked = false
            Prentdata.push(
              new TreeviewItem(element)
            );
          });

          setTimeout(() => {
            // this.category = data.data;
            console.log(Prentdata);
            this.items = data.data;
            this.itemsData = Prentdata;
          }, 500);

        });
        this.service.getParentCategory((response) => {
          console.log(response);
          this.dataSource = new MatTableDataSource(response.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        })
      })
    }
    window.scroll(0, 0);

  }

  uploadImage(event) {
    const fileInfo = document.querySelector('.file-info');
    const name = event.target.value.split(/\\|\//).pop();
    const truncated = name.length > 20
      ? name.substr(name.length - 20)
      : name;

    fileInfo.innerHTML = truncated;

    if (event.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      console.log(event.target.files);

      this.service.uploadImage(formData, (response) => {
        // console.log(response);
        if (response.responsecode == 200) {
          console.log(response);
          this.service.formData.image = response.fileurl;

          //this.createHierarchy.get('image').setValue(response.fileurl);
        }
      })
      // console.log(this.service.uploadDocumentsDriver);
    }
  }


  uploadMobileImage(event) {
    const fileInfo = document.querySelector('.file-info1');
    const name = event.target.value.split(/\\|\//).pop();
    const truncated = name.length > 20
      ? name.substr(name.length - 20)
      : name;

    fileInfo.innerHTML = truncated;

    if (event.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      console.log(event.target.files);

      this.service.uploadImage(formData, (response) => {
        // console.log(response);
        if (response.responsecode == 200) {
          console.log(response);
          //this.createHierarchy.get('mobileImageUrl').setValue(response.fileurl);
          this.service.formData.mobileImageUrl = response.fileurl;


        }
      })
      // console.log(this.service.uploadDocumentsDriver);
    }
  }

  submitHandler() {
    console.log(this.createHierarchy);
    // console.log(this.createHierarchy.value.parentid);
    // console.log(this.createHierarchy.value.parentid.join());
    if (this.createHierarchy.valid) {
      //this.service.formData.parentid = this.createHierarchy.value.parentid.join();
      console.log(this.service.formData);
      if (this.service.formData.id) {
        this.service.putData(this.service.formData.id, this.service.formData, (data) => {
          console.log(data);

          if (data.responsecode === 200 || data.responsecode === '200') {
            this.service.getParentChildTreeCategory((data) => {
              console.log(data.data);
              let Prentdata = [];
              this.dataSource = data.data;
              //this.dataforItems= data.data;
              data.data.forEach(element => {
                element.text = element.hName;
                element.value = element.id;
                element.checked = false
                Prentdata.push(
                  new TreeviewItem(element)
                );
              });

              setTimeout(() => {
                // this.category = data.data;
                console.log(Prentdata);
                this.items = data.data;
                this.itemsData = Prentdata;
              }, 500);

            });
            this.service.getParentCategory((response) => {
              console.log(response);
              this.dataSource = new MatTableDataSource(response.data);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.successbox.show();
              this.resetForm();
              this.createHierarchy.reset();
            })
          } else {
            this.errorbox.text = data.message;
            this.errorbox.show();
          }
        })


      }
      else {

        this.service.postData(this.service.formData, (response) => {
          console.log(response);

          if (response.responsecode === 200 || response.responsecode === '200') {
            this.service.getParentChildTreeCategory((data) => {
              console.log(data.data);
              let Prentdata = [];
              this.dataSource = data.data;
              //this.dataforItems= data.data;
              data.data.forEach(element => {
                element.text = element.hName;
                element.value = element.id;
                element.checked = false
                Prentdata.push(
                  new TreeviewItem(element)
                );
              });

              setTimeout(() => {
                // this.category = data.data;
                console.log(Prentdata);
                this.items = data.data;
                this.itemsData = Prentdata;
              }, 500);

            });
            this.service.getParentCategory((response) => {
              console.log(response);
              this.dataSource = new MatTableDataSource(response.data);
              this.dataSource.paginator = this.paginator;
              this.successbox.show();

              this.resetForm();
              this.createHierarchy.reset();
            })
          } else {
            this.errorbox.text = response.message;
            this.errorbox.show();
          }
        })
      }

    }
  }
  resetForm() {
    // this.createHierarchy.reset();

    this.service.formData.image = 'assets/media/logo/logo.png';
    this.service.formData.mobileImageUrl = 'assets/media/logo/logo.png';

    console.log(document.querySelector('.file-info'));
    console.log(document.querySelector('.file-info1'));
    if (document.querySelector('.file-info')) {

      const fileInfo = document.querySelector('.file-info');
      fileInfo.innerHTML = "";
    }
    if (document.querySelector('.file-info1')) {

      const fileInfo1 = document.querySelector('.file-info1');
      fileInfo1.innerHTML = ""
    }

    this.service.formData.id = "";
    this.service.formData.hName = "";
    this.service.formData.parentid = "";
    this.service.formData.type = "";
    this.service.formData.staticForm = "";
    this.service.formData.parentName = "";

  }
}


