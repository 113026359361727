import { Component, OnInit } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';
import { NetworkStatusService } from 'src/app/network-status.service.ts.service';

@Component({
  selector: 'app-layout2',
  templateUrl: './layout2.component.html',
  styleUrls: ['./layout2.component.scss']
})
export class Layout2Component implements OnInit {
  companyid: boolean = true;

  constructor(public session: SessionStorageService, public networkStatusService: NetworkStatusService,) { }

  ngOnInit() {
    this.networkStatusService.isOnline.subscribe(status => {
      var companyid = this.session.get('companyId');
      if(status === false){     
        if(companyid){
          this.companyid = false;
        } else {
          this.companyid = true;
        }
      } else {
        this.companyid = true;
      }
    });
    // console.log('dfsjdfh');
  }

}
