import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from '../apiroot.service';

@Injectable({
  providedIn: 'root'
})

export class QuestionService {

  formData = {section_id:''}

  constructor(public http:HttpClient,public service:ApiRootService) { }

  getControlTypeData(callback){
    return this.http.get(this.service.getBaseUrl()+'/getAllfieldType').subscribe(
      (data) =>{
        console.log(data);
        callback(data);
      }
    )}

  getAllSections(callback){
   return this.http.get(this.service.getBaseUrl()+'/getAllChecklistSections').subscribe(
      (data) => {
        console.log(data);
        callback(data);
    });

  }
  
  getQuestionData(checklistid,sectionid,callback){
   return this.http.get(this.service.getBaseUrl()+'/getChecklistQuestionDetailsByChecklistIdAndSectionId/'+checklistid+'/'+sectionid).subscribe(
      (data) => {
        console.log(data);
        callback(data);
    });

  }
  
  getQuestionDataOfChecklistId(checklistid,callback){
   return this.http.get(this.service.getBaseUrl()+'/getChecklistQuestionDetailsByChecklistId/'+checklistid).subscribe(
      (data) => {
        console.log(data);
        callback(data);
    });

  }
  
  
 getData(){
     
 }
 
 updateQuestionDetails(questionId,details,callback){
   return this.http.put(this.service.getBaseUrl()+'/updateChecklistQuestionDetails/'+questionId,details).subscribe(
      (data) => {
        console.log(data);
        callback(data);
    });
 }


  deleteData(id){
    return this.http.delete(this.service.getBaseUrl()+'/softDeleteChecklistQuestionDetailsByChecklistQuestionDetailsId/'+id);
  }

  
  saveCheckListData(FormData,callback){
    return this.http.post(this.service.getBaseUrl()+'/saveChecklistQuestionDetailsList',FormData).subscribe(
      (data) =>{
        console.log(data);
        callback(data);
      }
    )}
    
    

}
