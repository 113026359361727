import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ApiRootService} from './../apiroot.service';

@Injectable({
  providedIn: 'root'
})
export class HierarchyService {
  formData = {id:'',hName:'',parentid:'',image:'assets/media/logo/logo.png',mobileImageUrl:'assets/media/logo/logo.png',type:'',staticForm:''};
  
  constructor(public http:HttpClient,public service:ApiRootService) { }

  public getParentCategory(callback){
    return this.http.get(this.service.getBaseUrl()+'/getAllFolder').subscribe(
      (data) => {
        console.log(data);
        callback(data);
    });

  }

  uploadImage(formData,callback){
    return this.http.post(this.service.getBaseUrl()+'/uploadFile',formData).subscribe((data)=>{
      callback(data);
    })

  }

  postData(formData,callback){

    return this.http.post(this.service.getBaseUrl()+'/saveFolder',formData).subscribe((data)=>{
      callback(data);
    })

  }

  deleteData(id){
    return this.http.delete(this.service.getBaseUrl()+'/softDeleteFolderStructureById/'+id);
  }

  putData(id,formData,callback){
    return this.http.put(this.service.getBaseUrl()+'/updateFolderById/'+id,formData).subscribe((data)=>{
      console.log(data);
      callback(data);
  }
)}
}
