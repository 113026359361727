import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from '../apiroot.service';

@Injectable({
  providedIn: 'root'
})
export class ChecklistreportsService {

  formData = {bookTypeId:''};


  constructor(public http:HttpClient,public service:ApiRootService) { }

   getAllCat(callback){
    return this.http.get(this.service.getBaseUrl()+'/getAllChecklistCategory').subscribe(
      (data) =>{
          console.log(data);
        callback(data);
      }
    )
  }

  getAllChecklistByCatId(id, callback){
    return this.http.get(this.service.getBaseUrl()+'/getChecklistDetailsByChecklistCategoryId/'+id).subscribe(
      (data) =>{
          console.log(data);
        callback(data);
      }
    )
  }

  getAllChecklistAnsByChecklistId(checklist,vesselId, callback){
    return this.http.get(this.service.getBaseUrl()+'/getChecklistAnswerDetailsByChecklistIdAndDate/'+vesselId+'/'+checklist.checklistId+'/'+checklist.actionDate).subscribe(
      (data) =>{
          console.log(data);
        callback(data);
      }
    )
  }

  getChecklistAnswerDetailsByChecklistIdAndDateWithTime(checklist,vesselId, callback){
    console.log(checklist,vesselId,'===getChecklistAnswerDetailsByChecklistIdAndDateWithTime==');
    return this.http.get(this.service.getBaseUrl()+'/getChecklistAnswerDetailsByChecklistIdAndDateWithTime/'+vesselId+'/'+checklist.checklistId+'/'+checklist.actionDate+'/'+checklist.actionTime).subscribe(
      (data) =>{
          console.log(data);
        callback(data);
      }
    )
  }

  getAllNotes(id, callback){
    return this.http.get(this.service.getBaseUrl()+'/getChecklistNotesByChecklistId/'+id).subscribe(
      (data) =>{
          console.log(data);
        callback(data);
      }
    )
  }

  getChecklistAnswerDetailsByChecklistIdAndDateGroupByActionDate(id,vesselId, callback){
    return this.http.get(this.service.getBaseUrl()+'/getChecklistAnswerDetailsByChecklistIdAndDateGroupByActionDate/'+vesselId+'/'+id).subscribe(
      (data) =>{
          console.log(data);
        callback(data);
      }
    )
  }

   getChecklistDetailsByChecklistId(id, callback){
    return this.http.get(this.service.getBaseUrl()+'/getChecklistDetailsByChecklistDetailsId/'+id).subscribe(
      (data) =>{
          console.log(data);
        callback(data);
      }
    )
  }


  getChecklistApprovalDetails(checklist,vesselId, callback){
    return this.http.get(this.service.getBaseUrl()+'/getChecklistAnswerDetailsApprovalByChecklistIdAndDateWithTime/'+vesselId+'/'+checklist.checklistId+'/'+checklist.actionDate+'/'+checklist.actionTime).subscribe(
      (data) =>{
          console.log(data);
        callback(data);
      }
    )
  }


  getChecklistAnswerDetailsApprovalByChecklistIdAndFromDateAndToDateUsingGET(vesselId,checklistId,fromDate,toDate,searchValue, callback){
    return this.http.get(this.service.getBaseUrl()+'/getChecklistAnswerDetailsApprovalByChecklistIdAndFromDateAndToDate/'+vesselId+'/'+checklistId+'/'+fromDate+'/'+toDate+'/'+searchValue).subscribe(
      (data) =>{
          console.log(data);
        callback(data);
      }
    )
  }

  getChecklistAnswerDetailsApprovalByChecklistIdAndFromDateAndToDate(vesselId,checklistId, callback){
    return this.http.get(this.service.getBaseUrl()+'/getChecklistAnswerDetailsApprovalByChecklistIdAndFromDateAndToDate/'+vesselId+'/'+checklistId+'/null/null/null').subscribe(
      (data) =>{
          console.log(data);
        callback(data);
      }
    )
  }

  saveChecklistApprovalDetails(approvalDetail, callback){
    return this.http.post(this.service.getBaseUrl()+'/saveChecklistAnswerDetailsApproval',approvalDetail).subscribe(
      (data) =>{
          console.log(data);
         callback(data);
      }
    )
  }



  getCrewByCrewIdList(userIds, callback){
    return this.http.get(this.service.getBaseUrl()+'/getCrewByCrewIdList/'+userIds).subscribe(
      (data) =>{
          console.log(data);
        callback(data);
      }
    )
  }

  getChecklistAdditionalInfoByChecklistId(id, callback){
    return this.http.get(this.service.getBaseUrl()+'/getChecklistAdditionalInfoByChecklistId/'+id).subscribe(
      (data) =>{
          console.log(data);
        callback(data);
      }
    )
  }

  getChecklistAnsAdditionalInfoByChecklistIdWithDateTime(checklist, callback){
    return this.http.get(this.service.getBaseUrl()+'/getChecklistAnsAdditionalInfoByChecklistIdWithDateTime/'+checklist.checklistId+'/'+checklist.actionDate+'/'+checklist.actionTime).subscribe(
      (data) =>{
          console.log(data);
        callback(data);
      }
    )
  }

}
