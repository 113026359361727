import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from '../apiroot.service';

@Injectable({
  providedIn: 'root'
})
export class VesselsService {

  formTypeData = {id:'',companyId:'', passcode:'',noOfDevice:'' , vesselCode:'' , vesselName:'' ,vesselTypeId:'' ,customer:''}
  vesselFormData = {id:'',vesselCode:'',vesselName:'',vesselTypeId:'',vesselTypeName:'',customer:'',emailAddress:'',imoNo:'',mv:'',userId:'',userName:'',companyId:'',companyName:'',passcode:'',noOfDevice:'',vesselPreviousNameDetails:'',vesselPreviousDatesDetails:'',portOfRegistory:'',typeOfVessel:'',typeOfHull:'',registeredOwner:'',parentCompany:'',technicalOperator:'',commercialOperator:'',disponentOwner:'',builderName:'',builderAddress:'',yardNumber:'',dateDelivered:'',classificationSociety:'',classNotation:'',isClassificationSocietyChanged:'',dateOfLastDryDock:'',lastDryDockPlace:'',nextDryDockDueDate:'',lastSpecialSurveyDate:'',nextSpecialSurveyDate:'',lastAnnualSurveyDate:'',nextAnnualSurveyDate:'',lengthOverAll:'',lengthBetweenPerpendiculars:'',extremeBreadth:'',mouldedDepth:'',grossTonnage:'',netRegisteredTonnage:'',suezCanalTonnageGross:'',panamaCanalNetTonnage:'',docNumber:'',issuingAuthority:'',outstandingRecommendations:'',isVesselOperatesUnderQMS:'',typeOfSystem:'',vesselBallastingTime:'',rateOfBallasting:'',deballastingTime:'',deballastingRate:'',isGearedState:'',numberOfCranes:0,locationOfDerricks:'',maximumOutreachOfGear:'',maximumCargoLiftOnHook:'',speedBallast:'',speedLaden:'',passageBallastNumber:'',passageBallastAux:'',passageLadenNumber:'',passageLadenAux:'',inportWorkingNumber:'',inportWorkingAux:'',inportIdleNumber:'',inportIdleAux:'',inportOtherNumber:'',inportOtherAux:'',smcnumber:'',isVesselLogsFitted:'',registeredOwnerAddress:'',parentCompanyAddress:'',technicalOperatorAddress:'',commercialOperatorAddress:'',disponentOwnerAddress:'',safetyManagementCertNo:'',machineCallSign:'',machineINMARSATNumber:'',machineTelexNumber:'',machineFaxNumber:'',machineEmail:'',machineMMSINo:'',machineElectricalSupply:'',freshWaterStateCapacity:'',productionOfEvaporator:'',pAndIClub:'',hullAndMachineryPlaced:'',flag:''}
  // url : String = "http://192.168.0.58:8087/campbellservice/";


  constructor(public httpClient : HttpClient,public service:ApiRootService) { }

  postData(formData,callback){
    return this.httpClient.post(this.service.getBaseUrl()+'/saveVessels',formData).subscribe((data)=>{
      console.log(data);
      callback(data);
    })
  }

  getData(callback){
    return this.httpClient.get(this.service.getBaseUrl()+'/getAllVessels').subscribe(
      (data) =>{
        callback(data);
      }
    )
  }

  getDataById(id,callback){
    return this.httpClient.get(this.service.getBaseUrl()+'/getVesselsByVesselsId/'+id).subscribe(
      (data) =>{
        // console.log(data);
        callback(data);
      }
    )}

    getDataByCompanyId(id,callback){
    return this.httpClient.get(this.service.getBaseUrl()+'/getVesselsByCompanyId/'+id).subscribe(
      (data) =>{
      //  console.log(data);
        callback(data);
      }
    )}

  deleteData(id){
     return this.httpClient.delete(this.service.getBaseUrl()+'/softDeleteVesselsByVesselsId/'+id);

  }

  putData(id,formData,callback){
    return this.httpClient.put(this.service.getBaseUrl()+'/updateVessels/'+id,formData).subscribe((data)=>{
      console.log(data);
      callback(data);

    })
  }
}

