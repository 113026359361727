import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { VesselsService } from './vessels.service';
import { VesseltypeService } from '../vesseltype/vesseltype.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { SessionStorageService } from 'angular-web-storage';
import { CompanyService } from '../company/company.service';
import { ActivatedRoute, RouterModule, Routes, Router } from '@angular/router';
import { EospService } from '../eosp/eosp.service';

@Component({
  selector: 'app-vessels',
  templateUrl: './vessels.component.html',
  styleUrls: ['./vessels.component.css']
})
export class VesselsComponent implements OnInit {


  // displayedColumns: string[] = ['passcode', 'noOfDevice', 'companyId', 'vesselCode', 'vesselName', 'vesselTypeName', 'edit'];
  displayedColumns: string[] = ['passcode', 'companyId', 'vesselCode', 'vesselName', 'vesselTypeName', 'edit'];
  dataSource = new MatTableDataSource()

  myForm: FormGroup;
  isSubmitted = false;
  isLoading = true;
  loading = false;
  data: any = [];
  formTypeId: any;
  product: any;
  public formData;
  vesselType = [];
  userCompanyId: any = [];
  companyData: any = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('deleteSwal', { static: false }) public deleteSwal: SwalComponent;
  @ViewChild('successbox', { static: true }) public successbox: SwalComponent;

  @ViewChild('errorbox', { static: true }) public errorbox: SwalComponent;

  success;
  constructor(private route: ActivatedRoute, private router: Router, public companyService: CompanyService, public session: SessionStorageService, public fb: FormBuilder, public vs: VesselsService, public es:EospService, public vts: VesseltypeService,
    public http: HttpClient) {
    this.resetForm();


  }

  ngOnInit() {
    this.userCompanyId = this.session.get('companyId');
    this.companyService.getData((response) => {
      console.log(response);
      this.companyData = response.data;

    })


    of(this.dataSource).pipe(delay(1000))
      .subscribe(data => {
        this.isLoading = false;
        this.data = data
      }, error => this.isLoading = false);
    this.getProductDetails(this.route.snapshot.params['id']);

    this.getAllVesselsData(this.userCompanyId);

    this.vts.getData((response) => {
      console.log(response);
      this.vesselType = response.data;

    })

    this.myForm = this.fb.group({
      'companyId': new FormControl(this.userCompanyId, Validators.required),
      //  'noOfDevice': new FormControl(null, Validators.required),    (This is temprorary disabled)
      'vesselCode': new FormControl(null, [Validators.required, Validators.maxLength(15)]),
      'vesselName': new FormControl(null, [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(30)
      ]),
      'emailAddress': new FormControl(null, [
        Validators.required,
        Validators.email,
        Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$'),
        Validators.maxLength(30)
      ]),
      'vesselTypeId': new FormControl(null, Validators.required),
      'passcode': new FormControl(null)

    })
  }

  getCompanyNameById(id) {
    for (var comp of this.companyData) {
      if (comp.id == id) {
        //alert(comp.companyName)
        return comp.companyName;
        break;
      }
    }
  }


  getAllVesselsData(companyId) {
    if (companyId) {
      this.vs.getDataByCompanyId(companyId, (response) => {
        console.log("==getAllVesselsData==", response);
        this.dataSource = new MatTableDataSource(response.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        //this.vs.formTypeData = response.data;
      })
    } else {
      this.vs.getData((response) => {
        console.log("==getAllVesselsData==", response);
        this.dataSource = new MatTableDataSource(response.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        //this.vs.formTypeData = response.data;
      })
    }
  }

  submitHandler() {
    console.log(this.myForm);
    this.isSubmitted = true;
    if (this.myForm.valid) {
      this.isSubmitted = false;
      this.loading = true;
      console.log(this.myForm.value);

      if (this.userCompanyId != null && this.userCompanyId != 0) {
        this.vs.formTypeData.companyId = this.userCompanyId;
      }

      if (this.vs.formTypeData.id) {
        console.log(this.vs.formTypeData.id);
        this.vs.putData(this.vs.formTypeData.id, this.vs.formTypeData, (data) => {
          console.log(data);

          if (data.responsecode === 200 || data.responsecode === '200') {

            this.getAllVesselsData(this.userCompanyId)
            this.successbox.show();
            this.resetForm();
            this.myForm.reset();

          } else {
            this.errorbox.text = data.message;
            this.errorbox.show();
          }

          this.loading = false;
        })
      }
      else {
        this.vs.postData(this.vs.formTypeData, (response) => {
          console.log(response);

          if (response.responsecode === 200 || response.responsecode === '200') {
            this.getAllVesselsData(this.userCompanyId)
            this.successbox.show();
            this.resetForm();
            this.myForm.reset();
          } else {
            this.errorbox.text = response.message;
            this.errorbox.show();
          }
          //alert(this.isSubmitted);

          this.loading = false
        })
      }
    }
  }

  getProductDetails(id) {
    this.vs.getDataById(id, (response) => {
      console.log(response);
      this.product = response;
    })
  }



  // onClick(action, data) {
  //   if (action == 'edit') {
  //     this.session.set('vesselData', data);
  //     this.router.navigate(['/vessel']);

  //     this.vs.formTypeData.id = data.id;
  //     this.vs.formTypeData.vesselCode = data.vesselCode;
  //     this.vs.formTypeData.vesselName = data.vesselName;
  //     this.vs.formTypeData.vesselTypeId = data.vesselTypeId;
  //     this.vs.formTypeData.customer = data.customer;
  //     this.vs.formTypeData.noOfDevice = data.noOfDevice;
  //     this.vs.formTypeData.passcode = data.passcode;


  //   } else {
  //     this.vs.deleteData(data.id).subscribe((data) => {
  //       console.log('Deleted');
  //       this.vs.getData((response) => {
  //         console.log(response);
  //         this.dataSource = new MatTableDataSource(response.data);
  //         this.dataSource.paginator = this.paginator;
  //         this.dataSource.sort = this.sort;
  //       })
  //     })
  //   }
  //   window.scroll(0, 0);

  // }

  onClick(action, data) {
    if (action === 'edit') {

      this.session.set('vesselData', data);
  
      
      // this.es.getSmtpSettingsById(data.id, (smtpData) => {
        
      //   this.es.smtpData = smtpData;   

      //   this.es.smtpData = {
      //     id: smtpData.id,
      //     userName: smtpData.userName,
      //     userEmail: smtpData.userEmail,
      //     emailPassword: smtpData.emailPassword,
      //     host: smtpData.host,
      //     port: smtpData.port,
      //     sslEnabled: smtpData.sslEnabled,
      //     storeType: smtpData.storeType
      //   };

       // console.log('SMTP Settings:', this.es.smtpData);

        
        this.router.navigate(['/vessel']);
     // });
  

      this.vs.formTypeData.id = data.id;
      this.vs.formTypeData.vesselCode = data.vesselCode;
      this.vs.formTypeData.vesselName = data.vesselName;
      this.vs.formTypeData.vesselTypeId = data.vesselTypeId;
      this.vs.formTypeData.customer = data.customer;
      this.vs.formTypeData.noOfDevice = data.noOfDevice;
      this.vs.formTypeData.passcode = data.passcode;
  
    } else {
      this.vs.deleteData(data.id).subscribe(() => {
        console.log('Deleted');

        this.vs.getData((response) => {
          console.log(response);
          this.dataSource = new MatTableDataSource(response.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        });
      });
    }
  
    window.scroll(0, 0);
  }
  
  

  doFilter(value: string) {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }


  resetForm() {
    this.vs.formTypeData.id = "";
    this.vs.formTypeData.vesselCode = "";
    this.vs.formTypeData.vesselName = "";
    this.vs.formTypeData.vesselTypeId = "";
    this.vs.formTypeData.customer = "";
    this.isSubmitted = false;
    // this.myForm.reset();
  }

}
