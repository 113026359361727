import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiRootService } from '../../apiroot.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  token;
  headers;
  constructor(
    public _httpClient: HttpClient,
    public apiroot: ApiRootService
  ) { }

  loginUser(formData,callback){
    console.log(formData);

    // console.log(this.apiroot.getAuthURL()+'/auth');
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._httpClient.post(this.apiroot.getAuthURL()+'/auth',formData, { headers: this.headers, observe: 'response'}).subscribe(
      (data) => {
        // console.log(data);
        callback({responsecode:data.body,token:data.headers.get('authorization')});
      },
      (error)=>{
        // console.log(error);
        callback({responsecode:{responsecode:401,message:"User Name or Password is Incorrect"}});
        // callback({responsecode:'401',message:'User Name or Password Incorrect'});
      }
    )
  }
  
  public  getLatestCOSPByPasscode(id,callback){
    return this._httpClient.get(this.apiroot.getBaseUrl()+'/getLatestCOSPByPasscode/'+id).subscribe(
      (data) =>{
      //  console.log(data); 
        callback(data);
      }
    )}

}