import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { VesseltypeService } from './vesseltype.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-vesseltype',
  templateUrl: './vesseltype.component.html',
  styleUrls: ['./vesseltype.component.css']
})
export class VesseltypeComponent implements OnInit {

  displayedColumns: string[] = ['vesselTypeId', 'vesselTypeName', 'edit', 'delete'];
  dataSource = new MatTableDataSource()

  myForm: FormGroup;

  data: any = [];
  formTypeId: any;
  product: any;
  public formData;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild('deleteSwal', { static: false }) public deleteSwal: SwalComponent;
  @ViewChild('successbox', { static: true }) public successbox: SwalComponent;

  @ViewChild('errorbox', { static: true }) public errorbox: SwalComponent;

success;
  constructor(public fb: FormBuilder, public vts: VesseltypeService,
    public http: HttpClient) {
    this.resetForm();
  }

  ngOnInit() {

    // this.getProductDetails(this.route.snapshot.params['id']);

    this.vts.getData((response) => {
      console.log(response);
      this.dataSource = new MatTableDataSource(response.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })

    this.myForm = this.fb.group({

      'vesselTypeName': new FormControl(null, [
        Validators.required,
        Validators.minLength(4),
        Validators.pattern('^[a-zA-Z ]*$')
      ])

    })


  }

  submitHandler() {

    if (this.myForm.valid) {
      console.log(this.myForm.value);

      if (this.vts.formTypeData.vesselTypeId) {
        this.vts.putData(this.vts.formTypeData.vesselTypeId, this.vts.formTypeData, (data) => {
          console.log(data);

          if (data.responsecode === 200 || data.responsecode === '200') {

            this.vts.getData((response) => {
              console.log(response);
              this.dataSource = new MatTableDataSource(response.data);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.successbox.show();

              this.resetForm();
            })
          } else {
            this.errorbox.text = data.message;
            this.errorbox.show();
          }
        })
      }
      else {
        this.vts.postData(this.vts.formTypeData, (response) => {
          console.log(response);

          if (response.responsecode === 200 || response.responsecode === '200') {


            this.vts.getData((response) => {
              console.log(response);
              this.dataSource = new MatTableDataSource(response.data);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.successbox.show();

              this.resetForm();
            })
          } else {
            this.errorbox.text = response.message;
            this.errorbox.show();
          }
        })
      }
    }
  }

  getProductDetails(id) {
    this.vts.getDataById(id, (response) => {
      console.log(response);
      this.product = response;
    })
  }



  onClick(action, data) {
    if (action == 'edit') {
      this.vts.formTypeData.vesselTypeName = data.vesselTypeName;
      this.vts.formTypeData.vesselTypeId = data.vesselTypeId;
    } else {
      this.vts.deleteData(data.vesselTypeId).subscribe((data) => {
        console.log('Deleted');
        this.vts.getData((response) => {
          console.log(response);
          this.dataSource = new MatTableDataSource(response.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        })
      })
    }
    window.scroll(0,0);

  }

  resetForm() {
    this.vts.formTypeData.vesselTypeId = "";
    this.vts.formTypeData.vesselTypeName = "";
    // this.vts.formTypeData.vesselTypeId = "";
    // this.myForm.reset();
  }

}
