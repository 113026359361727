import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from './apiroot.service';

@Injectable({
  providedIn: 'root'
})
export class SailingModesChangeLogService {

  formData = { id: '', mode: '', changeDateTime: '', changedByUser: '' }

  constructor(public http: HttpClient,public service:ApiRootService) { }

  postData(formData, callback) {
    return this.http.post(this.service.getBaseUrl()+'/saveSailingModesChangeLog', formData).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }

  getData(callback) {
    return this.http.get(this.service.getBaseUrl()+'/getAllSailingModesChangeLogChangeLog').subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }

  putData(id, formData, callback) {
    return this.http.put(this.service.getBaseUrl()+'/updateSailingModesChangeLog/'+ id, formData).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }

  deleteData(id) {
    return this.http.delete(this.service.getBaseUrl()+'/softDeleteSailingModesChangeLogByid/'+ id);
  }

  getModesData(callback){
    return this.http.get(this.service.getBaseUrl()+'/getAllSailingModes').subscribe(
      (data) =>{
        console.log(data);
        callback(data);
      }
    )}

    getCrewrData(callback){
      return this.http.get(this.service.getBaseUrl()+'/getAllCrew').subscribe(
        (data) =>{
          console.log(data);
          callback(data);
        }
      )}

}
