import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { QuestionsectionformsService } from './questionsectionforms.service';
import { VesselsService } from '../vessels/vessels.service';
import { CospService } from '../cosp/cosp.service';;
import { CrewService } from '../crew/crew.service';
import { DatePipe } from '@angular/common';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { CrewComponent } from '../crew/crew.component';

@Component({
  selector: 'app-questionsectionforms',
  templateUrl: './questionsectionforms.component.html',
  styleUrls: ['./questionsectionforms.component.scss']
})
export class QuestionsectionformsComponent implements OnInit {

 
  isLoading = true;
  myForm: FormGroup;
  afs: any;
  success: boolean;
  loading: boolean;
  userVesselData:any = [];
  formData:any = [];
     
     
@ViewChild('successbox', { static: true }) public successbox: SwalComponent;
@ViewChild('errorbox', { static: true }) public errorbox: SwalComponent;

  constructor( public dialogRef: MatDialogRef<QuestionsectionformsComponent>,
    @Inject(MAT_DIALOG_DATA) public data, public fb: FormBuilder, public questionsectionformsService: QuestionsectionformsService,public vesselsService: VesselsService, public cospService: CospService, public crewService: CrewService,public datePipe: DatePipe) { }
    
    
 ngOnInit() {
    this.myForm = this.fb.group({
      'formlistSectionsId': new FormControl(this.data.section),
      'formsectionName': new FormControl(this.data.section),
      'formsectiondetails': new FormControl(this.data.section)
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
     
  submitHandler(){
    console.log("before validate");
    console.log(this.questionsectionformsService.formData);
    if (this.myForm.valid) {
        console.log("in validate");
        this.questionsectionformsService.postData(this.questionsectionformsService.formData, (response) => {
            if (response.responsecode === 200 || response.responsecode === '200') {
                this.successbox.show();
                this.dialogRef.close();
            } else {
                this.errorbox.text = response.message;
                this.errorbox.show();
            }
        })  
    }else{            
        console.log("not validate");
    }       
                 
    }

}
