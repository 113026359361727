import { Component, Inject, OnInit } from '@angular/core';
import {MatFormField,MatSelect,MatOption, MAT_DIALOG_DATA, MatDialogRef,MatSelectModule} from '@angular/material'
import { element } from 'protractor';
import { DialogData } from '../log-book-forms/log-book-forms.component';
import { SessionStorageService } from 'angular-web-storage';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';



@Component({
  selector: 'app-dialoglogbookforms',
  templateUrl: './dialoglogbookforms.component.html',
  styleUrls: ['./dialoglogbookforms.component.scss']
})
export class DialoglogbookformsComponent implements OnInit {
  date :any = []
  watchhour : any = []
  selecteddate:any = '';
  myForm:FormGroup;
  watchTime : string='';
  flag:boolean=false;


  constructor(  public dialogRef: MatDialogRef<DialoglogbookformsComponent>,
    @Inject(MAT_DIALOG_DATA)  public data: DialogData,public fb:FormBuilder,public session: SessionStorageService,) {
      dialogRef.disableClose = true ;
    }

  ngOnInit() {
  //   this.myForm = this.fb.group({
  //   'date':new FormControl(this.date,[Validators.required]),
  //  // 'watchhours':new FormControl(this.watchhour,[Validators.required])

  //   })



   // this.popupwatchhours.forEach((element) =>{
    //  this.date.push(element.date)
    //  console.log(this.date)

    //})

    console.log(this.data)

    this.data.popupwatchhours.forEach((element) =>{
        this.date.push(element.date)

       // console.log(this.date)
  })
  this.selecteddate = this.date[0];

  let index = this.data.popupwatchhours.findIndex(item => item.date == this.selecteddate);
  console.log(index);
  if(this.data.popupwatchhours[index]){
    this.watchhour = this.data.popupwatchhours[index].watchHour
   }
   console.log("===watchhour===",this.watchhour);
 //  this.selecteddate = this.date[index]
 //sessionStorage.setItem('actionDate',dat);
 //sessionStorage.setItem('actionTime',JSON.stringify(this.watchhour));
var splitdate = this.selecteddate;
var watchhourdate = splitdate.split("-");
console.log(watchhourdate[0]+"  "+watchhourdate[1]+"  "+watchhourdate[2]);
var watchhouractiondate = watchhourdate[1]+"/"+watchhourdate[0]+"/"+watchhourdate[2]
 this.session.set('watchHourDate', watchhouractiondate);
}


   selection(dat:any) {
     console.log(dat);

    let index = this.data.popupwatchhours.findIndex(item => item.date == dat);
    console.log(index);
    if(this.data.popupwatchhours[index]){
      this.watchhour = this.data.popupwatchhours[index].watchHour
     }
     console.log(this.watchhour);
   //  this.selecteddate = this.date[index]
   //sessionStorage.setItem('actionDate',dat);
   //sessionStorage.setItem('actionTime',JSON.stringify(this.watchhour));
    var splitdate = dat;
    var watchhourdate = splitdate.split("-");
    console.log(watchhourdate[0]+"  "+watchhourdate[1]+"  "+watchhourdate[2]);
    var watchhouractiondate = watchhourdate[1]+"/"+watchhourdate[0]+"/"+watchhourdate[2]
    this.session.set('watchHourDate', watchhouractiondate);


    }

   selected(dm:any,id:any) {
     console.log("BUTTON VALUE " + dm+ " "+id)
     this.session.set('watchHourTime',dm);
     this.session.set('watchHourId', id);
     this.watchTime=dm;
     this.flag=false;

   }

   onNoClick(): void {
    this.dialogRef.close(false);
  }

  submitHandler(data){
    console.log(this.watchTime)
    if(this.watchTime ==''){
      this.flag=true;
      console.log("watchTime Should Not Be Empty :" ,this.flag);
    }else{
      this.dialogRef.close(true);
    }
    console.log("POPUPDATA IS " + "" + data)
   }

  //  datepick(event)
  //  {
  //    console.log("datpicker" + " " + event.value);
  //  }
  }

