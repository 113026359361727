import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from '../apiroot.service';
import { timeEnd } from 'console';
import { timeout, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  formData = { id: '', companyName: '', email: '', phoneNumber: '', street: '', city: '', state: '', country: '', zipCode: '', profileImage: '' }

  constructor(public http: HttpClient, public service: ApiRootService) { }

  postData(formData, callback) {
    return this.http.post(this.service.getBaseUrl() + '/saveShipCompany', formData).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      });

  }

  getData(callback) {
    return this.http.get(this.service.getBaseUrl() + '/getAllShipCompany').subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }

  getDataSame(callback, errorCallback) {
    return this.http.get(this.service.getBaseUrl() + '/getAllShipCompany').pipe(timeout(8000),
    catchError(error => {
      errorCallback(error); // Handle timeout via error callback
      return throwError(() => new Error('Request Timeout'));
    })).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    ), (error) => {
      console.log("Error in getDataByIdSame:", error);
      errorCallback(error);
    }
  }


  getDataById(id, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getShipCompanyByShipCompanyId/' + id).subscribe(
      (data) => {
        // console.log(data);
        callback(data);
      }
    )
  }

  getDataByIdSame(id, callback, errorCallback) {
    return this.http.get(this.service.getBaseUrl() + '/getShipCompanyByShipCompanyId/' + id).pipe(timeout(8000), 
        catchError(error => {
          errorCallback(error); // Handle timeout via error callback
          return throwError(() => new Error('Request Timeout'));
        })).subscribe(
      (data) => {
        // console.log(data);
        callback(data);
      }
    ),(error) => {
      console.log("Error in getDataByIdSame:", error);
      errorCallback(error);
    }
  }

  putData(id, formData, callback) {
    return this.http.put(this.service.getBaseUrl() + '/updateShipCompany/' + id, formData).subscribe((data) => {
      console.log(data);
      callback(data);
    }
    )
  }

  deleteData(id) {
    return this.http.delete(this.service.getBaseUrl() + '/softDeleteShipCompanyByShipCompanyId/' + id);
  }


  getOrgData(callback) {
    return this.http.get(this.service.getBaseUrl() + '/getAllOrganizations').subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }

  uploadImage(formData, callback) {
    return this.http.post(this.service.getBaseUrl() + '/uploadFile', formData).subscribe((data) => {
      callback(data);
    })

  }

  public getSettingDetail(callback) {
    return this.http.get(this.service.getBaseUrl() + '/getSettingDetail').subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }

}
