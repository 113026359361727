import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { VoyagelistService } from './voyagelist.service';
import { HttpClient } from '@angular/common/http';
import { VesselsService } from '../vessels/vessels.service';
import { SessionStorageService } from 'angular-web-storage';
import { CompanyService } from '../company/company.service';
import { ActivatedRoute, RouterModule, Routes,Router } from '@angular/router';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-voyagelist',
  templateUrl: './voyagelist.component.html',
  styleUrls: ['./voyagelist.component.scss']
})
export class VoyagelistComponent implements OnInit {

  //displayedColumns: string[] = ['type','id','shipName','voyageNo', 'fromPort', 'toPort', 'loadedBallast','createdAt', 'edit'];
  displayedColumns: string[] = ['type','shipName','voyageNo', 'fromPort', 'toPort', 'loadedBallast','createdAt', 'edit'];
  //displayedColumns: string[] = ['id','voyageNo', 'loadedBallast','voyageStartDate'];
  //displayedColumnsEosp: string[] = ['id','voyageNo', 'shipName','voyageStartDate'];
  dataSource = new MatTableDataSource();
  eospDataSource = new MatTableDataSource();
  userCompanyId:any = [];
  companyData:any = [];
  vesselData:any = [];
  vesselId:any;
  userVesselData:any = [];
    data: any = [];
     isLoading = true;

     arr:any = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('deleteSwal', { static: false }) public deleteSwal: SwalComponent;
  @ViewChild('successbox', { static: true }) public successbox: SwalComponent;
  @ViewChild('errorbox', { static: true }) public errorbox: SwalComponent;

  success;
  constructor(private route:ActivatedRoute,private router:Router, public companyService: CompanyService,public session: SessionStorageService,public vs: VesselsService,public voyagelistService: VoyagelistService,
    private http: HttpClient) { }

  ngOnInit() {

      this.userCompanyId = this.session.get('companyId');

      this.companyService.getData((response) => {
               console.log(response);
               this.companyData = response.data;

       })


       this.getAllVesselsData(this.userCompanyId);


       if(this.session.get('vesselId')){
          this.getCompanyVesselList(this.session.get('companyId'));

       }


        of(this.dataSource).pipe(delay(1000))
     .subscribe(data => {
       this.isLoading = false;
       this.data = data
     }, error => this.isLoading = false);

  }

    getCompanyVesselList(id){
         this.vs.getDataByCompanyId(id,(response) => {
             console.log("get company data");
                    console.log(response);
                    this.userVesselData = response.data;
                    this.userCompanyId = id;
                    if(this.session.get('vesselId')){
                        this.getVoyageData(this.session.get('vesselId'));
                        this.vesselId = this.session.get('vesselId');
                    }
            })
     }


     getVoyageData(id){

                 this.voyagelistService.getAllClosedVoyageByvesselId(id,(response)=>{
                    console.log("follow the data123",response);
                    var combinedArray = [];
                    for(let key in response.data){
                        response.data[key].cosp['type'] = (parseInt(key)+1) + '- COSP'
                        if(response.data[key].eosp){
                          response.data[key].cosp['eospId'] = response.data[key].eosp['id'];
                        }
                        combinedArray.push(response.data[key].cosp);
                        if(response.data[key].eosp){
                            response.data[key].eosp['type'] = (parseInt(key)+1) + '- EOSP'
                            combinedArray.push(response.data[key].eosp);
                        }
                    }

                    console.log("===combinedArray==",combinedArray);
                      this.dataSource = new MatTableDataSource(combinedArray);
                      this.vesselId = id;
                      this.dataSource.paginator = this.paginator;
                      this.dataSource.sort = this.sort;
                })

     }


     getShipName(id){
        for (var val of this.userVesselData) {
            if(val.id==id){
                this.voyagelistService.getDataById(id,(response)=>{
                    console.log("follow the data");
                    console.log(response.data);

                      this.dataSource = new MatTableDataSource([response.data]);
                      this.vesselId = id;
                      //this.dataSource.paginator = this.paginator;
                      //this.dataSource.sort = this.sort;
                })

                this.voyagelistService.getEospDataById(id,(response)=>{
                    console.log("follow the data eops");
                    console.log(response.data);
                      this.eospDataSource = new MatTableDataSource([response.data]);
                      //this.eospDataSource.paginator = this.paginator;
                      //this.eospDataSource.sort = this.sort;
                })



            }
        }

     }


    getAllVesselsData(companyId){
       if(companyId){
               this.vs.getDataByCompanyId(companyId,(response) => {
                console.log(response);
                this.userVesselData = response.data;
            })
    }else{
           this.vs.getData((response) => {
            console.log(response);
            this.userVesselData = response.data;

          })
    }
  }


   onClick(action, data) {
    if (action == 'edit') {
      console.log("===onClick==",data);
        this.session.set('cospId',data.id);
        this.session.set('eospId',data.eospId);
        this.session.set('vesselId',data.vesselId);
        this.session.set('companyId',this.userCompanyId);
        this.router.navigate(['/cosp']);

    } else {
      this.vs.deleteData(data.vesselTypeId).subscribe((data) => {
        console.log('Deleted');
        this.vs.getData((response) => {
          console.log(response);
          this.dataSource = new MatTableDataSource(response.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        })
      })
    }
    window.scroll(0,0);

  }


}
