import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from './apiroot.service';

@Injectable({
  providedIn: 'root'
})
export class WatchHourService {

  formData = {id: '', date: '', watchHour: '', active: '', watchhourbydate: ''};

  constructor(public http: HttpClient, public service: ApiRootService) { }

  // postData(formData,callback){
  //   return this.http.post("http://192.168.0.39:8087/campbellservice/generateWatchHoursBasedOnMode", formData).subscribe(
  //      (data) => {
  //        console.log(data);
  //        callback(data);
  //    });

  //  }

   getData(callback) {
    return this.http.get(this.service.getBaseUrl() + '/getAllWatchHour').subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    ); }

    putData(id, formData, callback) {
      return this.http.put(this.service.getBaseUrl() + '/updateWatchHour/' + id, formData).subscribe((data) => {
        console.log(data);
        callback(data);
    }
  ); }

  deleteData(id) {
    return this.http.delete(this.service.getBaseUrl() + '/softDeleteWatchHourByid/' + id);
  }

  getwatchhours(callback) {
    return this.http.get(this.service.getBaseUrl() + '/generateWatchHoursBasedOnMode').subscribe((data) => {
      console.log(data);
      callback(data);
    });
   }

  generatewatchhoursbasedonmodebydate(watchhourbydate, callback) {
    return this.http.get(this.service.getBaseUrl() + '/generateWatchHoursBasedOnModeByDate/' + watchhourbydate).subscribe((data) => {
      console.log(data);
      callback(data);
    });
  }


}
