import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from '../apiroot.service';

@Injectable({
  providedIn: 'root'
})
export class QuestionsectionformsService {

  formData = {formlistSectionsId:'',formsectionName:'',formsectiondetails:''};
  
  constructor(public http:HttpClient,public service:ApiRootService) { }
  
    
  postData(formData,callback){
   console.log(formData);
   return this.http.post(this.service.getBaseUrl()+'/saveFormsSections', formData).subscribe(
      (data) => {
        console.log(data);
        callback(data);
    });

  }
//   putData(id,formData,callback){
//      return this.http.put(this.service.getBaseUrl()+'/updateCrewVesselAssignment/'+id,formData).subscribe((data)=>{
//        console.log(data);
//        callback(data);
//    }
//  )} 
}
