import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator, MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA, MatRadioChange } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl, ReactiveFormsModule, FormArray, RequiredValidator } from '@angular/forms';
import { QuestionsectionformsService } from '../questionsectionforms/questionsectionforms.service';
import { QuestionformsService } from './questionforms.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { SessionStorageService } from 'angular-web-storage';
import { ActivatedRoute, RouterModule, Routes, Router } from '@angular/router';
import { QuestionsectionformsComponent } from '../questionsectionforms/questionsectionforms.component';
import { error } from 'console';
import { get } from 'http';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-questionforms',
  templateUrl: './questionforms.component.html',
  styleUrls: ['./questionforms.component.scss']
})
export class QuestionformsComponent implements OnInit {

  formData: any = [];
  rows: FormArray;
  itemForm: any = [];
  sectionData: any = [];
  formsId: any;
  sectionId: any;
  showEdit: any;
  htmlControls = [];
  dataExists: any;
  newSectionId: any;
  newSectionName: any;
  showDeleteFirstRow: boolean = false;

  @ViewChild('deleteSwal', { static: false }) public deleteSwal: SwalComponent;
  @ViewChild('successbox', { static: true }) public successbox: SwalComponent;
  @ViewChild('errorbox', { static: true }) public errorbox: SwalComponent;

  constructor(private router: Router, public qs: QuestionformsService, private formBuilder: FormBuilder, public session: SessionStorageService, private dialog: MatDialog, private questionsectionformsService: QuestionsectionformsService, private cdr: ChangeDetectorRef) {
    this.rows = this.formBuilder.array([]);
  }

  ngOnInit() {
    this.rows = this.formBuilder.array([]);
    this.formsId = this.session.get('formlistId');
    console.log('==check-formlist-id==', this.formsId)
    this.sectionId = 1;

    this.qs.formData.section_id = this.sectionId;
    this.formData = this.formBuilder.group({
      sectionId: new FormControl(null),
    })
    this.qs.getAllSections((response) => {
      console.log(response);
      this.sectionData = [];
      this.sectionData = response.data;
      console.log('===getAllSections==');
      console.log(this.sectionData);
      console.log(this.sectionData.length);
      this.dataExists = false;
      // this.addFirstRow();
      console.log('==ngoniit-formlist==', this.formsId)
      if (this.formsId) {
        this.qs.getQuestionDataOfFormlistId(this.formsId, (response) => {
          console.log('getQuestionDataOfFormlistId-response', response.data);
          if (response.data) {
            this.dataExists = true;
            this.addDataRowArrangement(response.data.length, response.data);
          } else {
            this.addFirstRow();
          }

        })

      }
    })


    this.qs.getControlTypeData((response) => {
      console.log(response);
      this.htmlControls = response.data;
      // this.dataSource = new MatTableDataSource(response.data);
    })

    console.log("==ngOnInit-row-before-addcontrol==", this.rows)
    this.formData.addControl('rows', this.rows);

    console.log("====section data=======")
    console.log(this.sectionData);

  }

  addFirstRow() {
    this.onAddSectionArrangement(1);
  }

  sort_by_key(array, key) {
    return array.sort(function (a, b) {
      var x = a[key]; var y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }

  getSectionName(id) {
    for (var r of this.sectionData) {
      if (r.id == id) {
        return r.formsectionName;
      }
      console.log(this.sectionData);
      console.log(r.formsectionName);
    }
  }

  submitHandler() {
    console.log("before save");
    this.itemForm = [];


    const dummyRow = this.createItemFormGroupArrangement({
      sectionId: null,
      uuid: null,
      sectionName: '',
      formsId: this.formsId,
      questiondetails: '',
      controlType: '',
      controlValue: '',
      newrow: 1,
      newsection: 0,
      hidingsection: 0,
      id: null
    });
  
    this.rows.push(dummyRow);
    this.rows.removeAt(this.rows.length - 1);
  
    console.log("Final Form Data:", this.formData.value);

  
    console.log("Final Form Data:", this.formData.value); 

    console.log("before-checking-validation", this.formData);

    if (this.formData.valid) {
      console.log("Form Data:", this.formData.value);

      let hasEmptyRow = false;

      // Iterate over each row in 'rows' FormArray
      for (let row of this.formData.get('rows').controls) {
        console.log("===check-each-row===", row);

        const questiondetailsControl = row.get('questiondetails');
        const controlTypeControl = row.get('controlType');
        const columnTypeControl = row.get('columnType'); // ✅ Extract columnType per row
        const uId = row.get('uId');

        if ((questiondetailsControl && questiondetailsControl.invalid) ||
          (controlTypeControl && controlTypeControl.invalid)) {
          hasEmptyRow = true;
          if (questiondetailsControl) questiondetailsControl.markAsTouched();
          if (controlTypeControl) controlTypeControl.markAsTouched();
        } else {
          // ✅ Ensure each row retains its own columnType instead of a single global value
          this.itemForm.push({
            ...row.value,
            columnType: columnTypeControl ? columnTypeControl.value : 'single',
            uId: uId ? uId.value : 'null'
          });
        }
      }

      if (hasEmptyRow) {
        this.errorbox.text = 'Please fill required fields before saving';
        this.errorbox.show();
        return;
      }

      // Ensure we send an array instead of an object
      if (this.itemForm.length > 0) {
        this.qs.saveFormListData(this.itemForm, (response) => {  // ✅ Send only the array
          console.log("Save Response:", response);
          if (response.responsecode === 200 || response.responsecode === '200') {
            this.successbox.show();
            this.ngOnInit();
          } else {
            this.errorbox.text = response.message;
            this.errorbox.show();
          }
        });
      }

      console.log("Final Payload Sent:", this.itemForm);
    } else {
      this.errorbox.text = 'Please fill required fields before saving';
      this.errorbox.show();
    }
  }



  onAddRowArrangement(sectionid: number, uuid, rowControl) {
    console.log('==check-uuid==', uuid)



    console.log('==this.rows.controls==', this.rows.controls)

    console.log('==get-formdata==', this.formData)

    console.log('check-rowcontrol', rowControl)

    console.log("==onAddR owArrangement-uuid==", uuid)
    console.log("==onAddRowArrangement-sectionid==", sectionid);
    console.log("==onAddRowArrangement-sectionid-type==", typeof (sectionid));
    if (sectionid != 0) {

      // Find the first row for the section
      let firstRowIndex = -1;
      console.log("==onAddRowArrangement-row-control-length==", this.rows.controls.length)
      for (let r = 0; r < this.rows.controls.length; r++) {
        console.log("==onAddRowArrangement-forloop-value==", this.rows.controls[r]['controls']['sectionId'].value)
        if (this.rows.controls[r]['controls']['sectionId'].value == sectionid) {
          firstRowIndex = r;
          break;
        }
      }

      // Check if the first row is filled
      if (firstRowIndex !== -1) {
        console.log("==firstrow-value==", this.rows.controls[firstRowIndex])
        const firstRow = this.rows.controls[firstRowIndex];
        const questiondetailsControl = firstRow.get('questiondetails');
        const controlTypeControl = firstRow.get('controlType');

        if (!questiondetailsControl.value || !controlTypeControl.value) {
          // Show validation message if the first row is not filled
          this.errorbox.text = 'Please fill out the first row before adding another question.';
          this.errorbox.show();
          return; // Stop adding a new row
        }
      }

      // Proceed with adding a new row if the first row is filled
      console.log("check-row-control-length", this.rows.controls.length);
      console.log("check-row-control", this.rows.controls);

      const sectionName = this.getSectionName(sectionid);
      var arr = {
        'sectionId': sectionid,
        'uuid': uuid,
        'sectionName': sectionName,
        'formsId': this.formsId,
        'questiondetails': '',
        'controlType': '',
        'controlValue': '',
        'newrow': 1,
        'newsection': 0,
        'hidingsection': 0,
        'id': null
      };
      console.log('onAddRowArrangement-array', arr);

      const newRow = this.createItemFormGroupArrangement(arr);

      // Set validators for new row
      const questiondetailsControlNew = newRow.get('questiondetails');
      if (questiondetailsControlNew) {
        questiondetailsControlNew.setValidators(Validators.required);
      }

      const controlTypeControlNew = newRow.get('controlType');
      if (controlTypeControlNew) {
        controlTypeControlNew.setValidators(Validators.required);
      }

      // Find where to insert the new row
      var arrGroup = [];
      for (let r = 0; r < this.rows.controls.length; r++) {
        if (this.rows.controls[r]['controls']['sectionId'].value == sectionid) {
          arrGroup.push(r + 1);
        }
      }

      // Insert the new row at the correct position
      this.rows.controls.splice(arrGroup[arrGroup.length - 1], 0, newRow);
      console.log("after insert1");
      console.log(this.rows.controls);
    } else {
      this.errorbox.text = 'Please select a section before creating a new field.';
      this.errorbox.show();
    }
  }

  generateUniqueId() {
    return 'sec-' + Math.random().toString(36).slice(2, 11);
  }

  onSectionSelect(index, section_id) {
    for (let r = 0; r < this.rows.controls.length; r++) {
      if (r == index) {
        this.rows.controls[r]['controls']['sectionId'].value == section_id;
      }
    }
    console.log("after insert2")
    console.log(this.rows.controls);
  }

  onAddSectionArrangement(event: any) {

    console.log("Trying to add a new section...");

    let hasEmptyField = false;

    // Validate existing rows before adding a new section
    for (let row of this.rows.controls) {
      const questiondetailsControl = row.get('questiondetails');
      const controlTypeControl = row.get('controlType');

      if (
        (questiondetailsControl && !questiondetailsControl.value) ||
        (controlTypeControl && !controlTypeControl.value)
      ) {
        hasEmptyField = true;
        if (questiondetailsControl) questiondetailsControl.markAsTouched();
        if (controlTypeControl) controlTypeControl.markAsTouched();
      }
    }

    // Prevent adding a new section if validation fails
    if (hasEmptyField) {
      console.error("Cannot add a new section. Please fill in all required fields first.");
      this.errorbox.title = 'Error';
      this.errorbox.text = 'You need to insert the above data before adding a new section.';
      this.errorbox.show()
      return;
    }

    console.log("All fields are valid, adding a new section...");
    console.log('==check=before-row345', this.rows)
    // Default values for new rows
    var arr = {
      'sectionId': 0,
      'sectionName': '',
      'formsId': this.formsId,
      'uuid': `uuid-${Date.now()}-${Math.floor(Math.random() * 1000)}`,
      'questiondetails': '',
      'controlType': '',
      'controlValue': '',
      'columnType': 'single',
      'newrow': 1,
      'newsection': 1,
      'hidingsection': 0,
      'id': null
    };

    console.log('onAddSectionArrangement-array', arr)


    let newArrtest = [];

    // Add new rows if all fields are filled
    for (var i = 0; i < event; i++) {
      const newRow = this.createItemFormGroupArrangement(arr);
      console.log('==check-newrow', newRow)
      this.rows.push(newRow);
      newArrtest.push(newRow);
    }

    console.log('==newArrtest==', newArrtest);
    console.log('Check uuid is there or not', this.rows)

    console.log("New section added successfully!");
    console.log(this.rows);
  }



  addDataRowArrangement(count, arr: any) {

    console.log('==addDataRowArrangement-count==', count);
    console.log('==addDataRowArrangement-count==', arr)
    console.log('==addDataRowArrangement-formdata==', this.formData.value.rows)
    console.log('==addDataRowArrangement-formdata-length==', this.formData.value.rows.length)


    // if (this.formData.value.rows && this.formData.value.rows.length > 0) {
    //   if (this.formData.value.rows[0] && this.formData.value.rows[0].newrow === 1) {
    //     this.rows.removeAt(0);
    //   }
    // }

    console.log('==addDataRowArrangement-afterrow-formdata==', this.formData.value.rows)


    for (var i = 0; i < count; i++) {
      console.log('==check-arr-in-row==', arr[i])
      this.rows.push(this.createItemFormGroupArrangement(arr[i]));

    }
    console.log("after insert4")
    console.log(this.rows.controls);
  }

  // createItemFormGroupArrangement(datas: any): FormGroup {
  //   console.log('==createItemFormGroupArrangement-datas-23432==', datas)
  //   var hidesection = 0;
  //   for (let t = 0; t < this.formData.value.rows.length; t++) {
  //     if (this.formData.value.rows[t].sectionId == datas.sectionId) {
  //       hidesection = 1;
  //     }
  //   }

  //   var hideControlType = ["Radio", "Checkbox", "DropDown"];
  //   datas.hidecontrolValue = 1;
  //   var controlTypeValue = datas.controlType;
  //   if (hideControlType.indexOf(controlTypeValue) < 0) {
  //     datas.hidecontrolValue = 0;
  //   }

  //   return this.formBuilder.group({
  //     formsId: (datas.formsId),
  //     uId: [datas.uId],
  //     sectionId: [datas.sectionId, [Validators.required, Validators.min(1)]],
  //     sectionName: (datas.sectionName),
  //     questiondetails: [datas.questiondetails, Validators.required],
  //     controlValue: (datas.controlValue),
  //     controlType: [datas.controlType, Validators.required],
  //     columnType: [datas.columnType ? datas.columnType : 'single', Validators.required],
  //     newrow: (datas.newrow ? datas.newrow : 0),
  //     newsection: (datas.newsection ? datas.newsection : 0),
  //     hidesection: (hidesection),
  //     hidecontrolValue: (datas.hidecontrolValue),
  //     id: (datas.id ? datas.id : null)
  //   });


  // }

  createItemFormGroupArrangement(datas: any): FormGroup {
    console.log('==createItemFormGroupArrangement-rows==', this.rows)
    console.log('==Step 1: Incoming datas==', datas);

    var hidesection = 0;
    console.log('==Step 2: Initial hidesection==', hidesection);

    for (let t = 0; t < this.formData.value.rows.length; t++) {
      console.log(`==Step 3.${t}: Checking row ${t} sectionId==`, this.formData.value.rows[t].sectionId);
      if (this.formData.value.rows[t].sectionId == datas.sectionId) {
        hidesection = 1;
        console.log(`==Step 4.${t}: hidesection updated to==`, hidesection);
        break;
      }
    }

    var hideControlType = ["Radio", "Checkbox", "DropDown"];
    datas.hidecontrolValue = 1;
    console.log('==Step 5: Initial hidecontrolValue==', datas.hidecontrolValue);

    var controlTypeValue = datas.controlType;
    console.log('==Step 6: controlTypeValue==', controlTypeValue);

    if (hideControlType.indexOf(controlTypeValue) < 0) {
      datas.hidecontrolValue = 0;
      console.log('==Step 7: hidecontrolValue updated to==', datas.hidecontrolValue);
    }

    console.log('==Step 8: Creating FormGroup with values==', {
      formsId: datas.formsId,
      uId: datas.uuid,
      sectionId: datas.sectionId,
      sectionName: datas.sectionName,
      questiondetails: datas.questiondetails,
      controlValue: datas.controlValue,
      controlType: datas.controlType,
      columnType: datas.columnType ? datas.columnType : 'single',
      newrow: datas.newrow ? datas.newrow : 0,
      newsection: datas.newsection ? datas.newsection : 0,
      hidesection: hidesection,
      hidecontrolValue: datas.hidecontrolValue,
      id: datas.id ? datas.id : null
    });

    const formGroup = this.formBuilder.group({
      formsId: (datas.formsId),
      uId: [datas.uuid],
      sectionId: [datas.sectionId, [Validators.required, Validators.min(1)]],
      sectionName: (datas.sectionName),
      questiondetails: [datas.questiondetails, Validators.required],
      controlValue: (datas.controlValue),
      controlType: [datas.controlType, Validators.required],
      columnType: [datas.columnType ? datas.columnType : 'single', Validators.required],
      newrow: (datas.newrow ? datas.newrow : 0),
      newsection: (datas.newsection ? datas.newsection : 0),
      hidesection: (hidesection),
      hidecontrolValue: (datas.hidecontrolValue),
      id: (datas.id ? datas.id : null)
    });

    console.log('==Step 9: Final FormGroup Value==', formGroup.value);
    return formGroup;
  }


  onRemoveRowArrangement(rowIndex: number, id, uId, formRow) {

    console.log('==onRemoveRowArrangement-uid==', uId)
    console.log('==formRow==', formRow)

    // let commonRow = [];
    // for(let i = 0; i < formRow.length; i++){
    //   if(formRow.value.uId == uId) {
    //     console.log
    //   }
    // }

    console.log("==onRemoveRowArrangement-rowIndex==", rowIndex);
    console.log("==check-whole-row==", this.rows.controls);

    // Iterate through each row to check if required fields are missing
    if (id) {
      for (let i = 0; i < this.rows.controls.length; i++) {
        const row = this.rows.controls[i];

        // Get values
        let questiondetails = row.get('questiondetails');
        let controlType = row.get('controlType');

        // If any required field is missing, show an alert and stop deletion 
        if (!questiondetails.value || !controlType.value) {
          this.errorbox.title = 'Wait';
          this.errorbox.text = 'Please fill in all required fields before deleting a row.'
          this.errorbox.show()
          return;
        }
      }
    }

    if (this.formData.value.rows && this.formData.value.rows.length > rowIndex && this.formData.value.rows[rowIndex].id) {
      console.log("For this index id is present", this.formData.value.rows[rowIndex].id)
      this.qs.deleteData(this.formData.value.rows[rowIndex].id).subscribe((data) => {
        console.log('Data is Deleted', data);
        console.log(data);
      })
    } else {
      console.log("no id is present for this index")
    }

    console.log("==onRemoveRowArrangement-rowINdex==", rowIndex)
    console.log("==check-row-before-removing==", this.rows)
    this.rows.removeAt(rowIndex);

    this.formData.setControl(...this.rows.controls);

    // ✅ Mark form as updated
    this.formData.markAsDirty();
    this.formData.updateValueAndValidity();
  
    // ✅ Force Angular to detect changes
    setTimeout(() => {
      this.cdr.detectChanges();
    });
    


    // this.ngOnInit()
    console.log("==after-check-row-before-removing==", this.rows)
    if (this.rows.length <= 0) {
      this.router.navigate(['/formlist']);
    }


    console.log('==in-lastformRow==', formRow)
  }



  updateRowArrangement(rowIndex: number) {
    if (this.formData.value.rows[rowIndex].newrow == 0) {
      //let index = (this.sectionData as FormArray).findIndex(x => x.id == this.formData.value.rows[rowIndex].sectionId);
      this.formData.value.rows[rowIndex].sectionName = this.getSectionName(this.formData.value.rows[rowIndex].sectionId)
      console.log('==updateRowArrangement==');
      console.log(this.formData.value.rows[rowIndex]);
      this.qs.updateQuestionDetails(this.formData.value.rows[rowIndex].id, this.formData.value.rows[rowIndex], (data) => {
        console.log(data);
        if (data.responsecode === 200 || data.responsecode === '200') {
          this.successbox.show();
          this.ngOnInit();
        } else {
          this.errorbox.text = data.message;
          this.errorbox.show();
        }
      })
    }
    this.showEdit = -1;
  }

  editRowArrangement(val, rowIndex: number) {
    console.log('==editRowArrangement==callling==')

    if (val && rowIndex !== undefined && this.rows.controls[rowIndex]) {
      console.log('==editRowArrangement-val==', val);
      console.log('==rowindex==', rowIndex);
      console.log('check-specific-row', this.rows.controls[rowIndex]);

      if (this.rows.controls[rowIndex]['controls'] && this.rows.controls[rowIndex]['controls']['controlType']) {
        console.log('check-choosy-value', this.rows.controls[rowIndex]['controls']['controlType'].value);
      }

      if (this.formData && this.formData.value && this.formData.value.rows && this.formData.value.rows[rowIndex]) {
        console.log('check-form-data-value', this.formData.value.rows[rowIndex].controlType);

        if (!this.formData.value.rows[rowIndex].controlType) {
          this.formData.value.rows[rowIndex].controlType = val
        }
      }
    }




    var contorlType;
    if (this.rows.controls[rowIndex]['controls']['newrow'].value == 0) {
      this.showEdit = rowIndex;
    }
    contorlType = this.rows.controls[rowIndex]['controls']['controlType'].value
    var hideControlType = ["Radio", "Checkbox", "DropDown"];
    if (hideControlType.indexOf(contorlType) < 0) {

      this.rows.controls[rowIndex]['controls']['hidecontrolValue'].value = 0;
    } else {
      this.rows.controls[rowIndex]['controls']['hidecontrolValue'].value = 1;
    }
  }

  // onFieldValueChange(rowIndex: number, oldSectionId: number) {

  //   console.log("Old Section ID:", oldSectionId);
  //   console.log("Form Rows:", this.formData.value.rows);

  //   if (this.formData.value.rows && this.formData.value.rows.length > rowIndex && this.formData.value.rows[rowIndex].sectionId) {
  //     this.newSectionId = this.formData.value.rows[rowIndex].sectionId;
  //     this.newSectionName = this.getSectionName(this.newSectionId);
  //     this.formData.value.rows[rowIndex].sectionName = this.newSectionName;
  //   }


  //   console.log("New Section ID:", this.newSectionId);
  //   console.log("New Section Name:", this.newSectionName);


  //   if (oldSectionId == 0) {
  //     console.log("coming because oldsection id is equal to zero")
  //     oldSectionId = this.newSectionId;
  //   }

  //   console.log("new-section-id store in old one if oldsectionid == 0", oldSectionId)

  //   this.formData.value.rows.forEach((row, i) => {
  //     console.log("check-row", row, "check-i-value", i)
  //     // if (i === rowIndex || row.sectionId === oldSectionId) {
  //     if (row.sectionId === oldSectionId) {
  //       console.log(`Updating row ${i} from old Section ID: ${oldSectionId} to new Section ID: ${this.newSectionId}`);


  //       this.formData.controls.rows.controls[i].patchValue({ sectionId: this.newSectionId, sectionName: this.newSectionName });
  //     }
  //   });

  //   console.log("Form Rows after", this.formData.value.rows);

  //   if (this.formData.value.rows && this.formData.value.rows.length > rowIndex && this.formData.value.rows[rowIndex].newrow == 0) {
  //     this.showEdit = rowIndex;
  //   }
  // }

  onFieldValueChange(rowIndex: number, oldSectionId: number, uuid) {
    console.log('onFieldValueChange-uuid', uuid)
    console.log("Function called: onFieldValueChange");
    console.log("Received oldSectionId:", oldSectionId);
    console.log("Received rowIndex:", rowIndex);
    console.log("Initial Form Rows Length:", this.formData.value.rows.length);
    console.log("Initial Form Rows:", this.formData.value.rows);

    if (this.formData.value.rows && this.formData.value.rows.length > rowIndex) {
      console.log(`Row exists at index ${rowIndex}, checking sectionId...`);

      if (this.formData.value.rows[rowIndex].sectionId) {
        this.newSectionId = this.formData.value.rows[rowIndex].sectionId;
        console.log("New Section ID retrieved:", this.newSectionId);

        this.newSectionName = this.getSectionName(this.newSectionId);
        console.log("New Section Name retrieved:", this.newSectionName);

        this.formData.value.rows[rowIndex].sectionName = this.newSectionName;
        console.log(`Updated sectionName for row ${rowIndex}:`, this.newSectionName);
      } else {
        console.log(`Row ${rowIndex} does not have a sectionId.`);
      }
    } else {
      console.log("Row index out of bounds or formData.value.rows is undefined.");
    }

    console.log("Updated New Section ID:", this.newSectionId);
    console.log("Updated New Section Name:", this.newSectionName);

    if (oldSectionId === 0) {
      console.log("Old Section ID is 0, updating to new Section ID");
      oldSectionId = this.newSectionId;
    }
    console.log("Updated oldSectionId after check:", oldSectionId);

    console.log("Starting loop to update rows...");
    console.log('==Form-control-row==', this.formData.controls.rows.controls)
    this.formData.value.rows.forEach((row, i) => {
      console.log(`Loop iteration: i=${i}, Current row data:`, row);

      if (row.sectionId === oldSectionId && row.uId == uuid) {
        console.log(`Row ${i} matches oldSectionId (${oldSectionId}), updating...`);
        console.log(`Patching values - sectionId: ${this.newSectionId}, sectionName: ${this.newSectionName}`);

        this.formData.controls.rows.controls[i].patchValue({
          sectionId: this.newSectionId,
          sectionName: this.newSectionName,
        });

        console.log(`Row ${i} updated.`);
      } else {
        console.log(`Row ${i} does not match oldSectionId (${oldSectionId}), skipping.`);
      }
    });

    console.log("Form Rows after loop:", this.formData.value.rows);
    console.log("Form Rows after loop Length:", this.formData.value.rows.length);
    console.log("==rowIndex==", rowIndex)

    if (this.formData.value.rows && this.formData.value.rows.length > rowIndex) {
      console.log(`Checking newrow flag for row ${rowIndex}...`);
      if (this.formData.value.rows[rowIndex].newrow === 0) {
        console.log(`newrow is 0, setting showEdit to ${rowIndex}`);
        this.showEdit = rowIndex;
      } else {
        console.log(`newrow is not 0 for row ${rowIndex}, showEdit unchanged.`);
      }
    } else {
      console.log("Row index out of bounds while checking newrow flag.");
    }

    console.log("Function execution complete.");

    console.log("Last Initial Form Rows:", this.formData.value.rows);
  }





  onColumnTypeChange(event: MatRadioChange, changedRow: FormGroup) {
    console.log("==check-event==", event);
    console.log("==changedRow==", changedRow);

    const newValue = event.value;
    console.log("==onColumnTypeChange-newValue==", newValue);
    const sectionName = changedRow.get('sectionName') ? changedRow.get('sectionName').value : '';
    console.log("==onColumnTypeChange-sectionName==", sectionName);

    this.formData.get('rows').controls.forEach((row: FormGroup) => {
      if (row.get('sectionName').value === sectionName) {
        row.get('columnType').setValue(newValue);
      }
    });

    console.log("Updated Column Type For All Row In Section", this.formData.value)
  }

  // updateColumnType(event, changedRow) {
  //   const updatedColumnType = event.value;
  //   const sectionId = changedRow.get('sectionId').value; // Get sectionId

  //   // Iterate over rows and update only the rows with the same sectionId
  //   this.formData.get('rows').controls.forEach(row => {
  //     if (row.get('sectionId').value === sectionId) {
  //       row.get('columnType').setValue(updatedColumnType);
  //     }
  //   });
  // }


  backButton() {
    this.router.navigate(['/formlist']);
  }

  openDialog(): void {
    this.questionsectionformsService.formData.formsectionName = '';
    this.questionsectionformsService.formData.formsectiondetails = '';
    const dialogRef = this.dialog.open(QuestionsectionformsComponent, {
      data: { sectionId: '' }
    });
    dialogRef.afterClosed().subscribe(() => {
      this.qs.getAllSections((response) => {
        console.log(response);
        this.sectionData = [];
        this.sectionData = response.data;
      });
    });
  }

  onSectionChange(event) {
    alert('-----');
    console.log('===event===');
    console.log(event);
  }


}
