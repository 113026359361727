import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatFormFieldControl } from '@angular/material';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators } from '@angular/forms';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { FormlistService } from './formlist.service';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { RolesService } from '../roles/roles.service';
import { SessionStorageService } from 'angular-web-storage';
import { ActivatedRoute, RouterModule, Routes, Router } from '@angular/router';
import { SelectAutocompleteComponent } from 'mat-select-autocomplete';
import { MatDateFormats, MAT_DATE_FORMATS, MAT_NATIVE_DATE_FORMATS } from '@angular/material/core';




// export const MY_DATE_FORMATS = {
//   parse: {
//     dateInput: 'DD/MM/YYYY',
//   },
//   display: {
//     dateInput: 'DD/MM/YYYY',
//   },
// };
export const GRI_DATE_FORMATS: MatDateFormats = {
  ...MAT_NATIVE_DATE_FORMATS,
  display: {
    ...MAT_NATIVE_DATE_FORMATS.display,
    dateInput: {
      day: 'numeric',
      year: 'numeric',
      month: 'short',
    } as Intl.DateTimeFormatOptions,
  }
};

@Component({
  selector: 'app-formlist',
  templateUrl: './formlist.component.html',
  styleUrls: ['./formlist.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: GRI_DATE_FORMATS }
  ]
})



export class FormlistComponent implements OnInit {

  @ViewChild(SelectAutocompleteComponent, { static: false }) multiSelect: SelectAutocompleteComponent;

  selectedOptions = [];
  selectedAddOptions = [];
  showError = false;
  errorMessage = 'Please select one';
  rows: FormArray;

  //displayedColumns: string[] = ['id','formsName','categoryId','revisionDate', 'edit', 'Add'];
  displayedColumns: string[] = ['formsName', 'categoryId', 'revisionDate', 'edit', 'Add'];
  additionalOption = [{ label: "Name" }, { label: "Rank" }, { label: "Nationality" }, { label: "Agency" }];
  htmlControls = [{ label: "TextBox", name: "textbox" }, { label: "TextArea", name: "textarea" }, { label: "DatePicker", name: "datepicker" }, { label: "TimePicker", name: "timepicker" }];

  dataSource = new MatTableDataSource()

  myForm: FormGroup;

  allcategory = [];
  itemForm: any = [];
  allCrew = [];
  allCrewDisplay = [];
  authoritySignature = [{ label: "Caption", checked: false }, { label: "Deck Officer", checked: false }, { label: "Chief Steward", checked: false }, { label: "Wardroom Officers", checked: false }, { label: "Petty Officer", checked: false }, { label: "Cheif Engineer", checked: false }, { label: "Super Admin", checked: false }];
  isLoading = true;
  data: any = [];
  formTypeId: any;
  loading: boolean;
  product: any;
  authorityIds = 'formlist';
  formsadditional = 'formsadditional';
  public formData;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild('deleteSwal', { static: false }) public deleteSwal: SwalComponent;
  @ViewChild('successbox', { static: true }) public successbox: SwalComponent;

  @ViewChild('errorbox', { static: true }) public errorbox: SwalComponent;

  success;
  constructor(private router: Router, public fb: FormBuilder, public datePipe: DatePipe, public formlistService: FormlistService, public rolesService: RolesService,
    public http: HttpClient, public session: SessionStorageService) {
    this.resetForm();
    this.rows = this.fb.array([]);
  }

  ngOnInit() {

    of(this.dataSource).pipe(delay(1000))
      .subscribe(data => {
        this.isLoading = false;
        this.data = data;
      }, error => this.isLoading = false)

    this.formlistService.getData((response) => {
      console.log('==getData==', response)
      this.dataSource = new MatTableDataSource(response.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort; 
      console.log(response.data);
    })

    this.myForm = new FormGroup({
      'formsName': new FormControl(null, [Validators.required]),
      'categoryId': new FormControl(null, [Validators.required]),
      'revisionDate': new FormControl(null, [Validators.required]),
      'authorityIds': new FormArray([]),
      'isNotify': new FormControl(null),
      'formsNotification': new FormControl(null),
      'reportHeader1': new FormControl(null),
      'reportHeader2': new FormControl(null)
    });

    this.formlistService.getAllCat((response) => {
      this.allcategory = response.data;
      console.log("categories", response.data);
    })
    this.getAllRole();
    console.log("===addControl===");
    this.myForm.addControl('rows', this.rows);
    console.log("===addControl11===");
    //this.formData.addControl('rows', this.rows);

  }

  doFilter(value: string) {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
    console.log(value);
  }

  getAllRole() {
    this.formlistService.getAllRole((response) => {
      var res = response.data;
      console.log('=== start====');
      console.log(res);
      for (var i = 0; i < res.length; i++) {
        var rowData = res[i];
        //console.log(res[i]);
        //console.log(rowData.roleName);
        this.allCrew[i] = { id: rowData.id, roleName: rowData.roleName, checked: false };
        if (rowData.roleName) {
          this.allCrewDisplay[rowData.id] = rowData.roleName;
        }
      }
      //console.log('=== allCrewDisplay====');
      //console.log(this.allCrewDisplay);
    })
  }

  getCrewName(ids) {
    var crewNameList = [];
    if (ids != '') {
      var idsArray = ids.split(',');
      //alert(idsArray);
      idsArray = idsArray.filter((x, i, a) => a.indexOf(x) == i)
      if (idsArray.length) {
        for (var i = 0; i < this.allCrew.length; i++) {
          var crewId = this.allCrew[i].id;
          for (var inc = 0; inc < idsArray.length; inc++) {
            //alert(idsArray[inc]+"=="+crewId+"---"+this.allCrew[i].roleName);
            if (idsArray[inc] == crewId) {
              crewNameList.push(this.allCrew[i].roleName);
            }
          }
        }
        return crewNameList.toString();
      }
    }
  }

  getCatName(id) {
    for (var inc = 0; inc < this.allcategory.length; inc++) {
      if (this.allcategory[inc].id == id) {
        return this.allcategory[inc].categoryName;
      }
    }
  }

  submitHandler1() {
    console.log(this.myForm.value);
  }

  submitHandler() {
    this.loading = true;
    this.formlistService.formTypeData.revisionDate = this.datePipe.transform(this.formlistService.formTypeData.revisionDate, "dd-MMM-yyyy");
    var authorityIdsData = this.selectedOptions.filter((x, i, a) => a.indexOf(x) == i)
    this.formlistService.formTypeData.authorityIds = authorityIdsData.join();
    this.formlistService.formTypeData.reportHeader = this.formlistService.formTypeData.reportHeader1 + ',' + this.formlistService.formTypeData.reportHeader2;
    console.log("===submitHandler===");
    console.log(this.formlistService.formTypeData);
    if (this.myForm.valid) {

      console.log("===submitHandler11===");
      console.log(this.formlistService.formTypeData.id);
      if (this.formlistService.formTypeData.id) {
        console.log("===submitHandler22===");
        this.formlistService.putData(this.formlistService.formTypeData.id, this.formlistService.formTypeData, (data) => {
          console.log('---putData---');
          console.log(this.formlistService.formTypeData);
          this.loading = false;
          //   console.log(data);
          if (data.responsecode == 200) {
            if (data.data.id != null) {
              console.log(data.data.id);
              console.log(data);
              this.itemForm = [];
              if (this.selectedAddOptions != undefined) {
                for (let name of this.selectedAddOptions) {
                  this.itemForm.push({ 'formId': data.data.id, 'infoControlType': 'textbox', 'infoLabel': name });
                }
              }

              if (this.myForm.get('rows') != undefined) {
                for (let row of this.myForm.get('rows')['controls']) {
                  this.itemForm.push({ 'formId': data.data.id, 'infoControlType': row.controls.infoControlType.value, 'infoLabel': row.controls.infoLabel.value });
                }
              }



              console.log('------this.itemForm---');
              console.log(this.itemForm);
              if (this.itemForm.length > 0  ) {
                this.formlistService.saveFormAdditionalInfo(this.itemForm, (response) => {
                  console.log('------saveFormAdditionalInfo done---');
                  console.log(response);
                  console.log(this.itemForm)
                  this.loading = false
                  if (response.responsecode === 200 || response.responsecode === '200') {
                    this.successbox.show();
                    //this.resetForm();
                  } else {
                    this.errorbox.text = response.message;
                    this.errorbox.show();
                  }
                })
              }
            }


            this.formlistService.getData((response) => {
              console.log(response);
              this.dataSource = new MatTableDataSource(response.data);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.successbox.show();
              this.getAllRole();
              this.resetForm();
              this.myForm.reset();
            })
          } else {
            this.errorbox.text = data.message;
            this.errorbox.show();
          }
        })
      } else {
        this.formlistService.postData(this.formlistService.formTypeData, (response) => {
          console.log(response);
          this.loading = false;

          if (response.responsecode === 200) {
            if (response.data.id != null) {
              if (this.selectedAddOptions != undefined) {
                for (let name of this.selectedAddOptions) {
                  this.itemForm.push({ 'formId': response.data.id, 'infoControlType': 'textbox', 'infoLabel': name });
                }
              }
              //                if(this.myForm.get('rows')!=undefined){
              //                    for(let row of this.myForm.get('rows').controls){
              //                           this.itemForm.push({'formlistId':data.data.id,'infoLabel':row.controls.infoLabel.value,'infoControlType':row.controls.infoControlType.value});
              //                    }
              //                }

              if (this.myForm.get('rows') != undefined) {
                for (let row of this.myForm.get('rows')['controls']) {
                  this.itemForm.push({ 'formId': response.data.id, 'infoControlType': row.controls.infoControlType.value, 'infoLabel': row.controls.infoLabel.value });
                }
              }



              console.log('------this.itemForm---');
              console.log(this.itemForm);
              if (this.itemForm.length > 0) {
                this.loading = true;
                this.formlistService.saveFormAdditionalInfo(this.itemForm, (response) => {
                  console.log('------saveFormAdditionalInfo done---');
                  console.log(response);
                  console.log(this.itemForm)
                  this.loading = false;
                  if (response.responsecode === 200 || response.responsecode === '200') {
                    //this.successbox.show();
                    //this.resetForm();
                  } else {
                    this.errorbox.text = response.message;
                    this.errorbox.show();
                  }
                })
              }
            }
            this.formlistService.getData((response) => {
              console.log(response);
              this.dataSource = new MatTableDataSource(response.data);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.successbox.show();
              this.getAllRole();
              this.resetForm();
              this.myForm.reset();
            })
          } else {
            this.errorbox.text = response.message;
            this.errorbox.show();
          }
        })
      }
    }

  }




  onClick(action, data) {
    console.log('-----onClick---');
    console.log(data);
    if (action == 'edit') {
      var authorityIdsData = data.authorityIds.split(',');
      var authorityIdsDataNew = authorityIdsData.map(Number);
      this.selectedOptions = authorityIdsDataNew;

      this.updateDisabledOptions()

      this.formlistService.formTypeData.id = data.id;
      this.formlistService.formTypeData.formsName = data.formsName;
      this.formlistService.formTypeData.categoryId = data.categoryId;
      console.log("CATEGORYID" + data.categoryId);
      console.log("FORMID" + data.id);
      if (data.revisionDate != null) {
        this.formlistService.formTypeData.revisionDate = this.datePipe.transform(data.revisionDate, "yyyy-MM-dd");
      }
      this.formlistService.formTypeData.isNotify = data.isNotify;
      this.formlistService.formTypeData.formsNotification = data.formsNotification;
      if (data.reportHeader != null) {
        var reportHeader = data.reportHeader.split(',');
        console.log('-----reportHeader---');
        console.log(reportHeader);
        this.formlistService.formTypeData.reportHeader1 = reportHeader[0];
        this.formlistService.formTypeData.reportHeader2 = reportHeader[1];
      }
      console.log("data.id");
      console.log(data.id);
      this.selectedAddOptions = [];
      console.log("==this.rows====");
      console.log(this.rows);
      console.log("==this.rows1111====");
      if (this.rows != undefined) {
        this.rows.controls = [];
      }
      console.log(this.rows);
      console.log("==this.rows222====");

      this.formlistService.getFormAdditionalInfoByFormId(data.id, (response) => {
        console.log("====getFormAdditionalInfoByFormId===");
        console.log(response.data);
        if (response.data) {
          console.log("data");
          console.log(response.data);
          console.log("data22");
          var infoLabelData1 = [];
          var infoLabelData2 = [];
          for (let resA of response.data) {
            if (this.getArrayRes(resA.infoLabel)) {
              infoLabelData1.push(resA.infoLabel);
            } else {
              infoLabelData2.push({ 'formlistId': data.id, 'infoLabel': resA.infoLabel, 'infoControlType': resA.infoControlType });
            }
          }
          this.selectedAddOptions = infoLabelData1.filter((x, i, a) => a.indexOf(x) == i)
          this.addDataRowArrangement(infoLabelData2.length, infoLabelData2);
          console.log("get data");
          console.log(this.selectedAddOptions);
          console.log(infoLabelData2);
          console.log("get data22");
        }
      })
      window.scroll(0, 0);


    } else {
      this.formlistService.deleteData(data.id).subscribe((data) => {
        console.log('Deleted');
        this.formlistService.getData((response) => {
          console.log("GET ALL FORMS")
          console.log(response);
          this.dataSource = new MatTableDataSource(response.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        })
      })
    }

  }

  getArrayRes(infoLabel) {
    for (let resB of this.additionalOption) {
      if (infoLabel == resB.label) {
        return true;
      }
    }
    return false;
  }

  addDataRowArrangement(count, arr: any) {
    if (arr.length == 0) {
      arr = [{ 'formlistId': '', 'infoLabel': '', 'infoControlType': '' }];
    }
    for (var i = 0; i < count; i++) {
      this.rows.push(this.createItemFormGroupArrangement(arr[i]));
    }
  }

  resetForm() {
    // this.formlistService.formTypeData.id="";
    this.formlistService.formTypeData.formsName = "";
    this.formlistService.formTypeData.categoryId = "";
    this.formlistService.formTypeData.revisionDate = "";
    this.formlistService.formTypeData.authorityIds = "";
    this.formlistService.formTypeData.reportHeader = "";
    this.formlistService.formTypeData.reportHeader1 = "";
    this.formlistService.formTypeData.reportHeader2 = "";
    this.formlistService.formTypeData.formsNotification = "";
    this.formlistService.formTypeData.isNotify = "";
    this.getAllRole();
    this.selectedOptions = [];
    this.selectedAddOptions = [];
    if (this.rows != undefined) {
      this.rows.controls = [];
    }
  }

  addQuestion(data) {
    this.session.set('formlistId', data.id);
    this.router.navigate(['/questionforms']);
  }

  assignVessels(data) {
    this.session.set('formlistId', data.id);
    this.router.navigate(['/formlistvessels']);
  }

  addNote(data) {
    this.session.set('formlistId', data.id);
    this.router.navigate(['/formnotes']);
  }

  onToggleDropdown() {
    this.multiSelect.toggleDropdown();
  }

  getSelectedOptions(selected) {
    if (selected.length > 4) {
      this.errorMessage = 'You can select up to 4 options only';
    } else {
      this.errorMessage = '';
      this.selectedOptions = selected;
      this.updateDisabledOptions();
    }
  }

  updateDisabledOptions() {
    if (this.selectedOptions.length >= 4) {
      this.allCrew.forEach(option => {
        if (!this.selectedOptions.includes(option.id)) {
          option.disabled = true;
        }
      });
    } else {
      this.allCrew.forEach(option => {
        option.disabled = false;
      });
    }
  }

  getSelectedAddOptions(selected) {
    this.selectedAddOptions = selected;
  }

  onResetSelection() {
    this.selectedOptions = [];
  }

  createItemFormGroupArrangement(datas: any): FormGroup {
    return this.fb.group({
      formlistId: (datas.formlistId),
      infoLabel: (datas.infoLabel),
      infoControlType: (datas.infoControlType)
    });
  }

  onRemoveRowArrangement(rowIndex: number) {
    this.rows.removeAt(rowIndex);
  }


}
