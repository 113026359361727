import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cospalert',
  templateUrl: './cospalert.component.html',
  styleUrls: ['./cospalert.component.scss']
})
export class CospalertComponent implements OnInit {

  constructor(private router:Router,public dialogRef: MatDialogRef<CospalertComponent>) { 
    dialogRef.disableClose = true ;

  }

  ngOnInit() {
    
  }

  cospalert()
  {
    this.router.navigate(['/cosp']);
  }

}
