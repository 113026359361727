import { Component, OnInit, ViewChild, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { MatDialog} from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { SessionStorageService } from 'angular-web-storage';
import { ActivatedRoute, Router } from '@angular/router';
import { FormreportsService } from 'src/app/formreports/formreports.service';
import { CrewService } from "src/app/crew/crew.service";
import { DatePipe } from "@angular/common";
import { ApiRootService } from 'src/app/apiroot.service';
import { HttpClient } from '@angular/common/http';
import { VesseltypeService } from 'src/app/vesseltype/vesseltype.service';
import { ReportsService } from 'src/app/reports/reports.service';
import { LocalStorageService } from 'src/app/local-storage.service';
import { NetworkStatusService } from 'src/app/network-status.service.ts.service';
import imageCompression from 'browser-image-compression';
import { Observable, from } from 'rxjs';
import { concatMap, catchError } from 'rxjs/operators';

@Component({
  selector: 'app-weeklypage',
  templateUrl: './weeklypage.component.html',
  styleUrls: ['./weeklypage.component.scss']
})

export class WeeklypageComponent implements OnInit {
  @Input() formData: any;
  @Input() editFormId: any;
  @Input() reportList: any;
  @Input() vesselName: any;
  @Output() updateParent = new EventEmitter();
  isOnline: any;
  dataForEdit: any;
  dynamicForm: FormGroup;
  form: FormGroup;
  isDataBind: boolean = false;
  isAddNewQuestion: boolean;
  formansweradditioninfomodel: any = [];
  @ViewChild('successbox', { static: true }) public successbox: SwalComponent;
  @ViewChild('errorbox', { static: true }) public errorbox: SwalComponent;
  @ViewChild('noData', { static: true }) public noData: SwalComponent;
  @ViewChild('approval', { static: true }) public approval: SwalComponent;
  items: any[] = ['item1', 'item2', 'item3'];
  fileFormData: FormData;
  selectedVesselId: number;
  voyageNo: any;
  userId: any;
  currentActionDate: any;
  currentActionTime: any;
  authurl: any = this.session.get('Authurl');
  count: number = 0;
  imageItem: any[] = [];
  loading; boolean;
  confirmdetail: boolean;
  addInfoDetail: any;
  existingObjectId: any;
  infoFormId; number;
  groupedDataArray;
  globalIndex = 0;
  currentShow;
  firstName: string;
  lastName: string;
  maxLength: number = 1000;
  fromDate: Date;
  toDate: Date;
  masters: any = [];
  chiefOfficers: any = [];
  weeklyEditData: any
  timestamp: any;
  selectedMasterName: string = '';
  selectedChiefOfficerName: string = '';
  allDateRange: any;
  hseqOffline;
  technicalOffline;
  mlcOffline
  fieldMappings: any = [];
  syncMsgLoading: boolean = false;
  maxImgHseqTab: number = 112;
  maxImgTechnicalTab: number = 168;
  maxImgMlcTab: number = 112;


  public getvalue: any;
  //isOnline: boolean = true;
  offlineDataReqStoreList: any = [];
  selectedImages: { [key: string]: any[] } = {

    // HSQE Section
    deck_Monday_Image: [],
    deck_Tuesday_Image: [],
    deck_Wednesday_Image: [],
    deck_Thursday_Image: [],
    deck_Friday_Image: [],
    deck_Saturday_Image: [],
    deck_Sunday_Image: [],
    lsa_ffa_Monday_Image: [],
    lsa_Ffa_Tuesday_Image: [],
    lsa_Ffa_Wednesday_Image: [],
    lsa_Ffa_Thursday_Image: [],
    lsa_Ffa_Friday_Image: [],
    lsa_Ffa_Saturday_Image: [],
    lsa_Ffa_Sunday_Image: [],

    // Technical Section
    engine_Electrical_Monday_Image: [],
    engine_Electrical_Tuesday_Image: [],
    engine_Electrical_Wednesday_Image: [],
    engine_Electrical_Thursday_Image: [],
    engine_Electrical_Friday_Image: [],
    engine_Electrical_Saturday_Image: [],
    engine_Electrical_Sunday_Image: [],

    filterDeck_Engine_Monday_Image: [],
    filterDeck_Engine_Tuesday_Image: [],
    filterDeck_Engine_Wednesday_Image: [],
    filterDeck_Engine_Thursday_Image: [],
    filterDeck_Engine_Friday_Image: [],
    filterDeck_Engine_Saturday_Image: [],
    filterDeck_Engine_Sunday_Image: [],

    rsd_Engine_Monday_Image: [],
    rsd_Engine_Tuesday_Image: [],
    rsd_Engine_Wednesday_Image: [],
    rsd_Engine_Thursday_Image: [],
    rsd_Engine_Friday_Image: [],
    rsd_Engine_Saturday_Image: [],
    rsd_Engine_Sunday_Image: [],

    // MLC
    mlc_Sanitary_Laundry_Facilities_Image: [],
    mlc_Galley_Storage_Room_Image: [],
    mlc_Mess_Room_Image: [],
    mlc_Sleeping_Room_Image: [],
    mlc_Hospital_Acc_Image: [],
    mlc_General_Image: [],
    mlc_Ch_Cook_Image: [],

    hki_Bridge_Image: [],
    hki_Accomodation_Area_Image: [],
    hki_Od_Store_Image: [],
    hki_Engine_Room_Image: [],
    hki_Steearing_Room_Image: [],
    hki_Od_Lfc_Image: [],
    hki_Od_Spd_Image: []

  };

  deck_Monday;
  deck_Tuesday;
  deck_Wednesday;
  deck_Thursday;
  deck_Friday;
  deck_Saturday;
  deck_Sunday;

  lsa_Ffa_Monday;
  lsa_Ffa_Tuesday;
  lsa_Ffa_Wednesday;
  lsa_Ffa_Thursday;
  lsa_Ffa_Friday;
  lsa_Ffa_Saturday;
  lsa_Ffa_Sunday;

  cert_Due_3M;
  cert_class_Cond_Due_3M;
  cert_survey_Flag_Due_3M;

  engine_Electrical_Monday
  engine_Electrical_Tuesday
  engine_Electrical_Wednesday
  engine_Electrical_Thursday
  engine_Electrical_Friday
  engine_Electrical_Saturday
  engine_Electrical_Sunday


  filterDeck_Engine_Monday
  filterDeck_Engine_Tuesday
  filterDeck_Engine_Wednesday
  filterDeck_Engine_Thursday
  filterDeck_Engine_Friday
  filterDeck_Engine_Saturday
  filterDeck_Engine_Sunday

  rsd_Engine_Monday
  rsd_Engine_Tuesday
  rsd_Engine_Wednesday
  rsd_Engine_Thursday
  rsd_Engine_Friday
  rsd_Engine_Saturday
  rsd_Engine_Sunday

  mr_Boiler_Chloride
  mr_Boiler_Ph
  mr_Boiler_Nitrate
  mr_Boiler_P_alkanity
  mr_Ht_Chlroide
  mr_Ht_Ph
  mr_Ht_Nitrate
  mr_Ht_P_alkanity
  mr_Lt_Chlroide
  mr_Lt_Ph
  mr_Lt_Nitrate
  mr_Lt_P_alkanity
  mr_Me_Tbn
  mr_Me_Water
  mr_Me_Visc
  mr_Ae1_Tbn
  mr_Ae1_Water
  mr_Ae1_Visc
  mr_Ae2_Tbn
  mr_Ae2_Water
  mr_Ae2_Visc
  mr_Ae3_Tbn
  mr_Ae3_Water
  mr_Ae3_Visc
  mr_St_Tbn
  mr_St_Water
  mr_St_Visc
  mr_Si_Quant
  mr_Si_Ho
  mr_Se_Quant
  mr_Se_Ho
  mr_Fwg_Quant
  mr_Fwg_Ho
  mr_Bilge_Quant
  mr_Bilge_Ho
  mr_Ts_Rq
  mr_Ts_Capc
  mr_Tb_Rq
  mr_Tb_Capc
  mr_Tf_Rq
  mr_Tf_Capc


  mlc_Sanitary_Laundry_Facilities
  mlc_Galley_Storage_Room
  mlc_Mess_Room
  mlc_Sleeping_Room
  mlc_Hospital_Acc
  mlc_General
  mlc_Ch_Cook

  hki_Bridge
  hki_Accomodation_Area
  hki_Od_Store
  hki_Engine_Room
  hki_Steearing_Room
  hki_Od_Lfc
  hki_Od_Spd


  constructor(private router: Router, private http: HttpClient, private route: ActivatedRoute, private formBuilder: FormBuilder, public session: SessionStorageService, private dialog: MatDialog, public formreportsService: FormreportsService, public crewService: CrewService, public datePipe: DatePipe, private service: ApiRootService, private vts: VesseltypeService, private rs: ReportsService, public networkStatusService: NetworkStatusService, public LocalStorage: LocalStorageService) {
    this.form = this.formBuilder.group({
      items: this.formBuilder.array([]),
      additionalItems: this.formBuilder.array([])
    });
  }

  ngOnInit() {
    this.networkStatusService.isOnline.subscribe(status => {
      this.isOnline = status;
    });

    this.userId = sessionStorage.getItem('userid');
    let currentDate = new Date();
    if (currentDate) {
      this.currentActionDate = this.datePipe.transform(currentDate, 'dd-MMM-yyyy');
      this.currentActionTime = this.datePipe.transform(currentDate, 'HH:mm');
    }
    console.log("after currentDate");
    this.get('masters');
    this.get('chiefOfficers');
    this.get('reportList');
    console.log("after getMasterAndChiefByVesselId");
  }

  add(keyName, value) {
    console.log('Value: ', keyName);
    this.LocalStorage.add(keyName, value).then(res => {
      if (res) {
        this.successbox.show();
        console.log(keyName + " Inserted Successfully.",)
      }
    });
  }

  get(keyName) {
    // console.log('Get_Data: ', keyName);
    this.LocalStorage.get(keyName).then(res => {
      // console.log('Get_Data_res_1: ', keyName, res);
      if (res) {
        // console.log('Get_Data_res: ', res);
        // this.getvalue = res;
        if (keyName === 'masters') {
          this.masters = res;
          console.log('masters: ', this.masters);
        }
        if (keyName === 'chiefOfficers') {
          this.chiefOfficers = res;
          // console.log('chiefOfficers: ', this.chiefOfficers);
        }
        if (keyName === 'reportList') {
          this.reportList = res;
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log("====changes==:", changes);
    if (changes['formData']) {
      this.get('reportList');
      var teston = ''
      if (this.formData.id != 80 && (this.formData.recordId == undefined || !this.formData.recordId)) {
        this.errorbox.text = "Please ensure the General Details form is saved before proceeding to complete any other forms.";
        this.errorbox.show();
        this.currentShow = 80;
      } else {
        this.currentShow = this.formData && this.formData.id ? this.formData.id : null;
      }
      console.log("formData received from parent (ngOnChanges):", this.formData);
      // console.log("this.formData-path)-property", this.formData.matchedEntries[0].data[0].wwd_hseq[0])

      const vesselId = this.formData && this.formData.vesselId ? this.formData.vesselId : null;
      // Set initial values based on formData
      this.fromDate = this.parseDate(this.formData && this.formData.fromDate ? this.formData.fromDate : null) || null;
      this.toDate = this.parseDate(this.formData && this.formData.toDate ? this.formData.toDate : null) || null;
      this.selectedMasterName = this.formData && this.formData.master ? this.formData.master : '';
      this.selectedChiefOfficerName = this.formData && this.formData.chiefOfficer ? this.formData.chiefOfficer : '';

      if (this.formData.id == 80) {
        this.LocalStorage.getNew('wwd_general_update_' + this.formData.recordId)
          .then((res) => {
            if (res) {
              console.log('wwd_general_update_' + this.formData.recordId + 'Fetched Data hseqData before checkentries:', res);

              if (res != undefined && res.data != undefined && res.data[0] != undefined && res.data[0].formData != undefined) {
                this.selectedMasterName = res.data[0].formData.master_Name ? res.data[0].formData.master_Name : '';
                this.selectedChiefOfficerName = res.data[0].formData.chief_Officer_Name ? res.data[0].formData.chief_Officer_Name : '';
              }
            }
          })
      }

      var storageKeyName = '';
      if (this.formData.id == 81) {
        var storageKeyName = 'wwd_hseq_' + this.formData.recordId;
      } else if (this.formData.id == 82) {
        var storageKeyName = 'wwd_technical_' + this.formData.recordId;
      } else if (this.formData.id == 83) {
        var storageKeyName = 'wwd_mlc_' + this.formData.recordId;
      }
      console.log("=====storageKeyName===", storageKeyName);
      if (storageKeyName && this.formData.recordId) {
        this.LocalStorage.getNew(storageKeyName)
          .then((res) => {
            console.log(storageKeyName + 'Fetched Data hseqData before checkentries:', res);
            if (res != 'undefined') {
              this.editWeeklyData(this.formData.id, res);
            } else if (res == 'undefined') {
              this.currentShow = 80;
              if (this.isOnline) {
                this.getSinglewwdRowData()
              }
            }
          })
      }

    }
  }

  getSinglewwdRowData() {
    var recordId = this.formData.recordId;
    var formId = this.formData.formId;
    this.syncMsgLoading = true
    if (recordId && formId) {
      console.log("==getwwdRowData recordId==", recordId);
      this.rs.getWeeklyIndiviualDataEntry(formId, this.formData.vesselId, recordId, (response) => {
        console.log("===getWeeklyIndiviualData=====", response);
        this.syncMsgLoading = false;
        if (response['responsecode'] === 200 || response['responsecode'] === '200') {
          if (response.data[0].wwd_hseq != undefined) {
            if (response.data[0].wwd_hseq[0]) {
              this.add('wwd_hseq_' + recordId, response.data[0].wwd_hseq[0]);
              console.log('getwwdRowData ' + 'wwd_hseq_' + recordId, response.data[0].wwd_hseq[0]);
            } else {
              this.add('wwd_hseq_' + recordId, '');
            }
          } else if (response.data[0].wwd_technical != undefined) {
            if (response.data[0].wwd_technical[0]) {
              this.add('wwd_technical_' + recordId, response.data[0].wwd_technical[0]);
              console.log('getwwdRowData ' + 'wwd_technical_' + recordId, response.data[0].wwd_technical[0]);
            } else {
              this.add('wwd_technical_' + recordId, '');
            }
          } else if (response.data[0].wwd_mlc != undefined) {
            if (response.data[0].wwd_mlc[0]) {
              this.add('wwd_mlc_' + recordId, response.data[0].wwd_mlc[0]);
              console.log('getwwdRowData ' + 'wwd_mlc_' + recordId, response.data[0].wwd_mlc[0]);
            } else {
              this.add('wwd_mlc_' + recordId, '');
            }
          }
        }
      });
    }
  }



  parseDataComparison(dateStr: string): string {
    const [day, month, year] = dateStr.split('/');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`; // Output format: yyyy-MM-dd
  }

  isDateRangeOverlapping(newFromDate, newToDate) {
    console.log("Checking overlap for dates:", newFromDate, newToDate);
    const updatedFromDate = this.convertDateFormat(newFromDate);
    const updatedToDate = this.convertDateFormat(newToDate);

    console.log("check-updateDate", updatedFromDate, updatedToDate);


    // Check if this.allDateRange is defined and is an array
    if (!Array.isArray(this.allDateRange) || this.allDateRange.length === 0) {
      console.warn("allDateRange is not defined or is empty.");
      return false; // No overlap if there's no data to check against
    }

    const isOverlapping = this.allDateRange.some(record => {
      console.log("check-record", record.formDate, record.toDate);

      if (record.formDate === updatedFromDate && record.toDate === updatedToDate) {
        console.log("Match found: overlapping dates");
        return true; // Return true if a match is found
      }
      return false; // Explicitly return false to continue checking other records
    });

    console.log("Is date range overlapping:", isOverlapping);
    return isOverlapping; // Return the result of .some(), true if match is found, otherwise false
  }

  getAllDateRangOnVessel() {
    console.log("git ");
    this.rs.getWeeklyFormDataByVesselIdAndFormId(80, this.formData.vesselId, (res) => {
      if (res.responsecode === 200 || res.responsecode === '200') {
        this.allDateRange = res.data;
        console.log("Fetched all date ranges:", this.allDateRange);
      } else {
        console.log("Error in getWeeklyFormDataByVesselIdAndFormId:", res.message);
      }
    }, (error) => { // Error Callback (Handles Timeout)
      console.log('[API Timeout/Error]', error);
      this.errorbox.text = "Request timed out. Please try again.";
      this.errorbox.show();
    });
  }


  convertDateFormat(inputDate: string): string {
    const dateParts = inputDate.split('-');
    if (dateParts.length !== 3) {
      throw new Error('Invalid date format. Please provide a date in the format yyyy-m-d');
    }
    const year = dateParts[0];
    const month = dateParts[1].padStart(2, '0');
    const day = dateParts[2].padStart(2, '0');
    return `${year}-${day}-${month}`;
  }

  getTimestampFromYyyyDdMm(dateString: string): number {
    console.log(`Input date string: ${dateString}`);
    const parts = dateString.split('-');
    console.log(`Split parts: ${parts}`);
    const year = parseInt(parts[0], 10);
    console.log(`Parsed year: ${year}`);
    const day = parseInt(parts[1], 10);
    console.log(`Parsed day: ${day}`);
    const month = parseInt(parts[2], 10) - 1;
    console.log(`Parsed month (0-based): ${month}`);

    const date = new Date(year, month, day);
    console.log(`Created Date object: ${date}`);

    const timestamp = date.getTime();
    console.log(`Timestamp (milliseconds since epoch): ${timestamp}`);

    return timestamp;
  }

  private parseDateSecond(dateString: string): Date | null {
    if (!dateString) {
      console.error("Invalid date string: ", dateString);
      return null;
    }

    const parts = dateString.split('/');
    if (parts.length !== 3) {
      console.error("Date string is not in the expected format: ", dateString);
      return null;
    }

    return new Date(+parts[2], +parts[1] - 1, +parts[0]);
  }




  editWeeklyData(editFormId, response = null) {
    console.log("editWeeklyData response", response);
    if (response != null) {
      this.assignWeeklyData(response);
    } else {
      this.loading = false;
      console.log("====this.loading14====", this.loading);
      this.rs.getWeeklyIndiviualDataEntry(
        editFormId,
        this.formData.vesselId,
        this.formData.recordId,
        (res) => {
          // this.loading = false;
          if (res.responsecode === 200 || res.responsecode === '200') {
            console.log("edit-weeklydata", res);
            this.weeklyEditData = res.data;
            var responseData = '';
            if (editFormId == 81) {
              responseData = this.weeklyEditData[0] && this.weeklyEditData[0].wwd_hseq && this.weeklyEditData[0].wwd_hseq[0];
            } else if (editFormId == 82) {
              responseData = this.weeklyEditData[0] && this.weeklyEditData[0].wwd_technical && this.weeklyEditData[0].wwd_technical[0];
            } else if (editFormId == 83) {
              responseData = this.weeklyEditData[0] && this.weeklyEditData[0].wwd_mlc && this.weeklyEditData[0].wwd_mlc[0];
            }
            if (responseData) {
              this.assignWeeklyData(responseData);
            }
          }
        }
      );
    }
  }

  assignWeeklyData(responseData: any) {
    console.log("====responseData====", responseData);

    this.fieldMappings = responseData;
    // Assign image data dynamically
    if (responseData.images) {
      Object.keys(responseData.images).forEach((key) => {
        if (Array.isArray(responseData.images[key]) && responseData.images[key].length > 0) {
          this.selectedImages[key] = responseData.images[key];
        }
      });
    }
    console.log("===assignWeeklyData this.fieldMappings===", this.fieldMappings);
    console.log("===assignWeeklyData this.selectedImages===", this.selectedImages);
  }


  private parseDate(dateString: string): Date {
    console.log("======dateString=====", dateString);
    if (dateString) {
      const [year, day, month] = dateString.split('-').map(Number);
      return new Date(year, month - 1, day); // Month is 0-indexed
    }
  }

  onImageSelected(event: Event, elementId: string) {
    console.log("===onImageSelected-event====", event);
    console.log("===onImageSelected-elementId====", elementId);
    const target = event.target as HTMLInputElement;
    console.log("===onImageSelected-target", target)
    const files = target.files;
    console.log("===onImageSelected-files===", files);

    const validFormats = ['image/png', 'image/jpeg'];
    const maxSize = 5 * 1024 * 1024;
    const maxImages = 15;

    if (files) {
      console.log("==this.selectedImages[elementId]==", this.selectedImages[elementId])
      if (!this.selectedImages[elementId]) {
        this.selectedImages[elementId] = [];
      }

      console.log("==console-file-length==", files.length);
      console.log("==console-selectedimages[elementId-length==]", this.selectedImages[elementId].length);

      let currentImageCount = this.selectedImages[elementId].length;
      let totalNewImages = currentImageCount + files.length;

      // Check if adding new images would exceed the limit
      if (totalNewImages > maxImages) {
        this.errorbox.text = `You can upload only ${maxImages} images for this field.`;
        this.errorbox.show();
        return;
      }

      // Process each file
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        console.log("==console-each-file-iteration==", file);
        // Validate file type
        if (!validFormats.includes(file.type)) {
          this.errorbox.text = `Invalid File Format: ${file.name}. Only PNG & JPEG are allowed.`;
          this.errorbox.show();
          continue;
        }

        // Validate file size
        if (file.size > maxSize) {
          this.errorbox.text = `File size too large: ${file.name}. Maximum allowed size is 5 MB.`;
          this.errorbox.show();
          continue;
        }

        // If valid, process the image
        this.loading = true;
        console.log("====this.loading====", this.loading);

        this.compressImage(file).then((base64) => {
          this.loading = false;

          // Check again before pushing to ensure the limit is not exceeded
          if (this.selectedImages[elementId].length < maxImages) {
            this.selectedImages[elementId].push({
              base64: base64,
              name: file.name
            });

            const sizeInBytes = this.getBase64Size(base64);
            console.log(
              `Base64 image Size after compression: ${(sizeInBytes / 1024).toFixed(2)} KB`
            );
          } else {
            this.errorbox.text = `You can upload only ${maxImages} images for this field.`;
            this.errorbox.show();
          }
        });
      }
      console.log('==console-all-image-in element==', this.selectedImages[elementId]);
      console.log('==console-all-image-in element-length==', this.selectedImages[elementId][0].length);
    }
  }


  removeAllImages(elementId: string) {
    // Clear all images for the specific field
    if (this.selectedImages[elementId]) {
      this.selectedImages[elementId] = [];
      console.log(`All images removed for: ${elementId}`);
    }
  }

  getBase64Size(base64String: string): number {
    // Remove Base64 metadata (if any, e.g., "data:image/png;base64,")
    const base64Body = base64String.split(',')[1] || base64String;

    // Calculate padding (number of "=" characters at the end)
    const padding = (base64Body.match(/=+$/) || [''])[0].length;

    // Calculate size in bytes
    const sizeInBytes = (base64Body.length * 3) / 4 - padding;

    return sizeInBytes;
  }

  removeImage(elementId: string, index: number, fileInput: HTMLInputElement) {
    if (this.selectedImages[elementId]) {
      this.selectedImages[elementId].splice(index, 1);
      fileInput.value = '';
    }
  }

  dateStringToYyyyMmDd(dateString: string): string {
    console.log(`Input date string: ${dateString}`);

    // Split the date string into components
    const parts = dateString.split('/');
    console.log(`Split parts: ${parts}`);

    const month = parts[0]; // MM
    const day = parts[1];   // DD
    const year = parts[2];  // YYYY

    // Construct the new format YYYY-MM-DD
    const formattedDate = `${year}-${month}-${day}`;
    console.log(`Formatted date: ${formattedDate}`);

    return formattedDate;
  }

  convertDateToTimestamp(dateString: string): number {
    console.log(`Input date string: ${dateString}`);

    // Split the date string into components
    const parts = dateString.split('/');
    console.log(`Split parts: ${parts}`);

    const month = parseInt(parts[0], 10) - 1; // Month is zero-based in JavaScript Date
    console.log(`Parsed month (0-based): ${month}`);

    const day = parseInt(parts[1], 10);
    console.log(`Parsed day: ${day}`);

    const year = parseInt(parts[2], 10);
    console.log(`Parsed year: ${year}`);

    // Create a new Date object
    const date = new Date(year, month, day);
    console.log(`Created Date object: ${date}`);

    // Return the timestamp (milliseconds since epoch)
    const timestamp = date.getTime();
    console.log(`Timestamp (milliseconds since epoch): ${timestamp}`);

    return timestamp;

  }

  public onSubmit(formId, form) {
    this.get('reportList');
    this.firstName = this.session.get("firstname");
    this.lastName = this.session.get("lastname");
    console.log("Full Name of Current User is", this.firstName + " " + this.lastName);
    console.log("onSubmit is working for formId: ", formId);
    let parameterdata;
    let parameterdataOffline;
    let parameterdImageData;
    let formattedFromDate
    let formattedToDate
    if (formId === 80) {
      console.log("Current Form ID is 80 => General Detail => Weekly Work");

      const gd_Vessel_Name = (document.getElementById("vessel_name") as HTMLTextAreaElement).value;
      const gd_From_Date = (document.getElementById("gd_from_date") as HTMLInputElement).value;
      const gd_To_Date = (document.getElementById("gd_to_date") as HTMLInputElement).value;
      const master_Name = this.selectedMasterName;
      const chief_Officer_Name = this.selectedChiefOfficerName;

      console.log("From Date:", gd_From_Date, "To Date:", gd_To_Date);
      console.log("Master Name:", master_Name, "Chief Officer Name:", chief_Officer_Name);

      if (!gd_From_Date || !gd_To_Date || !master_Name || !chief_Officer_Name) {
        this.errorbox.text = "All the Fields are required in General Detail Section";
        this.errorbox.show();
        console.log("Error: Missing required fields");
        return;
      }

      const parseDate = (dateStr: string): string => {
        console.log("Parsing submission date:", dateStr);
        const [day, month, year] = dateStr.split("/");
        const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        console.log("Parsed submission date:", formattedDate);
        return formattedDate;
      };

      formattedFromDate = parseDate(gd_From_Date);
      this.timestamp = formattedFromDate ? new Date(formattedFromDate).getTime() : null;
      formattedToDate = parseDate(gd_To_Date);
      console.log("Formatted From Date:", formattedFromDate, "Formatted To Date:", formattedToDate);

      parameterdata = {
        gd_Vessel_Name,
        gd_From_Date: formattedFromDate,
        gd_To_Date: formattedToDate,
        master_Name,
        chief_Officer_Name
      };
      console.log("Parameter data prepared for submission:", parameterdata);
    }

    if (formId == 81) {
      console.log("Current Formid is 81 => HSEQ => Weekly Work");
      const deck_Monday = (document.getElementById("deck_Monday") as HTMLInputElement).value;
      const deck_Tuesday = (document.getElementById("deck_Tuesday") as HTMLInputElement).value;
      const deck_Wednesday = (document.getElementById("deck_Wednesday") as HTMLInputElement).value;
      const deck_Thursday = (document.getElementById("deck_Thursday") as HTMLInputElement).value;
      const deck_Friday = (document.getElementById("deck_Friday") as HTMLInputElement).value;
      const deck_Saturday = (document.getElementById("deck_Saturday") as HTMLInputElement).value;
      const deck_Sunday = (document.getElementById("deck_Sunday") as HTMLInputElement).value;

      const lsa_Ffa_Monday = (document.getElementById("lsa_Ffa_Monday") as HTMLInputElement).value;
      const lsa_Ffa_Tuesday = (document.getElementById("lsa_Ffa_Tuesday") as HTMLInputElement).value;
      const lsa_Ffa_Wednesday = (document.getElementById("lsa_Ffa_Wednesday") as HTMLInputElement).value;
      const lsa_Ffa_Thursday = (document.getElementById("lsa_Ffa_Thursday") as HTMLInputElement).value;
      const lsa_Ffa_Friday = (document.getElementById("lsa_Ffa_Friday") as HTMLInputElement).value;
      const lsa_Ffa_Saturday = (document.getElementById("lsa_Ffa_Saturday") as HTMLInputElement).value;
      const lsa_Ffa_Sunday = (document.getElementById("lsa_Ffa_Sunday") as HTMLInputElement).value;

      const cert_Due_3M = (document.getElementById("cert_Due_3M") as HTMLInputElement).value;
      const cert_class_Cond_Due_3M = (document.getElementById("cert_class_Cond_Due_3M") as HTMLInputElement).value;
      const cert_survey_Flag_Due_3M = (document.getElementById("cert_survey_Flag_Due_3M") as HTMLInputElement).value;

      parameterdata = {
        deck_Monday, deck_Tuesday, deck_Wednesday, deck_Thursday, deck_Friday, deck_Saturday, deck_Sunday, lsa_Ffa_Monday, lsa_Ffa_Tuesday, lsa_Ffa_Wednesday, lsa_Ffa_Thursday, lsa_Ffa_Friday, lsa_Ffa_Saturday, lsa_Ffa_Sunday, cert_Due_3M, cert_class_Cond_Due_3M, cert_survey_Flag_Due_3M
      }


      parameterdImageData = ['deck_Monday_Image', 'deck_Tuesday_Image', 'deck_Wednesday_Image', 'deck_Thursday_Image',
        'deck_Friday_Image', 'deck_Saturday_Image', 'deck_Sunday_Image', 'lsa_ffa_Monday_Image',
        'lsa_Ffa_Tuesday_Image', 'lsa_Ffa_Wednesday_Image', 'lsa_Ffa_Thursday_Image', 'lsa_Ffa_Friday_Image',
        'lsa_Ffa_Saturday_Image', 'lsa_Ffa_Sunday_Image'];

        const isAnyFieldFilled =
        Object.values(parameterdata).some(function (field) { return field != null && field !== ''; }) ||
        parameterdImageData.some(function (image) { return this.selectedImages[image] && this.selectedImages[image].length > 0; }.bind(this));

      if (!isAnyFieldFilled) {
        this.errorbox.title = 'Wait';
        this.errorbox.text = 'Please fill in at least one field (text or image) in the HSEQ Tab before saving'
        this.errorbox.show()
        return; // Stop the form submission
      }

      var imagesKeyValue = {};
      let hseqCurrentTabImageCount: number = 0;
      for (const key in this.selectedImages) {

        console.log("===key===", key, parameterdImageData.includes(key));
        if (this.selectedImages.hasOwnProperty(key) && (parameterdImageData.includes(key))) {
          imagesKeyValue[key] = this.selectedImages[key];
          hseqCurrentTabImageCount += this.selectedImages[key].length;
        }
      }

      console.log("===imagesKeyValue===", imagesKeyValue);
      console.log("Total images in HSEQ tab: ", hseqCurrentTabImageCount);

      if (hseqCurrentTabImageCount > this.maxImgHseqTab) {
        const extraImages = hseqCurrentTabImageCount - this.maxImgHseqTab;

        this.errorbox.title = 'Wait';
        this.errorbox.text = `You can't upload more than ${this.maxImgHseqTab} images in the HSEQ tab. You have uploaded ${extraImages} extra images. Please delete them.`;
        this.errorbox.show();
        return;
      }

      parameterdataOffline = {
        deck_Monday, deck_Tuesday, deck_Wednesday, deck_Thursday, deck_Friday, deck_Saturday, deck_Sunday, lsa_Ffa_Monday, lsa_Ffa_Tuesday, lsa_Ffa_Wednesday, lsa_Ffa_Thursday, lsa_Ffa_Friday, lsa_Ffa_Saturday, lsa_Ffa_Sunday, cert_Due_3M, cert_class_Cond_Due_3M, cert_survey_Flag_Due_3M,
        "images": imagesKeyValue
      }

      console.log("check-parameterdata", parameterdata);
    }

    if (formId == 82) {
      if (form.invalid) {
        this.errorbox.text = "All fields in Miscellaneous Reports are required. Please ensure all fields are filled."
        this.errorbox.show();
        return; // Exit function
      }
      console.log("Current Formid is YOUR_FORM_ID => HSEQ => Engine and Filter Data");

      // Engine Electrical Values
      const engine_Electrical_Monday = (document.getElementById("engine_Electrical_Monday") as HTMLInputElement).value;
      const engine_Electrical_Tuesday = (document.getElementById("engine_Electrical_Tuesday") as HTMLInputElement).value;
      const engine_Electrical_Wednesday = (document.getElementById("engine_Electrical_Wednesday") as HTMLInputElement).value;
      const engine_Electrical_Thursday = (document.getElementById("engine_Electrical_Thursday") as HTMLInputElement).value;
      const engine_Electrical_Friday = (document.getElementById("engine_Electrical_Friday") as HTMLInputElement).value;
      const engine_Electrical_Saturday = (document.getElementById("engine_Electrical_Saturday") as HTMLInputElement).value;
      const engine_Electrical_Sunday = (document.getElementById("engine_Electrical_Sunday") as HTMLInputElement).value;

      // Filter Deck Engine Values
      const filterDeck_Engine_Monday = (document.getElementById("filterDeck_Engine_Monday") as HTMLInputElement).value;
      const filterDeck_Engine_Tuesday = (document.getElementById("filterDeck_Engine_Tuesday") as HTMLInputElement).value;
      const filterDeck_Engine_Wednesday = (document.getElementById("filterDeck_Engine_Wednesday") as HTMLInputElement).value;
      const filterDeck_Engine_Thursday = (document.getElementById("filterDeck_Engine_Thursday") as HTMLInputElement).value;
      const filterDeck_Engine_Friday = (document.getElementById("filterDeck_Engine_Friday") as HTMLInputElement).value;
      const filterDeck_Engine_Saturday = (document.getElementById("filterDeck_Engine_Saturday") as HTMLInputElement).value;
      const filterDeck_Engine_Sunday = (document.getElementById("filterDeck_Engine_Sunday") as HTMLInputElement).value;

      // Riding Squad Deck + Engine
      const rsd_Engine_Monday = (document.getElementById("rsd_Engine_Monday") as HTMLInputElement).value;
      const rsd_Engine_Tuesday = (document.getElementById("rsd_Engine_Tuesday") as HTMLInputElement).value;
      const rsd_Engine_Wednesday = (document.getElementById("rsd_Engine_Wednesday") as HTMLInputElement).value;
      const rsd_Engine_Thursday = (document.getElementById("rsd_Engine_Thursday") as HTMLInputElement).value;
      const rsd_Engine_Friday = (document.getElementById("rsd_Engine_Friday") as HTMLInputElement).value;
      const rsd_Engine_Saturday = (document.getElementById("rsd_Engine_Saturday") as HTMLInputElement).value;
      const rsd_Engine_Sunday = (document.getElementById("rsd_Engine_Sunday") as HTMLInputElement).value;

      // Tbale 1 MR
      const mr_Boiler_Chloride = (document.getElementById("mr_Boiler_Chloride") as HTMLInputElement).value;
      const mr_Boiler_Ph = (document.getElementById("mr_Boiler_Ph") as HTMLInputElement).value;
      const mr_Boiler_Nitrate = (document.getElementById("mr_Boiler_Nitrate") as HTMLInputElement).value;
      const mr_Boiler_P_alkanity = (document.getElementById("mr_Boiler_P_alkanity") as HTMLInputElement).value;
      const mr_Ht_Chlroide = (document.getElementById("mr_Ht_Chlroide") as HTMLInputElement).value;
      const mr_Ht_Ph = (document.getElementById("mr_Ht_Ph") as HTMLInputElement).value;
      const mr_Ht_Nitrate = (document.getElementById("mr_Ht_Nitrate") as HTMLInputElement).value;
      const mr_Ht_P_alkanity = (document.getElementById("mr_Ht_P_alkanity") as HTMLInputElement).value;
      const mr_Lt_Chlroide = (document.getElementById("mr_Lt_Chlroide") as HTMLInputElement).value;
      const mr_Lt_Ph = (document.getElementById("mr_Lt_Ph") as HTMLInputElement).value;
      const mr_Lt_Nitrate = (document.getElementById("mr_Lt_Nitrate") as HTMLInputElement).value;
      const mr_Lt_P_alkanity = (document.getElementById("mr_Lt_P_alkanity") as HTMLInputElement).value;


      // Table 2 MR
      const mr_Me_Tbn = (document.getElementById("mr_Me_Tbn") as HTMLInputElement).value;
      const mr_Me_Water = (document.getElementById("mr_Me_Water") as HTMLInputElement).value;
      const mr_Me_Visc = (document.getElementById("mr_Me_Visc") as HTMLInputElement).value;
      const mr_Ae1_Tbn = (document.getElementById("mr_Ae1_Tbn") as HTMLInputElement).value;
      const mr_Ae1_Water = (document.getElementById("mr_Ae1_Water") as HTMLInputElement).value;
      const mr_Ae1_Visc = (document.getElementById("mr_Ae1_Visc") as HTMLInputElement).value;
      const mr_Ae2_Tbn = (document.getElementById("mr_Ae2_Tbn") as HTMLInputElement).value;
      const mr_Ae2_Water = (document.getElementById("mr_Ae2_Water") as HTMLInputElement).value;
      const mr_Ae2_Visc = (document.getElementById("mr_Ae2_Visc") as HTMLInputElement).value;
      const mr_Ae3_Tbn = (document.getElementById("mr_Ae3_Tbn") as HTMLInputElement).value;
      const mr_Ae3_Water = (document.getElementById("mr_Ae3_Water") as HTMLInputElement).value;
      const mr_Ae3_Visc = (document.getElementById("mr_Ae3_Visc") as HTMLInputElement).value;
      const mr_St_Tbn = (document.getElementById("mr_St_Tbn") as HTMLInputElement).value;
      const mr_St_Water = (document.getElementById("mr_St_Water") as HTMLInputElement).value;
      const mr_St_Visc = (document.getElementById("mr_St_Visc") as HTMLInputElement).value;


      // Table 3
      const mr_Si_Quant = (document.getElementById("mr_Si_Quant") as HTMLInputElement).value;
      const mr_Si_Ho = (document.getElementById("mr_Si_Ho") as HTMLInputElement).value;
      const mr_Se_Quant = (document.getElementById("mr_Se_Quant") as HTMLInputElement).value;
      const mr_Se_Ho = (document.getElementById("mr_Se_Ho") as HTMLInputElement).value;
      const mr_Fwg_Quant = (document.getElementById("mr_Fwg_Quant") as HTMLInputElement).value;
      const mr_Fwg_Ho = (document.getElementById("mr_Fwg_Ho") as HTMLInputElement).value;
      const mr_Bilge_Quant = (document.getElementById("mr_Bilge_Quant") as HTMLInputElement).value;
      const mr_Bilge_Ho = (document.getElementById("mr_Bilge_Ho") as HTMLInputElement).value;

      // Table 4
      const mr_Ts_Rq = (document.getElementById("mr_Ts_Rq") as HTMLInputElement).value;
      const mr_Ts_Capc = (document.getElementById("mr_Ts_Capc") as HTMLInputElement).value;
      const mr_Tb_Rq = (document.getElementById("mr_Tb_Rq") as HTMLInputElement).value;
      const mr_Tb_Capc = (document.getElementById("mr_Tb_Capc") as HTMLInputElement).value;
      const mr_Tf_Rq = (document.getElementById("mr_Tf_Rq") as HTMLInputElement).value;
      const mr_Tf_Capc = (document.getElementById("mr_Tf_Capc") as HTMLInputElement).value;

      // Consolidating Data
      parameterdata = {
        engine_Electrical_Monday, engine_Electrical_Tuesday, engine_Electrical_Wednesday, engine_Electrical_Thursday, engine_Electrical_Friday, engine_Electrical_Saturday, engine_Electrical_Sunday,
        filterDeck_Engine_Monday, filterDeck_Engine_Tuesday, filterDeck_Engine_Wednesday, filterDeck_Engine_Thursday, filterDeck_Engine_Friday, filterDeck_Engine_Saturday, filterDeck_Engine_Sunday,
        rsd_Engine_Monday, rsd_Engine_Tuesday, rsd_Engine_Wednesday, rsd_Engine_Thursday, rsd_Engine_Friday, rsd_Engine_Saturday, rsd_Engine_Sunday,
        mr_Boiler_Chloride, mr_Boiler_Ph, mr_Boiler_Nitrate, mr_Boiler_P_alkanity, mr_Lt_P_alkanity, mr_Ht_Chlroide, mr_Ht_Ph, mr_Ht_Nitrate, mr_Ht_P_alkanity, mr_Lt_Chlroide, mr_Lt_Ph, mr_Lt_Nitrate, mr_Me_Tbn, mr_Me_Water, mr_Me_Visc, mr_Ae1_Tbn, mr_Ae1_Water, mr_Ae1_Visc, mr_Ae2_Tbn, mr_Ae2_Water, mr_Ae2_Visc, mr_Ae3_Tbn, mr_Ae3_Water, mr_Ae3_Visc, mr_St_Tbn, mr_St_Water, mr_St_Visc, mr_Si_Quant, mr_Si_Ho, mr_Se_Quant, mr_Se_Ho, mr_Fwg_Quant, mr_Fwg_Ho, mr_Bilge_Quant, mr_Bilge_Ho, mr_Ts_Rq, mr_Ts_Capc, mr_Tb_Rq, mr_Tb_Capc, mr_Tf_Rq, mr_Tf_Capc
      };

      parameterdImageData = ['engine_Electrical_Monday_Image', 'engine_Electrical_Tuesday_Image',
        'engine_Electrical_Wednesday_Image', 'engine_Electrical_Thursday_Image', 'engine_Electrical_Friday_Image',
        'engine_Electrical_Saturday_Image', 'engine_Electrical_Sunday_Image', 'filterDeck_Engine_Monday_Image',
        'filterDeck_Engine_Tuesday_Image', 'filterDeck_Engine_Wednesday_Image', 'filterDeck_Engine_Thursday_Image',
        'filterDeck_Engine_Friday_Image', 'filterDeck_Engine_Saturday_Image', 'filterDeck_Engine_Sunday_Image',
        'rsd_Engine_Monday_Image', 'rsd_Engine_Tuesday_Image', 'rsd_Engine_Wednesday_Image', 'rsd_Engine_Thursday_Image',
        'rsd_Engine_Friday_Image', 'rsd_Engine_Saturday_Image', 'rsd_Engine_Sunday_Image'];

      var imagesKeyValue = {};
      let technicalCurrentTabImageCount: number = 0;
      for (const key in this.selectedImages) {
        if (this.selectedImages.hasOwnProperty(key) && (parameterdImageData.includes(key))) {
          imagesKeyValue[key] = this.selectedImages[key];
          technicalCurrentTabImageCount += this.selectedImages[key].length;
        }
      }

      console.log("===imagesKeyValue===", imagesKeyValue);
      console.log('Total Images in Technical Tab', technicalCurrentTabImageCount);

      if (technicalCurrentTabImageCount > this.maxImgTechnicalTab) {
        const extraImages = technicalCurrentTabImageCount - this.maxImgTechnicalTab;
        this.errorbox.title = 'Wait';
        this.errorbox.text = `You can't upload more than ${this.maxImgTechnicalTab} images in the Technical tab. You have uploaded ${extraImages} extra images. Please delete them.`;
        this.errorbox.show();
        return;
      }


      parameterdataOffline = {
        engine_Electrical_Monday, engine_Electrical_Tuesday, engine_Electrical_Wednesday, engine_Electrical_Thursday, engine_Electrical_Friday, engine_Electrical_Saturday, engine_Electrical_Sunday,
        filterDeck_Engine_Monday, filterDeck_Engine_Tuesday, filterDeck_Engine_Wednesday, filterDeck_Engine_Thursday, filterDeck_Engine_Friday, filterDeck_Engine_Saturday, filterDeck_Engine_Sunday,
        rsd_Engine_Monday, rsd_Engine_Tuesday, rsd_Engine_Wednesday, rsd_Engine_Thursday, rsd_Engine_Friday, rsd_Engine_Saturday, rsd_Engine_Sunday,
        mr_Boiler_Chloride, mr_Boiler_Ph, mr_Boiler_Nitrate, mr_Boiler_P_alkanity, mr_Lt_P_alkanity, mr_Ht_Chlroide, mr_Ht_Ph, mr_Ht_Nitrate, mr_Ht_P_alkanity, mr_Lt_Chlroide, mr_Lt_Ph, mr_Lt_Nitrate, mr_Me_Tbn, mr_Me_Water, mr_Me_Visc, mr_Ae1_Tbn, mr_Ae1_Water, mr_Ae1_Visc, mr_Ae2_Tbn, mr_Ae2_Water, mr_Ae2_Visc, mr_Ae3_Tbn, mr_Ae3_Water, mr_Ae3_Visc, mr_St_Tbn, mr_St_Water, mr_St_Visc, mr_Si_Quant, mr_Si_Ho, mr_Se_Quant, mr_Se_Ho, mr_Fwg_Quant, mr_Fwg_Ho, mr_Bilge_Quant, mr_Bilge_Ho, mr_Ts_Rq, mr_Ts_Capc, mr_Tb_Rq, mr_Tb_Capc, mr_Tf_Rq, mr_Tf_Capc,
        "images": imagesKeyValue
      };

      console.log("check-parameterdata", parameterdata);
    }

    if (formId == 83) {
      console.log("Current Formid is 83 => HSEQ => Weekly Work");

      // MLC Section
      const mlc_Sanitary_Laundry_Facilities = (document.getElementById("mlc_Sanitary_Laundry_Facilities") as HTMLInputElement).value;
      const mlc_Galley_Storage_Room = (document.getElementById("mlc_Galley_Storage_Room") as HTMLInputElement).value;
      const mlc_Mess_Room = (document.getElementById("mlc_Mess_Room") as HTMLInputElement).value;
      const mlc_Sleeping_Room = (document.getElementById("mlc_Sleeping_Room") as HTMLInputElement).value;
      const mlc_Hospital_Acc = (document.getElementById("mlc_Hospital_Acc") as HTMLInputElement).value;
      const mlc_General = (document.getElementById("mlc_General") as HTMLInputElement).value;
      const mlc_Ch_Cook = (document.getElementById('mlc_Ch_Cook') as HTMLInputElement).value;

      // House Keeping Inspection
      const hki_Bridge = (document.getElementById("hki_Bridge") as HTMLInputElement).value;
      const hki_Accomodation_Area = (document.getElementById("hki_Accomodation_Area") as HTMLInputElement).value;
      const hki_Od_Store = (document.getElementById('hki_Od_Store') as HTMLInputElement).value;
      const hki_Engine_Room = (document.getElementById("hki_Engine_Room") as HTMLInputElement).value;
      const hki_Steearing_Room = (document.getElementById("hki_Steearing_Room") as HTMLInputElement).value;
      const hki_Od_Lfc = (document.getElementById("hki_Od_Lfc") as HTMLInputElement).value;
      const hki_Od_Spd = (document.getElementById("hki_Od_Spd") as HTMLInputElement).value;

      parameterdata = {
        mlc_Sanitary_Laundry_Facilities, mlc_Galley_Storage_Room, mlc_Mess_Room, mlc_Sleeping_Room, mlc_Hospital_Acc, mlc_General, mlc_Ch_Cook, hki_Bridge, hki_Accomodation_Area, hki_Od_Store, hki_Engine_Room, hki_Steearing_Room, hki_Od_Lfc, hki_Od_Spd
      }



      parameterdImageData = ['mlc_Sanitary_Laundry_Facilities_Image', 'mlc_Galley_Storage_Room_Image',
        'mlc_Mess_Room_Image', 'mlc_Sleeping_Room_Image', 'mlc_Hospital_Acc_Image',
        'mlc_General_Image', 'mlc_Ch_Cook_Image', 'hki_Bridge_Image',
        'hki_Accomodation_Area_Image', 'hki_Od_Store_Image', 'hki_Engine_Room_Image', 'hki_Steearing_Room_Image',
        'hki_Od_Lfc_Image', 'hki_Od_Spd_Image'];

              // Check if any text field is filled or if any image field is selected
      const isAnyFieldFilled =
      Object.values(parameterdata).some(function (field) { return field != null && field !== ''; }) ||
      parameterdImageData.some(function (image) { return this.selectedImages[image] && this.selectedImages[image].length > 0; }.bind(this));

    if (!isAnyFieldFilled) {
      this.errorbox.title = 'Wait';
      this.errorbox.text = 'Please fill in at least one field (text or image) in the MLC Tab before saving'
      this.errorbox.show();
      return; // Stop the form submission

    }

      var imagesKeyValue = {};
      let mlcCurrentTabImageCount: number = 0
      for (const key in this.selectedImages) {
        if (this.selectedImages.hasOwnProperty(key) && (parameterdImageData.includes(key))) {
          imagesKeyValue[key] = this.selectedImages[key];
          mlcCurrentTabImageCount += this.selectedImages[key].length;
        }
      }

      console.log("===imagesKeyValue===", imagesKeyValue);
      console.log('Total Images in MLC Tab', mlcCurrentTabImageCount);

      if (mlcCurrentTabImageCount > this.maxImgMlcTab) {
        const extraImages = mlcCurrentTabImageCount - this.maxImgMlcTab;
        this.errorbox.title = 'Wait';
        this.errorbox.text = `You can't upload more than ${this.maxImgMlcTab} images in the MLC tab. You have uploaded ${extraImages} extra images. Please delete them.`;
        this.errorbox.show();
        return;
      }


      parameterdataOffline = {
        mlc_Sanitary_Laundry_Facilities, mlc_Galley_Storage_Room, mlc_Mess_Room, mlc_Sleeping_Room, mlc_Hospital_Acc, mlc_General, mlc_Ch_Cook, hki_Bridge, hki_Accomodation_Area, hki_Od_Store, hki_Engine_Room, hki_Steearing_Room, hki_Od_Lfc, hki_Od_Spd,
        "images": imagesKeyValue
      }
    }

    // Current timestamp
    let currentDate = new Date();
    let actionDate = this.datePipe.transform(currentDate, "dd-MMM-yyyy");
    let actionTime = this.datePipe.transform(currentDate, "HH:mm");


    console.log("==this.selectedImages==", this.selectedImages);

    console.log("check-date-format", this.formData.fromDate, this.formData.toDate)

    const parseDate = (dateStr: string | undefined): string | undefined => {
      if (!dateStr) {
        return undefined;  // Return undefined if the date is not provided
      }
      const [year, day, month] = dateStr.split("-");
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    };

    if (formId == 81 || formId == 82 || formId == 83) {
      formattedFromDate = this.formData.fromDate ? parseDate(this.formData.fromDate) : null;
      formattedToDate = this.formData.toDate ? parseDate(this.formData.toDate) : null;
    }

    var recordId = this.formData.recordId;
    if (formId == 80) {
      formattedFromDate = '';
      formattedToDate = '';
      if (this.formData.recordId != undefined && this.formData.recordId.includes('add_new_report')) {
        recordId = '';
      }
    }

    var fromDateTimestamp = formattedFromDate ? new Date(formattedFromDate).getTime() : null;
    console.log("checkfromDateTimestamp", fromDateTimestamp);

    if (this.timestamp == undefined || this.timestamp == null) {
      this.timestamp = formattedFromDate ? new Date(formattedFromDate).getTime() : null;
      console.log("check-timestamp", this.timestamp)
    }

    // Construct the data object for API submission
    var weeklyData = {
      "data": [
        {
          "actionDate": actionDate,
          "actionTime": actionTime,
          "changeFields": "",
          "formData": parameterdata,
          "formId": formId,
          "fromDate": formattedFromDate,
          "images": imagesKeyValue,
          "isComplete": false,
          "is_email_notify_sync": false,
          "is_mobile_sync": false,
          "is_sync": false,
          "recordId": recordId,
          "rowId": "rowId" + currentDate.getTime(),
          "sectionId": "",
          "timeStamp": this.timestamp,
          "toDate": formattedToDate,
          "vesselId": this.formData.vesselId,
          "status": 0,
        }
      ],
      "userId": this.session.get("userid"),
      "userName": this.firstName + this.lastName,
      "userSign": this.session.get("userSignature")
    }

    var testn = ''
    if (this.isOnline && testn) {
      this.loading = true;
      console.log("====this.loading18====", this.loading);
      this.rs.weeklyWorkDone(weeklyData, (response) => {
        this.loading = false;
        if (response['responsecode'] === 200 || response['responsecode'] === '200') {
          this.successbox.show();
        } else {
          this.errorbox.text = response['message'];
          this.errorbox.show();
        }
        if (formId == 80) {
          this.updateParent.emit(response);
        }
      }, (error) => { // Error Callback (Handles Timeout)
        console.log('[API Timeout/Error]', error);
        this.errorbox.text = "Request timed out. Please try again.";
        this.errorbox.show();
      });
    } else {
      this.currentShow = formId;
      console.log("===formId===", formId);
      if (formId == 80) {
        var lastItemIndex = this.reportList.length;
        if (lastItemIndex > 0) {
          from(this.reportList) // Converts the array into an observable sequence
            .pipe(
              concatMap((report, index) => {
                if (report && (report as { formDate: string }).formDate !== undefined) {
                  const formDate = (report as { formDate: string }).formDate
                  const toDate = (report as { toDate: string }).toDate
                  const recordId = (report as { id: string }).id
                  console.log("report.formDate: ", formDate, "parameterdata.gd_From_Date: ", parameterdata.gd_From_Date, "report.toDate: ", toDate, "parameterdata.gd_To_Date: ", parameterdata.gd_To_Date, "this.formData.recordId: ", this.formData.recordId, "recordId: ", recordId);
                  return new Promise((resolve, reject) => {


                    if ((this.formData.recordId != recordId) && (formDate == parameterdata.gd_From_Date && toDate == parameterdata.gd_To_Date)) {
                      reject('duplicate');
                    } else if (this.formData.recordId == recordId) {
                      this.reportList[index].formDate = parameterdata.gd_From_Date;
                      this.reportList[index].toDate = parameterdata.gd_To_Date;
                      this.reportList[index].newReport = 1
                      reject('update');
                    } else {
                      resolve({ success: 'save', index });
                    }
                  })
                }
              }),
              catchError((err) => {
                console.error("Stopping loop:", err);
                if (err == 'duplicate') {
                  this.errorbox.text = "The selected date range overlaps with existing entries. Please choose a different range.";
                  this.errorbox.show();
                }
                if (err == 'update') {
                  this.addWWDReport(weeklyData, parameterdata, currentDate, false);
                  console.log("weeklyData: ", weeklyData);
                  this.add('reportList', this.reportList);
                }
                return 'response'; // Stops further processing
              })
            ).subscribe({
              next: (result) => {
                console.log(`[this.reportList] Successfully processed item at index: `, lastItemIndex, (result as { index: number }).index, 'result as { success: boolean }).success', (result as { success: string }).success);
                if (((result as { success: string }).success === 'save') && ((result as { index: number }).index === (lastItemIndex - 1))) {
                  console.log(`[this.reportList] save: `, result);
                  this.addWWDReport(weeklyData, parameterdata, currentDate, true);
                }
              }
            });
        } else {
          this.addWWDReport(weeklyData, parameterdata, currentDate, true);
        }
      } else if (formId == 81) {
        this.add('wwd_hseq_' + this.formData.recordId, parameterdataOffline);
        if (this.formData.recordId.includes('add_new_report')) {
          this.add('wwd_hseq_save_' + this.formData.recordId, weeklyData);
        } else {
          this.add('wwd_hseq_update_' + this.formData.recordId, weeklyData);
        }
      } else if (formId == 82) {
        this.add('wwd_technical_' + this.formData.recordId, parameterdataOffline);
        if (this.formData.recordId.includes('add_new_report')) {
          this.add('wwd_technical_save_' + this.formData.recordId, weeklyData);
        } else {
          this.add('wwd_technical_update_' + this.formData.recordId, weeklyData);
        }
      } else if (formId == 83) {
        this.add('wwd_mlc_' + this.formData.recordId, parameterdataOffline);
        if (this.formData.recordId.includes('add_new_report')) {
          this.add('wwd_mlc_save_' + this.formData.recordId, weeklyData);
        } else {
          this.add('wwd_mlc_update_' + this.formData.recordId, weeklyData);
        }
      }

    }
  }

  addWWDReport(weeklyData, parameterdata, currentDate, addNewReport = false) {
    if (this.formData.recordId) {
      if (this.formData.recordId.includes('add_new_report')) {
        var recordId: any = this.formData.recordId;
        this.add('wwd_general_save_' + recordId, weeklyData);
      } else {
        var recordId: any = this.formData.recordId;
        this.add('wwd_general_update_' + this.formData.recordId, weeklyData);
      }
    } else {
      var recordId: any = 'add_new_report' + currentDate.getTime();
      this.add('wwd_general_save_' + recordId, weeklyData);
    }
    var formDataLoad = {
      "data": {
        vesselId: weeklyData.data[0].vesselId,
        gd_From_Date: parameterdata.gd_From_Date,
        gd_To_Date: parameterdata.gd_To_Date,
        master_Name: parameterdata.master_Name,
        chief_Officer_Name: parameterdata.chief_Officer_Name,
        _id: recordId
      }
    };

    if (addNewReport) {
      var reportListArray = [{
        id: recordId,
        vesselName: this.vesselName,
        formDate: parameterdata.gd_From_Date,
        toDate: parameterdata.gd_To_Date,
        master: parameterdata.master_Name,
        chiefOfficer: parameterdata.chief_Officer_Name,
        formId: 80,
        vesselId: weeklyData.data[0].vesselId,
        status: 0,
        timeStamp: currentDate.getTime(),
        updated_at: currentDate.getTime(),
        newReport: 1
      }];
      this.reportList = reportListArray.concat(this.reportList);
    }
    this.add('reportList', this.reportList);

    this.updateParent.emit(formDataLoad);
  }

  addNewWWDReport(weeklyData, parameterdata, currentDate) {
    var recordId = this.addWWDReport(weeklyData, parameterdata, currentDate);
    var reportListArray = [{
      id: recordId,
      vesselName: this.vesselName,
      formDate: parameterdata.gd_From_Date,
      toDate: parameterdata.gd_To_Date,
      master: parameterdata.master_Name,
      chiefOfficer: parameterdata.chief_Officer_Name,
      formId: 80,
      vesselId: weeklyData.data[0].vesselId,
      status: 0,
      timeStamp: currentDate.getTime(),
      updated_at: currentDate.getTime(),
      newReport: 1
    }];
    this.reportList = reportListArray.concat(this.reportList);
    this.add('reportList', this.reportList);
  }

  toDateFilter = (date: Date | null): boolean => {
    if (!date || !this.fromDate) return true;
    return date >= this.fromDate;
  };

  saveFormDetail() {

    this.approval.title = 'Mark as completed';
    this.approval.text = 'Are you sure you want to mark this form as completed? Once you mark it as completed, you will not be able to make any changes.';
    this.approval.show();
  }

  getVesselDetailByVesselId(vesselid) {
    this.vts.getVesselDataById(vesselid, (vesselData) => {
      console.log("check-vessel-data", vesselData);
    })
  }

  private async compressImage(file: File): Promise<string> {
    const options = {
      maxSizeMB: .1,          // Maximum file size in MB
      maxWidthOrHeight: 800, // Maximum width or height
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);
      return await this.convertToBase64(compressedFile);
    } catch (error) {
      console.error('Error compressing the image:', error);
      throw error;
    }
  }

  private async convertToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  }

  calculateSizeInMB(): number {
    const jsonString = JSON.stringify(this.selectedImages); // Convert JSON to string
    const bytes = new TextEncoder().encode(jsonString).length; // Calculate byte length
    const sizeInMB = (bytes / (1024 * 1024)); // Convert to MB
    return sizeInMB;
  }
}
