import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from '../apiroot.service';

@Injectable({
  providedIn: 'root'
})

export class ReleasenoteService {

  formData = {section_id:''}

  constructor(public http:HttpClient,public service:ApiRootService) { }



  getVersionDetails(callback){
   return this.http.get(this.service.getBaseUrl()+'/getVersionDetails').subscribe(
      (data) => {
        console.log(data);
        callback(data);
    });

  }

}
