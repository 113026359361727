import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from './apiroot.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  formData = {id: '', name: '', email: '', organizationId: '', userName: '', password: ''};

  constructor(public http: HttpClient, public service: ApiRootService) { }

  postData(formData, callback) {
   return this.http.post(this.service.getBaseUrl() + '/saveUser', formData).subscribe(
      (data) => {
        console.log(data);
        callback(data);
    });

  }

  getData(callback) {
    return this.http.get(this.service.getBaseUrl() + '/getAllUsers').subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    ); }

    // getDataById(id,formData,callback){
    //   return this.http.get('http://192.168.0.2:8087/campbellservice/getfieldTypeByfieldTypeId/'+id,formData).subscribe(
    //     (data) =>{
    //       // console.log(data);
    //       callback(data);
    //     }
    //   )}

    getDataById(id, callback) {
      return this.http.get(this.service.getBaseUrl() + '/getfieldTypeByfieldTypeId/' + id).subscribe(
        (data) => {
          // console.log(data);
          callback(data);
        }
      ); }

    putData(id, formData, callback) {
      return this.http.put(this.service.getBaseUrl() + '/updateUserName/' + id + '/' + formData, '').subscribe((data) => {
        console.log(data);
        callback(data);
    }
  ); }

  deleteData(id) {
    return this.http.delete(this.service.getBaseUrl() + '/softDeleteUserById/' + id);
  }


  getOrgData(callback) {
    return this.http.get(this.service.getBaseUrl() + '/getAllOrganizations').subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    ); }

}
