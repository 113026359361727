import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from '../apiroot.service';
import { TreeviewItem } from 'ngx-treeview';

@Injectable({
  providedIn: 'root'
})
export class HierarchyTreeService {

  formData = {parentName:'',id:'',hName:'',parentid:'',image:'assets/media/logo/logo.png',mobileImageUrl:'assets/media/logo/logo.png',type:'',staticForm:''};
  
  constructor(public http:HttpClient,public service:ApiRootService) { }

  public getParentCategory(callback){
    return this.http.get(this.service.getBaseUrl()+'/getAllFolder').subscribe(
      (data) => {
        console.log(data);
        callback(data);
    });

  }
  getProducts(): TreeviewItem[] {
    const fruitCategory = new TreeviewItem({
        text: 'Fruit', value: 1, children: [
            { text: 'Apple', value: 11 },
            { text: 'Mango', value: 12 }
        ]
    });
    const vegetableCategory = new TreeviewItem({
        text: 'Vegetable', value: 2, children: [
            { text: 'Salad', value: 21 },
            { text: 'Potato', value: 22 }
        ]
    });
    vegetableCategory.children.push(new TreeviewItem({ text: 'Mushroom', value: 23, checked: false }));
    vegetableCategory.correctChecked(); // need this to make 'Vegetable' node to change checked value from true to false
    return [fruitCategory, vegetableCategory];
}
  public getParentChildTreeCategory(callback){
    return this.http.get(this.service.getBaseUrl()+'/getAllFolderWithChildInTree').subscribe(
      (data) => {
        console.log(data);
        callback(data);
    });

  }

  uploadImage(formData,callback){
    return this.http.post(this.service.getBaseUrl()+'/uploadFile',formData).subscribe((data)=>{
      callback(data);
    })

  }

  postData(formData,callback){

    return this.http.post(this.service.getBaseUrl()+'/saveFolder',formData).subscribe((data)=>{
      callback(data);
    })

  }

  deleteData(id){
    return this.http.delete(this.service.getBaseUrl()+'/softDeleteFolderStructureById/'+id);
  }

  putData(id,formData,callback){
    return this.http.put(this.service.getBaseUrl()+'/updateFolderById/'+id,formData).subscribe((data)=>{
      console.log(data);
      callback(data);
  }
)}
}
