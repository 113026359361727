import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { UserService } from '../user.service';
import { HttpClient } from '@angular/common/http';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  displayedColumns: string[] = ['id', 'name', 'email', 'organizationId', 'userName', 'edit', 'delete'];
  dataSource;

  myForm: FormGroup;
  afs: any;
  success: boolean;
  loading: boolean;
  data: any = [];
  fieldTypeId: any;
  product: any;
  public formData;
  orgName = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('deleteSwal', { static: false }) public deleteSwal: SwalComponent;
  @ViewChild('successbox', { static: true }) public successbox: SwalComponent;

  @ViewChild('errorbox', { static: true }) public errorbox: SwalComponent;

  constructor(public fb: FormBuilder, public userService: UserService,
    private http: HttpClient) {
    this.resetForm();
  }

  ngOnInit() {

    // this.getProductDetails(this.route.snapshot.params['id']);

    this.userService.getData((response) => {
      console.log(response);
      this.dataSource = new MatTableDataSource(response.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });

    this.userService.getOrgData((response) => {
      console.log(response);
      this.orgName = response.data;
      // this.dataSource.paginator = this.paginator;
      // this.dataSource.sort = this.sort;
    });

    this.myForm = this.fb.group({
      // 'name':new FormControl(null,[Validators.required,
      //   Validators.pattern('.*\\S.*[a-zA-Z]+$')]),
      'name': new FormControl(null, [
        Validators.required,
        // Validators.maxLength(4),
        // Validators.minLength(2),
        Validators.pattern('^[a-zA-Z ]*$')
      ]),
      // 'email':new FormControl(null,[Validators.required,Validators.email,
      //   Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-zA-Z]{2,4}$')]),
      'email': new FormControl(null, [Validators.required, Validators.email,
      Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')]),
      // [a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}
      'organizationId': new FormControl(null, Validators.required),
      'userName': new FormControl(null, Validators.required),
      'password': new FormControl(null, [Validators.required,
      Validators.pattern('[a-zA-Z0-9._@%+-]+$')]),
      //  'confirmPassword':['', [Validators.required, Validators.minLength(6)]],


    });
  }



  submitHandler() {

    if (this.myForm.valid) {
      console.log(this.myForm.value);
      if (this.userService.formData.id) {
        this.userService.putData(this.userService.formData.id, this.userService.formData, (data) => {
          console.log(data);
          if (data.responsecode === 200 || data.responsecode === '200') {
          this.userService.getData((response) => {
            console.log(response);
            this.dataSource = new MatTableDataSource(response.data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.successbox.show();

              this.resetForm();
            });
          } else {
            this.errorbox.text = data.message;
            this.errorbox.show();
          }
        });
      } else {

        this.userService.postData(this.userService.formData, (response) => {
          console.log(response);

          if (response.responsecode === 200 || response.responsecode === '200') {

          this.userService.getData((response) => {
            console.log(response);
            this.dataSource = new MatTableDataSource(response.data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.successbox.show();

            this.resetForm();
          });
        } else {
          this.errorbox.text = response.message;
          this.errorbox.show();
        }
          //  console.log(response);
        });
        // }

      }
    }
  }

  // getProductDetails(id) {
  //   this.fts.getDataById(id,(response)=>{
  //     console.log(response);
  //     this.product = response;
  //   })
  // }

  onClick(action, data) {
    if (action == 'edit') {
      this.userService.formData.userName = data.userName;
      this.userService.formData.id = data.id;
      this.userService.formData.name = data.name;
      this.userService.formData.organizationId = data.organizationId;
      this.userService.formData.email = data.email;
    } else {
      this.userService.deleteData(data.id).subscribe((data) => {
        console.log('Deleted');
        this.userService.getData((response) => {
          console.log(response);
          this.dataSource = new MatTableDataSource(response.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        });
      });
    }
  }
  resetForm() {
    // this.myForm.reset();
    this.userService.formData.userName = '';
    this.userService.formData.id = '';
    this.userService.formData.name = '';
    this.userService.formData.organizationId = '';
    this.userService.formData.email = '';
  }

}
