import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { SectionsService } from './sections.service';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-sections',
  templateUrl: './sections.component.html',
  styleUrls: ['./sections.component.scss']
})
export class SectionsComponent implements OnInit {
  private  filterSubject = new Subject<string>();
  displayedColumns: string[] = ['sectionId', 'image', 'mobileimage', 'sectionName', 'parentid', 'edit'];
  dataSource
  isLoading = true;
  myForm: FormGroup;
  afs: any;
  success: boolean;
  loading: boolean;
  data: any = [];
  sectionId: any;
  product: any;
  public formData;
  sectionData;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('deleteSwal', { static: false }) public deleteSwal: SwalComponent;
  @ViewChild('successbox', { static: true }) public successbox: SwalComponent;

  @ViewChild('errorbox', { static: true }) public errorbox: SwalComponent;

  @ViewChild('image1', { static: false }) image1: ElementRef;
  @ViewChild('image2', { static: false }) image2: ElementRef;

  constructor(public fb: FormBuilder, public sectionsService: SectionsService,
    public http: HttpClient) {
    this.resetForm();
    this.filterSubject.pipe(debounceTime(300)).subscribe(value => {
      this.applyFilter(value);
    })
  }

  ngOnInit() {

    this.sectionsService.getData((response) => {
      console.log(response);
      this.isLoading = false;
      this.dataSource = new MatTableDataSource(response.data);
      this.sectionData = response.data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    })

    this.myForm = this.fb.group({
      'sectionName': new FormControl(null, Validators.required),
      'parentid': new FormControl(null, Validators.required),
      'image': new FormControl(null, Validators.required),
      'mobileImageUrl': new FormControl(null, [Validators.required])

    })
  }

  doFilter(value: string): void {
    this.filterSubject.next(value.trim().toLowerCase());
  }

  private applyFilter(value: string): void {
    this.dataSource.filter = value || '';
  }

  uploadImage(event) {

    const fileInfo = document.querySelector('.file-info');
    const name = event.target.value.split(/\\|\//).pop();
    const truncated = name.length > 20
      ? name.substr(name.length - 20)
      : name;

    fileInfo.innerHTML = truncated;

    if (event.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      console.log(event.target.files);

      this.sectionsService.uploadImage(formData, (response) => {
        // console.log(response);
        if (response.responsecode == 200) {
          console.log(response);
          this.sectionsService.formData.image = response.fileurl;

          this.myForm.get('image').setValue(response.fileurl);
        }
      })
      // console.log(this.service.uploadDocumentsDriver);
    }
  }


  uploadMobileImage(event) {

    const fileInfo = document.querySelector('.file-info1');
    const name = event.target.value.split(/\\|\//).pop();
    const truncated = name.length > 20
      ? name.substr(name.length - 20)
      : name;

    fileInfo.innerHTML = truncated;

    if (event.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      console.log(event.target.files);

      this.sectionsService.uploadImage(formData, (response) => {
        // console.log(response);
        if (response.responsecode == 200) {
          console.log(response);

          this.sectionsService.formData.mobileImageUrl = response.fileurl;
          this.myForm.get('mobileImageUrl').setValue(response.fileurl);


        }
      })
      // console.log(this.service.uploadDocumentsDriver);
    }
  }

  submitHandler() {
    console.log(this.myForm);
    console.log(this.sectionsService.formData);
    if (this.myForm.valid) {
      console.log(this.myForm.value);
      if (this.sectionsService.formData.sectionId) {
        this.sectionsService.putData(this.sectionsService.formData.sectionId, this.sectionsService.formData, (data) => {
          console.log(data);
          if (data.responsecode === 200 || data.responsecode === '200') {
            this.sectionsService.getData((response) => {
              console.log(response);
              this.dataSource = new MatTableDataSource(response.data);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.successbox.show();
              this.resetForm();
              this.myForm.reset();

              //       this.sectionsService.formData.sectionId = "";
              // this.sectionsService.formData.sectionName =  "";
              // this.sectionsService.formData.parentid =  "";
            })
          } else {
            this.errorbox.text = data.message;
            this.errorbox.show();
          }
        })
      }
      else {

        this.sectionsService.postData(this.myForm.value, (response) => {
          if (response.responsecode === 200 || response.responsecode === '200') {
            this.sectionsService.getData((response) => {
              console.log(response);
              this.dataSource = new MatTableDataSource(response.data);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.successbox.show();
              this.resetForm();
              this.myForm.reset();

              // this.sectionsService.formData.sectionId = "";
              // this.sectionsService.formData.sectionName =  "";
              // this.sectionsService.formData.parentid =  "";
            })
            //  console.log(response);
          } else {
            this.errorbox.text = response.message;
            this.errorbox.show();
          }
        })
      }

    }
  }

  // getProductDetails(id) {
  //   this.fieldTypeService.getDataById(id,(response)=>{
  //     console.log(response);
  //     this.product = response;
  //   })
  // }

  onClick(action, data) {
    if (action == 'edit') {
      this.sectionsService.formData.sectionId = data.sectionId;
      this.sectionsService.formData.sectionName = data.sectionName;
      this.sectionsService.formData.parentid = data.parentid;
      if (data.image) {
        this.sectionsService.formData.image = data.image;

      } else {
        this.sectionsService.formData.image = 'assets/media/logo/logo.png';

      }

      if (data.mobileImageUrl) {
        this.sectionsService.formData.mobileImageUrl = data.mobileImageUrl;

      } else {
        this.sectionsService.formData.mobileImageUrl = 'assets/media/logo/logo.png';

      }


      this.myForm.get('image').setValue(this.sectionsService.formData.image);
      this.myForm.get('mobileImageUrl').setValue(this.sectionsService.formData.mobileImageUrl);
    } else {
      this.sectionsService.deleteData(data.sectionId).subscribe((data) => {
        console.log('Deleted');
        this.sectionsService.getData((response) => {
          console.log(response);
          this.dataSource = new MatTableDataSource(response.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;

        })
      })
    }
    window.scroll(0,0);

  }

  resetForm() {
    // this.myForm.reset();
    this.sectionsService.formData.sectionId = "";
    this.sectionsService.formData.sectionName = "";
    this.sectionsService.formData.parentid = "";
    this.sectionsService.formData.image = 'assets/media/logo/logo.png';
    this.sectionsService.formData.mobileImageUrl = 'assets/media/logo/logo.png';

    if(document.querySelector('.file-info')){
      const fileInfo = document.querySelector('.file-info');
      fileInfo.innerHTML = "";
    }
    if(document.querySelector('.file-info')){
      const fileInfo1 = document.querySelector('.file-info1');
      fileInfo1.innerHTML = "";
    }

  }


}
