import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from '../apiroot.service';

@Injectable({
  providedIn: 'root'
})
export class CrewService {

  // url : String = "http://192.168.0.39:8087/campbellservice";


  formData = { id: '', companyId: '', profileImage: 'assets/media/logo/logo.png', crewNumber: '', firstName: '', middleName: '', lastName: '', email: '', userName: '', password: '', dateOfBirth: '', roleId: '', nationality: '', gender: '', signatureImage: 'assets/media/logo/logo.png', signatureData: '', active: '' }

  constructor(public http: HttpClient, public service: ApiRootService) { }


  postData(formData, callback) {
    return this.http.post(this.service.getBaseUrl() + '/saveCrew', formData).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      });

  }

  uploadImage(formData, callback) {
    return this.http.post(this.service.getBaseUrl() + '/uploadFile', formData).subscribe((data) => {
      callback(data);
    })

  }

  getData(callback) {
    return this.http.get(this.service.getBaseUrl() + '/getAllCrew').subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }

  getDataByCompanyId(id, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getAllCrewByCompanyId/' + id).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }

  // getDataById(id,formData,callback){
  //   return this.http.get('http://192.168.0.2:8087/campbellservice/getfieldTypeByfieldTypeId/'+id,formData).subscribe(
  //     (data) =>{
  //       // console.log(data);
  //       callback(data);
  //     }
  //   )}

  getDataById(id, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getCrewByCrewId/' + id).subscribe(
      (data) => {
        // console.log(data);
        callback(data);
      }
    )
  }

  putData(id, formData, callback) {
    return this.http.put(this.service.getBaseUrl() + '/updateCrew/' + id, formData).subscribe((data) => {
      console.log(data);
      callback(data);
    }
    )
  }

  deleteData(id) {
    return this.http.delete(this.service.getBaseUrl() + '/softDeleteCrewById/' + id);
  }


  getAllCrewVesselAssignmentData(callback) {
    return this.http.get(this.service.getBaseUrl() + '/getAllCrewVesselAssignment').subscribe(
      (data) => {
        callback(data);
      }
    )
  }

  // unassignCrew(crewMemberId: number, assignedVesselId: number, callback) {
  //   return this.http.put(this.service.getBaseUrl() + '/crewVesselUnAssignmentById/' + crewMemberId, assignedVesselId).subscribe((data) => {
  //     console.log(data);
  //     callback(data);
  //   }
  //   )
  // }

  // unassignCrew(crewMemberId: number, assignedVesselId: number, callback) {
  //   const url = this.service.getBaseUrl() + `/crewVesselUnAssignmentById/${crewMemberId}/${assignedVesselId}`;
  //   return this.http.put(url, null).subscribe(
  //     (data) => {
  //       console.log(data);
  //       callback(data);
  //     },
  //     (error) => {
  //       console.error(error);
  //       callback(error);
  //     }
  //   );
  // }

  unassignCrew(id: number, callback) {
    const url = `${this.service.getBaseUrl()}/crewVesselUnAssignmentById/${id}`;
    return this.http.delete(url).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      },
      (error) => {
        console.error(error);
        callback(error);
      }
    );
  }
  


}
