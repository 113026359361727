import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from '../apiroot.service';

@Injectable({
  providedIn: 'root'
})
export class FieldvalidationService {

  formTypeData = {id:'' , formId:'' , fieldId:'' ,validationId:'' ,value:'',fields:''}

  // url : String = "http://192.168.0.58:8087/campbellservice/";


  constructor(public httpClient : HttpClient,public service:ApiRootService) { }

  postData(formData,callback){
    return this.httpClient.post(this.service.getBaseUrl()+'/saveFieldValidation',formData).subscribe((data)=>{
      console.log(data);
    callback(data);
    })
  }

  getFieldData(id,callback){
    return this.httpClient.get(this.service.getBaseUrl()+'/getFormFieldListsByFormId/'+id).subscribe(
      (data) =>{
        callback(data);
      }
    )
  }

  getData(callback){
    return this.httpClient.get(this.service.getBaseUrl()+'/getAllFieldValidation').subscribe(
      (data) =>{
        callback(data);
      }
    )
  }

  getDataById(id,callback){
    return this.httpClient.get(this.service.getBaseUrl()+'/getFieldValidationByFieldValidationId/'+id).subscribe(
      (data) =>{
        // console.log(data);
        callback(data);
      }
    )}

  // editData(formData,callback){
  //   return this.httpClient.get("http://localhost:3000/user/"+formData).subscribe((data) => {
  //     console.log(data);
  //     callback(data);

  //   })
  // }

  deleteData(id){
     return this.httpClient.delete(this.service.getBaseUrl()+'/softDeleteFieldValidation/'+id);
   
  }

  putData(id,formData,callback){
    return this.httpClient.put(this.service.getBaseUrl()+'/updateFieldValidation/'+id,formData).subscribe((data)=>{
      console.log(data);
      callback(data);

    })
  }
}
