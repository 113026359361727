import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator, MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl, ReactiveFormsModule, FormArray } from '@angular/forms';
import { QuestionsectionComponent } from '../../questionsection/questionsection.component';
import { QuestionsectionformsService } from '../../questionsectionforms/questionsectionforms.service';
import { QuestionformsService } from '../../questionforms/questionforms.service';
import { HttpClient } from '@angular/common/http';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { SessionStorageService } from 'angular-web-storage';
import { ActivatedRoute, RouterModule, Routes, Router } from '@angular/router';
import { FormreportsService } from '../formreports.service';
import { CrewService } from "src/app/crew/crew.service";
import { DatePipe } from "@angular/common";
import { createWriteStream } from 'fs';
import { FormsModule } from '@angular/forms';
import {MatExpansionModule} from '@angular/material/expansion';

@Component({
  selector: 'report-formpage',
  templateUrl: './formpage.component.html',
  styleUrls: ['./formpage.component.scss']
})
export class FormpageComponent implements OnInit {
  @Input() formData: any;
  @Output() updateParent = new EventEmitter();
  dataForEdit: any;
  dynamicForm: FormGroup;
  form: FormGroup;
  isDataBind: boolean = false;
  isAddNewQuestion: boolean;
  formansweradditioninfomodel: any = [];
  @ViewChild('successbox', { static: true }) public successbox: SwalComponent;
  @ViewChild('errorbox', { static: true }) public errorbox: SwalComponent;
  @ViewChild('noData', { static: true }) public noData: SwalComponent;
  @ViewChild('approval', { static: true }) public approval: SwalComponent;
  @ViewChild('fileInfoElement', { static: false }) fileInfoElement: ElementRef;
  items: any[] = ['item1', 'item2', 'item3'];
  fileFormData: FormData;
  selectedVesselId: number;
  voyageNo: any;
  userId: any;
  currentActionDate: any;
  currentActionTime: any;
  authurl: any = this.session.get('Authurl');
  count: number = 0;
  imageItem: any[] = [];
  loading; boolean;
  confirmdetail: boolean;
  addInfoDetail: any;
  existingObjectId: any;
  infoFormId; number;
  groupedDataArray;
  globalIndex = 0;
  panelStates: boolean[] = [];

  constructor(private router: Router, private route: ActivatedRoute, public qs: QuestionformsService, private formBuilder: FormBuilder, public session: SessionStorageService, private dialog: MatDialog, private questionsectionformsService: QuestionsectionformsService, public formreportsService: FormreportsService, public crewService: CrewService, public datePipe: DatePipe,) {
    this.form = this.formBuilder.group({
      items: this.formBuilder.array([]),
      additionalItems: this.formBuilder.array([])
      
    });
    this.panelStates = []
  }

  ngOnInit() {

    console.log("check=formdata=formadd", this.formData)

    let currentDate = new Date();
    this.userId = this.session.get('userid');

    if (currentDate) //audits inspections
    {
      this.currentActionDate = (this.datePipe.transform(currentDate, 'dd-MMM-yyyy'));
      this.currentActionTime = (this.datePipe.transform(currentDate, 'HH:mm'));
    }

    if (this.formData.isAddNewQuestion && this.formData.id) {
      console.log("working-if");
      console.log("check-this.formData", this.formData);
      this.infoFormId = this.formData.id;
      this.isAddNewQuestion = this.formData.isAddNewQuestion;
      this.selectedVesselId = this.formData.vesselId;
      this.getAdInfoFormData(this.formData, this.formData.id);
      this.getFormData(this.formData, this.formData.id);
    } else {
      console.log("working-else");
      let elemData = this.formData;
      console.log("check-elemData", elemData);
      this.infoFormId = this.formData.id;
      this.isAddNewQuestion = elemData.isAddNewQuestion;
      this.selectedVesselId = elemData.vesselId;
      const vesselId = elemData.vesselId;
      delete elemData['isAddNewQuestion'];
      // delete elemData['vesselId'];
      this.getAdInfoFormData(elemData, vesselId);
      this.getFormData(elemData, vesselId);
      console.log("check-elemData", elemData);
    }

    // Example: Adding a new form control to additionalItems on init
    // this.addAdditionalItems();

    console.log("check-form-grop-oninit", this.form);
    console.log("check-form-control-formValues", this.form.controls.items.value[0]);
    console.log("check-formcontrols", this.form.controls.items.value);


  }

  closeForm() {
    this.isDataBind = false;
    this.formData = null;
    this.dataForEdit = null;
  }

  generateUniqueId() {
    return Math.random().toString(36).slice(2, 11) + Date.now().toString();
  }

  getFormData(forms: any, vesselId: any) {
    console.log("check-getFormData-vesselId", vesselId);
    if (forms.isAddNewQuestion) {
      console.log("if condition is working");
      this.formreportsService.getFormsQuestionDetailsByFormsId(vesselId, (response) => {
        if (response.responsecode === 200 && response.data.length > 0) {
          this.dataForEdit = response.data;
          console.log('this.dataForEdit: ', this.dataForEdit);
          // Grouping data
          this.groupedDataArray = Object.values(this.groupFormData(this.dataForEdit));
          console.log('Grouped Data isAddNewQuestion true: ', this.groupedDataArray);
          if (this.dataForEdit && this.dataForEdit.length > 0) {
            this.voyageNo = this.dataForEdit[0].voyageNo;
            var oldSectionId = '';
            this.dataForEdit = this.dataForEdit.map((item, index) => {
              if (item.sectionId != oldSectionId) {
                oldSectionId = item.sectionId;
              } else {
                item.sectionName = '';
              }

              let additionInfo = {
                actionDate: this.isAddNewQuestion ? this.currentActionDate : item.actionDate,
                actionTime: this.isAddNewQuestion ? this.currentActionTime : item.actionTime,
                formId: item.formId,
                formInfoId: item.formId,
                infoDetails: null,
                infoLabel: null,
                vesselId: this.selectedVesselId
              }
              this.formansweradditioninfomodel.push(additionInfo);
              item.objectId = null;
              item.questionId = item.questionId ? item.questionId : item.id;
              item.formId = vesselId;
              item.id = null;

              if (item.controlValue && item.controlType !== 'Checkbox') {
                item.controlValueArray = this.mapToKeyValueIndexArray(item.controlValue);
              }

              if (item.controlValue && item.controlType === 'Checkbox') {
                if (this.isAddNewQuestion) {
                  item.answer = null;
                }
                const array1 = this.mapToKeyValueIndexArray(item.controlValue);
                const array2 = item.answer ? this.mapToKeyValueIndexArray(item.answer) : [];

                // Iterate through array2
                array2.forEach(item2 => {
                  array1.forEach(item1 => {
                    if (item2.value.trim() == item1.value.trim()) {
                      item1.isChecked = true;
                    }
                  });
                });

                item.controlValueArray = array1;
              }

              if (item.imagesData) {
                const values = item.imagesData.split(',');
                const result = [];
                if (this.isAddNewQuestion) {
                  item.imagesDataArray = [];
                  this.addNewImage(item.imagesDataArray, 0);
                } else {
                  item.imagesDataArray = this.mapToKeyValueIndexArray(item.imagesData);
                }
              }

              if (item.answer) {
                switch (item.controlType) {
                  case 'TimePicker':
                    item.answer = item.answer;
                    break;
                  case 'DatePicker':
                    item.answer = item.answer;
                    break;
                  // Add more cases as needed
                }
              }

              if (item) {
                switch (item.controlType) {
                  case 'TextBox':
                    let textBoxGroup = this.formBuilder.group({
                      // actionDate: ['', Validators.required],
                      actionDate: [this.isAddNewQuestion ? this.currentActionDate : item.actionDate],
                      actionTime: [this.isAddNewQuestion ? this.currentActionTime : item.actionTime],
                      answer: [!this.isAddNewQuestion ? item.answer : null],
                      formId: [item.formId],
                      imagesData: [item.imagesData],
                      imagesDataLocal: [null],
                      questionId: [item.questionId],
                      sectionId: [item.sectionId],
                      rowId: [item.rowId],
                      controlType: [item.controlType],
                      changeQuestionIds: [null]
                    });
                    this.itemsFormArray.push(textBoxGroup);
                    if (this.isAddNewQuestion) { item.answer = null; }
                    break;
                  case 'Text':
                    let textGroup = this.formBuilder.group({
                      // actionDate: ['', Validators.required],
                      actionDate: [this.isAddNewQuestion ? this.currentActionDate : item.actionDate],
                      actionTime: [this.isAddNewQuestion ? this.currentActionTime : item.actionTime],
                      answer: [!this.isAddNewQuestion ? item.answer : null],
                      formId: [item.formId],
                      imagesData: [item.imagesData],
                      imagesDataLocal: [null],
                      questionId: [item.questionId],
                      sectionId: [item.sectionId],
                      rowId: [item.rowId],
                      controlType: [item.controlType],
                      changeQuestionIds: [null]
                    });
                    this.itemsFormArray.push(textGroup);
                    if (this.isAddNewQuestion) { item.answer = null; }
                    break;
                  case 'TextArea':
                    let textareaGroup = this.formBuilder.group({
                      actionDate: [this.isAddNewQuestion ? this.currentActionDate : item.actionDate],
                      actionTime: [this.isAddNewQuestion ? this.currentActionTime : item.actionTime],
                      answer: [!this.isAddNewQuestion ? item.answer : null],
                      formId: [item.formId],
                      imagesData: [item.imagesData],
                      imagesDataLocal: [null],
                      questionId: [item.questionId],
                      sectionId: [item.sectionId],
                      rowId: [item.rowId],
                      controlType: [item.controlType],
                      changeQuestionIds: [null]
                    });
                    this.itemsFormArray.push(textareaGroup);
                    if (this.isAddNewQuestion) { item.answer = null; }
                    break;
                  case 'Radio':
                    let radioGroup = this.formBuilder.group({
                      actionDate: [this.isAddNewQuestion ? this.currentActionDate : item.actionDate],
                      actionTime: [this.isAddNewQuestion ? this.currentActionTime : item.actionTime],
                      answer: [!this.isAddNewQuestion ? item.answer : null],
                      formId: [item.formId],
                      imagesData: [item.imagesData],
                      imagesDataLocal: [null],
                      questionId: [item.questionId],
                      sectionId: [item.sectionId],
                      rowId: [item.rowId],
                      controlType: [item.controlType],
                      changeQuestionIds: [null]
                    });
                    this.itemsFormArray.push(radioGroup);
                    if (this.isAddNewQuestion) { item.answer = null; }
                    break;
                  case 'DropDown':
                    let dropdownGroup = this.formBuilder.group({
                      actionDate: [this.isAddNewQuestion ? this.currentActionDate : item.actionDate],
                      actionTime: [this.isAddNewQuestion ? this.currentActionTime : item.actionTime],
                      answer: [!this.isAddNewQuestion ? item.answer : null],
                      formId: [item.formId],
                      imagesData: [item.imagesData],
                      imagesDataLocal: [null],
                      questionId: [item.questionId],
                      sectionId: [item.sectionId],
                      rowId: [item.rowId],
                      controlType: [item.controlType],
                      changeQuestionIds: [null]
                    });
                    this.itemsFormArray.push(dropdownGroup);
                    if (this.isAddNewQuestion) { item.answer = null; }
                    break;

                  case 'DatePicker':
                    let datePicGroup = this.formBuilder.group({
                      actionDate: [this.isAddNewQuestion ? this.currentActionDate : item.actionDate],
                      actionTime: [this.isAddNewQuestion ? this.currentActionTime : item.actionTime],
                      answer: [!this.isAddNewQuestion ? item.answer : null],
                      formId: [item.formId],
                      imagesData: [item.imagesData],
                      imagesDataLocal: [null],
                      questionId: [item.questionId],
                      sectionId: [item.sectionId],
                      rowId: [item.rowId],
                      controlType: [item.controlType],
                      changeQuestionIds: [null]
                    });
                    this.itemsFormArray.push(datePicGroup);
                    if (this.isAddNewQuestion) { item.answer = null; }
                    break;

                  case 'Checkbox':
                    let checkboxGroup = this.formBuilder.group({
                      actionDate: [this.isAddNewQuestion ? this.currentActionDate : item.actionDate],
                      actionTime: [this.isAddNewQuestion ? this.currentActionTime : item.actionTime],
                      answer: [!this.isAddNewQuestion ? item.answer : null],
                      formId: [item.formId],
                      imagesData: [item.imagesData],
                      imagesDataLocal: [null],
                      questionId: [item.questionId],
                      sectionId: [item.sectionId],
                      rowId: [item.rowId],
                      controlType: [item.controlType],
                      changeQuestionIds: [null]
                    });
                    this.itemsFormArray.push(checkboxGroup);
                    if (this.isAddNewQuestion) { item.answer = null; }
                    break;

                  case 'TimePicker':
                    let timePicGroup = this.formBuilder.group({
                      actionDate: [this.isAddNewQuestion ? this.currentActionDate : item.actionDate],
                      actionTime: [this.isAddNewQuestion ? this.currentActionTime : item.actionTime],
                      answer: [!this.isAddNewQuestion ? item.answer : null],
                      formId: [item.formId],
                      imagesData: [item.imagesData],
                      imagesDataLocal: [null],
                      questionId: [item.questionId],
                      sectionId: [item.sectionId],
                      rowId: [item.rowId],
                      controlType: [item.controlType],
                      changeQuestionIds: [null]
                    });
                    this.itemsFormArray.push(timePicGroup);
                    if (this.isAddNewQuestion) { item.answer = null; }
                    break;

                  case 'ImagePicker':
                    let imgGroup = this.formBuilder.group({
                      actionDate: [this.isAddNewQuestion ? this.currentActionDate : item.actionDate],
                      actionTime: [this.isAddNewQuestion ? this.currentActionTime : item.actionTime],
                      answer: [!this.isAddNewQuestion ? item.answer : null],
                      formId: [item.formId],
                      imagesData: [item.imagesData],
                      imagesDataLocal: [null],
                      questionId: [item.questionId],
                      sectionId: [item.sectionId],
                      rowId: [item.rowId],
                      controlType: [item.controlType],
                      changeQuestionIds: [null]
                    });
                    this.itemsFormArray.push(imgGroup);
                    if (this.isAddNewQuestion) { item.answer = null; }
                    break;
                }
              }
              this.isDataBind = true;
              return item;
            });
          }
        } else {
          this.noData.show();
          this.isDataBind = false;
          this.dataForEdit = null;
        }
      });
    } else {
      console.log("else condition is working");
      this.formreportsService.getFormAnswerDetailsByFormIdAndDateWithTimev1(forms, vesselId, (response) => {
        if (response.responsecode === 200 && response.data.length > 0) {
          console.log("check-whole-response", response);
          this.dataForEdit = response.data;
          this.existingObjectId = response.data[0].objectId;
          console.log("check-existingObjectId", this.existingObjectId);
          console.log('dataForEdit: ', this.dataForEdit);
          // Grouping Data 
          this.groupedDataArray = Object.values(this.groupFormData(this.dataForEdit));
          console.log('Grouped Data isAddNewQuestion false: ', this.groupedDataArray);
          if (this.dataForEdit && this.dataForEdit.length > 0) {
            this.voyageNo = this.dataForEdit[0].voyageNo;
            var oldSectionId = '';
            this.dataForEdit = this.dataForEdit.map((item, index) => {
              if (item.sectionId != oldSectionId) {
                oldSectionId = item.sectionId;
              } else {
                item.sectionName = '';
              }

              let additionInfo = {
                actionDate: item.actionDate,
                actionTime: item.actionTime,
                formId: item.formId,
                formInfoId: item.formId,
                infoDetails: null,
                infoLabel: null,
                vesselId: item.vesselId,
              }
              this.formansweradditioninfomodel.push(additionInfo);
              item.objectId = null;
              item.id = null;

              if (item.controlValue && item.controlType !== 'Checkbox') {
                item.controlValueArray = this.mapToKeyValueIndexArray(item.controlValue);
              }

              if (item.controlValue && item.controlType === 'Checkbox') {
                const array1 = this.mapToKeyValueIndexArray(item.controlValue);
                const array2 = item.answer ? this.mapToKeyValueIndexArray(item.answer) : [];

                // Iterate through array2
                array2.forEach(item2 => {
                  array1.forEach(item1 => {
                    if (item2.value.trim() == item1.value.trim()) {
                      item1.isChecked = true;
                    }
                  });
                });

                item.controlValueArray = array1;
              }

              if (item.imagesData) {
                const values = item.imagesData.split(',');
                const result = [];
                item.imagesDataArray = this.mapToKeyValueIndexArray(item.imagesData);
              }

              if (item.answer) {
                switch (item.controlType) {
                  case 'TimePicker':
                    item.answer = item.answer;
                    break;

                  case 'DatePicker':
                    if (item.answer) {
                      var months = {
                        "Jan": 0, "Feb": 1, "Mar": 2, "Apr": 3, "May": 4, "Jun": 5,
                        "Jul": 6, "Aug": 7, "Sep": 8, "Oct": 9, "Nov": 10, "Dec": 11
                      };

                      var parts = item.answer.split('-');
                      var day = parseInt(parts[0]);
                      var month = months[parts[1]];
                      var year = parseInt(parts[2]);

                      var formattedDate = new Date(Date.UTC(year, month, day));

                      item.answer = formattedDate;
                    }
                    break;
                  // Add more cases as needed
                }
              }

              if (item) {
                switch (item.controlType) {
                  case 'TextBox':
                    let textBoxGroup = this.formBuilder.group({
                      actionDate: [item.actionDate],
                      actionTime: [item.actionTime],
                      answer: [item.answer],
                      formId: [item.formId],
                      imagesData: [item.imagesData],
                      imagesDataLocal: [null],
                      questionId: [item.questionId],
                      sectionId: [item.sectionId],
                      rowId: [item.rowId],
                      controlType: [item.controlType],
                      changeQuestionIds: [null]
                    });
                    this.itemsFormArray.push(textBoxGroup);
                    break;

                  case 'Text':
                    let textGroup = this.formBuilder.group({
                      actionDate: [item.actionDate],
                      actionTime: [item.actionTime],
                      answer: [item.answer],
                      formId: [item.formId],
                      imagesData: [item.imagesData],
                      imagesDataLocal: [null],
                      questionId: [item.questionId],
                      sectionId: [item.sectionId],
                      rowId: [item.rowId],
                      controlType: [item.controlType],
                      changeQuestionIds: [null]
                    });
                    this.itemsFormArray.push(textGroup);
                    break;

                  case 'TextArea':
                    let textareaGroup = this.formBuilder.group({
                      actionDate: [item.actionDate],
                      actionTime: [item.actionTime],
                      answer: [item.answer],
                      formId: [item.formId],
                      imagesData: [item.imagesData],
                      imagesDataLocal: [null],
                      questionId: [item.questionId],
                      sectionId: [item.sectionId],
                      rowId: [item.rowId],
                      controlType: [item.controlType],
                      changeQuestionIds: [null]
                    });
                    this.itemsFormArray.push(textareaGroup);
                    break;

                  case 'Radio':
                    let radioGroup = this.formBuilder.group({
                      actionDate: [item.actionDate],
                      actionTime: [item.actionTime],
                      answer: [item.answer],
                      formId: [item.formId],
                      imagesData: [item.imagesData],
                      imagesDataLocal: [null],
                      questionId: [item.questionId],
                      sectionId: [item.sectionId],
                      rowId: [item.rowId],
                      controlType: [item.controlType],
                      changeQuestionIds: [null]
                    });
                    this.itemsFormArray.push(radioGroup);
                    break;

                  case 'DropDown':
                    let dropdownGroup = this.formBuilder.group({
                      actionDate: [item.actionDate],
                      actionTime: [item.actionTime],
                      answer: [item.answer],
                      formId: [item.formId],
                      imagesData: [item.imagesData],
                      imagesDataLocal: [null],
                      questionId: [item.questionId],
                      sectionId: [item.sectionId],
                      rowId: [item.rowId],
                      controlType: [item.controlType],
                      changeQuestionIds: [null]
                    });
                    this.itemsFormArray.push(dropdownGroup);
                    break;
                  case 'DatePicker':
                    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                    const date = new Date(item.answer);
                    console.log("getformdata-date", date);
                    const day = date.getDate();
                    const monthIndex = date.getMonth();
                    const year = date.getFullYear();
                    const newDate = `${day}-${months[monthIndex]}-${year}`;
                    let datePicGroup = this.formBuilder.group({
                      actionDate: [item.actionDate],
                      actionTime: [item.actionTime],
                      answer: [newDate],
                      formId: [item.formId],
                      imagesData: [item.imagesData],
                      imagesDataLocal: [null],
                      questionId: [item.questionId],
                      sectionId: [item.sectionId],
                      rowId: [item.rowId],
                      controlType: [item.controlType],
                      changeQuestionIds: [null]
                    });
                    this.itemsFormArray.push(datePicGroup);
                    break;

                  case 'Checkbox':
                    let checkboxGroup = this.formBuilder.group({
                      actionDate: [item.actionDate],
                      actionTime: [item.actionTime],
                      answer: [item.answer],
                      formId: [item.formId],
                      imagesData: [item.imagesData],
                      imagesDataLocal: [null],
                      questionId: [item.questionId],
                      sectionId: [item.sectionId],
                      rowId: [item.rowId],
                      controlType: [item.controlType],
                      changeQuestionIds: [null]
                    });
                    this.itemsFormArray.push(checkboxGroup);
                    break;

                  case 'TimePicker':
                    let timePicGroup = this.formBuilder.group({
                      actionDate: [item.actionDate],
                      actionTime: [item.actionTime],
                      answer: [item.answer],
                      formId: [item.formId],
                      imagesData: [item.imagesData],
                      imagesDataLocal: [null],
                      questionId: [item.questionId],
                      sectionId: [item.sectionId],
                      rowId: [item.rowId],
                      controlType: [item.controlType],
                      changeQuestionIds: [null]
                    });
                    this.itemsFormArray.push(timePicGroup);
                    break;

                  case 'ImagePicker':
                    let imgGroup = this.formBuilder.group({
                      // actionDate: ['', Validators.required],
                      actionDate: [item.actionDate],
                      actionTime: [item.actionTime],
                      answer: [item.answer],
                      formId: [item.formId],
                      imagesData: [item.imagesData],
                      imagesDataLocal: [null],
                      questionId: [item.questionId],
                      sectionId: [item.sectionId],
                      rowId: [item.rowId],
                      controlType: [item.controlType],
                      changeQuestionIds: [null]
                    });
                    this.itemsFormArray.push(imgGroup);
                    break;
                }
              }
              this.isDataBind = true;
              return item;
            });
            console.log('dataForEdit1: ', this.dataForEdit);
          }
        } else {
          this.noData.show();
          this.isDataBind = false;
          this.dataForEdit = null;
        }
      });
    }
  }

  // groupFormData(data: any[]) {
  //   return data.reduce((acc, item) => {
  //     const key = item.sectionId; // Group by sectionId
  //     if (!acc[key]) {
  //       acc[key] = {
  //         sectionId: item.sectionId,
  //         sectionName: item.sectionName,
  //         questions: [],
  //         answer: item.answer
  //       };
  //     }
  //     acc[key].questions.push(item);
  //     return acc;
  //   }, {});
  // }

  // groupFormData(data: any[]) {
  //   console.log("Input Data:", data);
  
  //   return data.reduce((acc, item, index) => {
  //     console.log(`Processing item ${index}:`, item);
  
  //     const key = item.sectionId;
  //     console.log("Section ID:", key);
  
  //     if (!acc[key]) {
  //       console.log(`Creating new section for sectionId: ${key}`);
  //       acc[key] = {
  //         sectionId: item.sectionId,
  //         sectionName: item.sectionName,
  //         questions: [],
  //         answer: item.answer
  //       };
  //     }
  
  //     console.log(`Pushing question to section ${key}:`, item);
  //     acc[key].questions.push(item);
  
  //     console.log(`Accumulator after processing item ${index}:`, acc);
  
  //     return acc;
  //   }, {});
  // }

  groupFormData(responseData: any[]): any[] {
    const groupedMap = new Map<number, { sectionId: number; sectionName: string; questions: any[] }>();
  
    responseData.forEach(item => {
      if (!groupedMap.has(item.sectionId)) {
        groupedMap.set(item.sectionId, {
          sectionId: item.sectionId,
          sectionName: item.sectionName || '', 
          questions: []
        });
      }
      groupedMap.get(item.sectionId)!.questions.push(item);
    });
  
    return Array.from(groupedMap.values());
  }
  
  

  getAdInfoFormData(forms: any, vesselId: any) {
    console.log("check-adinfoFormData-vesselId", vesselId);
    if (forms.isAddNewQuestion) {
      this.formreportsService.getFormAdditionalInfoByFormId(vesselId, (res) => {
        console.log('resAdd_AddInfo: ', res);
        this.addInfoDetail = res.data;
        console.log("check-additonalinfo-data1", res.data);
        console.log("check-additonalinfo-data2", this.addInfoDetail);
        if (this.addInfoDetail && this.addInfoDetail.length > 0) {
          this.addInfoDetail = this.addInfoDetail.map((item, index) => {

            if (item.infoDetails) {
              switch (item.infoControlType) {
                case 'timepicker':
                  item.infoDetails = item.infoDetails;
                  break;

                case 'datepicker':
                  if (item.infoControlType) {
                    var months = {
                      "Jan": 0, "Feb": 1, "Mar": 2, "Apr": 3, "May": 4, "Jun": 5,
                      "Jul": 6, "Aug": 7, "Sep": 8, "Oct": 9, "Nov": 10, "Dec": 11
                    };

                    var parts = item.infoDetails.split('-');
                    var day = parseInt(parts[0]);
                    var month = months[parts[1]];
                    var year = parseInt(parts[2]);

                    var formattedDate = new Date(Date.UTC(year, month, day));

                    item.infoDetails = formattedDate;
                  }
                  break;
                // Add more cases as needed
              }
            }


            if (item) {
              switch (item.infoControlType) {
                case 'textbox':
                  let infoTextBoxGroup = this.formBuilder.group({
                    actionDate: [this.isAddNewQuestion ? this.currentActionDate : item.actionDate],
                    actionTime: [this.isAddNewQuestion ? this.currentActionTime : item.actionTime],
                    id: [item.id],
                    formId: [item.formId],
                    formInfoId: [item.id],
                    infoDetails: [!this.isAddNewQuestion ? item.infoDetails : null],
                    infoLabel: [item.infoLabel],
                    controlType: [item.infoControlType],
                    vesselId: vesselId
                  });
                  this.additionalInfoFormArray.push(infoTextBoxGroup);
                  if (this.isAddNewQuestion) { item.infoDetails = null };
                  break;

                case 'textarea':
                  let infoTextAreaGroup = this.formBuilder.group({
                    actionDate: [this.isAddNewQuestion ? this.currentActionDate : item.actionDate],
                    actionTime: [this.isAddNewQuestion ? this.currentActionTime : item.actionTime],
                    id: [item.id],
                    formId: [item.formId],
                    formInfoId: [item.id],
                    infoDetails: [!this.isAddNewQuestion ? item.infoDetails : null],
                    infoLabel: [item.infoLabel],
                    controlType: [item.infoControlType],
                    vesselId: vesselId
                  });
                  this.additionalInfoFormArray.push(infoTextAreaGroup);
                  if (this.isAddNewQuestion) { item.infoDetails = null };
                  break;

                case 'datepicker':
                  let infoDatePicGroup = this.formBuilder.group({
                    actionDate: [this.isAddNewQuestion ? this.currentActionDate : item.actionDate],
                    actionTime: [this.isAddNewQuestion ? this.currentActionTime : item.actionTime],
                    id: [item.id],
                    formId: [item.formId],
                    formInfoId: [item.id],
                    infoDetails: [!this.isAddNewQuestion ? item.infoDetails : null],
                    infoLabel: [item.infoLabel],
                    controlType: [item.infoControlType],
                    vesselId: vesselId
                  });
                  this.additionalInfoFormArray.push(infoDatePicGroup);
                  if (this.isAddNewQuestion) { item.infoDetails = null };
                  break;

                case 'timepicker':
                  let infoTimePicGroup = this.formBuilder.group({
                    actionDate: [this.isAddNewQuestion ? this.currentActionDate : item.actionDate],
                    actionTime: [this.isAddNewQuestion ? this.currentActionTime : item.actionTime],
                    id: [item.id],
                    formId: [item.formId],
                    formInfoId: [item.id],
                    infoDetails: [!this.isAddNewQuestion ? item.infoDetails : null],
                    infoLabel: [item.infoLabel],
                    controlType: [item.infoControlType],
                    vesselId: vesselId
                  });
                  this.additionalInfoFormArray.push(infoTimePicGroup);
                  if (this.isAddNewQuestion) { item.infoDetails = null };
                  break;

                case 'radio':
                  let InforadioGroup = this.formBuilder.group({
                    actionDate: [this.isAddNewQuestion ? this.currentActionDate : item.actionDate],
                    actionTime: [this.isAddNewQuestion ? this.currentActionTime : item.actionTime],
                    id: [item.id],
                    formId: [item.formId],
                    formInfoId: [item.id],
                    infoDetails: [!this.isAddNewQuestion ? item.infoDetails : null],
                    infoLabel: [item.infoLabel],
                    controlType: [item.infoControlType],
                    vesselId: vesselId
                  });
                  this.additionalInfoFormArray.push(InforadioGroup);
                  if (this.isAddNewQuestion) { item.infoDetails = null };
                  break;
              }
            }
            return item;
          })
        }
      })
    } else {

      this.formreportsService.getFormAnsAdditionalInfoByVesselFormIdWithDateTime(forms, vesselId, (res) => {
        console.log("check-new-api", res);
        this.addInfoDetail = res.data;
        console.log('===check-addInfoDetail===', this.addInfoDetail)
        if (this.addInfoDetail && this.addInfoDetail.length > 0) {
          console.log('===check-addInfoDetail-inside===', this.addInfoDetail)
          this.addInfoDetail = this.addInfoDetail.map((item, index) => {

            if (item.infoDetails) {
              switch (item.infoControlType) {
                case 'timepicker':
                  item.infoDetails = item.infoDetails;
                  break;

                case 'datepicker':
                  if (item.infoControlType) {
                    var months = {
                      "Jan": 0, "Feb": 1, "Mar": 2, "Apr": 3, "May": 4, "Jun": 5,
                      "Jul": 6, "Aug": 7, "Sep": 8, "Oct": 9, "Nov": 10, "Dec": 11
                    };

                    var parts = item.infoDetails.split('-');
                    var day = parseInt(parts[0]);
                    var month = months[parts[1]];
                    var year = parseInt(parts[2]);

                    var formattedDate = new Date(Date.UTC(year, month, day));

                    item.infoDetails = formattedDate;
                  }
                  break;
                // Add more cases as needed
              }
            }


            if (item) {
              switch (item.infoControlType) {
                case 'textbox':
                  let infoTextBoxGroup = this.formBuilder.group({
                    actionDate: [this.isAddNewQuestion ? this.currentActionDate : item.actionDate],
                    actionTime: [this.isAddNewQuestion ? this.currentActionTime : item.actionTime],
                    id: [item.id],
                    formId: [item.formId],
                    formInfoId: [item.id],
                    infoDetails: [item.infoDetails],
                    infoLabel: [item.infoLabel],
                    controlType: [item.infoControlType],
                    vesselId: vesselId
                  });
                  this.additionalInfoFormArray.push(infoTextBoxGroup);
                  break;

                case 'textarea':
                  let infoTextAreaGroup = this.formBuilder.group({
                    actionDate: [this.isAddNewQuestion ? this.currentActionDate : item.actionDate],
                    actionTime: [this.isAddNewQuestion ? this.currentActionTime : item.actionTime],
                    id: [item.id],
                    formId: [item.formId],
                    formInfoId: [item.id],
                    infoDetails: [item.infoDetails],
                    infoLabel: [item.infoLabel],
                    controlType: [item.infoControlType],
                    vesselId: vesselId
                  });
                  this.additionalInfoFormArray.push(infoTextAreaGroup);
                  break;

                case 'datepicker':
                  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                  const date = new Date(item.infoDetails);
                  console.log("checkl-date-datepicker", date);
                  const day = date.getDate();
                  const monthIndex = date.getMonth();
                  const year = date.getFullYear();
                  console.log("check-date-format", day, monthIndex, year)
                  const newDate = `${day}-${months[monthIndex]}-${year}`;
                  console.log("check-newDate", newDate);
                  var test531 = newDate;
                  console.log("check-test531", test531);
                  let infoDatePicGroup = this.formBuilder.group({
                    actionDate: [this.isAddNewQuestion ? this.currentActionDate : item.actionDate],
                    actionTime: [this.isAddNewQuestion ? this.currentActionTime : item.actionTime],
                    id: [item.id],
                    formId: [item.formId],
                    formInfoId: [item.id],
                    infoDetails: [newDate],
                    infoLabel: [item.infoLabel],
                    controlType: [item.infoControlType],
                    vesselId: vesselId
                  });
                  this.additionalInfoFormArray.push(infoDatePicGroup);
                  break;

                case 'timepicker':
                  let infoTimePicGroup = this.formBuilder.group({
                    actionDate: [this.isAddNewQuestion ? this.currentActionDate : item.actionDate],
                    actionTime: [this.isAddNewQuestion ? this.currentActionTime : item.actionTime],
                    id: [item.id],
                    formId: [item.formId],
                    formInfoId: [item.id],
                    infoDetails: [item.infoDetails],
                    infoLabel: [item.infoLabel],
                    controlType: [item.infoControlType],
                    vesselId: vesselId
                  });
                  this.additionalInfoFormArray.push(infoTimePicGroup);
                  break;

                case 'radio':
                  let InforadioGroup = this.formBuilder.group({
                    actionDate: [this.isAddNewQuestion ? this.currentActionDate : item.actionDate],
                    actionTime: [this.isAddNewQuestion ? this.currentActionTime : item.actionTime],
                    id: [item.id],
                    formId: [item.formId],
                    formInfoId: [item.id],
                    infoDetails: [!this.isAddNewQuestion ? item.infoDetails : null],
                    infoLabel: [item.infoLabel],
                    controlType: [item.infoControlType],
                    vesselId: vesselId
                  });
                  this.additionalInfoFormArray.push(InforadioGroup);
                  if (this.isAddNewQuestion) { item.infoDetails = null };
                  break;
              }
            }
            return item;
          })
        }
      })

    }
  }



  get itemsFormArray() {
    return this.form.get('items') as FormArray;
  }

  get additionalInfoFormArray() {
    return this.form.get('additionalItems') as FormArray;
  }

  removeItem(index: number) {
    this.itemsFormArray.removeAt(index);
  }

  // Method to remove an item at a specific index from the additionalInfo FormArray
  removeAdditionalInfo(index: number): void {
    this.additionalInfoFormArray.removeAt(index);
  }

  addNewImage(itemData: any, imgIndex: number) {
    let neItem = {
      "id": imgIndex + 1,
      "name": "",
      "value": ""
    }
    if (itemData) {
      itemData.push(neItem);
    } else {
      let newImgData = [];
      newImgData.push(neItem);
      itemData = newImgData;
    }
  }

  // addNewImageFresh(question: any, imgIndex: number) {
  //   console.log("check-imageindex", imgIndex);
  //   let neItem = {
  //     "id": imgIndex + 1,
  //     "name": "",
  //     "value": ""
  //   }
  //   console.log("dataForEdit-inside-addNewImageFresh-before-map", this.dataForEdit);
  //   console.log("FreshImage::", neItem);
  //   this.dataForEdit.map((qu, i) => {
  //     if (qu && i === imgIndex) {
  //       qu.imagesDataArray = [],
  //         qu.imagesDataArray.push(neItem);
  //       return qu;
  //     }
  //     return qu;
  //   })
  //   console.log("dataForEdit-inside-addNewImageFresh-after-map", this.dataForEdit);
  //   console.log("FreshImage-dataForEdit::", this.dataForEdit);
  // }

  addNewImageFresh(question: any, imgIndex: number) {

    let oldIndex = question.questionId;
    const finalIndex = this.form.controls.items.value.findIndex((x) => x.questionId === oldIndex);

    // Log the index
    if (finalIndex !== -1) {
      console.log("Found index of questionId:", finalIndex);
    } else {
      console.log("questionId not found in the array");
    }



    console.log("check-imageindex", imgIndex);
    let neItem = {
      "id": finalIndex + 1,
      "name": "",
      "value": ""
    }
    console.log("dataForEdit-inside-addNewImageFresh-before-map", this.dataForEdit);
    console.log("FreshImage::", neItem);
    this.dataForEdit.map((qu, i) => {
      if (qu && i === finalIndex) {
        qu.imagesDataArray = [],
          qu.imagesDataArray.push(neItem);
        return qu;
      }
      return qu;
    })
    console.log("dataForEdit-inside-addNewImageFresh-after-map", this.dataForEdit);
    console.log("FreshImage-dataForEdit::", this.dataForEdit);
  }

  removeImage(itemData: any, question, id: number, index, cidx) {


    let oldIndex = question.questionId;
    const pIdx = this.form.controls.items.value.findIndex((x) => x.questionId === oldIndex);

    // Log the index
    if (pIdx !== -1) {
      console.log("Found index of questionId:", pIdx);
    } else {
      console.log("questionId not found in the array");
    }
    console.log("check-removeimage-index", pIdx);
    if (itemData && itemData.length > 0) {
      const index = itemData.findIndex(item => item.id === id);
      if (index !== -1) {
        itemData.splice(index, 1);
      };
      let cloneList2 = itemData && JSON.parse(JSON.stringify(itemData));
      cloneList2 = cloneList2.map((item) => { return item.value.trim() });
      this.form.controls.items.value[pIdx].imagesDataLocal = cloneList2.join(",");
      this.form.controls.items.value[pIdx].imagesData = cloneList2.join(",");
    }
  }

  mapToKeyValueIndexArray(csv) {
    // Split the CSV string into an array of values
    const values = csv.split(',');
    // Create an empty object to store the result
    const result = [];
    // Loop through the values array
    values.forEach((value, index) => {
      let newVal = {
        id: (index + 1),
        name: value,
        value: value
      }
      result.push(newVal);
    });
    return result;
  }

  modifyTime(time) {
    let timeParts = time && time.split(":");
    let hours = timeParts[0];
    let minutes = timeParts[1];
    let ampm = timeParts[2] && timeParts[2].split(" ")[1]; // Extracting AM/PM part

    time = hours + ":" + minutes + " " + ampm;
    return time;
  }

  modifyDate(inputDate) {
    if (inputDate) {
      var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      // Split the input date into day, month, and year
      var parts = inputDate.split('-');
      var day = parseInt(parts[0]);
      var month = parts[1];
      var year = parseInt(parts[2]);

      // Create a new Date object with the given day, month, and year
      var date = new Date(year, monthNames.indexOf(month), day);

      // Output the date in the desired format
      inputDate = date.toUTCString();
    }
  }

  ngAfterViewInit(): void {
    // Check if the fileInfoElement is available
    if (this.fileInfoElement) {
      console.log("file_Info ngafter element:", this.fileInfoElement.nativeElement);
    } else {
      console.log("fileInfoElement is ngafter  undefined");
    }
  }

  uploadProfileImage(event, question, imagesDataArray, control, indexval, cIdx) {
    console.log("file_Info element::", this.fileInfoElement.nativeElement);

    let oldIndex = question.questionId;
    const pIdx = this.form.controls.items.value.findIndex((x) => x.questionId === oldIndex);

    // Log the index
    if (pIdx !== -1) {
      console.log("Found index of questionId:", pIdx);
    } else {
      console.log("questionId not found in the array");
    }

    console.log("event:::", event);
    console.log("question", question);
    console.log("control", control)
    console.log("pIdx and cIdx", pIdx, cIdx);
    console.log("DataEditForm:::", this.dataForEdit);
    console.log("imagesDataArray:::", imagesDataArray);
    let index = imagesDataArray.length - 1;
    console.log("index::", index);
    let value = control.value;
    const file_Info = document.getElementById('file_info_' + pIdx + cIdx + control.id) as HTMLElement;
    console.log("file_Info element::", file_Info);
    if (!file_Info) {
      console.log("The element with the given ID does not exist yet.");
    }

    const name = event.target.value.split(/\\|\//).pop();
    console.log("File name extracted::", name);

    for (let i = 0; i < event.target.files.length; i++) {
      if (event.target.files.length > 0) {
        const formData = new FormData();
        let fileName = event.target.files[i].name.replace(/\s/g, '');
        let filetype = event.target.files[i].type;
        console.log("fileName:", fileName, "filetype:", filetype);
        if (filetype == 'image/png' || filetype == 'image/jpeg' || filetype == 'image/gif' || filetype == 'image/jpg') {
          formData.append("file", event.target.files[i], fileName);
          const file = event.target.files[0];
          this.fileFormData = formData;
          console.log("FormData before API call:", formData);

          this.crewService.uploadImage(formData, (response) => {
            if (response.responsecode == 200) {
              console.log("Enter:::")
              console.log("Image uploaded successfully, response:", response);
              this.fileInfoElement.nativeElement.innerHTML = "<span style='padding-left: 5px; line-height: 37px;'>" + response.filename + "</span>";
              let resValue = '/campbellservice/downloadFile/' + response.filename;
              console.log("Generated file download URL:", resValue);
              let newItems = {
                "id": this.count,
                "name": "",
                "value": resValue
              }

              this.dataForEdit.map((qu, i) => {
                if (qu && i === pIdx) {
                  console.log("qu.imageArray", qu.imagesDataArray[0].value)
                  let newValue = qu.imagesDataArray[0].value;
                  if (value == '') {
                    console.log("i::", i);
                    qu.imagesDataArray[0] = newItems;
                    console.log("newValueIF::", newItems);
                    value = newItems.value;
                    return qu
                  } else {
                    console.log("i::", i);
                    index = index + 1;
                    qu.imagesDataArray[index] = newItems;
                    console.log("Index+1::", index);
                    console.log("newValueElse::", newItems);
                    return qu;
                  }

                }
                return qu;

              })
              let cloneList = imagesDataArray && JSON.parse(JSON.stringify(imagesDataArray));
              cloneList = cloneList.map((item) => { return response.filename });
              let cloneList2 = imagesDataArray && JSON.parse(JSON.stringify(imagesDataArray));
              cloneList2 = cloneList2.map((item) => { return item.value.trim() });
              this.form.controls.items.value[pIdx].imagesDataLocal = cloneList2.join(",");
              this.form.controls.items.value[pIdx].imagesData = cloneList2.join(",");
              this.count++;
              console.log("count::", this.count);


            } else {
              let ele = document.getElementById('image_' + pIdx + cIdx + control.id) as HTMLInputElement;
              ele.value = '';
              file_Info.innerHTML = "<span style='padding-left: 5px; line-height: 37px;'>Choose file..</span>";
              control.value = null;
              control = control;
              imagesDataArray[cIdx] = control;
              this.errorbox.text = response.message;
              this.errorbox.show();
            }
          });
        } else {
          this.errorbox.text = 'Supported image formats: JPEG, PNG, and GIF only.';
          this.errorbox.show();
        }

      }
    }
    console.log("ImageItem::", this.imageItem);
    console.log("dataForEdit::", this.dataForEdit);
  }



  // onChange(question: any, index: number, sindex: number, value: any) {
  //   console.log('onChange');
  //   console.log("check-form-control", this.form);
  //   console.log("check-onchange-question", question, index, value);
  //   console.log("check-onchange-index", index);
  //   console.log("check-s-index", sindex)
  //   console.log("check-onchange-value", value);
  //   console.log("check-formcontrols-onchange", this.form.controls.items.value);
  //   console.log("check-question-id", question.questionId);
  //   let oldIndex = question.questionId;
  //   console.log("check-oldindex", oldIndex);

  //   if (value) {
  //     this.form.controls.items.value[index].answer = value;
  //   }
  // }

  onChange(question: any, index: number, value: any) {
    console.log('onChange');
    console.log("check-form-control", this.form);
    console.log("check-onchange-question", question, index, value);
    console.log("check-onchange-index", index);
    console.log("check-onchange-value", value);
    console.log("check-formcontrols-onchange", this.form.controls.items.value);
    console.log("check-question-id", question.questionId);
    let oldIndex = question.questionId;
    console.log("check-oldindex", oldIndex);

    // Find the index of the questionId in the array
    const finalIndex = this.form.controls.items.value.findIndex((x) => x.questionId === oldIndex);

    // Log the index
    if (finalIndex !== -1) {
      console.log("Found index of questionId:", finalIndex, value);
    } else {
      console.log("questionId not found in the array");
    }

    if (value) {
      this.form.controls.items.value[finalIndex].answer = value;
    }
  }


  onAdditionalInfoChange(question: any, index: number, value: any) {
    console.log("onAdditionalInfoChange");
    console.log("check-form-control", this.form);
    console.log("check-onchange-question", question, index, value);
    console.log("check-onchange-index", index);
    console.log("check-onchange-value", value);
    if (value) {
      this.form.controls.additionalItems.value[index].infoDetails = value;
    }
    console.log("check-onchange-value", this.form.controls.additionalItems.value)
  }


  // onChangeRadio(question: any, index: number, event: any) {
  //   if (event.value) {
  //     this.form.controls.items.value[index].answer = event.value;
  //   }
  // }

  onChangeRadio(question: any, index: number, event: any) {
    console.log('==onChangeRadio-question==', question);
    console.log('==onChangeRadio-index==', index);
    console.log('==onChangeRadio-event==', event)
    console.log('==event-target-value==', event.target.value)

    // Find the questionId from the question object
    let oldIndex = question.questionId;
    console.log("check-oldindex", oldIndex);

    // Find the index of the questionId in the array
    const finalIndex = this.form.controls.items.value.findIndex((x) => x.questionId === oldIndex);

    // Log the index
    if (finalIndex !== -1) {
      console.log("Found index of questionId:", finalIndex, event.target.value);
      // Update the answer using the finalIndex found
      if (event.target.value) {
        this.form.controls.items.value[finalIndex].answer = event.target.value;
      }
    } else {
      console.log("questionId not found in the array");
    }
  }


  onEndTimePickerClosedForAdd(question: any, index, event) {

    console.log("Constructed element ID:", 'timePicker_' + question.formsId + question.questionId);
    let oldIndex = question.questionId;
    console.log("check-oldindex", oldIndex);
    console.log("check-index-add", index);

    // Find the index of the questionId in the array
    const finalIndex = this.form.controls.items.value.findIndex((x) => x.questionId === oldIndex);

    // Log the index
    if (finalIndex !== -1) {
      console.log("Found index of questionId:", finalIndex);
    } else {
      console.log("questionId not found in the array");
    }


    console.log("check-question", question);
    let timeElement = document.getElementById('timePicker_' + question.formsId + question.questionId) as HTMLInputElement;
    console.log("check-timelement", timeElement);
    console.log("check-timeelement-value", timeElement.value)
    console.log("check-timelemnt-detail---->", "timePicker_" + question.formsId + index);
    if (timeElement.value) {
      this.form.controls.items.value[finalIndex].answer = timeElement.value;
    }
    console.log("check-form-control", this.form.controls.items.value[finalIndex].answer)
  }


  onEndTimePickerClosedForEdit(question: any, index, event) {
    // Find the questionId from the question object
    let oldIndex = question.questionId;
    console.log("check-oldindex", oldIndex);
    console.log("check-event", event);
    console.log("check-index-edit", index)

    // Find the index of the questionId in the array
    const finalIndex = this.form.controls.items.value.findIndex((x) => x.questionId === oldIndex);

    // Log the index
    if (finalIndex !== -1) {
      console.log("Found index of questionId:", finalIndex);
    } else {
      console.log("questionId not found in the array");
    }

    console.log("check-onEndTimePickerClosedForEdit-index", index);
    console.log("check-question-id", question.id);
    let timeElement = document.getElementById('timePicker_' + question.id + question.questionId) as HTMLInputElement;
    if (timeElement) {
      console.log("Present", timeElement);
    } else {
      console.log("Absent");
    }
    if (timeElement.value) {
      console.log("checktimeelemtvalue", timeElement.value)
      this.form.controls.items.value[finalIndex].answer = timeElement.value;
    }


    console.log("check-form-value", this.form.controls.items.value[finalIndex]);
  }

  onInfoEndTimePickerClosedForAdd(info, index, event) {
    console.log('info object:', info);

    console.log('Generated ID:', 'timePicker_' + info.id + index);

    console.log("check-event", event);
    console.log("check-info", info);
    console.log("check-index", index);
    let infoTimeElement = document.getElementById('timePicker_' + info.id + index) as HTMLInputElement;
    console.log("check-infotimeelementvalue", infoTimeElement);

    if (infoTimeElement && infoTimeElement.value) {
      console.log("infoTimeElement has value");
      this.form.controls.additionalItems.value[index].infoDetails = infoTimeElement.value;
    }
  }


  // onDateChange(question, index, event: any) {
  //   if (event.target.value) {
  //     console.log("check-question", question);
  //     console.log('check-index', index);
  //     console.log("check-event", event);
  //     console.log("check-question.answer", question.answer)
  //     question.answer = event.target.value;
  //     const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  //     const date = new Date(event.target.value);
  //     const day = date.getDate();
  //     const monthIndex = date.getMonth();
  //     const year = date.getFullYear();
  //     const newDate = `${day}-${months[monthIndex]}-${year}`;
  //     this.form.controls.items.value[index].answer = newDate;
  //   }
  // }

  onDateChange(question: any, index: number, event: any) {
    if (event.target.value) {
      console.log("check-question", question);
      console.log('check-index', index);
      console.log("check-event", event);
      console.log("check-question.answer", question.answer);

      // Find the questionId from the question object
      let oldIndex = question.questionId;
      console.log("check-oldindex", oldIndex);

      // Find the index of the questionId in the array
      const finalIndex = this.form.controls.items.value.findIndex((x) => x.questionId === oldIndex);

      // Log the index
      if (finalIndex !== -1) {
        // Update the answer using the finalIndex found
        question.answer = event.target.value; // Update local question's answer
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const date = new Date(event.target.value);
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();
        const newDate = `${day}-${months[monthIndex]}-${year}`;

        // Update the form control's answer using the finalIndex found
        this.form.controls.items.value[finalIndex].answer = newDate;
        console.log("Updated date format:", newDate);
        console.log("check-finalindex", finalIndex);
      } else {
        console.log("questionId not found in the array");
      }
    }
  }



  onAdditionalDateChange(info, index, event: any) {
    console.log("checkformcontrol", this.form);
    if (event) {
      console.log("check-info", info);
      console.log('check-index', index);
      console.log("check-event", event);
      console.log("check-info.infoDetails", info.infoDetails)
      info.infoDetails = event;
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const date = new Date(event);
      const day = date.getDate();
      const monthIndex = date.getMonth();
      const year = date.getFullYear();
      const newDate = `${day}-${months[monthIndex]}-${year}`;
      console.log("check-newDate", newDate)
      this.form.controls.additionalItems.value[index].infoDetails = newDate;
    }
  }

  // onChangeDropdown(question: any, index: number, value: any) {
  //   if (value) {
  //     this.form.controls.items.value[index].answer = value;
  //   }
  // }

  onChangeDropdown(question: any, index: number, value: any) {
    if (value) {
      console.log('onChangeDropdown');
      console.log("check-question", question);
      console.log("check-dropdown-index", index);
      console.log("check-dropdown-value", value);

      // Find the questionId from the question object
      let oldIndex = question.questionId;
      console.log("check-oldindex", oldIndex);

      // Find the index of the questionId in the array
      const finalIndex = this.form.controls.items.value.findIndex((x) => x.questionId === oldIndex);

      // Log the index
      if (finalIndex !== -1) {
        console.log("Found index of questionId:", finalIndex);
        // Update the answer using the finalIndex found
        this.form.controls.items.value[finalIndex].answer = value;
      } else {
        console.log("questionId not found in the array");
      }
    }
  }


  // onCheckboxChange(event: any, checkBoxArr: any, question: any, item: any, pIndex: any, cIndex: any) {
  //   if (event.checked && item.value) {
  //     item.isChecked = true;
  //     const checkedValues = checkBoxArr.length > 0 && checkBoxArr
  //       .filter(item => item.isChecked)
  //       .map(item => item.value);
  //     const result = checkedValues;
  //     this.form.controls.items.value[pIndex].answer = result.join(", ");
  //   } else {
  //     item.isChecked = false;
  //     const checkedValues = checkBoxArr.length > 0 && checkBoxArr
  //       .filter(item => item.isChecked)
  //       .map(item => item.value);
  //     const result = checkedValues;
  //     this.form.controls.items.value[pIndex].answer = result.join(", ");
  //   }
  // }

  onCheckboxChange(event: any, checkBoxArr: any, question: any, item: any, pIndex: any, cIndex: any) {


    console.log("==== Checkbox Change Event Triggered ====");

    console.log("Event:", event);
    console.log("Event Checked:", event.target.checked);

    console.log("Checkbox Array (checkBoxArr):", checkBoxArr);
    console.log("Question Object:", question);
    console.log("Checkbox Item:", item);
    console.log("Parent Index (pIndex):", pIndex);
    console.log("Child Index (cIndex):", cIndex);


    let oldIndex = question.questionId;
    console.log("check-oldindex", oldIndex);

    // Find the index of the questionId in the array
    const finalIndex = this.form.controls.items.value.findIndex((x) => x.questionId === oldIndex);

    if (finalIndex !== -1) {
      console.log("Found index of questionId:", finalIndex);
    } else {
      console.log("questionId not found in the array");
    }


    if (event.target.checked && item.value) {
      item.isChecked = true;
      const checkedValues = checkBoxArr.length > 0 && checkBoxArr
        .filter(item => item.isChecked)
        .map(item => item.value);
      const result = checkedValues;
      this.form.controls.items.value[finalIndex].answer = result.join(", ");
    } else {
      item.isChecked = false;
      const checkedValues = checkBoxArr.length > 0 && checkBoxArr
        .filter(item => item.isChecked)
        .map(item => item.value);
      const result = checkedValues;
      this.form.controls.items.value[finalIndex].answer = result.join(", ");
    }
  }

  onSubmit() {

    // this.loading = true;

    // Retrieve question IDs from the form if there are items
    let questionIdList = this.form.controls.items.value.length > 0
      ? this.form.controls.items.value.map(item => item.questionId)
      : [];

    console.log("check-additonalitem-formcontrol", this.form.controls.additionalItems.value);

    // Get additional information values from the form
    let additionalInfoValues = this.form.controls.additionalItems.value;

    // Determine the object ID based on whether it's a new question or an existing one
    let objectId = this.isAddNewQuestion ? this.generateUniqueId() : this.existingObjectId;

    // Prepare additional information data
    let additionalInfoData = additionalInfoValues.map(item => ({
      actionDate: item.actionDate || '',
      actionTime: item.actionTime || '',
      id: item.id || '',
      formId: item.formId || 0,
      formInfoId: item.formInfoId || 0,
      infoDetails: item.infoDetails || '',
      infoLabel: item.infoLabel || '',
      vesselId: this.selectedVesselId,
      infoControlType: item.controlType
    }));

    // Create the request body for saving form answers
    let newReqBody = [
      {
        changeFields: this.isAddNewQuestion ? "" : questionIdList,
        formansweradditioninfomodel: this.formansweradditioninfomodel,
        imagesData: "[]",
        isMobileSync: false,
        questionWithAnswer: this.form.controls.items.value,
        objectId: objectId,
        responsecode: 0,
        userId: this.userId,
        userSign: "",
        vesselId: this.selectedVesselId,
        voyageNo: this.voyageNo
      }
    ];



    let formValues = this.form.controls.items.value;
    const formData = new FormData();
    formData.append('model', JSON.stringify(newReqBody));

    console.log("====formData===", formData);
    console.log("check-items-onsubmit", this.form.controls.items.value);
    console.log("check-additionalItems-onsubmit", this.form.controls.additionalItems.value)

    // if (this.form.controls.items.value && this.form.controls.items.value.some(item => item.answer != null)) {
    if (this.form.controls.items.value) {
      this.formreportsService.saveFormAnsAdditionalInfoList(additionalInfoData, (res) => {
        if (res.responsecode === 200 || res.responsecode === '200') {
          console.log(res);
        }
      });

      if (this.isAddNewQuestion) {
        this.formreportsService.saveNewFormAnswerDetailsList(formData, (resData) => {
          if (resData.responsecode === 200 || resData.responsecode === '200') {
            this.successbox.show();
            this.updateParent.emit(resData);
            this.loading = false;
          }
        });
      } else {
        this.formreportsService.saveFormAnswerDetailsList(formData, (resData) => {
          if (resData.responsecode === 200 || resData.responsecode === '200') {
            this.successbox.show();
            this.updateParent.emit(resData);
            this.loading = false;
          }
        });
      }
    } else {
      this.errorbox.text = 'Please enter the data before saving.';
      this.errorbox.show();
    }

  }

  saveFormDetail() {

    this.approval.title = 'Mark as completed';
    this.approval.text = 'Are you sure you want to mark this form as completed? Once you mark it as completed, you will not be able to make any changes.';
    this.approval.show();
  }

  completationSignOff() {
    console.log("saveformdetail is clicked")
    let questionIdList = this.form.controls.items.value.length > 0 && this.form.controls.items.value.map(item => item.questionId);
    let newReqBody = [
      {
        changeFields: this.isAddNewQuestion ? "" : questionIdList,
        formansweradditioninfomodel: this.formansweradditioninfomodel,
        imagesData: "[]",
        isMobileSync: false,
        questionWithAnswer: this.form.controls.items.value,
        objectId: "",
        responsecode: 0,
        userId: this.userId,
        userSign: "",
        vesselId: this.selectedVesselId,
        voyageNo: this.voyageNo
      }
    ];
    const formData = new FormData();
    formData.append('model', JSON.stringify(newReqBody));
    console.log('check-form-value', this.form.controls.items.value)
    console.log("check-formData", formData);

    this.formreportsService.completeSaveFormAnswer(formData, (res) => {
      if (res.responsecode === 200 || res.responsecode === '200') {
        console.log('check-res-here', res)
        console.log('check-isComplete-here', res.data.isComplete)
        this.successbox.show();
        this.updateParent.emit(res);
      }
    })
  }


}
