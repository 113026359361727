import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  private dbName = "saveddata";
  private storeName = "SavedData";
  private db: IDBDatabase;
  weeklyDataListForSaveUpdate: any = [];

  constructor() {
    this.initDatabase();
  }

  private initDatabase() {
    const request = indexedDB.open(this.dbName, 1);

    request.onupgradeneeded = (event) => {
      this.db = request.result;
      if (!this.db.objectStoreNames.contains(this.storeName)) {
        this.db.createObjectStore(this.storeName);
      }
    };

    request.onsuccess = (event) => {
      this.db = request.result;
    };

    request.onerror = (event) => {
      console.error("Database initialization error", event);
    };
  }

  add(keyname, value) {
    // console.log('addIndexDb_Data: ', keyname, value);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
      if (this.db) {
        const transaction = this.db.transaction([this.storeName], "readwrite");
        const store = transaction.objectStore(this.storeName);
        const request = store.put(value, keyname);
        request.onsuccess = (event) => {
          resolve(event);
        };

        request.onerror = (event) => {
          console.error("Error adding to store", event);
          reject(false);
        };
      } else {
        reject("Database not initialized");
      }
    }, 500);
    });
  }
  // get the data from index DebugEventListener
  get(keyname) {
     console.log('get data: ', keyname);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
      if (this.db) {
        const transaction = this.db.transaction([this.storeName], "readwrite");
        const store = transaction.objectStore(this.storeName);
        const request = store.get(keyname);

        // console.log('Data_transaction', transaction);
        // console.log('Data_store', store);
        // console.log('Data_request', request);

        request.onsuccess = (event) => {
          resolve(request.result);
        };

        request.onerror = (event) => {
          console.error("Error on get data to store", event);
          reject(false);
        };
      } else {
        reject("Database not initialized");
      }
    }, 500);
    });
  }

  getNew(keyName: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.db) {
        const transaction = this.db.transaction([this.storeName], "readwrite");
        const store = transaction.objectStore(this.storeName);
        const request = store.get(keyName);

        request.onsuccess = () => {
          resolve(request.result); // Resolves the fetched data
        };

        request.onerror = (event) => {
          console.error("Error retrieving data", event);
          reject(false);
        };
      } else {
        reject("Database not initialized");
      }
    });
  }


  // delete the data from index DB
  delete(keyname) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (this.db) {
          const transaction = this.db.transaction([this.storeName], "readwrite");
          const store = transaction.objectStore(this.storeName);
          const request = store.delete(keyname);

          console.log('Data_delete_DB: ', keyname, this.db);

          request.onsuccess = () => {
            console.log('Delete successful:', keyname);
            resolve('Success');
          };

          request.onerror = (event) => {
            console.error("Error deleting data from store", event);
            reject(false);
          };
        } else {
          console.error("Database not initialized");
          reject("Database not initialized");
        }
      }, 500);
    });
  }

  clearIndexedDB(): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (this.dbName) {
        const request = indexedDB.deleteDatabase(this.dbName);

        request.onsuccess = () => {
          console.log(`IndexedDB database "${this.dbName}" successfully deleted.`);
          resolve();
        };

        request.onerror = (event) => {
          console.error(`Failed to delete IndexedDB database "${this.dbName}":`, event);
          reject(event);
        };

        request.onblocked = () => {
          console.warn(`Deletion of IndexedDB database "${this.dbName}" is blocked.`);
          setTimeout(() => {
            this.clearIndexedDB().then(resolve).catch(reject);
          }, 1000);
        };
        }
      }, 500);
    });
  }

  deleteMatchingKeys(prefix: string) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (this.db) {
          const transaction = this.db.transaction([this.storeName], "readwrite");
          const store = transaction.objectStore(this.storeName);
          const request = store.getAllKeys(); // Retrieve all keys in the store

          request.onsuccess = () => {
            const allKeys = request.result;
            const matchingKeys = allKeys.filter((key: string) => key.startsWith(prefix)); // Match keys with the prefix

            if (matchingKeys.length > 0) {
              console.log("Matching keys to delete:", matchingKeys);

              // Delete matching keys
              matchingKeys.forEach((key) => {
                const deleteRequest = store.delete(key);

                deleteRequest.onsuccess = () => {
                  console.log(`Deleted key: ${key}`);
                };

                deleteRequest.onerror = (event) => {
                  console.error(`Failed to delete key: ${key}`, event);
                };
              });

              transaction.oncomplete = () => {
                console.log("All matching keys deleted successfully.");
                resolve('Success');
              };

              transaction.onerror = (event) => {
                console.error("Transaction error while deleting matching keys.", event);
                reject("Transaction error");
              };
            } else {
              console.log("No keys matched the prefix.");
              resolve("No keys matched");
            }
          };

          request.onerror = (event) => {
            console.error("Error retrieving keys from the store", event);
            reject("Error retrieving keys");
          };
        } else {
          console.error("Database not initialized");
          reject("Database not initialized");
        }
      }, 500);
    });
  }



  getMatchingKeys(prefix: string) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (this.db) {
          const transaction = this.db.transaction([this.storeName], "readwrite");
          const store = transaction.objectStore(this.storeName);
          const request = store.getAllKeys(); // Retrieve all keys in the store

          request.onsuccess = () => {
            console.log("request-onsuccess", request)
            const allKeys = request.result;
            const matchingKeys = allKeys.filter((key: string) => key.startsWith(prefix)); // Match keys with the prefix

            if (matchingKeys.length > 0) {
              console.log("Matching keys to get:", matchingKeys);
              resolve(matchingKeys);
            } else {
              console.log("No keys matched the prefix.");
              resolve([]);
            }
          };

          request.onerror = (event) => {
            console.error("Error retrieving keys from the store", event);
            reject("Error retrieving keys");
          };
        } else {
          console.error("Database not initialized");
          reject("Database not initialized");
        }
      }, 500);
    });
  }



  getAllMatchingKeys(prefixes: string[]) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (this.db) {
          const transaction = this.db.transaction([this.storeName], "readwrite");
          const store = transaction.objectStore(this.storeName);
          const request = store.getAllKeys(); // Retrieve all keys in the store

          request.onsuccess = () => {
            const allKeys = request.result;
            let mergedArray= [];

            prefixes.forEach((prefix) => {
              const matchingKeys = allKeys.filter((key: string) => key.startsWith(prefix));
              if (matchingKeys.length > 0) {
                console.log("Matching keys to get:", matchingKeys);

                // Merge the matching keys into the merged array
                mergedArray = mergedArray.concat(matchingKeys);
              }
            });
            console.log("Merged array of keys to get:", mergedArray);
            resolve(mergedArray);
          };

          request.onerror = (event) => {
            console.error("Error retrieving keys from the store", event);
            reject("Error retrieving keys");
          };
        } else {
          console.error("Database not initialized");
          reject("Database not initialized");
        }
      }, 500);
    });
  }


}
