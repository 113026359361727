import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { RolesandrightsService } from './rolesandrights.service';
import { HttpClient } from '@angular/common/http';
import { RolesService } from '../roles/roles.service';
import { CompanyService } from '../company/company.service';
import { FormsService } from '../forms/forms.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { SessionStorageService } from 'angular-web-storage';
import * as _ from 'lodash';

export interface IsTrueFalse {
  id: boolean;
  viewValue: boolean;
}

@Component({
  selector: 'app-rolesandrights',
  templateUrl: './rolesandrights.component.html',
  styleUrls: ['./rolesandrights.component.scss']
})
export class RolesandrightsComponent implements OnInit {

  //displayedColumns: string[] = ['id', 'companyId', 'roleId', 'rolePermission','edit'];
  displayedColumns: string[] = ['companyId', 'roleId', 'rolePermission','edit'];
  dataSource

  isLoading = true;
  myForm: FormGroup;
  afs: any;
  success: boolean;
  loading: boolean;
  data: any = [];
  id: any;
  product: any;
  public formData;
  roles = [];
  forms = [];
  companies = [];
  permissionGroup = [];
  allPremissions = [];
  userRoleDetails:any = [];
  userType:any;
  responseData:any=[]

  allDisabled = ['dashboard','hierarchy','hierarchytree','sections','forms','formfield','watchhours','sailingmodes','sailingmodelogs','workflow','formworkflow','workflowaction','validations','fieldvalidation','formbuilder','orbcode','orbcodeitem','company','formreports','formlist'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild('deleteSwal', { static: false }) public deleteSwal: SwalComponent;
  @ViewChild('successbox', { static: true }) public successbox: SwalComponent;

  @ViewChild('errorbox', { static: true }) public errorbox: SwalComponent;


  istruefalse: IsTrueFalse[] = [
    { id: true, viewValue: true },
    { id: false, viewValue: false }
  ]


  constructor(public session: SessionStorageService,public comps: CompanyService, public fb: FormBuilder, public rolesAndRightsService: RolesandrightsService,
    public rolesService: RolesService, public formsService: FormsService,
    public http: HttpClient) {
      this.resetForm();

  }

  ngOnInit() {

    of(this.formData).pipe(delay(1000))
      .subscribe(data => {
        this.isLoading = false;
        this.data = data;
      }, error => this.afs.isLoading = false)
    // this.getProductDetails(this.route.snapshot.params['id']);



     this.userType = this.session.get('rolename');
      if(this.userType.toString().toLowerCase()=='super admin'){
            this.comps.getData((response) => {
                this.companies = response.data;
            })

            this.rolesAndRightsService.getData((response) => {
                //console.log(response);
                console.log("===rolesAndRightsService===",response);
                this.dataSource = new MatTableDataSource(response.data);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
              })

              this.permissionGroup = this.rolesAndRightsService.getPermissionGroup(1);

      }else{
           this.comps.getDataById(this.session.get('companyId'),(response) => {
                this.companies.push(response.data);
                this.rolesAndRightsService.formData.companyId = this.session.get('companyId');
            })

             this.rolesAndRightsService.getAllRolePermissionDetailsByCompanyId(this.session.get('companyId'),(response) => {
                //console.log(response);
                console.log("===getgetAllRolePermissionDetailsByCompanyIdData===",response);
                this.responseData= _.sortBy(response.data, 'id' ).reverse();
                console.log("=== this.responseData===", this.responseData);
                this.dataSource = new MatTableDataSource(this.responseData);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
              })

              this.permissionGroup = this.rolesAndRightsService.getPermissionGroup(0);
      }

    this.rolesService.getData((response) => {
      console.log("===getData===",response);
      this.roles = response.data;
      // this.dataSource.paginator = this.paginator;
      // this.dataSource.sort = this.sort;
    })

//    this.formsService.getData((response) => {
//      console.log(response);
//      this.forms = response.data;
//      // this.dataSource.paginator = this.paginator;
//      // this.dataSource.sort = this.sort;
//    })


    this.myForm = this.fb.group({
      'roleId': new FormControl(null, [Validators.required]),
      'roleDetails': new FormControl(null, [Validators.required]),
      'companyId': new FormControl(null, [Validators.required]),
      'rolePermission': new FormControl(null, [Validators.required])

    })


    this.allPremissions = this.rolesAndRightsService.getAllControllers()
  }

  getRoleName(id){
      for(let key in this.roles){
          if(this.roles[key].id==id){
              return this.roles[key].roleName;
          }
      }
  }

  getCompName(id){
      for(let key in this.companies){
          if(this.companies[key].id==id){
              return this.companies[key].companyName;
          }
      }
  }

 getPermissionName(id){
      for(let key in this.permissionGroup){
          if(this.permissionGroup[key].id==id){
              return this.permissionGroup[key].groupName;
          }
      }
  }


  getFormsData(id){
      for(let key in this.permissionGroup){
          if(this.permissionGroup[key].id==id){
               //this.rolesAndRightsService.formData.roleDetails=this.permissionGroup[key].permission.toString().split(',');
               this.userRoleDetails = this.permissionGroup[key].permission.toString().split(',');
               this.rolesAndRightsService.formData.roleDetails = this.userRoleDetails.join();
          }
      }
  }

    getUpdateDetails(){
      console.log(this.userRoleDetails)
      this.rolesAndRightsService.formData.roleDetails = this.userRoleDetails.join();
      console.log(this.rolesAndRightsService.formData.roleDetails);
  }


  submitHandler() {

    if (this.myForm) {
      this.loading = true;
      if (this.rolesAndRightsService.formData.id) {
          console.log("===savebefore===");
          console.log(this.rolesAndRightsService.formData.roleDetails);
          this.rolesAndRightsService.formData.roleDetails = this.userRoleDetails.join();
          console.log("this.userRoleDetails");
          console.log(this.rolesAndRightsService.formData.roleDetails);

          //this.rolesAndRightsService.formData.roleDetails = this.userRoleDetails;
          this.rolesAndRightsService.putData(this.rolesAndRightsService.formData.id, this.rolesAndRightsService.formData, (data) => {

          console.log(data);
          this.loading = false;
          if(data.responsecode === 200 || data.responsecode === '200'){
          this.rolesAndRightsService.getData((response) => {
            console.log(response);
            this.dataSource = new MatTableDataSource(response.data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.successbox.show();
            this.resetForm();
            this.myForm.reset();
          })
        }else{
          this.errorbox.text = data.message;
              this.errorbox.show();
        }
        })
      }
      else {
        this.rolesAndRightsService.formData.roleDetails = this.userRoleDetails.join();
        this.rolesAndRightsService.postData(this.rolesAndRightsService.formData, (response) => {
          console.log(response);
          this.loading = false;
          if(response.responsecode === 200 || response.responsecode === '200'){
          this.rolesAndRightsService.getData((response) => {
            console.log(response);
            this.dataSource = new MatTableDataSource(response.data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.successbox.show();
            this.resetForm();
            this.myForm.reset();
          })
        }else{
          this.errorbox.text = response.message;
              this.errorbox.show();
        }
        })
      }

    }
  }

  // getProductDetails(id) {
  //   this.fieldTypeService.getDataById(id,(response)=>{
  //     console.log(response);
  //     this.product = response;
  //   })
  // }

  onClick(action, data) {
    if (action == 'edit') {
        console.log('===data===')
        console.log(data)
      this.rolesAndRightsService.formData.id = data.id;
      this.rolesAndRightsService.formData.roleId = data.roleId;
      this.rolesAndRightsService.formData.companyId = data.companyId;
      this.rolesAndRightsService.formData.rolePermission = data.rolePermission;
      //this.rolesAndRightsService.formData.roleDetails=data.roleDetails.toString().split(',');
      this.userRoleDetails = data.roleDetails.toString().split(',');
      console.log("==this.roleDetails==");
      console.log(this.userRoleDetails);
       console.log(data.roleDetails);
      this.rolesAndRightsService.formData.roleDetails = this.userRoleDetails.join();

    } else {
      //this.rolesAndRightsService.formData.roleDetails = this.roleDetails.join();
      this.rolesAndRightsService.deleteData(data.id).subscribe((data) => {
        console.log('Deleted');
        this.rolesAndRightsService.getData((response) => {
          console.log(response);
          this.dataSource = new MatTableDataSource(response.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        })
      })
    }
    window.scroll(0,0);

  }

  resetForm(){
    this.rolesAndRightsService.formData.id = "";
    this.rolesAndRightsService.formData.roleId = "";
    //this.rolesAndRightsService.formData.companyId = "";
    this.rolesAndRightsService.formData.rolePermission = "";
    this.rolesAndRightsService.formData.roleDetails = "";

    // this.myForm.reset();
  }


}
