import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SessionStorageService } from 'angular-web-storage';
import { CookieService } from 'ngx-cookie-service';
import { CompanyService } from '../company/company.service';
import { VesselsService } from '../vessels/vessels.service';
import { VoyagelistService } from '../voyagelist/voyagelist.service';
import { StatussheetreportService } from './statussheetreport.service';
import domtoimage from 'dom-to-image';
import * as jsPDF from 'jspdf';
import { AppCommonService } from 'src/app/app.common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-statussheetreport',
  templateUrl: './statussheetreport.component.html',
  styleUrls: ['./statussheetreport.component.scss']
})
export class StatussheetreportComponent implements OnInit {
  allVessel:any = [];
  allVessel1:any = [];
  crewListAvailable:any;
  dataFillingStarted:any;
  dataSyncFromShipServerToShoreServer:any;
  dataSyncWithDesktop:any;
  dataSyncWithIpadTablet:any;
  nextStepsForCampbell:any;
  nextStepsForEsoft:any;
  percentCompletion:any;
  responsibityNextStepsCampbell:any;
  responsibityNextStepsEsoft:any;
  shipName:any;
  softwareImplementedByEsoft:any;
  supportRequiredFromCaptainKeshav:any;
  fleetOverallImplementationKPI: any;
  roleName: string;
  userVesselData: any = [];
  voyageId: any;
  imoNo: any;
  shipFrom: any;
  shipTo: any;
  startDate: any;
  selectedCompany:any;
  userVoyageData: any = [];
  companyList: any = [];
  selectedVoyage: string;
  voyageNo: any;
  vesselcode:any;
  public cookieIp = "";
  userSign: any = [];
  currentVessel: string;
  collection:any = [];

  Shipname:any;
  softwareimplementedbyESoft:any;
  tablecrewListAvailable:any;
  tabledataSyncFromShipServerToShoreServer:any;
  tabledataSyncWithIpadTablet:any;
  tabledataSyncWithDesktop:any;
  tabledataFillingStarted:any;
  tablenextStepsForCampbell:any;
  tableresponsibityNextStepsCampbell:any;
  tablenextStepsForEsoft:any;
  tableresponsibityNextStepsEsoft:any;
  tablepercentCompletion:any;
  tablesupportRequiredFromCaptainKeshav:any;
  searchValue:string = '';
  currentBookName:any;
 
  constructor(public http: HttpClient,public Statussheetservice:StatussheetreportService,
    public datePipe: DatePipe, public session: SessionStorageService, private cookieService: CookieService,
    private domSanitizer: DomSanitizer,public vs: VesselsService,public voyagelistService: VoyagelistService,
    public cmps: CompanyService, public commonService: AppCommonService, private router: Router) { }

  ngOnInit() {

  //   this.Statussheetservice.getAllVesselsStatusSheetReport((response)=>{
  //     console.log(response);
  //   })
  // }
  this.Statussheetservice.getAllVesselsStatusSheetReport((response)=>{
    this.collection = [];
    console.log("STATUS SHEET REPORT")
    console.log(response);
    this.allVessel = response.data;
    console.log("ALL VESSEL");
    console.log(this.allVessel);
    this.crewListAvailable = this.allVessel.crewListAvailableTotal
    this.softwareImplementedByEsoft = this.allVessel.softwareImplementedByEsoftTotal;
    this.dataSyncFromShipServerToShoreServer = this.allVessel.dataSyncFromShipServerToShoreServerTotal;
    this.dataSyncWithIpadTablet = this.allVessel.dataSyncWithIpadTabletTotal;
    this.dataSyncWithDesktop = this.allVessel.dataSyncWithDesktopTotal;
    this.dataFillingStarted = this.allVessel.dataFillingStartedTotal;
    this.fleetOverallImplementationKPI = this.allVessel.fleetOverallImplementationKPI;
    // this.allVessel.statusSheetLists.forEach(element => {
    //   console.log(element.id);
    //   console.log(element.shipName);
    //   this.collection.push({'shipName':element.shipName})
    // });
});

this.cookieIp = this.cookieService.get("campbell_app_ip_address");

var companyid = this.session.get("companyId");
if (companyid) {
 this.getVesselList(companyid);
} else {
 this.getCompanyList();
}

if (this.session.get("passcode")) {
  this.Statussheetservice.getLatestCOSPByPasscode(
    this.session.get("passcode"),
    (response) => {
   
      const responseData = response.data;
  
      if (response.responsecode == 200) {
       this.getVoyageData(responseData.vesselId);
        this.currentVessel = responseData.vesselId;
        console.log(this.currentVessel);
        console.log(responseData.vesselId);

        if (this.voyageId) {
          this.vs.getDataById(responseData.vesselId, (vesseldata) => {
            if (vesseldata.responsecode == 200) {
           //   console.log("=================33333==================");

              if (
                vesseldata.data.imoNo != null &&
                vesseldata.data.imoNo != ""
              ) {
                this.imoNo = vesseldata.data.imoNo;
              } else {
                this.imoNo = vesseldata.data.vesselCode;
              }
              console.log(this.imoNo);
              console.log( vesseldata.data.imoNo);
              console.log(vesseldata.data.vesselCode);
              this.shipName = responseData.shipName;
              this.shipFrom = responseData.fromPort;
              this.shipTo = responseData.toPort;
              this.startDate = responseData.voyageStartDate;
              this.selectedCompany = vesseldata.data.companyId;
              if (!companyid) {
                this.getVesselList(this.selectedCompany);
              }
            }
          });
        }

      }
    }
  );
}            
}

getCompanyList() {
  this.cmps.getData((response) => {
 //   console.log("====company====");
 //   console.log(response.data);
    this.companyList = response.data;
  });
}

selectCompany(id) {
  this.getVesselList(id);
 // this.currentVessel = id;
}

getVesselList(id) {
  this.vs.getDataByCompanyId(id, (response) => {
//    console.log(response);
    this.userVesselData = response.data;
  });
}

getVesselDetailsById(id) {
  this.vs.getDataById(id, (response) => {
 //   console.log("vessel===data");
 //   console.log(response);
    this.selectedCompany = response.data.companyId;
    this.getVesselList(response.data.companyId);
  });
}

selectVoyage(value) {
  this.voyageNo = value;

}



getVoyageData(id) {
  this.voyagelistService.getAllClosedVoyageByvesselId(id, (response) => {
    console.log("follow the data123");
    console.log(response.data);
    var combinedArray = [];

    this.voyagelistService.getDataById(id, (resp) => {
      if (resp.data) combinedArray.push(resp.data);

      for (let key in response.data) {
        //response.data[key].cosp['type'] = (parseInt(key)+1) + '- COSP'
        combinedArray.push(response.data[key].cosp);
      }
      this.userVoyageData = combinedArray;
      console.log("====resp.data====");
      console.log(resp.data);

      this.Statussheetservice.getAllVesselsStatusSheetReport((response)=>{
        this.collection = [];
        console.log("STATUS SHEET REPORT")
        console.log(response);
        this.allVessel1 = response.data;
        console.log("ALL VESSEL");
        console.log(this.allVessel);
        this.allVessel.statusSheetLists.forEach(element => {
          console.log(this.shipName);
         
          console.log(element.id);
          this.Shipname = element.shipName;
          this.softwareimplementedbyESoft = element.softwareImplementedByEsoft;
          this.tablecrewListAvailable  = element.crewListAvailable    
          this.tabledataSyncFromShipServerToShoreServer  = element.dataSyncFromShipServerToShoreServer
          this.tabledataSyncWithIpadTablet = element.dataSyncWithIpadTablet
          this.tabledataSyncWithDesktop = element.dataSyncWithDesktop
          this.tabledataFillingStarted = element.dataFillingStarted
          this.tablenextStepsForCampbell = element.nextStepsForCampbell
          this.tableresponsibityNextStepsCampbell = element.responsibityNextStepsCampbell
          this.tablenextStepsForEsoft = element.nextStepsForEsoft
          this.tableresponsibityNextStepsEsoft = element.responsibityNextStepsEsoft
          this.tablepercentCompletion = element.percentCompletion
          this.tablesupportRequiredFromCaptainKeshav = element.supportRequiredFromCaptainKeshav
          if(this.Shipname == this.shipName){
            this.collection.push({'shipName':this.Shipname,'softwareimplementedbyESoft':this.softwareimplementedbyESoft,'crewListAvailable':this.tablecrewListAvailable,'dataSyncFromShipServerToShoreServer': this.tabledataSyncFromShipServerToShoreServer,
          'dataSyncWithIpadTablet':this.tabledataSyncWithIpadTablet,'dataSyncWithDesktop':this.tabledataSyncWithDesktop,'dataFillingStarted':this.tabledataFillingStarted,
        'nextStepsForCampbell':this.tablenextStepsForCampbell,'responsibityNextStepsCampbell':this.tableresponsibityNextStepsCampbell,'nextStepsForEsoft':this.tablenextStepsForEsoft,
      'responsibityNextStepsEsoft':this.tableresponsibityNextStepsEsoft,'percentCompletion':this.tablepercentCompletion,'supportRequiredFromCaptainKeshav':this.tablesupportRequiredFromCaptainKeshav});
          }
         
        console.log(this.collection);
        });
       
    });

     
      this.shipName = resp.data.shipName;
      this.shipFrom = resp.data.fromPort;
      this.shipTo = resp.data.toPort;
      this.startDate = resp.data.voyageStartDate;
    });
  });
}

// doFilter(value: string){
//   this.allVessel.statusSheetLists.filter = value.trim().toLocaleLowerCase();
//   console.log(this.allVessel.statusSheetLists);
//   console.log(value);
// }

getSignature(imagedata: string) {
  return (imagedata != "" && imagedata != null) ? this.domSanitizer.bypassSecurityTrustUrl('data:image/jpg;base64, ' + imagedata) : this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABmJLR0QA/wD/AP+gvaeTAAABQUlEQVRYhe3WTytEYRTH8Q+NNERZmFIWlhQrspK1LUuy8C5EsmFnyxvwHpQsrZSVWVhYSA01CxulFA2LeYY7/tzM4xoW91en+3See875du7TPQ+5/rGeI+wcQ38JkClEI2EMcCYQsQDlxLP0FwCDOJNBJ2IBZAVxhLkIgM+s/FVQRwxZCkCaWq5VwCYucYUtdLXO1fKnfNWGj63cbifARQicxXRYV9oJcB8Ce36YKDWuMyJhpsoBcoAcoNCGGqkz4DsdKAaL0QAWI2OdqP/B9rDj87Fawi6quMFCYq+I4xC3FgMwjgfU8IRHTCX2V3CreVg1ZkU/DoPvCsMxALDu4yTsxX7Cf4DJsK5hxtu9sIqx2OLUD+lpSNgdfMsh+R2WEu9ea+5GGSM/Kd7QhObWwypG3/nmA0RF/cz0ZVE816/rBQZFh/X/XGGHAAAAAElFTkSuQmCC');
}


print(event: any): void {
  //var printContents = document.getElementById('print-section').innerHTML;
  const page_header = (document.getElementById('page_header') != null) ? document.getElementById('page_header').innerHTML : '';
  //const page_body = (document.getElementById('page_body')!=null)?document.getElementById('page_body').innerHTML:'';

  const page_body = (document.getElementById('page_body') != null) ? document.getElementById('page_body').innerHTML : document.getElementById('print-section').innerHTML;

  const page_footer = (document.getElementById('page_footer') != null) ? document.getElementById('page_footer').innerHTML : '';
  const stylesHtml = this.getTagsHtml('style');
  const linksHtml = this.getTagsHtml('link');
  const bookname = document.getElementById('bookname').innerHTML;

  const popupWin = window.open('', '_blank', 'top=0,left=0,height=500,width=500');
  //popupWin.document.open();
  popupWin.document.write(`
  <html>
      <head>
          <title>${bookname}</title>
           <style>
        
          @media print {
              
              @page {
                margin:5px;
                font-size:20px;
                padding:5px; text-align:left;
              }
            
          .row {
              display: -webkit-box;
              display: inline-flex;
              text-align:left;
             
          }
              .col-sm-12 {
                  width: 100%;
                  text-align:left;
              }
              .col-sm-2 {
                  width: 350px;
                  text-align:left;
                  
                  margin:5px 0x;
              }
              .smtext {position: absolute;}
              .sign-date {font-size:15px;line-height:50px !important; border:1px solid black}
              .date-txt {font-size:20px}
              .col-sm-9 {
                  width:83%;
              clear:both
                  font-size:20px;margin:5px 0x;
          }
          #page-header {margin:0px}

          
          .frontprint {width:100%}
          .frontprint .col-sm-2 {width:25%}
          .frontprint .col-sm-10 {width:75%}

          .printtext1 {font-size:0.8em !important;}
          .footerprint {width:100%}
.footerprint .col-sm-2 {width:25%}
.footerprint .col-sm-10 {width:70%}


.grabfront {width:100%; margin:0px; padding:0px;}
.grabfront .col-sm-2 {width:200px; text-align:left}
.grabfront fronthead1 {width:100px !important; text-align:left}

          .printtext {font-size:1.0em}
              .col-sm-10 {
                  width:83%;
                  clear:both
                  font-size:20px;margin:5px 0x;
         }
              .col-sm-3 {width:200px;
                  text-align:left;
                  font-size:20px; padding:0px;}
              input {background: #fff;
                  border: 0px;
                  border-bottom: 1px solid #ccc;
                  padding: 5px 10px;
                  width: 100%; margin-left:10px; margin-bottom:10px;}

              table {border-collapse: collapse;border:1px solid #fff; font-size:20px;}
              table td, table th {border:1px solid #fff; }
             thead h2 {
                  margin: 0;
                  color: #464457;
                  font-size:0.8em;
                  text-align: left;
                  padding:20px 0;
         }
         
            tfoot .col-md-12 {width:100%; text-align:right; font-size:0.8em}
            tfoot .col-sm-2 {
              width:350px;
              text-align:left;
              
              margin:5px 0x;
          }
              tbody .table th {border:1px solid #ccc; text-align:left;  font-size:20px!important; padding:10px}
              tbody .table td {border:1px solid #ccc;  font-size:20px!important; padding:10px; display: table-cell;
              }
              thead th{ border:0px solid red;}
                tfoot th {bottom: 0; left:0; border:0px solid red; background : white; height:100px;}
          .textblack {font-size:20px}
          .card {
              
              width: 100%;
          }
          .garbagefont {transform: scale(1.0); transform-origin:0 0; }
          .scale {transform: scale(1.0); transform-origin:0 0; }
          .scalefuel {transform: scale(0.9); transform-origin:0 0; }
          .scale td, .scale th {font-size:1em}
          ul {
              font-size: 20px;
              line-height: 1.5em;
              padding: 0;
              display: contents;
              width:100%;
         }
          ul li {
              list-style: none;
              position: relative;
              padding: 0 20px 0 0px !important;
              display: -webkit-box;
              display: inline-block;
         }
          ul.square li::before {
              content: "";
              position: absolute;
              left: 0;
              top: 9px;
              width: 6px;
              height: 6px;
              background-color: black;
         }
          ul.round li::before {
              content: "";
             /*position: absolute;
             */
              content: "";
              left: 0;
              top: 9px;
              width: 6px;
              height: 6px;
              background-color: black;
              border-radius: 50%;
              -moz-border-radius: 50%;
              -webkit-border-radius: 50%;
              margin-right: 15px;
              position: absolute;
         }
         thead  input {width:90%;  font-size:1.0em}
        thead label {padding-left:10px; }
        tfoot th input {width:90%;}
        tfoot th label {padding-left:10px; }
         .text-right {text-align:left;}
         
         
         
      }

      .signtext  {width:100%;text-align:left}

      .signtext .col-sm-5 {width:300px;}
      .signtext .col-sm-7 {width:400px;}

      .signtext .offset-7 {margin-left:50%}
      .signtext .rev {margin-left:75%}
      .col-sm-7 {
         width: 58.33333333%;
      }
     
      .mainmastr {width: 100%;
         }
     
         .revtext {width: 100%; tex-align:left;
         }

      .mastesign .col-sm-7 {
          width:300px;
       }
       .mastesign .col-sm-7 input {width:90%}
       .mastesign .col-sm-5 {
          width:250px;
       }
       .mastdate .col-sm-7 {
          width:300px;
       }
       .mastdate .col-sm-5 {
          width:220px; padding-left:10px;
       }
       .card-title  {text-align:left; line-height:50px; padding-left:0px !important;
          padding-bottom:10px; margin-left: 0px !important; font-size:1.0em}

       .garbage1 {width:200px}
       .garbage2 {width:300px}
       .garbage3 {width:250px}
       .garbage4 {width:350px}
       .garbage5 {width:150px}
       .garbage6 {width:400pt}
       
         .formprview  {display: flex;    white-space: nowrap; font-size:13px;}
.formlabel {padding-top:16px;}
.inputwidth   {width:100%; padding: 0 10px;}
.forminput {background: none;
border: 0px;
border-bottom: 1px solid #ddd;}
.radio-label  {
padding: 10px 25px 0 7px;
}

.inputwidth1 {width:94% !important; }

.formprviewinner  {display: flex;}

.radio {padding-top: 9px;}

.formprviewinner .formlabel1 {padding-top:16px;}
.formprviewinner .inputwidth1   {width:100%; padding: 0 10px;}
.textsmall {font-size:13px;} 
       
       .decklog .col-sm-2 {width:250px}
       
         }
         
         
          </style>
          
      </head>
      <body onload="window.print();window.close()">
      <table border="0" width="100%" style="font-size:2em;" >
           <thead><tr><th>${page_header}</th></tr></thead>
           <tbody><tr><th>${page_body}</th></tr></tbody>
           <tfoot><tr><th>${page_footer}</th></tr></tfoot>
           </table>
      </body>
  </html>
  `
  );
  popupWin.document.close();
}

private getTagsHtml(tagName: keyof HTMLElementTagNameMap): string {
const htmlStr: string[] = [];
const elements = document.getElementsByTagName(tagName);
for (let idx = 0; idx < elements.length; idx++) {
    htmlStr.push(elements[idx].outerHTML);
}

return htmlStr.join('\r\n');
}

downloadPDF(pdfTitle: any){  
  var node = document.getElementById('print-section');
  var newPdfTitle = pdfTitle ? pdfTitle : 'Title';
  const htmlContent = node.innerHTML;
  this.commonService.openDialog(htmlContent, newPdfTitle);
  }
    
}



