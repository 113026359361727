import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl,ReactiveFormsModule,FormArray } from '@angular/forms';
import { AddformnotesService } from './addformnotes.service';
import { HttpClient } from '@angular/common/http';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { SessionStorageService } from 'angular-web-storage';
import { ActivatedRoute, RouterModule, Routes,Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Location } from '@angular/common';
import * as _ from 'lodash'

@Component({
  selector: 'app-addformsnotes',
  templateUrl: './addformsnotes.component.html',
  styleUrls: ['./addformsnotes.component.scss']
})
export class AddformsnotesComponent implements OnInit {

  formData: any = [];
  rows: FormArray;
  itemForm: any = [];
  noteData: any = [];
  formId:any; sectionId:any;
  showEdit: any;
  htmlControls = [];
  dataExists:any;
  noteListType:any = [];
  
    @ViewChild('deleteSwal', { static: false }) public deleteSwal: SwalComponent;
  @ViewChild('successbox', { static: true }) public successbox: SwalComponent;

  @ViewChild('errorbox', { static: true }) public errorbox: SwalComponent;
  
  constructor(public location:Location,private router:Router, public addformsnoteService: AddformnotesService,private formBuilder: FormBuilder, public session: SessionStorageService) { 
       this.rows = this.formBuilder.array([]);
  }

  
 
  ngOnInit() {
      this.formId = this.session.get('formlistId');
      this.sectionId = 1;
      
      this.formData = this.formBuilder.group({
              formId: new FormControl(null)
      })
      
     
      this.getformdata();
      
       
       
      this.formData.addControl('rows', this.rows);
      this.createNoteTypeJson();
    
  }

  getformdata(){
    this.addformsnoteService.getAllNoteByformlistId(this.formId,(response) => {
      console.log(response.data);
      this.noteData = response.data;
      if(response.data){
          this.addDataRowArrangement(this.noteData.length,this.noteData);
      }else{
          this.onAddSectionArrangement(1);
      }
 })
  }

  arrUnique(arr) {
    var cleaned = [];
    arr.forEach(function(itm) {
        var unique = true;
        cleaned.forEach(function(itm2) {
            if (_.isEqual(itm, itm2)) unique = false;
        });
        if (unique)  cleaned.push(itm);
    });
    return cleaned;
}


  
  submitHandler(){
    
      console.log("before save");
      this.itemForm = [];
      if (this.formData.valid) {
       
        console.log(this.formData.value);
          for(let row of this.formData.get('rows').controls){
              if(row.controls.newsection.value==1){
                console.log(this.formData);
                console.log(row);
                console.log(this.formData.get('rows').controls);
            //    console.log(this.formId);
                
                  this.itemForm.push({'formId':this.formId,'title':row.controls.title.value,'description':row.controls.description.value,'position':row.controls.position.value,});
              }
          }
         
           console.log(this.itemForm); 
       //    console.log(this.formData);
        //    if(this.formData.get('rows').value.length != 0 && this.formData.get('rows').value[0].id==null){
        //  //  this.formData.get('rows').value[0].remove();
        //    console.log(this.formData.value);
        //    console.log(this.formData.value.rows[0]);
        //   //  this.formData.get('rows').value[0] = null;
        //    }
           if(this.rows.value.length != 0 &&  this.rows.value[0].id == null){
          //  this.formData.removeControl('rows', this.rows.value[0]);
            console.log(this.rows);
            console.log(this.rows.value[0])
         //   this.rows.value[0].remove();
          }
          console.log(this.rows);
           var cleanedarray = this.arrUnique(this.itemForm);
           console.log(cleanedarray);

           
        
        if(this.itemForm.length>0){
               this.addformsnoteService.saveformlistNotes(cleanedarray, (response) => {
                     console.log(response);
                     console.log(cleanedarray)
                    if (response.responsecode === 200 || response.responsecode === '200') {
                      
                          console.log(cleanedarray);
                            this.successbox.show();
                            
                            this.getformdata();
                      
                        //    window.location.reload();
              
                      
                    } else {
                              this.errorbox.text = response.message;
                              this.errorbox.show();
                    }
                })
        }
           
           console.log(this.itemForm);
       }
  }

  
  
  createNoteTypeJson(){
      this.noteListType[0] = {'notTypeValue':'Header','notTypeName':'Header Note'};
      this.noteListType[1] = {'notTypeValue':'Footer','notTypeName':'Footer Note'};
      this.noteListType[2] = {'notTypeValue':'FooterBottom','notTypeName':'Footer below Signature'};
  }
  
  
  onSectionSelect(index,section_id){
            for (let r = 0; r<this.rows.controls.length; r++){
                    if(r==index){//alert(index)
                        this.rows.controls[r]['controls']['sectionId'].value==section_id;
                    }
                }
   }
   
   onAddSectionArrangement(event: any) {
     console.log(event);
        var arr = {'formId':this.formId,'title':'','position':'Header','description':'','newsection':1,'hidingsection':0,'id':null}
        for(var i=0; i<event; i++){
            this.rows.push(this.createItemFormGroupArrangement(arr));

        }
   }
  
    addDataRowArrangement(count,arr: any) {
   // console.log(this.rows);
    if(this.rows.value.length != 0 &&  this.rows.value[0].id == null){
      this.rows.value[0].removeControl('rows');
      this.rows.value[0].remove();
    }
    console.log(arr);
       for(var i=0; i<count; i++){
           this.rows.push(this.createItemFormGroupArrangement(arr[i]));
       }
       console.log('test');
    }
     
     
 createItemFormGroupArrangement(datas: any): FormGroup {
                    
    return this.formBuilder.group({
      formId: (datas.formId),
      description: (datas.description),
      position: (datas.position),
      title: (datas.title),
      newsection:(datas.newsection?datas.newsection:0),
      hidesection:(0),
      id:(datas.id?datas.id:null)
    });
  }
  

  onRemoveRowArrangement(rowIndex:number){
        // if(this.formData.value.rows[rowIndex].id){
        //       this.addformsnoteService.deleteData(this.formData.value.rows[rowIndex].id).subscribe((data) => {
        //         console.log('Deleted');
        //       })
        //     }
        if (this.formData.value.rows[rowIndex].id) {
          this.addformsnoteService.deleteData(this.formData.value.rows[rowIndex].id,(data) => {
            console.log(data);
            if (data.responsecode === 200 || data.responsecode === '200') {
             this.successbox.show();
             this.getformdata();
            } else { 
              this.errorbox.text = data.message;
              this.errorbox.show();
            }
          })
  }
        this.rows.removeAt(rowIndex);
        if(this.rows.length<=0)
        this.router.navigate(['/formlist']);
    }
    
    updateRowArrangement(rowIndex:number){
        console.log("click")
        console.log(this.formData.value.rows[rowIndex]);
              if (this.formData.value.rows[rowIndex].newsection==0) {
                    this.addformsnoteService.updateNotesDetails(this.formData.value.rows[rowIndex].id, this.formData.value.rows[rowIndex], (data) => {
                      console.log(data);
                      if (data.responsecode === 200 || data.responsecode === '200') {
                       this.successbox.show();
                      } else {
                        this.errorbox.text = data.message;
                        this.errorbox.show();
                      }
                    })
            }
        this.showEdit = -1;
    }
    
    editRowArrangement(rowIndex:number){
        if (this.formData.value.rows[rowIndex].newsection==0) {
            this.showEdit = rowIndex;
        }
    }        
  
  backButton(){
   this.router.navigate(['/formlist']);   
  }
  
    
  
  htmlContent = '';

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    sanitize: false,
    height: '8rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
}
