import { Component, OnInit, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { WatchHourService } from "../watch-hour.service";
import { MatTableDataSource, MatPaginator, MatSort } from "@angular/material";
import { MatFormFieldModule } from "@angular/material/form-field";
import { DatePipe } from "@angular/common";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { of } from "rxjs";
import { delay } from "rxjs/operators";

export interface IsTrueFalse {
  id: boolean;
  viewValue: boolean;
}

@Component({
  selector: "app-watch-hour",
  templateUrl: "./watch-hour.component.html",
  styleUrls: ["./watch-hour.component.css"],
})
export class WatchHourComponent implements OnInit {
  isLoading = true;
  // displayedColumns: string[] = ['id', 'date', 'watchHour', 'active', 'edit'];
  displayedColumns: string[] = ["date", "watchHour", "active", "edit"];
  dataSource;

  myForm: FormGroup;
  Watchhourbydate: FormGroup;
  afs: any;
  success: boolean;
  loading: boolean;
  data: any = [];
  id: any;
  product: any;
  watchhour: any = [];
  public formData;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild("deleteSwal", { static: false }) public deleteSwal: SwalComponent;
  @ViewChild("successbox", { static: true }) public successbox: SwalComponent;

  @ViewChild("errorbox", { static: true }) public errorbox: SwalComponent;
  @ViewChild("watchhourerrorbox", { static: true })
  public watchhourerrorbox: SwalComponent;
  @ViewChild("deletesuccessbox", { static: true })
  public deletesuccessbox: SwalComponent;

  istruefalse: IsTrueFalse[] = [
    { id: true, viewValue: true },
    { id: false, viewValue: false },
  ];

  constructor(
    public datePipe: DatePipe,
    public fb: FormBuilder,
    public watchHourService: WatchHourService,
    public http: HttpClient
  ) {
    this.resetForm();
  }

  ngOnInit() {
    of(this.dataSource)
      .pipe(delay(1000))
      .subscribe(
        (data) => {
          this.isLoading = false;
          this.data = data;
        },
        (error) => (this.isLoading = false)
      );

    this.watchHourService.getData((response) => {
      console.log(response);
      this.dataSource = new MatTableDataSource(response.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });

    this.myForm = this.fb.group({
      date: new FormControl(null, [Validators.required]),
      watchHour: new FormControl(null, [Validators.required]),
      active: new FormControl(null, [Validators.required]),
    });

    this.Watchhourbydate = this.fb.group({
      watchhourbydate: new FormControl(null, [Validators.required]),
    });
  }

  submitHandler() {

    if (this.myForm.valid) {
      this.watchHourService.formData.date = this.datePipe.transform(
        this.watchHourService.formData.date,
        "dd-MM-yyyy"
      );

      console.log(this.myForm.value);
      console.log(this.watchHourService.formData);
      if (this.watchHourService.formData.id) {
        this.watchHourService.putData(
          this.watchHourService.formData.id,
          this.watchHourService.formData,
          (data) => {
            console.log(data);

            if (data.responsecode === 200 || data.responsecode === "200") {
              this.watchHourService.getData((response) => {
                console.log(response);
                this.dataSource = new MatTableDataSource(response.data);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                this.successbox.show();

                this.resetForm();
                this.myForm.reset();

              });
            } else {
              this.errorbox.text = data.message;
              this.errorbox.show();
            }
          }
        );
      }
      //  else {

      //   this.fieldTypeService.postData(this.myForm.value,(response)=>{
      //    console.log(response);
      //   })
      // }
    }
  }

  generatetodaywatchhour() {
    this.loading = true;
    this.watchHourService.getwatchhours((response) => {
      console.log(response);

      if (response.responsecode === 200 || response.responsecode === "200") {
        this.successbox.show();
        this.watchHourService.getData((response) => {
          console.log(response);
          this.dataSource = new MatTableDataSource(response.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.loading = false;
          this.successbox.show();
          this.resetForm();
        });
      } else {
        this.loading = false;
        this.watchhourerrorbox.show();
      }
    });
  }

  Watchbydate() {
    if (this.Watchhourbydate.valid) {
      // Show loader
    this.loading = true;

      console.log(this.Watchhourbydate.value);
      this.watchHourService.formData.watchhourbydate = this.datePipe.transform(
        this.Watchhourbydate.value.watchhourbydate,
        "dd-MM-yyyy"
      );
      this.watchHourService.generatewatchhoursbasedonmodebydate(
        this.watchHourService.formData.watchhourbydate,
        (response) => {
          console.log(response);
          if (
            response.responsecode === 200 ||
            response.responsecode === "200"
          ) {
            this.watchHourService.getData((response) => {
              console.log(response);
              this.dataSource = new MatTableDataSource(response.data);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.loading = false;
              this.successbox.show();
              this.resetForm();
            });
            this.successbox.show();
          } else {
            this.loading = false;
            this.errorbox.show();
          }
        }
      );
    }
  }

  onClick(action, data) {
    if (action == "edit") {
      this.watchHourService.formData.id = data.id;
      this.watchHourService.formData.date = data.date;
      this.watchHourService.formData.watchHour = data.watchHour;
      this.watchHourService.formData.active = data.active;

      const month = data.date.split("-");
      const newDate = new Date(+month[2], +month[1] - 1, +month[0]);
      console.log(newDate);
      this.watchHourService.formData.date = newDate.toISOString();
    } else {
      this.watchHourService.deleteData(data.id).subscribe((data) => {
        console.log("Deleted");
        console.log(data);
        if (data["responsecode"] == "200") {
          this.deletesuccessbox.text = data["message"];
          this.deletesuccessbox.show();
          this.watchHourService.getData((response) => {
            console.log(response);
            this.dataSource = new MatTableDataSource(response.data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          });
        }else{
          this.errorbox.text = data["message"];
          this.errorbox.show();

        }
      });
    }
    window.scroll(0, 0);
  }

  resetForm() {
    this.watchHourService.formData.id = "";
    this.watchHourService.formData.date = "";
    this.watchHourService.formData.watchHour = "";
    this.watchHourService.formData.active = "";

    this.watchHourService.formData.date = "";
    // this.myForm.reset();
  }
}
