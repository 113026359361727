import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from '../apiroot.service';
@Injectable({
  providedIn: 'root'
})
export class FormlistvesselsService {

  formTypeData = {id:'', vesselId:''}
  
constructor(public httpClient : HttpClient,public service:ApiRootService) { }

  

  postData(formData,callback){
    console.log('---save data--');
    console.log(formData);
    return this.httpClient.post(this.service.getBaseUrl()+'/saveNewFormsVesselDetailswithVesselList',formData).subscribe((data)=>{
    console.log(data);
    console.log('---save data done--');
    callback(data);
  })
}
  
  getDataById(id,callback){
    console.log('---getDataById--');
    console.log(this.service.getBaseUrl()+'/getFormsVesselDetailsByVesselId/'+id);
    return this.httpClient.get(this.service.getBaseUrl()+'/getFormsVesselDetailsByVesselId/'+id).subscribe(
      (data) =>{
           console.log('---getDataById done--');
        // console.log(data);
        callback(data);
      }
    )
  }  
  // http://3.141.94.216:9002/campbellservice/getFormsVesselDetailsByFormsId/185

  getDataByIdNew(id,callback){
    console.log('---getDataById--');
    console.log(this.service.getBaseUrl()+'/getFormsVesselDetailsByFormsId/'+id);
    return this.httpClient.get(this.service.getBaseUrl()+'/getFormsVesselDetailsByFormsId/'+id).subscribe(
      (data) =>{
           console.log('---getDataById done--');
        // console.log(data);
        callback(data);
      }
    )
  }  

  deleteData(id){
     return this.httpClient.delete(this.service.getBaseUrl()+'/softDeleteFormsVesselDetailsByFormsId/'+id);   
  }
}
