import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from '../apiroot.service';

@Injectable({
  providedIn: 'root'
})
export class EmailformreportsService {
  // getAllRole(arg0: (response: any) => void) {
  //   throw new Error('Method not implemented.');
  // }

  formData = {bookTypeId:''};


  constructor(public http:HttpClient,public service:ApiRootService) { }


  getCompanyDetail(vesselId, callback){
    return this.http.get(this.service.getBaseUrl()+'/getVesselsByVesselsId/'+vesselId).subscribe(
      (data) => {
        console.log('getcompanynamebyvessel',data)
        callback(data);
      }
    )
  }

   getAllCat(callback){
    return this.http.get(this.service.getBaseUrl()+'/getAllFormsCategory').subscribe(
      (data) =>{
          console.log(data);
        callback(data);
      }
    )
  }

  getAllFormlistByCatId(id, callback){
    return this.http.get(this.service.getBaseUrl()+'/getFormtDetailsByCategoryId/'+id).subscribe(
      (data) =>{
          console.log(data);
        callback(data);
      }
    )
  }

  getAllFormlistAnsByChecklistId(formlist,vesselId, callback){
    return this.http.get(this.service.getBaseUrl()+'/getFormAnswerDetailsByFormIdAndDate/'+vesselId+'/'+formlist.formlistId+'/'+formlist.actionDate).subscribe(
      (data) =>{
          console.log(data);
        callback(data);
      }
    )
  }

  getFormAnswerDetailsByFormIdAndDateWithTime(forms,vesselId, callback){
    return this.http.get(this.service.getBaseUrl()+'/getFormAnswerDetailsByFormIdAndDateWithTime/'+vesselId+'/'+forms.id+'/'+forms.actionDate+'/'+forms.actionTime).subscribe(
      (data) =>{
          console.log(data);
        callback(data);
      }
    )
  }

  getAllNotes(id, callback){
    return this.http.get(this.service.getBaseUrl()+'/getFormNotesByFormId/'+id).subscribe(
      (data) =>{
          console.log(data);
        callback(data);
      }
    )
  }

  getFormAnswerDetailsByFormIdAndDateGroupByActionDate(id,vesselId, callback){
    return this.http.get(this.service.getBaseUrl()+'/getFormAnswerDetailsByFormIdAndDateGroupByActionDate/'+vesselId+'/'+id).subscribe(
      (data) =>{
          console.log(data);
        callback(data);
      }
    )
  }

   getFormlistDetailsByFormlistId(id, callback){
    return this.http.get(this.service.getBaseUrl()+'/getFormsDetailsByFormsId/'+id).subscribe(
      (data) =>{
          console.log(data);
        callback(data);
      }
    )
  }


  getFormlistApprovalDetails(forms,vesselId, callback){
    return this.http.get(this.service.getBaseUrl()+'/getFormAnswerDetailsApprovalByFormIdAndDateWithTime/'+vesselId+'/'+forms.id+'/'+forms.actionDate+'/'+forms.actionTime).subscribe(
      (data) =>{
          console.log(data);
        callback(data);
      }
    )
  }

  saveFormlistApprovalDetails(approvalDetail, callback){
    return this.http.post(this.service.getBaseUrl()+'/saveFormAnswerDetailsApproval',approvalDetail).subscribe(
      (data) =>{
          console.log(data);
         callback(data);
      }
    )
  }



  getCrewByCrewIdList(userIds, callback){
    return this.http.get(this.service.getBaseUrl()+'/getCrewByCrewIdList/'+userIds).subscribe(
      (data) =>{
          console.log(data);
        callback(data);
      }
    )
  }

  getFormAdditionalInfoByFormId(id, callback){
    return this.http.get(this.service.getBaseUrl()+'/getFormAdditionalInfoByFormId/'+id).subscribe(
      (data) =>{
          console.log(data);
        callback(data);
      }
    )
  }

  getFormAnsAdditionalInfoByFormIdWithDateTime(forms, callback){
    return this.http.get(this.service.getBaseUrl()+'/getFormAnsAdditionalInfoByFormIdWithDateTime/'+forms.id+'/'+forms.actionDate+'/'+forms.actionTime).subscribe(
      (data) =>{
          console.log(data);
        callback(data);
      }
    )
  }

}




