
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { SessionStorageService } from 'angular-web-storage';
import domtoimage from "dom-to-image";
import { jsPDF } from "jspdf";
// import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { ReportsService } from './reports/reports.service';

import html2canvas from "html2canvas";
import $ from "jquery";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { DownloadReportComponent } from 'src/app/reports/download-report/download-report.component';
import {
  MatPaginator,
  MatSort,
  MatTableDataSource,
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MAT_DATE_FORMATS,
} from "@angular/material";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';
import autoTable from 'jspdf-autotable';
import html2pdf from 'html2pdf.js';
@Injectable({
  providedIn: 'root'
})
export class AppCommonService {

  constructor(private domSanitizer: DomSanitizer, public dialog: MatDialog, public rs: ReportsService) { }

  downloadPdfUrl: any;
  loading: boolean = false;

  downloadPdfCommonFn1(nodeHtmlId: any = null, fileName: any = null) {
    var pdfLoaderId = document.getElementById('isPdfLoading');
    // console.log('DownloadPDF_CommonFn_0: ', nodeHtmlId, fileName, pdfLoaderId);
    let pdfTitleName = fileName ? fileName : 'Forms'
    if (!nodeHtmlId) {
      return;
    } else {
      if (pdfLoaderId) {
        pdfLoaderId.style.display = 'inline-block';
      }
    }
    'use strict';
    window["html2canvas"] = html2canvas;

    var node = nodeHtmlId;
    var downloadPdfButtonId = document.getElementById('downloadPdfButtonId');
    var clientPrintSection = document.getElementById('print-section');
    var reportDialogClose = document.getElementById('reportDialogClose');
    var applogo = document.getElementById('appLogo');
    var dataUrl;
    console.log('clientPrintSection: ', clientPrintSection);
    setTimeout(() => domtoimage.toPng(clientPrintSection)
      .then(function (dataUrl) {
        'use strict';
        var img = new Image();
        img.src = dataUrl;
        //img.crossOrigin = 'Anonymous';
        var newImage = img.src;
        img.onload = function () {
          var pdfWidth = img.width;
          var pdfHeight = img.height;
          var HTML_Width = pdfWidth;
          var HTML_Height = pdfHeight;
          var top_bottom_margin = 54;
          var left_right_margin = 15;
          var PDF_Width = pdfWidth;
          var PDF_Height = (PDF_Width * 1.5) + (top_bottom_margin * 2);
          var canvas_image_width = HTML_Width;
          var canvas_image_height = HTML_Height;

          var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

          //  var imgData = canvas.toDataURL("image/png", 1.0);
          var pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
          var marginTop = 0; // Adjust this value as needed
          var marginBottom = 0; // Adjust this value as needed
          var whitespaceHeight = 50;
          pdf.addImage(newImage, 'JPG', left_right_margin, top_bottom_margin, canvas_image_width - 30, canvas_image_height + 60, 'someAlias', 'FAST');
          var filename = pdfTitleName + '.pdf';
          const contentWidth = clientPrintSection.clientWidth;
          const scale = PDF_Width / contentWidth;

          for (var i = 1; i <= totalPDFPages; i++) {
            pdf.addPage();
            pdf.addImage(newImage, 'JPG', left_right_margin, -(PDF_Height * i) + (top_bottom_margin) + 80, canvas_image_width - 30, canvas_image_height + 60, 'someAlias', 'FAST');
          }

          if (pdfLoaderId) {
            pdfLoaderId.style.display = 'none';
          }
          pdf.save(filename);
          reportDialogClose.click();

        };
      })
      .catch(function (error) {
        if (pdfLoaderId) {
          pdfLoaderId.style.display = 'none';
        }
        console.error('oops, something went wrong!', error);
      }), 1000);
  }

  openDialog(htmlContent: any, title: any, pdfType?: any) {
    this.loading = true;

    this.rs.getSettingDetail((response) => {
      console.log("Getting Flag and other detail", response)
      console.log("shoreURL -", response.data.shorePdfUrl, 'vesselURL -', response.data.vesselPdfUrl)
      console.log("Is-this-shore-server-value", response.isShoreServer);

      if (response.isShoreServer == true || response.isShoreServer == 'true') {
        this.downloadPdfUrl = response.data.shorePdfUrl;
      } else {
        this.downloadPdfUrl = response.data.vesselPdfUrl;
      }


      let titleNew = title ? title : 'Title';
      const dialogRef = this.dialog.open(DownloadReportComponent, {
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
        panelClass: 'full-screen-modal',
        data: { name: titleNew, htmlContent: htmlContent, pdfType: pdfType, downloadPdfUrl: this.downloadPdfUrl }
      });



      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
    })
  }
  

  transform(v: any): any {
    return this.domSanitizer.bypassSecurityTrustHtml(v);
  }

  downloadPdfCommonFn(fileName: any = null, isPdfLoading) {
    "use strict";
    var node = (document.getElementById("header-footer") != null) ? document.getElementById("header-footer") : document.getElementById("print-section");
    var bookname = document.getElementById("bookname").innerHTML;
    var dataUrl;
    setTimeout(
      () =>
        domtoimage
          .toPng(node)
          .then(function (dataUrl) {
            "use strict";
            var img = new Image();
            img.src = dataUrl;
            var newImage = img.src;
            img.onload = function () {
              var pdfWidth = img.width;
              var pdfHeight = img.height;
              // Determine orientation of the PDF
              var orientation: any = (pdfWidth > pdfHeight) ? "l" : "p";
              var doc = new jsPDF(orientation, "px", [pdfWidth, pdfHeight], true);
              var width = doc.internal.pageSize.getWidth();
              var height = doc.internal.pageSize.getHeight();
              // Calculate scaling factor to fit the entire image within the PDF page
              var scaleFactor = Math.min(width / pdfWidth, height / pdfHeight);
              var scaledWidth = pdfWidth * scaleFactor;
              var scaledHeight = pdfHeight * scaleFactor;
              // Calculate centering position
              var x = (width - scaledWidth) / 2;
              var y = (height - scaledHeight) / 2;
              doc.addImage(newImage, "PNG", x, y, scaledWidth, scaledHeight);
              var filename = bookname + ".pdf";
              doc.save(filename);
              isPdfLoading.style.display = 'none';
            };
          })
          .catch(function (error) {
            console.error("oops, something went wrong!", error);
          }),
      1000
    );
  }

  generatePDF() {
    const doc = new jsPDF('p', 'mm', 'a4');
    const content = document.getElementById('print-section');

    if (!content) return;

    const tables = content.querySelectorAll('table');
    const pageHeight = doc.internal.pageSize.height;
    const margin = 10; // Adjust margin as needed
    let yOffset = margin;
    let yOffsettablle = margin;
    tables.forEach((table, index) => {
      if (index > 0 && yOffset + margin > pageHeight - margin) {
        doc.addPage();
        yOffsettablle = margin;
        yOffset = margin
      }

      (doc as any).autoTable({
        html: table,
        startY: yOffsettablle,
        theme: 'grid',
        styles: {
          cellPadding: 2,
          fontSize: 10,
          overflow: 'linebreak',
          valign: 'middle',
        },
        didDrawPage: (data) => {
          yOffset = data.cursor.y + margin;
          yOffsettablle = data.cursor.y + margin;
        },
      });

      const images = table.querySelectorAll('td img');
      let columnIndex = 0; // Track column index for images
      const columnWidth = doc.internal.pageSize.width / 2 - margin * 2;

      images.forEach((img: HTMLImageElement) => {
        const imgData = img.src;
        const imgProps = doc.getImageProperties(imgData);
        const imgWidth = columnWidth; // Fit image within column
        const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
        const positionX = margin + columnIndex * (imgWidth + margin);
        const positionY = yOffset + 5;

        if (positionY + imgHeight > pageHeight - margin) {
          doc.addPage();
          yOffset = margin;
          columnIndex = 0; // Reset column index
        }

        doc.addImage(imgData, imgData.startsWith('data:image/png;') ? 'PNG' : 'JPEG', positionX, yOffset, imgWidth, imgHeight);

        columnIndex = (columnIndex + 1) % 2; // Toggle between 0 and 1 for columns
        if (columnIndex === 0) {
          yOffset += imgHeight + margin; // Move to next row after every 2 images
          yOffsettablle += imgHeight + margin;
        }
      });

      if (yOffset + margin > pageHeight - margin) {
        doc.addPage();
        yOffset = margin;
        yOffsettablle = margin;
      }
    });
    // Add footer notes
    const notesDiv = document.getElementById('notes');
    if (notesDiv) {
      const footerNotes = notesDiv.querySelectorAll('.notesdiv');
      footerNotes.forEach((note, index) => {
        const title = note.querySelector('h4');
        const description = note.querySelector('.col-sm-12');

        if (title && description) {
          const titleText = title.innerText.trim();
          const descriptionHTML = description.innerHTML.trim();

          doc.text(titleText, margin, yOffset);
          yOffset += 10; // Adjust spacing between title and description
          doc.html(descriptionHTML);
        }
      });
    }
    doc.save('document.pdf');
  }


  handleExportPDF = async () => {
    const data = document.getElementById('print-section');
    const pageHeight = 295; // Set the desired height of each page in the PDF

    const doc = new jsPDF('p', 'mm');
    const docWidth = doc.internal.pageSize.getWidth();

    let positionY = 0;
    let currentPage = 1;

    const addNewPage = () => {
      doc.addPage();
      positionY = 0;
      currentPage++;
    };

    const renderContent = (child) => {
      return new Promise((resolve) => {
        html2canvas(child).then((canvas) => {
          const imgData = canvas.toDataURL('image/jpeg', 1.0);
          const imgHeight = (canvas.height * docWidth) / canvas.width;
          if (positionY + imgHeight > pageHeight) {
            addNewPage();
          }
          doc.addImage(imgData, 'JPEG', 0, positionY, docWidth, imgHeight);
          positionY += imgHeight;

          // resolve();
        });
      });
    };

    const children = Array.from(data.children);

    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      await renderContent(child);
    }

    doc.save('Download.pdf');
  };
}
