import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { HierarchyService } from '../hierarchy/hierarchy.service';
import { HttpClient } from '@angular/common/http';
import { WorkflowService } from '../workflow/workflow.service';
import { FormworkflowService } from './formworkflow.service';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-formworkflow',
  templateUrl: './formworkflow.component.html',
  styleUrls: ['./formworkflow.component.scss']
})
export class FormworkflowComponent implements OnInit {

  displayedColumns: string[] = ['formWorkFlowId', 'hierarchyName', 'workFlowName', 'edit'];
  dataSource = new MatTableDataSource()

  myForm: FormGroup;

  isLoading = true;
  data: any = [];
  formTypeId: any;
  product: any;
  public formData;
  hname = [];
  wfName = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('deleteSwal', { static: false }) public deleteSwal: SwalComponent;
  @ViewChild('successbox', { static: true }) public successbox: SwalComponent;

  @ViewChild('errorbox', { static: true }) public errorbox: SwalComponent;

success;
  constructor(public fb: FormBuilder, public hs: HierarchyService, public wfs: WorkflowService,
    public fwfs : FormworkflowService ,public http: HttpClient) {
    this.resetForm();


  }

  ngOnInit() {

    of(this.formData).pipe(delay(1000))
      .subscribe(data => {
        this.isLoading = false;
        this.data = data;
      }, error => this.isLoading = false)
    // this.getProductDetails(this.route.snapshot.params['id']);

    this.fwfs.getData((response) => {
      console.log(response);
      this.dataSource = new MatTableDataSource(response.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })

    this.hs.getParentCategory((response) => {
      console.log(response);
      this.hname = response.data;
      // this.dataSource = new MatTableDataSource(response.data);
    })

    this.wfs.getData((response) => {
      console.log(response);
      this.wfName = response.data;
      // this.dataSource = new MatTableDataSource(response.data);
    })

    this.myForm = this.fb.group({

      'parentFormId': new FormControl(null, Validators.required),

      'workFlowId': new FormControl(null, Validators.required)

    })
  }

  submitHandler() {

    if (this.myForm.valid) {
      console.log(this.myForm.value);

      if (this.fwfs.formTypeData.formWorkFlowId) {
        this.fwfs.putData(this.fwfs.formTypeData.formWorkFlowId, this.fwfs.formTypeData, (data) => {
          console.log(data);

          if (data.responsecode === 200 || data.responsecode === '200') {

            this.fwfs.getData((response) => {
              console.log(response);
              this.dataSource = new MatTableDataSource(response.data);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.successbox.show();

              this.resetForm();
            })
          } else {
            this.errorbox.text = data.message;
            this.errorbox.show();
          }
        })
      }
      else {
        this.fwfs.postData(this.fwfs.formTypeData, (response) => {
          console.log(response);

          if (response.responsecode === 200 || response.responsecode === '200') {

            this.fwfs.getData((response) => {
              console.log(response);
              this.dataSource = new MatTableDataSource(response.data);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.successbox.show();

              this.resetForm();
            })
          } else {
            this.errorbox.text = response.message;
            this.errorbox.show();
          }
        })
      }
    }
  }

  getProductDetails(id) {
    this.fwfs.getDataById(id, (response) => {
      console.log(response);
      this.product = response;
    })
  }



  onClick(action, data) {
    if (action == 'edit') {
      this.fwfs.formTypeData.formWorkFlowId = data.formWorkFlowId;
      this.fwfs.formTypeData.parentFormId = data.parentFormId;
      this.fwfs.formTypeData.workFlowId = data.workFlowId;


    } else {
      this.fwfs.deleteData(data.formWorkFlowId).subscribe((data) => {
        console.log('Deleted');
        this.fwfs.getData((response) => {
          console.log(response);
          this.dataSource = new MatTableDataSource(response.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        })
      })
    }
    window.scroll(0,0);

  }
  resetForm() {
    this.fwfs.formTypeData.formWorkFlowId = "";
    this.fwfs.formTypeData.parentFormId = "";
    this.fwfs.formTypeData.workFlowId = "";
    // this.myForm.reset();
  }

}
