import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from '../apiroot.service';
import { callbackify } from 'util';

@Injectable({
  providedIn: 'root'
})
export class AddformnotesService {

  formData = {section_id:''}

  constructor(public http:HttpClient,public service:ApiRootService) { }

  getAllNoteByformlistId(id,callback){
    return this.http.get(this.service.getBaseUrl()+'/getFormNotesByFormId/'+id).subscribe(
      (data) =>{
        console.log(data);
        callback(data);
      }
    )}

  getAllSections(callback){
   return this.http.get(this.service.getBaseUrl()+'/getAllFormsSections').subscribe(
      (data) => {
        console.log(data);
        callback(data);
    });

  }
  
  getQuestionData(formId,sectionId,callback){
   return this.http.get(this.service.getBaseUrl()+'/getFormsQuestionDetailsByFormsIdAndSectionId/'+formId+'/'+sectionId).subscribe(
      (data) => {
        console.log(data);
        callback(data);
    });

  }
  
  // getQuestionDataOfChecklistId(checklistid,callback){
  //  return this.http.get(this.service.getBaseUrl()+'/getChecklistQuestionDetailsByChecklistId/'+checklistid).subscribe(
  //     (data) => {
  //       console.log(data);
  //       callback(data);
  //   });

  // }
  
  
 getData(){
     
 }
 
 updateNotesDetails(formNotesId,details,callback){
   return this.http.put(this.service.getBaseUrl()+'/updateFormNotes/'+formNotesId,details).subscribe(
      (data) => {
        console.log(data);
        callback(data);
    });
 }


  deleteData(id,callback){
    return this.http.delete(this.service.getBaseUrl()+'/softDeleteFormNotesByFormNotesId/'+id).subscribe((data)=>{
      console.log(data);
      console.log(callback);
    });
  }

  
  saveformlistNotes(FormData,callback){
    return this.http.post(this.service.getBaseUrl()+'/saveFormNotesList',FormData).subscribe(
      (data) =>{
        console.log(data);
        callback(data);
      }
    )}
    
}
