import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { HttpClient } from '@angular/common/http';
import { FieldvalidationService } from './fieldvalidation.service';
import { FormsService } from '../forms/forms.service';
import { ValidationService } from '../validation/validation.service';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';


@Component({
  selector: 'app-fieldvalidation',
  templateUrl: './fieldvalidation.component.html',
  styleUrls: ['./fieldvalidation.component.scss']
})
export class FieldvalidationComponent implements OnInit {

  displayedColumns: string[] = ['id', 'formName', 'fieldName', 'validationName', 'value', 'fields', 'edit'];
  dataSource = new MatTableDataSource()

  myForm: FormGroup;

  isLoading = true;
  data: any = [];
  formTypeId: any;
  product: any;
  public formData;
  forms = [];
  fields = [];
  validations = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('deleteSwal', { static: false }) public deleteSwal: SwalComponent;
  @ViewChild('successbox', { static: true }) public successbox: SwalComponent;

  @ViewChild('errorbox', { static: true }) public errorbox: SwalComponent;

  success;
  constructor(public fb: FormBuilder, public validationService: ValidationService, public formsService: FormsService, public fieldValService: FieldvalidationService,
    public http: HttpClient) {
    this.resetForm();


  }

  ngOnInit() {

    of(this.dataSource).pipe(delay(2000))
      .subscribe(data => {
        this.isLoading = false;
        this.data = data;
      }, error => this.isLoading = false)
    // this.getProductDetails(this.route.snapshot.params['id']);

    this.fieldValService.getData((response) => {
      console.log(response);
      this.dataSource = new MatTableDataSource(response.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      // this.dataSource = new MatTableDataSource(response.data);
    })

    this.validationService.getData((response) => {
      console.log(response);
      this.validations = response.data;

    })

    this.formsService.getData((response) => {
      console.log(response);
      this.forms = response.data;
    })




    this.myForm = this.fb.group({

      'formId': new FormControl(null, Validators.required),
      'fieldId': new FormControl(null, Validators.required),
      'validationId': new FormControl(null, Validators.required),
      'value': new FormControl(null),
      'fields': new FormControl(null)
    })
  }

  updateField(value) {
    console.log(value.value);

    this.fieldValService.getFieldData(value.value,(response) => {
      console.log(response.data);
      this.fields = response.data;
      // this.dataSource = new MatTableDataSource(response.data);
    });

  }

  submitHandler() {

    if (this.myForm.valid) {
      console.log(this.myForm.value);

      if (this.fieldValService.formTypeData.id) {
        this.fieldValService.putData(this.fieldValService.formTypeData.id, this.fieldValService.formTypeData, (data) => {
          console.log(data);

          if (data.responsecode === 200 || data.responsecode === '200') {

            this.fieldValService.getData((response) => {
              console.log(response);
              this.dataSource = new MatTableDataSource(response.data);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.successbox.show();

              this.resetForm();
            })
          } else {
            this.errorbox.text = data.message;
            this.errorbox.show();
          }
        })
      }
      else {
        this.fieldValService.postData(this.fieldValService.formTypeData, (response) => {
          console.log(response);

          if (response.responsecode === 200 || response.responsecode === '200') {

            this.fieldValService.getData((response) => {
              console.log(response);
              this.dataSource = new MatTableDataSource(response.data);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.successbox.show();

              this.resetForm();
            })
          } else {
            this.errorbox.text = response.message;
            this.errorbox.show();
          }
        })
      }
    }
  }

  getProductDetails(id) {
    this.fieldValService.getDataById(id, (response) => {
      console.log(response);
      this.product = response;
    })
  }



  onClick(action, data) {
    console.log(data);
    if (action == 'edit') {
      this.fieldValService.formTypeData.id = data.id;
      this.fieldValService.formTypeData.formId = data.formId;
      this.fieldValService.formTypeData.fieldId = data.fieldId;
      this.fieldValService.formTypeData.validationId = data.validationId;
      this.fieldValService.formTypeData.value = data.value;
      this.fieldValService.formTypeData.fields = data.fields;


    } else {
      this.fieldValService.deleteData(data.id).subscribe((data) => {
        console.log('Deleted');
        this.fieldValService.getData((response) => {
          console.log(response);
          this.dataSource = new MatTableDataSource(response.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        })
      })
    }
    window.scroll(0, 0);

  }
  resetForm() {
    this.fieldValService.formTypeData.id = "";
    this.fieldValService.formTypeData.formId = "";
    this.fieldValService.formTypeData.fieldId = "";
    this.fieldValService.formTypeData.validationId = "";
    this.fieldValService.formTypeData.value = "";
    this.fieldValService.formTypeData.fields = "";
    // this.myForm.reset();
  }

}
